// HeaderSection.js

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faHome } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';

const HeaderSection = ({ handleReturnToPreviousStage }) => {
  const navigate = useNavigate();

  return (
    <div className="text-center my-3">
      <button
        className="harf-izgarasi-button"
        onClick={() => navigate(-1)}
        aria-label="Geri Dön"
      >
        <FontAwesomeIcon icon={faArrowLeft} /> Geri Dön
      </button>
      <button
        className="harf-izgarasi-button"
        onClick={() => navigate('/')}
        aria-label="Ana Sayfa"
      >
        <FontAwesomeIcon icon={faHome} /> Ana Sayfa
      </button>

      <button
        className="harf-izgarasi-button"
        onClick={handleReturnToPreviousStage}
        aria-label="Önceki Aşamaya Dön"
      >
        <FontAwesomeIcon icon={faArrowLeft} /> Önceki Aşamaya Dön
      </button>
    </div>
  );
};

export default HeaderSection;

import React, { useRef, useEffect, useState } from 'react';
import '../styles/rasgeleKelimelerStyle.css';
import ProgressBar from './ProgressBarRandomWords';

/*
Bu versiyonda:
- Tüm metin gösterilir.
- \n ile satırlara böleriz.
- currentLineIndex isimli bir state ekleyip o an hangi satırın vurgulanacağını takip ederiz.
- isPlaying true iken belli bir interval ile currentLineIndex'i artırırız.
- Her satırda ilk ve son kelime .word-highlight ile, o satır .active-line ile vurgulanır.
- CSS animasyonu .active-line için yapılır.
*/

const GameDisplayTamMetin = ({
  isPlaying,
  paragraphsList,
  selectedParagraphIndex,
  message,
  gameOver,
  fontSize,
  totalWords,
  currentWordIndex,
  handlePlayPause,
  handleReset,
  speed
}) => {
  const containerRef = useRef(null);

  const paragraphContent = paragraphsList[selectedParagraphIndex]?.content || '';

  const [currentLineIndex, setCurrentLineIndex] = useState(0);

  const getWordCount = (htmlContent) => {
    if (!htmlContent) return 0;
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = htmlContent;
    const textContent = tempDiv.textContent || tempDiv.innerText || '';
    const words = textContent.trim().split(/\s+/);
    return words.filter(w => w).length;
  };

  const totalW = totalWords || getWordCount(paragraphContent);
  const progress = totalW > 0 ? (currentWordIndex / totalW) * 100 : 0;

  const lines = paragraphContent.split('\n').map(line => line.trim()).filter(l => l.length > 0);

  // Satırdan satıra geçiş süresi:
  // speed ile orantılı olsun. speed arttıkça süre kısalabilir.
  // Örneğin base: 3000 ms / speed
  const lineDuration = Math.max(500, 3000 / speed); 

  useEffect(() => {
    let lineInterval;
    if (isPlaying && !gameOver && lines.length > 0) {
      lineInterval = setInterval(() => {
        setCurrentLineIndex((prev) => {
          const next = prev + 1;
          if (next >= lines.length) {
            // Bütün satırlar tarandı, durdurabiliriz.
            clearInterval(lineInterval);
            return prev;
          }
          return next;
        });
      }, lineDuration);
    } else {
      setCurrentLineIndex(0);
    }

    return () => clearInterval(lineInterval);
  }, [isPlaying, gameOver, lines.length, lineDuration]);

  const highlightLine = (line, isActive) => {
    const words = line.split(/\s+/).filter(w => w.length > 0);
    if (words.length === 0) {
      return line;
    }

    const firstWord = words[0];
    const lastWord = words[words.length - 1];

    return (
      <div className={`line ${isActive ? 'active-line' : ''}`} style={{ marginBottom: '0.5rem', position: 'relative' }}>
        {words.length > 1 ? (
          <>
            <span className="word-highlight">{firstWord}</span>{' '}
            {words.slice(1, words.length - 1).join(' ')}{' '}
            <span className="word-highlight">{lastWord}</span>
          </>
        ) : (
          <span className="word-highlight">{firstWord}</span>
        )}
      </div>
    );
  };

  return (
    <>
      <div className="rasgele-kelimeler-controls">
        <button
          onClick={handlePlayPause}
          disabled={gameOver}
          className="rasgele-kelimeler-button"
          aria-label={isPlaying ? 'Duraklat' : 'Başlat'}
        >
          {isPlaying ? 'Duraklat' : 'Başlat'}
        </button>
        <button
          onClick={handleReset}
          className="rasgele-kelimeler-button"
          aria-label="Yeniden Başlat"
        >
          Yeniden Başlat
        </button>
      </div>

      <ProgressBar progress={progress} />

      <div
        className="rasgele-kelimeler-game-display"
        style={{ fontSize, position: 'relative', overflow: 'auto', whiteSpace: 'pre-wrap' }}
        id="game-display-container"
        ref={containerRef}
      >
        {!paragraphsList[selectedParagraphIndex] ? (
          <div className="rasgele-kelimeler-message">
            Paragraf bulunamadı. Lütfen farklı bir paragraf veya zorluk seviyesi seçin.
          </div>
        ) : (
          <div className="rasgele-kelimeler-content">
            {lines.length === 0 ? (
              <div className="rasgele-kelimeler-message">Bu paragraf boş görünüyor.</div>
            ) : (
              lines.map((line, idx) => (
                <React.Fragment key={idx}>
                  {isPlaying
                    ? highlightLine(line, idx === currentLineIndex)
                    : <div className="line">{line}</div>
                  }
                </React.Fragment>
              ))
            )}
          </div>
        )}
      </div>

      {message && <div className="rasgele-kelimeler-message">{message}</div>}
    </>
  );
};

export default GameDisplayTamMetin;

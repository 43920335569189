import React from 'react';
import { Dialog, DialogTitle, DialogContent, Typography, DialogActions, Button } from '@mui/material';

const ConfirmDeleteDialog = ({
  confirmDeleteDialogOpen,
  setConfirmDeleteDialogOpen,
  handleDeleteOgrenci,
  studentToDelete,
  setStudentToDelete,
}) => {
  return (
    <Dialog
      open={confirmDeleteDialogOpen}
      onClose={() => setConfirmDeleteDialogOpen(false)}
    >
      <DialogTitle>Silme Onayı</DialogTitle>
      <DialogContent>
        <Typography>
          Bu öğrenciyi silmek istediğinizden emin misiniz? Bu işlem geri alınamaz.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setConfirmDeleteDialogOpen(false)} color="secondary">
          İptal
        </Button>
        <Button
          onClick={() => {
            handleDeleteOgrenci(studentToDelete);
            setConfirmDeleteDialogOpen(false);
            setStudentToDelete(null);
          }}
          color="primary"
          variant="contained"
        >
          Sil
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDeleteDialog;

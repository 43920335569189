// SchoolTable.js
import React, { useState } from 'react';
import { Typography, TextField, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper, Chip, ButtonGroup, IconButton, Tooltip, FormControl, InputLabel,
  Select, MenuItem, OutlinedInput, Checkbox, ListItemText, Grid, Button, Menu} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import * as XLSX from 'xlsx'; 

const SchoolTable = ({
  filteredOkulListesi,
  searchQuery,
  setSearchQuery,
  handleEdit,
  handleDelete,
  handleStatusChange,
  handleAdjustBalance,
  filterStatus,
  setFilterStatus,
  filterSehir,
  setFilterSehir,
  filterKurumTuru,
  setFilterKurumTuru,
  sehirListesi,
  okulTurleri,
}) => {

  const [anchorEl, setAnchorEl] = useState(null);

  const handleExportClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleExportClose = () => {
    setAnchorEl(null);
  };

  // CSV İndir
  const exportToCSV = () => {
    if (filteredOkulListesi.length === 0) return;

    const headers = [
      'Sıra No',
      'Ekleyen',
      'Okul Adı',
      'Şifre',
      'Email',
      'Telefon',
      'Şehir',
      'İlçe',
      'Okul Türü',
      'Abonelik Başlangıç',
      'Abonelik Bitiş',
      'Bakiye (Toplam)',
      'Bakiye 3 Ay',
      'Bakiye 6 Ay',
      'Bakiye 12 Ay',
      'Durum'
    ];

    const rows = filteredOkulListesi.map((kurum, index) => {
      const toplamBakiye = (kurum.bakiye3Ay || 0) + (kurum.bakiye6Ay || 0) + (kurum.bakiye12Ay || 0);

      const ekleyen =
        kurum.adminAdi && kurum.adminAdi !== 'Bulunamadı' && kurum.adminAdi !== 'null'
          ? kurum.adminAdi
          : (kurum.kurumAdi && kurum.kurumAdi !== 'Bulunamadı' && kurum.kurumAdi !== 'null'
              ? kurum.kurumAdi
              : '');

      const abonelikBaslangic = kurum.latestSubscription
        ? new Date(kurum.latestSubscription.baslangicTarihi).toLocaleDateString()
        : 'Yok';

      const abonelikBitis = kurum.latestSubscription
        ? new Date(kurum.latestSubscription.bitisTarihi).toLocaleDateString()
        : 'Yok';

      return [
        index + 1,
        ekleyen,
        kurum.okul_adi,
        kurum.password_open || '',
        kurum.email,
        kurum.telefon,
        kurum.sehirAdi || '',
        kurum.ilceAdi || '',
        kurum.okul_turu ? kurum.okul_turu.join(', ') : '',
        abonelikBaslangic,
        abonelikBitis,
        toplamBakiye,
        kurum.bakiye3Ay || 0,
        kurum.bakiye6Ay || 0,
        kurum.bakiye12Ay || 0,
        kurum.status === 'active' ? 'Aktif' : 'Pasif',
      ];
    });

    const csvContent =
      'data:text/csv;charset=utf-8,' +
      [headers.join(','), ...rows.map((e) => e.join(','))].join('\n');

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.href = encodedUri;
    link.download = 'okul_listesi.csv';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    handleExportClose();
  };

  // TXT İndir
  const exportToTXT = () => {
    if (filteredOkulListesi.length === 0) return;

    let txtContent = '';
    txtContent +=
      'Sıra No | Ekleyen | Okul Adı | Şifre | Email | Telefon | Şehir | İlçe | Okul Türü | Abonelik Başlangıç | Abonelik Bitiş | Bakiye(Toplam) | Bakiye 3 Ay | Bakiye 6 Ay | Bakiye 12 Ay | Durum\n';

    filteredOkulListesi.forEach((kurum, index) => {
      const toplamBakiye = (kurum.bakiye3Ay || 0) + (kurum.bakiye6Ay || 0) + (kurum.bakiye12Ay || 0);

      const ekleyen =
        kurum.adminAdi && kurum.adminAdi !== 'Bulunamadı' && kurum.adminAdi !== 'null'
          ? kurum.adminAdi
          : (kurum.kurumAdi && kurum.kurumAdi !== 'Bulunamadı' && kurum.kurumAdi !== 'null'
              ? kurum.kurumAdi
              : '');

      const abonelikBaslangic = kurum.latestSubscription
        ? new Date(kurum.latestSubscription.baslangicTarihi).toLocaleDateString()
        : 'Yok';

      const abonelikBitis = kurum.latestSubscription
        ? new Date(kurum.latestSubscription.bitisTarihi).toLocaleDateString()
        : 'Yok';

      txtContent += `${index + 1} | ${ekleyen} | ${kurum.okul_adi} | ${kurum.password_open || ''} | ${
        kurum.email
      } | ${kurum.telefon} | ${kurum.sehirAdi || ''} | ${kurum.ilceAdi || ''} | ${
        kurum.okul_turu ? kurum.okul_turu.join(', ') : ''
      } | ${abonelikBaslangic} | ${abonelikBitis} | ${toplamBakiye} | ${
        kurum.bakiye3Ay || 0
      } | ${kurum.bakiye6Ay || 0} | ${kurum.bakiye12Ay || 0} | ${
        kurum.status === 'active' ? 'Aktif' : 'Pasif'
      }\n`;
    });

    const blob = new Blob([txtContent], { type: 'text/plain;charset=utf-8' });
    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.download = 'okul_listesi.txt';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    handleExportClose();
  };

  // XLSX İndir (Excel)
  const exportToXLSX = () => {
    if (filteredOkulListesi.length === 0) return;

    const headers = [
      'Sıra No',
      'Ekleyen',
      'Okul Adı',
      'Şifre',
      'Email',
      'Telefon',
      'Şehir',
      'İlçe',
      'Okul Türü',
      'Abonelik Başlangıç',
      'Abonelik Bitiş',
      'Bakiye (Toplam)',
      'Bakiye 3 Ay',
      'Bakiye 6 Ay',
      'Bakiye 12 Ay',
      'Durum'
    ];

    const wsData = [];
    wsData.push(headers);

    filteredOkulListesi.forEach((kurum, index) => {
      const toplamBakiye = (kurum.bakiye3Ay || 0) + (kurum.bakiye6Ay || 0) + (kurum.bakiye12Ay || 0);

      const ekleyen =
        kurum.adminAdi && kurum.adminAdi !== 'Bulunamadı' && kurum.adminAdi !== 'null'
          ? kurum.adminAdi
          : (kurum.kurumAdi && kurum.kurumAdi !== 'Bulunamadı' && kurum.kurumAdi !== 'null'
              ? kurum.kurumAdi
              : '');

      const abonelikBaslangic = kurum.latestSubscription
        ? new Date(kurum.latestSubscription.baslangicTarihi).toLocaleDateString()
        : 'Yok';

      const abonelikBitis = kurum.latestSubscription
        ? new Date(kurum.latestSubscription.bitisTarihi).toLocaleDateString()
        : 'Yok';

      const row = [
        index + 1,
        ekleyen,
        kurum.okul_adi,
        kurum.password_open || '',
        kurum.email,
        kurum.telefon,
        kurum.sehirAdi || '',
        kurum.ilceAdi || '',
        kurum.okul_turu ? kurum.okul_turu.join(', ') : '',
        abonelikBaslangic,
        abonelikBitis,
        toplamBakiye,
        kurum.bakiye3Ay || 0,
        kurum.bakiye6Ay || 0,
        kurum.bakiye12Ay || 0,
        kurum.status === 'active' ? 'Aktif' : 'Pasif',
      ];

      wsData.push(row);
    });

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.aoa_to_sheet(wsData);
    XLSX.utils.book_append_sheet(wb, ws, 'Okul Listesi');

    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([wbout], { type: 'application/octet-stream' });

    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'okul_listesi.xlsx';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    handleExportClose();
  };

  return (
    <>
      <Typography variant="h5" component="h3" marginBottom={2}>
        Kayıtlı Kurum Listesi
      </Typography>
      <TextField
        fullWidth
        label="Ara..."
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        margin="normal"
      />

      {/* Filtreleme Bileşenleri */}
      <Grid container spacing={2} sx={{ mb: 2 }}>
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth variant="outlined">
            <InputLabel id="status-filter-label">Durum</InputLabel>
            <Select
              labelId="status-filter-label"
              id="status-filter"
              value={filterStatus}
              onChange={(e) => setFilterStatus(e.target.value)}
              label="Durum"
            >
              <MenuItem value="">
                <em>Tümü</em>
              </MenuItem>
              <MenuItem value="active">Aktif</MenuItem>
              <MenuItem value="inactive">Pasif</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={4}>
          <FormControl fullWidth variant="outlined">
            <InputLabel id="sehir-filter-label">Şehir</InputLabel>
            <Select
              labelId="sehir-filter-label"
              id="sehir-filter"
              value={filterSehir}
              onChange={(e) => setFilterSehir(e.target.value)}
              label="Şehir"
            >
              <MenuItem value="">
                <em>Tümü</em>
              </MenuItem>
              {sehirListesi.map((sehir) => (
                <MenuItem key={sehir._id} value={sehir._id}>
                  {sehir.sehir_adi}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={4}>
          <FormControl fullWidth variant="outlined">
            <InputLabel id="kurum-turu-filter-label">Okul Türü</InputLabel>
            <Select
              labelId="kurum-turu-filter-label"
              id="kurum-turu-filter"
              multiple
              value={filterKurumTuru}
              onChange={(e) => setFilterKurumTuru(e.target.value)}
              input={<OutlinedInput label="Okul Türü" />}
              renderValue={(selected) =>
                selected
                  .map((value) => okulTurleri.find((kt) => kt.id === value)?.adi)
                  .filter(Boolean)
                  .join(', ')
              }
            >
              {okulTurleri.map((turu) => (
                <MenuItem key={turu.id} value={turu.id}>
                  <Checkbox checked={filterKurumTuru.indexOf(turu.id) > -1} />
                  <ListItemText primary={turu.adi} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      {/* Filtreleri Temizle ve Dışa Aktar Butonları */}
      <Grid container spacing={2} sx={{ mb: 2 }}>
        <Grid item>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => {
              setFilterStatus('');
              setFilterSehir('');
              setFilterKurumTuru([]);
            }}
            sx={{ mr: 2 }}
          >
            Filtreleri Temizle
          </Button>

          <Button variant="outlined" onClick={handleExportClick}>
            Dışa Aktar
          </Button>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleExportClose}
          >
            <MenuItem onClick={exportToCSV}>CSV Olarak İndir</MenuItem>
            <MenuItem onClick={exportToTXT}>TXT Olarak İndir</MenuItem>
            <MenuItem onClick={exportToXLSX}>Excel (XLSX) Olarak İndir</MenuItem>
          </Menu>
        </Grid>
      </Grid>

      <TableContainer component={Paper}>
        <Table aria-label="okul listesi">
          <TableHead>
            <TableRow
              sx={{
                backgroundColor: 'primary.main',
              }}
            >
              <TableCell>
                <Typography variant="subtitle1" sx={{ color: 'white', fontWeight: 'bold' }}>
                  Sıra No
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle1" sx={{ color: 'white', fontWeight: 'bold' }}>
                  Ekleyen
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle1" sx={{ color: 'white', fontWeight: 'bold' }}>
                  Kurum Adı
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Typography variant="subtitle1" sx={{ color: 'white', fontWeight: 'bold' }}>
                  Şifre
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Typography variant="subtitle1" sx={{ color: 'white', fontWeight: 'bold' }}>
                  Email
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Typography variant="subtitle1" sx={{ color: 'white', fontWeight: 'bold' }}>
                  Telefon
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Typography variant="subtitle1" sx={{ color: 'white', fontWeight: 'bold' }}>
                  Şehir
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Typography variant="subtitle1" sx={{ color: 'white', fontWeight: 'bold' }}>
                  İlçe
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Typography variant="subtitle1" sx={{ color: 'white', fontWeight: 'bold' }}>
                  Okul Türü
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Typography variant="subtitle1" sx={{ color: 'white', fontWeight: 'bold' }}>
                  Abonelik Başlangıç
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Typography variant="subtitle1" sx={{ color: 'white', fontWeight: 'bold' }}>
                  Abonelik Bitiş
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Typography variant="subtitle1" sx={{ color: 'white', fontWeight: 'bold' }}>
                  Bakiye (Toplam)
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Typography variant="subtitle1" sx={{ color: 'white', fontWeight: 'bold' }}>
                  Bakiye 3 Ay
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Typography variant="subtitle1" sx={{ color: 'white', fontWeight: 'bold' }}>
                  Bakiye 6 Ay
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Typography variant="subtitle1" sx={{ color: 'white', fontWeight: 'bold' }}>
                  Bakiye 12 Ay
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Typography variant="subtitle1" sx={{ color: 'white', fontWeight: 'bold' }}>
                  Durum
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Typography variant="subtitle1" sx={{ color: 'white', fontWeight: 'bold' }}>
                  İşlem
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredOkulListesi.map((kurum, index) => {
              const toplamBakiye =
                (kurum.bakiye3Ay || 0) + (kurum.bakiye6Ay || 0) + (kurum.bakiye12Ay || 0);

              const ekleyen =
                kurum.adminAdi && kurum.adminAdi !== 'Bulunamadı' && kurum.adminAdi !== 'null'
                  ? kurum.adminAdi
                  : kurum.kurumAdi && kurum.kurumAdi !== 'Bulunamadı' && kurum.kurumAdi !== 'null'
                  ? kurum.kurumAdi
                  : '';

              return (
                <TableRow key={kurum._id}>
                  <TableCell component="th" scope="row">
                    {index + 1}
                  </TableCell>
                  <TableCell>{ekleyen}</TableCell>
                  <TableCell>{kurum.okul_adi}</TableCell>
                  <TableCell align="right">{kurum.password_open}</TableCell>
                  <TableCell align="right">{kurum.email}</TableCell>
                  <TableCell align="right">{kurum.telefon}</TableCell>
                  <TableCell align="right">{kurum.sehirAdi || ''}</TableCell>
                  <TableCell align="right">{kurum.ilceAdi || ''}</TableCell>
                  <TableCell align="right">
                    {kurum.okul_turu ? kurum.okul_turu.join(', ') : ''}
                  </TableCell>
                  <TableCell align="right">
                    {kurum.latestSubscription
                      ? new Date(kurum.latestSubscription.baslangicTarihi).toLocaleDateString()
                      : 'Yok'}
                  </TableCell>
                  <TableCell align="right">
                    {kurum.latestSubscription
                      ? new Date(kurum.latestSubscription.bitisTarihi).toLocaleDateString()
                      : 'Yok'}
                  </TableCell>
                  <TableCell align="right">{toplamBakiye}</TableCell>
                  <TableCell align="right">{kurum.bakiye3Ay || 0}</TableCell>
                  <TableCell align="right">{kurum.bakiye6Ay || 0}</TableCell>
                  <TableCell align="right">{kurum.bakiye12Ay || 0}</TableCell>
                  <TableCell align="right">
                    <Chip
                      label={kurum.status === 'active' ? 'Aktif' : 'Pasif'}
                      color={kurum.status === 'active' ? 'success' : 'error'}
                      onClick={() => handleStatusChange(kurum._id, kurum.status)}
                      sx={{
                        cursor: 'pointer',
                        '&:hover': {
                          backgroundColor: 'lightgray',
                        },
                      }}
                    />
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={{
                      minWidth: 200,
                      display: 'flex',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <ButtonGroup variant="contained" aria-label="outlined primary button group">
                      <Tooltip title="Bakiye Ayarla" arrow>
                        <IconButton
                          onClick={() => handleAdjustBalance(kurum)}
                          color="success"
                          size="medium"
                          sx={{
                            backgroundColor: '#4caf50',
                            color: '#fff',
                            '&:hover': {
                              backgroundColor: '#388e3c',
                              transform: 'scale(1.1)',
                              transition: 'transform 0.2s',
                            },
                            boxShadow: 'none',
                            borderRadius: '8px',
                            padding: '8px',
                          }}
                        >
                          <AttachMoneyIcon fontSize="medium" />
                        </IconButton>
                      </Tooltip>

                      <Tooltip title="Düzenle" arrow>
                        <IconButton
                          onClick={() => handleEdit(kurum._id)}
                          color="primary"
                          size="medium"
                          sx={{
                            backgroundColor: '#1976d2',
                            color: '#fff',
                            '&:hover': {
                              backgroundColor: '#1565c0',
                              transform: 'scale(1.1)',
                              transition: 'transform 0.2s',
                            },
                            boxShadow: 'none',
                            borderRadius: '8px',
                            padding: '8px',
                          }}
                        >
                          <EditIcon fontSize="medium" />
                        </IconButton>
                      </Tooltip>

                      <Tooltip title="Sil" arrow>
                        <IconButton
                          onClick={() => handleDelete(kurum._id)}
                          color="secondary"
                          size="medium"
                          sx={{
                            backgroundColor: '#f44336',
                            color: '#fff',
                            '&:hover': {
                              backgroundColor: '#d32f2f',
                              transform: 'scale(1.1)',
                              transition: 'transform 0.2s',
                            },
                            boxShadow: 'none',
                            borderRadius: '8px',
                            padding: '8px',
                          }}
                        >
                          <DeleteIcon fontSize="medium" />
                        </IconButton>
                      </Tooltip>
                    </ButtonGroup>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default SchoolTable;

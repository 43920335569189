// GameControls.js

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlay,
  faPause,
  faRedo,
  faMinus,
  faPlus,
} from '@fortawesome/free-solid-svg-icons';
import '../styles/nesneyiTakipEt.css';

export default function GameControls({
  speed,
  setSpeed,
  isPlaying,
  setIsPlaying,
  resetGame,
  shapeSides,
  increaseSides,
  decreaseSides,
}) {
  const handleDecreaseSpeed = () => {
    setSpeed(Math.max(speed - 10, 50));
  };

  const handleIncreaseSpeed = () => {
    setSpeed(speed + 10);
  };

  return (
    <div className="nesneyi-takip-et-game-controls">

      {/* Hız Kontrolleri */}
      <button onClick={handleIncreaseSpeed}>
        <FontAwesomeIcon icon={faMinus} /> Hızı Azalt
      </button>      
      <span>Hız: {speed}ms</span>
      <button onClick={handleDecreaseSpeed}>
        <FontAwesomeIcon icon={faPlus} /> Hızı Artır
      </button>
    
      {/* Köşe Sayısı Kontrolleri */}
      <button onClick={decreaseSides}>
        <FontAwesomeIcon icon={faMinus} /> Köşe Azalt
      </button>
      <span>Köşe Sayısı: {shapeSides}</span>
      <button onClick={increaseSides}>
        <FontAwesomeIcon icon={faPlus} /> Köşe Artır
      </button>

      {/* Başlat/Duraklat ve Yeniden Başlat */}
      <button onClick={() => setIsPlaying(!isPlaying)}>
        <FontAwesomeIcon icon={isPlaying ? faPause : faPlay} />{' '}
        {isPlaying ? 'Duraklat' : 'Başlat'}
      </button>
      <button onClick={resetGame}>
        <FontAwesomeIcon icon={faRedo} /> Yeniden Başlat
      </button>
    </div>
  );
}

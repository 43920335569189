import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faTimes } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContainer = styled.div`
  background: #1e1e2f;
  color: #ffffff;
  padding: 2.5rem;
  border-radius: 12px;
  width: 90%;
  max-width: 450px;
  box-shadow: 0 8px 16px rgba(0,0,0,0.25);
  position: relative;
  text-align: center;
  animation: ${fadeIn} 0.3s ease-out;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: transparent;
  border: none;
  color: #ffffff;
  font-size: 1.5rem;
  cursor: pointer;

  &:hover {
    color: #ff4d4f;
  }
`;

const Title = styled.h2`
  margin-bottom: 1.5rem;
  font-size: 2rem;
  color: #ff6347;
`;

const Score = styled.p`
  font-size: 1.5rem;
  margin-bottom: 1rem;
`;

const Time = styled.p`
  font-size: 1.2rem;
  margin-bottom: 1rem;
`;

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const Button = styled.button`
  padding: 0.75rem;
  font-size: 1rem;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.2s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;

  &.primary {
    background-color: #ff6347;
    color: #ffffff;

    &:hover {
      background-color: #ff4500;
    }
  }

  &.secondary {
    background-color: #6c757d;
    color: #ffffff;

    &:hover {
      background-color: #5a6268;
    }
  }

  &.outline {
    background-color: transparent;
    border: 2px solid #ff6347;
    color: #ff6347;

    &:hover {
      background-color: #ff6347;
      color: #ffffff;
    }
  }
`;

const GameOverModal = ({ score, elapsedTime, onRestart, userData, onClose }) => {
  const navigate = useNavigate();

  const handleLoginRedirect = () => {
    navigate('/register');
  };

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };

    window.addEventListener('keydown', handleEsc);

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, [onClose]);

  return (
    <Backdrop>
      <ModalContainer>
        <CloseButton onClick={onClose}>
          <FontAwesomeIcon icon={faTimes} />
        </CloseButton>
        <Title>Çalışma Bitti!</Title>

        {userData ? (
          <>
            <Score>Toplam Kelime: {score}</Score>
            <Time>Geçen Süre: {elapsedTime} saniye</Time>
            <ButtonGroup>
              <Button className="outline" onClick={onRestart}>Yeniden Oyna</Button>
              <Button className="secondary" onClick={() => navigate(-1)}>
                <FontAwesomeIcon icon={faArrowLeft} /> Geri Dön
              </Button>
            </ButtonGroup>
          </>
        ) : (
          <>
            <Score>Giriş yapmadığınız için skoru göremiyorsunuz.</Score>
            <ButtonGroup>
              <Button className="primary" onClick={handleLoginRedirect}>
                Kayıt Ol
              </Button>
              <Button className="secondary" onClick={() => navigate(-1)}>
                <FontAwesomeIcon icon={faArrowLeft} /> Geri Dön
              </Button>
            </ButtonGroup>
          </>
        )}
      </ModalContainer>
    </Backdrop>
  );
};

export default GameOverModal;

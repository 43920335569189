import React, { useState, useEffect, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate, useLocation } from 'react-router-dom';
import GameOverModal from './components/GameOverModal';
import GameHeader from './components/GameHeader';
import { API_URLS } from '../../../config/config';
import './styles/hangiSayiGordun.css';
import axiosInstance from '../../../utils/axiosInstance'; 
import axios from 'axios';

function HangiSayiGordun() {
  const navigate = useNavigate();
  const location = useLocation();
  const { stage, exercise } = location.state || {};

  const [difficulty, setDifficulty] = useState('kolay');
  const [numbers, setNumbers] = useState([]);
  const [input, setInput] = useState('');
  const [score, setScore] = useState(0);
  const [gameActive, setGameActive] = useState(false);
  const [timeLeft, setTimeLeft] = useState(15);
  const [showNumbers, setShowNumbers] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showIntroduction, setShowIntroduction] = useState(true);
  const canvasRef = useRef(null);
  const inputRef = useRef(null);
  const [attempts, setAttempts] = useState([]);

  const [userData, setUserData] = useState(null);
  const [userRole, setUserRole] = useState(null);

  const [displayDuration, setDisplayDuration] = useState(1000);

  // Kullanıcı verilerini al
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const studentResponse = await axiosInstance.get(`${API_URLS.STUDENT_URL}/me`);
        if (
          studentResponse.status === 200 &&
          studentResponse.data &&
          studentResponse.data.status === 'active'
        ) {
          const studentData = studentResponse.data;
          setUserData(studentData);
          setUserRole('student');
          return;
        } else {
          setUserData(null);
          setUserRole(null);
        }
      } catch (error) {
        console.error('Öğrenci verisi alınırken hata oluştu:', error);
      }

      // Öğrenci yoksa öğretmen verisine bak
      try {
        const token = localStorage.getItem('userToken');
        const teacherResponse = await fetch(`${API_URLS.TEACHER_URL}/me`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        if (teacherResponse.ok) {
          const teacherData = await teacherResponse.json();
          setUserData(teacherData);
          setUserRole('teacher');
          return;
        }
      } catch (error) {
        console.error('Öğretmen verisi alınırken hata oluştu:', error);
      }

      setUserData(null);
      setUserRole(null);
    };

    fetchUserData();
  }, []);

  // userData geldikten sonra totalTime'ı güncelle
  useEffect(() => {
    if (userData && userData.status === 'active') {
      setTimeLeft(40);
    } else {
      setTimeLeft(15);
    }
  }, [userData]);

  useEffect(() => {
    if (gameActive && timeLeft > 0) {
      const timer = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
      return () => clearTimeout(timer);
    } else if (timeLeft === 0) {
      onGameOver();
    }
  }, [timeLeft, gameActive]);

  useEffect(() => {
    if (gameActive && showNumbers) {
      drawCanvas();
    } else {
      clearCanvas();
    }
  }, [gameActive, showNumbers, numbers]);

  useEffect(() => {
    if (gameActive) {
      generateNumbers();
    }
  }, [gameActive]);

  useEffect(() => {
    if (gameActive && !showNumbers && inputRef.current) {
      inputRef.current.focus();
    }
  }, [gameActive, showNumbers]);

  const enterGame = () => {
    setShowIntroduction(false);
  };

  const startGame = () => {
    setScore(0);
    const initialTime = userData && userData.status === 'active' ? 40 : 15;
    setTimeLeft(initialTime);
    setGameActive(true);
    setAttempts([]);
  };

  const stopGame = () => {
    setGameActive(false);
  };

  const resetGame = () => {
    clearCanvas();
    setGameActive(false);
    setScore(0);
    const initialTime = userData && userData.status === 'active' ? 40 : 15;
    setTimeLeft(initialTime);
    setNumbers([]);
    setInput('');
    setShowNumbers(false);
    setShowModal(false);
    setShowIntroduction(false);
    setAttempts([]);
  };

  const onGameOver = () => {
    setGameActive(false);
    setShowModal(true);
    updateProgress();
  };

  const generateNumbers = () => {
    if (!canvasRef.current) return;

    const totalTime = userData && userData.status === 'active' ? 40 : 15;

    let numCount = 2;
    switch (difficulty) {
      case 'kolay':
        numCount = 2;
        break;
      case 'orta':
        numCount = 3;
        break;
      case 'zor':
        numCount = Math.floor(Math.random() * 2) + 4;
        break;
      default:
        numCount = 2;
    }

    const canvas = canvasRef.current;
    const centerX = canvas.width / 2;
    const centerY = canvas.height / 2;

    const maxRadius = canvas.width / 2 - 50;
    const minRadius = 50;
    const elapsed = totalTime - timeLeft;
    const baseRadius = minRadius + ((maxRadius - minRadius) * elapsed) / totalTime;

    const numsArray = [];
    const positions = [];

    let directions = [];

    if (numCount === 2) {
      directions = [1, -1];
    } else {
      directions = [1, -1];
      for (let i = 2; i < numCount; i++) {
        directions.push(Math.random() < 0.5 ? -1 : 1);
      }
      directions = shuffleArray(directions);
    }

    for (let i = 0; i < numCount; i++) {
      let direction = directions[i];
      const radius = baseRadius + i * 30;

      const x = centerX + direction * radius;
      const y = centerY;

      const overlap = positions.some((pos) => {
        const dx = pos.x - x;
        const distance = Math.abs(dx);
        return distance < 30;
      });

      if (!overlap) {
        positions.push({ x });
        const randomNumber = Math.floor(Math.random() * 9) + 1;
        numsArray.push({ value: randomNumber, x, y });
      } else {
        i--;
      }
    }

    setNumbers(numsArray);
    setShowNumbers(true);

    setTimeout(() => {
      setShowNumbers(false);
      clearCanvas();
    }, displayDuration);
  };

  const shuffleArray = (array) => {
    const shuffled = [...array];
    for (let i = shuffled.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
    }
    return shuffled;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let userInput = input.replace(/\D/g, '').split('').map(Number);
    let correctNumbers = numbers.map((numObj) => numObj.value);

    if (userInput.some((num) => isNaN(num))) {
      alert('Lütfen geçerli sayılar girin.');
      return;
    }

    userInput.sort((a, b) => a - b);
    correctNumbers.sort((a, b) => a - b);

    const isCorrect =
      userInput.length === correctNumbers.length &&
      userInput.every((num, idx) => num === correctNumbers[idx]);

    setAttempts([
      ...attempts,
      {
        correctNumbers: [...correctNumbers],
        userInput: [...userInput],
        isCorrect,
      },
    ]);

    if (isCorrect) {
      setScore(score + 1);
      setInput('');
      generateNumbers();
    } else {
      alert('Yanlış cevap!');
      setInput('');
      generateNumbers();
    }
  };

  const drawCanvas = () => {
    const canvas = canvasRef.current;
    if (!canvas) return;
    const ctx = canvas.getContext('2d');
    const centerX = canvas.width / 2;
    const centerY = canvas.height / 2;
    clearCanvas();

    ctx.beginPath();
    ctx.arc(centerX, centerY, 5, 0, 2 * Math.PI);
    ctx.fillStyle = '#888';
    ctx.fill();

    numbers.forEach((numObj) => {
      const x = numObj.x;
      const y = numObj.y;

      ctx.fillStyle = '#ffcc00';
      ctx.font = 'bold 40px Arial';
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.fillText(numObj.value, x, y);
    });
  };

  const clearCanvas = () => {
    const canvas = canvasRef.current;
    if (canvas) {
      const ctx = canvas.getContext('2d');
      ctx.clearRect(0, 0, canvas.width, canvas.height);
    }
  };

  const handleReturnToPreviousStage = () => {
    if (stage) {
      navigate(
        userRole === 'student' ? '/ogrenci-dashboard' : '/ogretmen-dashboard',
        {
          state: {
            selectedMenu: 'ExerciseList',
            selectedStage: stage,
          },
        }
      );
    } else {
      alert('Aşama bilgisi bulunmamaktadır.');
    }
  };

  const updateProgress = async () => {
    if (!userData || !userRole) return;
    const gameTime = (userData && userData.status === 'active') ? 40 - timeLeft : 15 - timeLeft;

    try {
      const apiUrl =
        userRole === 'student'
          ? `${API_URLS.STUDENT_URL}akillikurs/progress/update-game`
          : `${API_URLS.TEACHER_URL}akillikurs/progress/update-game`;

      if (userRole === 'student') {
        // Öğrenci: Token cookie üzerinden otomatik gider, axiosInstance kullan
        await axiosInstance.post(apiUrl, {
          stageNumber: stage?.number || 1,
          gameName: exercise?.gameName || 'Hangi Sayıyı Gördün?',
          score: score,
          time: gameTime,
          completed: true,
        });
      } else {
        // Öğretmen: LocalStorage token ile Authorization header ekle
        const teacherToken = localStorage.getItem('userToken');
        await axios.post(
          apiUrl,
          {
            stageNumber: stage?.number || 1,
            gameName: exercise?.gameName || 'Hangi Sayıyı Gördün?',
            score: score,
            time: gameTime,
            completed: true,
          },
          {
            headers: {
              Authorization: `Bearer ${teacherToken}`,
              'Content-Type': 'application/json',
            },
          }
        );
      }
    } catch (error) {
      console.error('İlerleme güncellenirken hata oluştu:', error);
    }
  };

  const handleDifficultyChange = (e) => {
    const newDifficulty = e.target.value;
    setDifficulty(newDifficulty);
    if (gameActive) {
      resetGame();
    }
  };

  const handleDisplayDurationChange = (e) => {
    const newDuration = Number(e.target.value);
    setDisplayDuration(newDuration);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  return (
    <div className="hangi-sayi-gordun-container">
      <GameHeader handleReturnToPreviousStage={handleReturnToPreviousStage} />
      {showIntroduction ? (
        <div className="hangi-sayi-gordun-intro">
          <div className="hangi-sayi-gordun-card">
            <div className="hangi-sayi-gordun-card-body">
              <h1 className="hangi-sayi-gordun-title">Hangi Sayıyı Gördün?</h1>
              <p className="hangi-sayi-gordun-description">
                Bu egzersizde, ekranda kısa bir süreliğine gösterilen sayıları hatırlayıp doğru
                şekilde girmeye çalışacaksınız.
              </p>
              <div className="hangi-sayi-gordun-instructions">
                <h3>Talimatlar</h3>
                <ul>
                  <li>Sayılar ekranda belirecek, dikkatlice izleyin.</li>
                  <li>Sayılar kaybolduktan sonra gördüğünüz sayıları girin.</li>
                  <li>Zorluk seviyesini seçerek çalışmayı özelleştirebilirsiniz.</li>
                </ul>
              </div>
              <button
                onClick={enterGame}
                className="hangi-sayi-gordun-button hangi-sayi-gordun-start-button"
              >
               BAŞLAT
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div className="hangi-sayi-gordun-gameplay">
          <div className="hangi-sayi-gordun-card">
            <div className="hangi-sayi-gordun-card-body">
              <div className="hangi-sayi-gordun-settings">
                <div className="hangi-sayi-gordun-setting">
                  <label htmlFor="difficultySelect">Zorluk Seviyesi:</label>
                  <select
                    id="difficultySelect"
                    value={difficulty}
                    onChange={handleDifficultyChange}
                  >
                    <option value="kolay">Kolay</option>
                    <option value="orta">Orta</option>
                    <option value="zor">Zor</option>
                  </select>
                </div>
                <div className="hangi-sayi-gordun-setting">
                  <label htmlFor="displayDurationRange">Gösterim Süresi:</label>
                  <input
                    type="range"
                    id="displayDurationRange"
                    min="500"
                    max="5000"
                    step="500"
                    value={displayDuration}
                    onChange={handleDisplayDurationChange}
                  />
                  <span>{displayDuration} ms</span>
                </div>
              </div>
              <div className="hangi-sayi-gordun-buttons">
                {gameActive ? (
                  <>
                    <button onClick={stopGame} className="hangi-sayi-gordun-button">
                      Durdur
                    </button>
                    <button onClick={resetGame} className="hangi-sayi-gordun-button">
                      Yeniden Başlat
                    </button>
                  </>
                ) : (
                  <>
                    <button onClick={startGame} className="hangi-sayi-gordun-button">
                      Başlat
                    </button>
                    <button onClick={resetGame} className="hangi-sayi-gordun-button">
                      Yeniden Başlat
                    </button>
                  </>
                )}
              </div>
              <div className="hangi-sayi-gordun-status">
                <p>Kalan Süre: {timeLeft} saniye</p>
                <p>Skor: {score}</p>
              </div>
              <div className="hangi-sayi-gordun-canvas-container">
                <canvas
                  ref={canvasRef}
                  width={400}
                  height={400}
                  className="hangi-sayi-gordun-canvas"
                  style={{ display: gameActive ? 'block' : 'none' }}
                />
                {gameActive && !showNumbers && (
                  <form onSubmit={handleSubmit} className="hangi-sayi-gordun-input-form">
                    <input
                      ref={inputRef}
                      type="text"
                      value={input}
                      onChange={(e) => setInput(e.target.value)}
                      placeholder="Gördüğün sayıları gir (rakamları peş peşe yazın)"
                    />
                    <button type="submit" className="hangi-sayi-gordun-button">
                      Gönder
                    </button>
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {showModal && (
        <GameOverModal
          score={score}
          onRestart={resetGame}
          userData={userData}
          onClose={handleClose}
          attempts={attempts}
        />
      )}
    </div>
  );
}

export default HangiSayiGordun;

// gameLogic.js

import { useRef, useEffect, useState, useCallback } from 'react';

export default function useGameLogic(userData, onGameOver) {
  const canvasRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [position, setPosition] = useState(0);
  const [speed, setSpeed] = useState(0.5); // Başlangıç hızını 0.5'e düşürdük
  const [orientation, setOrientation] = useState('horizontal');
  const [canvasSize, setCanvasSize] = useState({ width: 800, height: 600 });
  const [score, setScore] = useState(0);

  // Kullanıcının durumuna göre oyun süresini ayarla
  const [timeRemaining, setTimeRemaining] = useState(
    userData ? (userData.status === 'inactive' ? 15 : 60) : 15
  );

  const [difficulty, setDifficulty] = useState('seciniz');
  const [showModal, setShowModal] = useState(false);

  const updateCanvasSize = useCallback(() => {
    const container = document.querySelector('.zigzag-canvas-container');
    if (container) {
      const maxWidth = window.innerWidth * 0.9; // Ekran genişliğinin %90'ını kullan
      const maxHeight = window.innerHeight * 0.7; // Ekran yüksekliğinin %70'ini kullan

      let width = maxWidth;
      let height =
        orientation === 'horizontal' ? width * 0.75 : width * 1.33;

      // Yükseklik maksimum yüksekliği aşıyorsa, boyutları orantılı olarak azalt
      if (height > maxHeight) {
        const scale = maxHeight / height;
        width *= scale;
        height *= scale;
      }

      setCanvasSize({ width, height });
    }
  }, [orientation]);

  useEffect(() => {
    window.addEventListener('resize', updateCanvasSize);
    updateCanvasSize();
    return () => window.removeEventListener('resize', updateCanvasSize);
  }, [updateCanvasSize]);

  const draw = useCallback(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;
  
    const ctx = canvas.getContext('2d');
    const { width, height } = canvasSize;
    
    // Kenarlardan biraz boşluk bırakmak için amplitude hesabına margin ekliyoruz
    const margin = 20; // Bu değeri ihtiyaç duyduğunuz görünüme göre değiştirebilirsiniz
    const amplitude = orientation === 'horizontal' 
      ? (height / 2) - margin 
      : (width / 2) - margin;
  
    const wavelength = 200;
  
    ctx.clearRect(0, 0, width, height);
  
    ctx.fillStyle = '#1a1a1a';
    ctx.fillRect(0, 0, width, height);
  
    ctx.beginPath();
    ctx.lineWidth = 3;
    ctx.strokeStyle = 'rgba(255, 102, 0, 0.7)';
  
    if (orientation === 'horizontal') {
      ctx.moveTo(0, height / 2);
      for (let x = 0; x < width; x += 1) {
        ctx.lineTo(
          x,
          height / 2 + Math.sin((x / wavelength) * 2 * Math.PI) * amplitude
        );
      }
    } else {
      const shift = (width - amplitude * 2) / 2; 
      for (let y = 0; y < height; y++) {
        const x =
          shift +
          amplitude +
          amplitude * Math.sin((y / wavelength) * 2 * Math.PI);
        ctx.lineTo(x, y);
      }
    }
    ctx.stroke();
  
    const posY =
      orientation === 'horizontal'
        ? height / 2 +
          Math.sin((position / wavelength) * 2 * Math.PI) * amplitude
        : position;
    const posX =
      orientation === 'horizontal'
        ? position
        : (width - amplitude * 2) / 2 +
          amplitude +
          amplitude * Math.sin((position / wavelength) * 2 * Math.PI);
  
    ctx.beginPath();
    ctx.arc(posX, posY, 20, 0, 2 * Math.PI);
    ctx.fillStyle = '#ffcc00';
    ctx.fill();
  
    ctx.strokeStyle = 'rgba(255, 255, 255, 0.7)';
    ctx.stroke();
  }, [canvasSize, orientation, position]);
  
  

  useEffect(() => {
    let animationFrameId;

    const gameLoop = () => {
      if (isPlaying) {
        setPosition((prevPosition) => {
          const maxPosition =
            orientation === 'horizontal' ? canvasSize.width : canvasSize.height;
          return (prevPosition + speed) % maxPosition;
        });
        setScore((prevScore) => prevScore + speed / 100);
      }
      draw();
      animationFrameId = requestAnimationFrame(gameLoop);
    };

    gameLoop();

    return () => cancelAnimationFrame(animationFrameId);
  }, [isPlaying, speed, orientation, canvasSize, draw]);

  useEffect(() => {
    let timer;
    if (isPlaying && timeRemaining > 0) {
      timer = setInterval(() => {
        setTimeRemaining((prevTime) => prevTime - 1);
      }, 1000);
    } else if (timeRemaining === 0 && isPlaying) {
      setIsPlaying(false);
      setShowModal(true);
      if (onGameOver) {
        onGameOver();
      }
    }
    return () => clearInterval(timer);
  }, [isPlaying, timeRemaining, onGameOver]);

  const resetGame = () => {
    setIsPlaying(false);
    setScore(0);
    setTimeRemaining(
      userData ? (userData.status === 'inactive' ? 15 : 60) : 15
    );
    setPosition(0);
    setSpeed(0.5); // Hızı varsayılan değere sıfırladık
    setDifficulty('seciniz'); // Zorluk seviyesini sıfırladık
    setShowModal(false);
  };

  const handleDifficultyChange = (event) => {
    const newDifficulty = event.target.value;
    setDifficulty(newDifficulty);
    switch (newDifficulty) {
      case 'easy':
        setSpeed(0.5); // Kolay seviye için hızı 0.5 yaptık
        break;
      case 'normal':
        setSpeed(1); // Normal seviye için hızı 1 yaptık
        break;
      case 'hard':
        setSpeed(1.5); // Zor seviye için hızı 1.5 yaptık
        break;
      default:
        setSpeed(0.5); // Varsayılan hız 0.5
    }
  };

  return {
    canvasRef,
    isPlaying,
    setIsPlaying,
    position,
    setPosition,
    speed,
    setSpeed,
    orientation,
    setOrientation,
    canvasSize,
    setCanvasSize,
    score,
    setScore,
    timeRemaining,
    setTimeRemaining,
    difficulty,
    setDifficulty,
    updateCanvasSize,
    draw,
    resetGame,
    handleDifficultyChange,
    showModal,
    setShowModal,
  };
}

import React, { useState, useEffect } from 'react';
import { Paper, Grid } from '@mui/material';
import { API_URLS } from '../../../config/config';
import ResultDialog from '../../../pages/components/Auth/Dialog';

// Yeni oluşturulan bileşenleri import ediyoruz
import RolesTable from './components/RolesTable';
import PermissionsTable from './components/PermissionsTable';
import ResourcesTable from './components/ResourcesTable';
import AccessControlsTable from './components/AccessControlsTable';
import RoleDialog from './components/RoleDialog';
import PermissionDialog from './components/PermissionDialog';
import ResourceDialog from './components/ResourceDialog';
import AccessControlDialog from './components/AccessControlDialog';

const RoleAndPermissionManagement = () => {
  // Durum değişkenleri
  const [roles, setRoles] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [resources, setResources] = useState([]);
  const [accessControls, setAccessControls] = useState([]);

  // Dialog açık/kapalı durumları
  const [roleDialogOpen, setRoleDialogOpen] = useState(false);
  const [permissionDialogOpen, setPermissionDialogOpen] = useState(false);
  const [resourceDialogOpen, setResourceDialogOpen] = useState(false);
  const [accessControlDialogOpen, setAccessControlDialogOpen] = useState(false);

  // Diğer durumlar
  const [resultDialogOpen, setResultDialogOpen] = useState(false);
  const [resultDialogMessage, setResultDialogMessage] = useState('');
  const [resultDialogSeverity, setResultDialogSeverity] = useState('info');
  const [refetch, setRefetch] = useState(false);

  // Düzenleme için kullanılan durumlar
  const [editingRole, setEditingRole] = useState(null);
  const [editingPermission, setEditingPermission] = useState(null);
  const [editingResource, setEditingResource] = useState(null);
  const [editingAccessControl, setEditingAccessControl] = useState(null);

  const triggerRefetch = () => setRefetch(!refetch);

  const showResultDialog = (message, severity) => {
    setResultDialogMessage(message);
    setResultDialogSeverity(severity);
    setResultDialogOpen(true);
  };

  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const response = await fetch(`${API_URLS.ADMIN_URL}roles`);
        if (!response.ok) {
          throw new Error('Roller yüklenirken bir hata oluştu');
        }
        const data = await response.json();
        setRoles(data.roles);
      } catch (error) {
        console.error(error);
      }
    };

    const fetchPermissions = async () => {
      try {
        const response = await fetch(`${API_URLS.ADMIN_URL}roles/permissions`);
        if (!response.ok) {
          throw new Error('Yetkiler yüklenirken bir hata oluştu');
        }
        const data = await response.json();
        setPermissions(data.permissions);
      } catch (error) {
        console.error(error);
      }
    };

    const fetchResources = async () => {
      try {
        const response = await fetch(`${API_URLS.ADMIN_URL}roles/resources`);
        if (!response.ok) {
          throw new Error('Kaynaklar yüklenirken bir hata oluştu');
        }
        const data = await response.json();
        setResources(data.resources);
      } catch (error) {
        console.error(error);
      }
    };

    const fetchAccessControls = async () => {
      try {
        const response = await fetch(`${API_URLS.ADMIN_URL}roles/access-controls`);
        const data = await response.json();
        setAccessControls(data.accessControls);
      } catch (error) {
        console.error('Erişim kontrolleri yüklenirken bir hata oluştu', error);
      }
    };

    fetchRoles();
    fetchPermissions();
    fetchResources();
    fetchAccessControls();
  }, [refetch]);

  return (
    <Paper style={{ margin: 20, padding: 20 }}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <RolesTable
            roles={roles}
            permissions={permissions}
            setRoleDialogOpen={setRoleDialogOpen}
            setEditingRole={setEditingRole}
            showResultDialog={showResultDialog}
            triggerRefetch={triggerRefetch}
          />
        </Grid>
        <Grid item xs={6}>
          <PermissionsTable
            permissions={permissions}
            setPermissionDialogOpen={setPermissionDialogOpen}
            setEditingPermission={setEditingPermission}
            showResultDialog={showResultDialog}
            triggerRefetch={triggerRefetch}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <ResourcesTable
            resources={resources}
            setResourceDialogOpen={setResourceDialogOpen}
            setEditingResource={setEditingResource}
            showResultDialog={showResultDialog}
            triggerRefetch={triggerRefetch}
          />
        </Grid>
        <Grid item xs={6}>
          <AccessControlsTable
            accessControls={accessControls}
            roles={roles}
            resources={resources}
            setAccessControlDialogOpen={setAccessControlDialogOpen}
            setEditingAccessControl={setEditingAccessControl}
            showResultDialog={showResultDialog}
            triggerRefetch={triggerRefetch}
          />
        </Grid>
      </Grid>

      {/* Dialoglar */}
      <RoleDialog
        open={roleDialogOpen}
        setOpen={setRoleDialogOpen}
        roles={roles}
        permissions={permissions}
        editingRole={editingRole}
        setEditingRole={setEditingRole}
        showResultDialog={showResultDialog}
        triggerRefetch={triggerRefetch}
      />

      <PermissionDialog
        open={permissionDialogOpen}
        setOpen={setPermissionDialogOpen}
        permissions={permissions}
        editingPermission={editingPermission}
        setEditingPermission={setEditingPermission}
        showResultDialog={showResultDialog}
        triggerRefetch={triggerRefetch}
      />

      <ResourceDialog
        open={resourceDialogOpen}
        setOpen={setResourceDialogOpen}
        resources={resources}
        editingResource={editingResource}
        setEditingResource={setEditingResource}
        showResultDialog={showResultDialog}
        triggerRefetch={triggerRefetch}
      />

      <AccessControlDialog
        open={accessControlDialogOpen}
        setOpen={setAccessControlDialogOpen}
        accessControls={accessControls}
        roles={roles}
        resources={resources}
        editingAccessControl={editingAccessControl}
        setEditingAccessControl={setEditingAccessControl}
        showResultDialog={showResultDialog}
        triggerRefetch={triggerRefetch}
      />

      <ResultDialog
        open={resultDialogOpen}
        onClose={() => setResultDialogOpen(false)}
        title="İşlem Sonucu"
        message={resultDialogMessage}
        severity={resultDialogSeverity}
      />
    </Paper>
  );
};

export default RoleAndPermissionManagement;

// src/components/Auth/Dialog.js
import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@mui/material';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ResultDialog = ({ open, onClose, title, message, severity }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Alert severity={severity}>
          <Typography>{message}</Typography>
        </Alert>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">Tamam</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ResultDialog;

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Grid, Card, CardActionArea, CardContent, CardMedia, Button, Typography, CardActions } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Header from '../components/Header';
import Footer from '../components/Footer';
import '../../assets/Auth.css';

const theme = createTheme({
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
         
          maxWidth: 345,
          borderRadius: '16px', 
          boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2)', 
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {       
          padding: '8px 16px',
        },
      },
    },
  },
});

const loginOptions = [
  {
    title: 'Kurum Kayıt',

    imageUrl: '/images/corporate-login.webp', 
    path: '/okul-kayit',
  },
  {
    title: 'Öğretmen Kayıt',
  
    imageUrl: '/images/teacher-login.webp', 
    path: '/ogretmen-kayit',
  },
  {
    title: 'Öğrenci Kayıt',
  
    imageUrl: '/images/student-login.webp', 
    path: '/ogrenci-kayit',
  },

];
function MainRegister() {
  const navigate = useNavigate(); 

  const handleLoginClick = (path) => {
    navigate(path); 
  
  };

  return (
    <ThemeProvider theme={theme}>

      <Header />
      <div className='bg-main'>
      <Container maxWidth="md" sx={{
        mt: { xs: 2, sm: 4, md: 8 },
        mb: { xs: 2, sm: 4, md: 10 },
      }}>
    
    <Grid container spacing={4}>
          {loginOptions.map((option, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
            <Card sx={{
              maxWidth: 345,
              borderRadius: '16px',
              boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2)',
              transition: '0.3s',
              '&:hover': {
                boxShadow: '0 16px 32px 0 rgba(0,0,0,0.3)',
                transform: 'scale(1.05)',
              },
              '.MuiCardMedia-root': {
                height: 140,
                transition: 'transform 0.15s ease-in-out',
                '&:hover': {
                  transform: 'scale(1.03)',
                },
              },
              '.MuiCardContent-root': {
                backgroundColor: 'rgba(255, 255, 255, 0.8)',
              },
              '.MuiButton-root': {
                backgroundColor: '#d32f2f',
                color: '#fff',
                '&:hover': {
                  backgroundColor: '#9a0007',
                },
              },
            }}>
                <CardActionArea onClick={() => handleLoginClick(option.path)}>
                  <CardMedia
                    component="img"
                    image={option.imageUrl} 
                    alt={option.title}
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div" textAlign={'center'}>
                      {option.title}
                    </Typography>               
                  </CardContent>
                </CardActionArea>
                <CardActions sx={{ justifyContent: 'center', padding: '16px' }}>
                  <Button size="large" color="primary" variant="contained" fullWidth onClick={() => handleLoginClick(option.path)}>
                   KAYIT OL
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
      </div>
      <Footer />
    </ThemeProvider>
  );
}


export default MainRegister;


// ProgressBarIkiser.js
import React from 'react';
import '../styles/kayanYazilarIkiserStyle.css';

const ProgressBarIkiser = ({ progress }) => {
  return (
    <div className="kayan-yazilar-ikiser-progress-bar-container">
      <div
        className="kayan-yazilar-ikiser-progress-bar-fill"
        style={{ width: `${progress}%` }}
      ></div>
    </div>
  );
};

export default ProgressBarIkiser;

import React, { useState, useEffect } from 'react';
import { 
  Card, CardContent, TextField, Button, Typography, Container, CircularProgress, 
  FormControl, InputLabel, Select, MenuItem 
} from '@mui/material';
import Header from '../../../home/components/Header';
import Footer from '../../../home/components/Footer';
import ResultDialog from '../../components/Auth/Dialog';
import { API_URLS } from '../../../config/config';
import { useNavigate } from 'react-router-dom';

function OgrenciRegisterForm() {
  const navigate = useNavigate();

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [emailHata, setEmailHata] = useState(false);
  const [password, setPassword] = useState('');
  const [telefon, setTelefon] = useState('');
  const [telefonHata, setTelefonHata] = useState(false);
  const [okulAdi, setOkulAdi] = useState(''); 
  const [sinif, setSinif] = useState('');
  const [sinifHata, setSinifHata] = useState(false);
  const [sehirListesi, setSehirListesi] = useState([]);
  const [secilenSehirId, setSecilenSehirId] = useState('');
  const [secilenIlceId, setSecilenIlceId] = useState("");
  const [shouldFetch, setShouldFetch] = useState(true);
  const [loading, setLoading] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState('');
  const [dialogSeverity, setDialogSeverity] = useState('error'); 

  useEffect(() => {
    const fetchSehirler = async () => { 
      if (!shouldFetch) return;
      try {
        const response = await fetch(API_URLS.ADMIN_URL + "sehirler");
        if (!response.ok) {
          throw new Error('Şehir verisi çekilemedi.');
        }
        const sehirler = await response.json();    
        setSehirListesi(sehirler);
      } catch (err) {
        console.error("Şehirler yüklenirken bir hata oluştu:", err.message);
      } finally {
        setShouldFetch(false);
      }
    };

    if (shouldFetch) {
      fetchSehirler();
    }
  }, [shouldFetch]); 

  const handleEmailChange = (event) => {
    const { value } = event.target;
    setEmail(value);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setEmailHata(!emailRegex.test(value));
  };

  const handleTelefonChange = (event) => {
    const telefonRegex = /^0(\d{3}) (\d{3}) (\d{4})$/;
    let { value } = event.target;
    const numbers = value.replace(/\D/g, '');  
    value = numbers.substring(0, 4) + 
            (numbers.length > 4 ? ' ' : '') + 
            numbers.substring(4, 7) + 
            (numbers.length > 7 ? ' ' : '') + 
            numbers.substring(7, 11); 
    setTelefonHata(!telefonRegex.test(value) && value !== '');
    setTelefon(value);
  }; 

  const handleSehirChange = (event) => {
    setSecilenSehirId(event.target.value);
    setSecilenIlceId('');
  };  

  const handleIlceChange = (event) => {
    setSecilenIlceId(event.target.value);
  };

  const secilenSehirinIlceleri = sehirListesi.find(sehir => sehir._id === secilenSehirId)?.ilceler || [];

  const handleSinifChange = (event) => {
    const { value } = event.target;
    if (/^\d*$/.test(value)) {
      setSinif(value);
      setSinifHata(false);
    } else {
      setSinifHata(true);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (emailHata) {
      setDialogSeverity('error');
      setDialogContent('Lütfen geçerli bir email adresi giriniz.');
      setDialogOpen(true);
      return;
    }

    if (sinif.trim() === '' || isNaN(sinif) || parseInt(sinif) <= 0) {
      setSinifHata(true);
      setDialogSeverity('error');
      setDialogContent('Lütfen geçerli bir sınıf numarası giriniz.');
      setDialogOpen(true);
      return;
    }

    setLoading(true); 

    try {   
      const response = await fetch(API_URLS.STUDENT_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ad: firstName,
          soyad: lastName,
          email: email,
          password: password,
          password_open: password,
          // okul_adi isteğe bağlı olarak girilecek
          okul_adi: okulAdi || undefined,
          telefon: telefon,
          sehir: secilenSehirId,
          ilce: secilenIlceId,  
          sinif: parseInt(sinif),
        }),
      });

      const data = await response.json();
      setLoading(false); 
      if (!response.ok) {   
        setDialogSeverity('error');
        setDialogContent(data.message || 'Kayıt işlemi başarısız.');
        setDialogOpen(true); 
      } else {
        // Kayıt başarılı, şimdi otomatik giriş yapalım
        const loginResponse = await fetch(API_URLS.STUDENT_URL + 'login', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email, password }),
        });

        const loginData = await loginResponse.json();

        if (loginResponse.ok) {
          localStorage.setItem('userToken', loginData.token);
          setDialogSeverity('success');
          setDialogContent('Kayıt ve giriş başarılı! Yönlendiriliyorsunuz...');
          setDialogOpen(true); 
        } else {
          // Otomatik giriş başarısız olsa da kullanıcı kayıt oldu
          setDialogSeverity('warning');
          setDialogContent('Kayıt başarılı ancak otomatik giriş yapılamadı. Lütfen tekrar giriş yapınız.');
          setDialogOpen(true); 
        }
      }
    } catch (err) {
      setLoading(false);      
      setDialogContent('Kayıt sırasında bir hata oluştu: ' + err.message);
      setDialogOpen(true); 
    }
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    // Eğer başarıyla kayıt+giriş yapıldıysa ogrenci-dashboard'a yönlendir
    if (dialogSeverity === 'success') {
      navigate('/ogrenci-dashboard');
    } else if (dialogSeverity === 'warning') {
      navigate('/ogrenci-giris');
    }
  };

  return (
    <div>
      <Header />
      <div className='bg-main'>
        <Container 
          maxWidth="sm" 
          sx={{
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center', 
            minHeight: 'calc(100vh - 200px)', 
            marginTop: 4, 
            marginBottom: 4, 
            '.MuiCard-root': {
              background: 'linear-gradient(145deg, rgba(105,105,105,0.8) 0%, rgba(220,20,60,0.8) 100%)', 
              backdropFilter: 'blur(8px)', 
            }
          }}
        >
          <Card sx={{
            minWidth: 275, 
            backgroundColor: 'rgba(255, 255, 255, 0.75)', 
            backdropFilter: 'blur(8px)', 
            borderRadius: '16px',
            boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2)',
          }}>
            <CardContent sx={{
              display: 'flex', flexDirection: 'column', alignItems: 'center', 
            }}>
              <Typography
                variant="h5"
                component="div"
                sx={{
                  marginBottom: 2,
                  color: '#fff',
                  fontWeight: 'bold', 
                  textShadow: '2px 2px 4px rgba(0,0,0,0.5)',
                  letterSpacing: '0.05rem', 
                  fontSize: '1.5rem', 
                }}
              >
                Öğrenci - Kayıt Formu
              </Typography>

              <form onSubmit={handleSubmit} style={{ width: '100%' }}>
                <TextField
                  fullWidth
                  margin="normal"
                  label="Ad"
                  variant="outlined"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  required
                  sx={{ 
                    input: { color: 'white' }, 
                    label: { color: 'white' }, 
                    '& .MuiOutlinedInput-root': { '& fieldset': { borderColor: 'white' } }
                  }}
                />
                <TextField
                  fullWidth
                  margin="normal"
                  label="Soyad"
                  variant="outlined"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  required
                  sx={{ 
                    input: { color: 'white' }, 
                    label: { color: 'white' }, 
                    '& .MuiOutlinedInput-root': { '& fieldset': { borderColor: 'white' } }
                  }}
                />

                <TextField
                  fullWidth
                  margin="normal"
                  label="Okul Adı (isteğe bağlı)"
                  variant="outlined"
                  value={okulAdi}
                  onChange={(e) => setOkulAdi(e.target.value)}
                  sx={{ 
                    input: { color: 'white' }, 
                    label: { color: 'white' }, 
                    '& .MuiOutlinedInput-root': { '& fieldset': { borderColor: 'white' } }
                  }}
                />

                <TextField 
                  variant="outlined" 
                  fullWidth 
                  label="Sınıf"
                  type="number"
                  value={sinif} 
                  onChange={handleSinifChange} 
                  margin="normal" 
                  error={sinifHata}
                  helperText={sinifHata ? 'Lütfen geçerli bir sınıf numarası giriniz.' : ''}     
                  required
                  sx={{ 
                    input: { color: 'white' }, 
                    label: { color: 'white' },
                    '& .MuiOutlinedInput-root': { '& fieldset': { borderColor: 'white' } },
                    '& .Mui-error': { 
                      color: '#f44336', 
                      '& fieldset': {
                        borderColor: '#f44336'
                      },
                      '& .MuiFormHelperText-root': {
                        color: '#f44336', 
                        backgroundColor: 'white', 
                        borderRadius: '4px', 
                        padding: '2px' 
                      }
                    },
                    '& .MuiFormHelperText-root.Mui-error': { 
                      backgroundColor: 'white'
                    }
                  }} 
                />

                <FormControl fullWidth margin="normal" 
                  sx={{ 
                    input: { color: 'white' }, 
                    label: { color: 'white' }, 
                    '& .MuiOutlinedInput-root': { '& fieldset': { borderColor: 'white' } }
                  }}
                >
                  <InputLabel id="sehir-select-label" sx={{ color: 'white' }}>Şehir</InputLabel>
                  <Select
                    labelId="sehir-select-label"
                    value={secilenSehirId}
                    onChange={handleSehirChange}
                    displayEmpty
                    required
                    sx={{
                      color: 'white',
                      '& .MuiSvgIcon-root': { color: 'white' },
                    }}
                    renderValue={(selected) => {
                      if (selected === "") {
                        return <em>Şehir Seçiniz</em>;
                      }             
                      const secilenSehir = sehirListesi.find(sehir => sehir._id === selected);
                      return secilenSehir ? secilenSehir.sehir_adi : <em>Şehir Seçiniz</em>;
                    }}
                  >
                    <MenuItem disabled value="">
                      <em>Şehir Seçiniz</em>
                    </MenuItem>
                    {sehirListesi.map((sehir) => (
                      <MenuItem key={sehir._id} value={sehir._id}>
                        {sehir.sehir_adi}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                {secilenSehirId && (
                  <FormControl 
                    fullWidth 
                    margin="normal" 
                    sx={{ 
                      input: { color: 'white' }, 
                      label: { color: 'white' }, 
                      '& .MuiOutlinedInput-root': { '& fieldset': { borderColor: 'white' } }
                    }}
                  >
                    <InputLabel id="ilce-select-label" sx={{ color: 'white' }}>İlçe</InputLabel>
                    <Select
                      labelId="ilce-select-label"
                      id="ilce-select"
                      value={secilenIlceId}
                      onChange={handleIlceChange}
                      displayEmpty
                      required
                      sx={{
                        color: 'white',
                        '& .MuiSvgIcon-root': { color: 'white' },
                      }}
                      renderValue={(selected) => {
                        if (!selected) {
                          return <em>İlçe Seçiniz</em>;
                        }
                        const secilenIlce = secilenSehirinIlceleri.find(ilce => ilce._id === selected);
                        return secilenIlce ? secilenIlce.ilce_adi : <em>İlçe Seçiniz</em>;
                      }}
                    >
                      <MenuItem disabled value="">
                        <em>İlçe Seçiniz</em>
                      </MenuItem>
                      {secilenSehirinIlceleri.map((ilce) => (
                        <MenuItem key={ilce._id} value={ilce._id}>
                          {ilce.ilce_adi}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}

                <TextField
                  fullWidth
                  label="Telefon"
                  value={telefon}
                  onChange={handleTelefonChange}
                  error={telefonHata}
                  helperText={telefonHata ? 'Telefon numarası 05XX XXX XXXX formatında olmalıdır.' : ''}
                  margin="normal"
                  required
                  sx={{
                    input: { color: 'white' }, 
                    label: { color: 'white' }, 
                    '& .MuiOutlinedInput-root': { '& fieldset': { borderColor: 'white' } },
                    '& .Mui-error': { 
                      color: '#f44336', 
                      '& fieldset': {
                        borderColor: '#f44336'
                      },
                      '& .MuiFormHelperText-root': {
                        color: '#f44336', 
                        backgroundColor: 'white', 
                        borderRadius: '4px', 
                        padding: '2px' 
                      }
                    },
                    '& .MuiFormHelperText-root.Mui-error': { 
                      backgroundColor: 'white'
                    }
                  }}
                />

                <TextField
                  fullWidth
                  margin="normal"
                  label="E-posta"
                  type="email"
                  variant="outlined"
                  value={email}
                  onChange={handleEmailChange}
                  error={emailHata}
                  helperText={emailHata ? 'Lütfen geçerli bir email adresi giriniz.' : ''}
                  required
                  sx={{
                    input: { color: 'white' },
                    label: { color: 'white' },
                    '& .MuiOutlinedInput-root': { 
                      '& fieldset': { borderColor: 'white' } 
                    },
                    '& .Mui-error': {
                      color: '#f44336',
                      '& fieldset': {
                        borderColor: '#f44336'
                      },
                      '& .MuiFormHelperText-root': {
                        color: '#f44336',
                        backgroundColor: 'white',
                        borderRadius: '4px',
                        padding: '2px'
                      }
                    },
                    '& .MuiFormHelperText-root.Mui-error': {
                      backgroundColor: 'white'
                    }
                  }}
                />

                <TextField
                  fullWidth
                  margin="normal"
                  label="Şifre"
                  type="password"
                  variant="outlined"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  sx={{ 
                    input: { color: 'white' }, 
                    label: { color: 'white' }, 
                    '& .MuiOutlinedInput-root': { '& fieldset': { borderColor: 'white' } }
                  }}
                />
                <Button 
                  type="submit" 
                  variant="contained" 
                  color="primary" 
                  fullWidth 
                  sx={{ mt: 2, py: 1.5 }}
                > 
                  Kayıt Ol
                </Button>
              </form>
            </CardContent>
          </Card>
        </Container>
      </div>

      {loading && (
        <div style={{
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          zIndex: 9999,
        }}>
          <CircularProgress />
        </div>
      )}
      <ResultDialog
        open={dialogOpen}
        onClose={handleDialogClose}
        title={
          dialogSeverity === 'error' 
            ? "Bir Hata Oluştu!" 
            : dialogSeverity === 'success' 
            ? "Başarılı bir şekilde kayıt ve giriş yapıldı!" 
            : "Başarılı bir şekilde kayıt oldunuz!"
        }
        message={dialogContent}
        severity={dialogSeverity}
      />
      <Footer />
    </div>
  );
}

export default OgrenciRegisterForm;

import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, FormControl, InputLabel, Select, MenuItem, Chip,} from '@mui/material';
import { API_URLS } from '../../../../config/config';

const AccessControlDialog = ({
  open,
  setOpen,
  accessControls,
  roles,
  resources,
  editingAccessControl,
  setEditingAccessControl,
  showResultDialog,
  triggerRefetch,
}) => {
  const [selectedRole, setSelectedRole] = useState('');
  const [selectedResources, setSelectedResources] = useState([]);

  useEffect(() => {
    if (editingAccessControl) {
      setSelectedRole(editingAccessControl.role?._id);
      setSelectedResources(editingAccessControl.resources.map((resource) => resource._id));
    } else {
      setSelectedRole('');
      setSelectedResources([]);
    }
  }, [editingAccessControl]);

  const handleSubmit = async () => {
    if (!selectedRole || selectedResources.length === 0) {
      showResultDialog('Rol ve en az bir kaynak seçilmelidir.', 'error');
      return;
    }

    const accessControlData = {
      role: selectedRole,
      resources: selectedResources,
    };

    try {
      let response;
      if (editingAccessControl) {
        response = await fetch(`${API_URLS.ADMIN_URL}roles/access-controls/${editingAccessControl._id}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(accessControlData),
        });
      } else {
        response = await fetch(`${API_URLS.ADMIN_URL}roles/access-controls`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(accessControlData),
        });
      }

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Bir hata oluştu');
      }

      triggerRefetch();
      setOpen(false);
      setEditingAccessControl(null);
      showResultDialog('Erişim kontrolü başarıyla kaydedildi.', 'success');
    } catch (error) {
      console.error(error);
      showResultDialog(error.message, 'error');
    }
  };

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>{editingAccessControl ? 'Erişim Kontrolü Düzenle' : 'Yeni Erişim Kontrolü Ekle'}</DialogTitle>
      <DialogContent>
        <FormControl fullWidth margin="normal">
          <InputLabel>Rol</InputLabel>
          <Select value={selectedRole} onChange={(e) => setSelectedRole(e.target.value)}>
            {roles.map((role) => (
              <MenuItem key={role._id} value={role._id}>
                {role.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl fullWidth margin="normal">
          <InputLabel>Kaynaklar</InputLabel>
          <Select
            multiple
            value={selectedResources}
            onChange={(e) => setSelectedResources(e.target.value)}
            renderValue={(selected) => (
              <div>
                {selected.map((resourceId) => {
                  const resource = resources.find((r) => r._id === resourceId);
                  return resource ? <Chip key={resourceId} label={resource.name} style={{ margin: 2 }} /> : null;
                })}
              </div>
            )}
          >
            {resources.map((resource) => (
              <MenuItem key={resource._id} value={resource._id}>
                {resource.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)}>İptal</Button>
        <Button onClick={handleSubmit}>{editingAccessControl ? 'Güncelle' : 'Ekle'}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default AccessControlDialog;

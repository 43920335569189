import React from 'react';
import { Grid, TextField, FormControl, InputLabel, Select, MenuItem } from '@mui/material';

const TeacherFilters = ({
  sehirListesi,
  searchQuery,
  setSearchQuery,
  filterSehirId,
  setFilterSehirId,
  filterPackageType,
  setFilterPackageType
}) => {
  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={12} sm={4}>
        <TextField
          fullWidth
          label="Ara..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          margin="normal"
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <FormControl fullWidth margin="normal">
          <InputLabel id="filter-sehir-label">Şehir</InputLabel>
          <Select
            labelId="filter-sehir-label"
            value={filterSehirId}
            onChange={(e) => setFilterSehirId(e.target.value)}
          >
            <MenuItem value="">
              <em>Tümü</em>
            </MenuItem>
            {sehirListesi.map((sehir) => (
              <MenuItem key={sehir._id} value={sehir._id}>
                {sehir.sehir_adi}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={4}>
        <FormControl fullWidth margin="normal">
          <InputLabel id="filter-package-label">Paket Türü</InputLabel>
          <Select
            labelId="filter-package-label"
            value={filterPackageType}
            onChange={(e) => setFilterPackageType(e.target.value)}
          >
            <MenuItem value="">
              <em>Tümü</em>
            </MenuItem>
            <MenuItem value="bakiye3Ay">3 Ay</MenuItem>
            <MenuItem value="bakiye6Ay">6 Ay</MenuItem>
            <MenuItem value="bakiye12Ay">12 Ay</MenuItem>
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default TeacherFilters;

// GameExplanation.js

import React from 'react';
import '../styles/KelimeBulmaOyunu.css';

const GameExplanation = () => {
  return (
    <div className="kayu-game-explanation">
      <h3 className="kayu-instructions-title">Talimatlar</h3>
      <ul className="kayu-instructions-list">
        <li>
          <strong>1. Zorluk ve Paragraf Seçin:</strong> Zorluk seviyesini ve okumak istediğiniz paragrafı seçin.
        </li>
        <li>
          <strong>2. Çalışmayı Başlatın:</strong> "Başla" butonuna tıklayarak çalışmayı başlatın.
        </li>
        <li>
          <strong>3. Kelimeleri Bulun:</strong> Hedef kelimeleri paragraf içinde bulun ve üzerine tıklayın.
        </li>
        <li>
          <strong>4. Skorunuzu Artırın:</strong> Doğru kelimeleri buldukça puan kazanın.
        </li>
      </ul>
    </div>
  );
};

export default GameExplanation;

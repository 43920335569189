import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  DialogActions,
  Button,
} from '@mui/material';

const EditStudentDialog = ({
  duzenlemeDialogAcik,
  setDuzenlemeDialogAcik,
  duzenlenenOgrenci,
  setDuzenlenenOgrenci,
  sehirListesi,
  handleSehirChangeDuzenle,
  handleIlceChangeDuzenle,
  handleGuncelle,
}) => {
  return (
    <Dialog
      open={duzenlemeDialogAcik}
      onClose={() => setDuzenlemeDialogAcik(false)}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>Öğrenci Düzenle</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Adı"
              value={duzenlenenOgrenci.ad}
              onChange={(e) => setDuzenlenenOgrenci({ ...duzenlenenOgrenci, ad: e.target.value })}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Soyadı"
              value={duzenlenenOgrenci.soyad}
              onChange={(e) =>
                setDuzenlenenOgrenci({ ...duzenlenenOgrenci, soyad: e.target.value })
              }
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Okul Adı"
              value={duzenlenenOgrenci.okulAdi}
              onChange={(e) =>
                setDuzenlenenOgrenci({ ...duzenlenenOgrenci, okulAdi: e.target.value })
              }
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Sınıf"
              value={duzenlenenOgrenci.sinif}
              onChange={(e) =>
                setDuzenlenenOgrenci({ ...duzenlenenOgrenci, sinif: e.target.value })
              }
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Yaş"
              value={duzenlenenOgrenci.yas}
              onChange={(e) =>
                setDuzenlenenOgrenci({ ...duzenlenenOgrenci, yas: e.target.value })
              }
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth variant="outlined">
              <InputLabel id="sehir-select-label-duzenle">Şehir</InputLabel>
              <Select
                labelId="sehir-select-label-duzenle"
                id="sehir-select-duzenle"
                value={duzenlenenOgrenci.sehirId}
                onChange={handleSehirChangeDuzenle}
                label="Şehir"
              >
                <MenuItem value="">
                  <em>Şehir Seçiniz</em>
                </MenuItem>
                {sehirListesi.map((sehir) => (
                  <MenuItem key={sehir._id} value={sehir._id}>
                    {sehir.sehir_adi}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {duzenlenenOgrenci.sehirId && (
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth variant="outlined">
                <InputLabel id="ilce-select-label-duzenle">İlçe</InputLabel>
                <Select
                  labelId="ilce-select-label-duzenle"
                  id="ilce-select-duzenle"
                  value={duzenlenenOgrenci.ilceId}
                  onChange={handleIlceChangeDuzenle}
                  label="İlçe"
                  renderValue={(selected) => {
                    if (selected) {
                      const secilenIlce = duzenlenenOgrenci.ilceler.find(
                        (ilce) => ilce._id === selected
                      );
                      return secilenIlce ? secilenIlce.ilce_adi : 'İlçe bulunamadı';
                    }
                    return <em>İlçe Seçiniz</em>;
                  }}
                >
                  <MenuItem value="">
                    <em>İlçe Seçiniz</em>
                  </MenuItem>
                  {duzenlenenOgrenci.ilceler?.map((ilce) => (
                    <MenuItem key={ilce._id} value={ilce._id}>
                      {ilce.ilce_adi}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          )}
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Email"
              value={duzenlenenOgrenci.email}
              onChange={(e) =>
                setDuzenlenenOgrenci({ ...duzenlenenOgrenci, email: e.target.value })
              }
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Telefon"
              value={duzenlenenOgrenci.telefon}
              onChange={(e) =>
                setDuzenlenenOgrenci({ ...duzenlenenOgrenci, telefon: e.target.value })
              }
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Şifre"
              value={duzenlenenOgrenci.sifre}
              onChange={(e) =>
                setDuzenlenenOgrenci({ ...duzenlenenOgrenci, sifre: e.target.value })
              }
              variant="outlined"
              type="password"
              helperText="Şifreyi güncellemek istiyorsanız buraya yeni şifreyi girin. Aksi takdirde boş bırakın."
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setDuzenlemeDialogAcik(false)} color="secondary">
          İptal
        </Button>
        <Button onClick={handleGuncelle} color="primary" variant="contained">
          Güncelle
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditStudentDialog;

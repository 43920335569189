import React, { useState, useEffect } from 'react';
import { Button, Typography, Paper, Grid, TextField, Dialog, DialogTitle, DialogContent, DialogActions,
  FormControl, InputLabel, Select, MenuItem, Card } from '@mui/material';

import ResultDialog from '../../../pages/components/Auth/Dialog';
import TeacherForm from './components/TeacherForm';
import TeacherFilters from './components/TeacherFilters';
import TeacherTable from './components/TeacherTable';
import TeacherEditDialog from './components/TeacherEditDialog';
import BalanceTransferDialog from './components/BalanceTransferDialog';
import RevokeBalanceDialog from './components/RevokeBalanceDialog';

import { API_URLS } from '../../../config/config';
import useFetchUserData from '../../../hooks/kurum/useFetchUserData';

const TeacherPage = () => {
  const [ogretmenListesi, setOgretmenListesi] = useState([]);
  const [sehirListesi, setSehirListesi] = useState([]);  
  const [secilenSehirId, setSecilenSehirId] = useState('');
  const [secilenIlceId, setSecilenIlceId] = useState("");
  const [shouldFetch, setShouldFetch] = useState(true);
  const [ad, setAd] = useState('');
  const [soyad, setSoyad] = useState('');
  const [okulAdi, setOkulAdi] = useState('');
  const [telefon, setTelefon] = useState('');
  const [telefonHata, setTelefonHata] = useState(false);
  const [email, setEmail] = useState('');
  const [sifre, setSifre] = useState('');
  const [userData, setUserData] = useState(null);
  const [dialogContent, setDialogContent] = useState('');
  const [dialogSeverity, setDialogSeverity] = useState('info');
  const [dialogOpen, setDialogOpen] = useState(false);
  const [resultDialogOpen, setResultDialogOpen] = useState(false);
  const [resultDialogMessage, setResultDialogMessage] = useState('');
  const [resultDialogSeverity, setResultDialogSeverity] = useState('info');
  const [eklemeBasariliDialogOpen, setEklemeBasariliDialogOpen] = useState(false); 
  const [duzenlemeDialogAcik, setDuzenlemeDialogAcik] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useFetchUserData(setUserData);

  const [searchQuery, setSearchQuery] = useState('');
  const [filterSehirId, setFilterSehirId] = useState('');
  const [filterPackageType, setFilterPackageType] = useState('');

  const [duzenlenenOgretmen, setDuzenlenenOgretmen] = useState({
    ad:'',
    soyad:'',
    okulAdi: '',
    email: '',
    telefon: '',
    sehirId: '',
    ilceId: '', 
    ilceler: [],
  });

  const getFilteredOgretmenListesi = () => {
    return ogretmenListesi.filter((ogretmen) => {
      const matchesSearchQuery =
        ogretmen.ad.toLowerCase().includes(searchQuery.toLowerCase()) ||
        ogretmen.soyad.toLowerCase().includes(searchQuery.toLowerCase()) ||
        ogretmen.telefon.includes(searchQuery) ||
        ogretmen.email.toLowerCase().includes(searchQuery.toLowerCase()) ||
        (ogretmen.sehirAdi && ogretmen.sehirAdi.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (ogretmen.ilceAdi && ogretmen.ilceAdi.toLowerCase().includes(searchQuery.toLowerCase()));

      const matchesSehir =
        !filterSehirId || ogretmen.sehir === filterSehirId;

      const matchesPackageType =
        !filterPackageType ||
        (filterPackageType === 'bakiye3Ay' && ogretmen.bakiye3Ay > 0) ||
        (filterPackageType === 'bakiye6Ay' && ogretmen.bakiye6Ay > 0) ||
        (filterPackageType === 'bakiye12Ay' && ogretmen.bakiye12Ay > 0);

      return matchesSearchQuery && matchesSehir && matchesPackageType;
    });
  };

  const filteredOgretmenListesi = getFilteredOgretmenListesi();

  const fetchIlceler = async (sehirId, seciliIlceId) => {
    try {
      const response = await fetch(`${API_URLS.ADMIN_URL}sehirler/ilceler/${sehirId}`);
      if (!response.ok) throw new Error('İlçeler yüklenirken bir hata oluştu.');
      const data = await response.json(); 
      const ilceler = data.ilceler ? data.ilceler : []; 

      setDuzenlenenOgretmen(prev => ({ 
        ...prev, 
        ilceler: ilceler,
        ilceId: seciliIlceId && ilceler.some(ilce => ilce._id === seciliIlceId) ? seciliIlceId : prev.ilceId 
      }));
    } catch (error) {
      console.error('İlçeleri yüklerken bir hata oluştu:', error);
    }
  }; 

  const fetchOgretmenListesi = async () => {
    if (!userData || !userData._id) {
      console.log("Kullanıcı bilgisi yüklenemedi veya kullanıcı ID'si bulunamadı.");
      return;
    }

    setIsLoading(true);
    try {
      const response = await fetch(`${API_URLS.KURUMSAL_URL}teachers/${userData._id}`);
      if (!response.ok) {
        throw new Error('Öğretmen listesi yüklenirken bir hata oluştu.');
      }
      const data = await response.json();
      console.log("Gelen öğretmen verileri:", data);
      setOgretmenListesi(data);
    } catch (error) {
      console.error('Öğretmenleri yükleme hatası:', error);
      setDialogSeverity('error');
      setDialogContent('Öğretmenler yüklenirken bir hata oluştu.');
      setEklemeBasariliDialogOpen(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const fetchSehirler = async () => {
      if (!shouldFetch) return;
      try {
        const response = await fetch(API_URLS.ADMIN_URL + "sehirler");
        if (!response.ok) {
          throw new Error('Veri çekme işlemi başarısız');
        }
        const sehirler = await response.json();    
        setSehirListesi(sehirler);
      } catch (err) {
        console.error("Şehirler yüklenirken bir hata oluştu:", err.message);
      } finally {
        setShouldFetch(false);
      }
    };

    fetchSehirler();
    if (userData && userData._id) {
      fetchOgretmenListesi();
    }
  }, [userData, shouldFetch]);


  const handleDialogClose = () => {
    setDialogOpen(false);
    setResultDialogOpen(false); 
  };
 
  const handleTelefonChange = (event) => {
    const telefonRegex = /^0(\d{3}) (\d{3}) (\d{4})$/;
    let { value } = event.target;
    const numbers = value.replace(/\D/g, '');  
    value = numbers.substring(0, 4) + (numbers.length > 4 ? ' ' : '') + numbers.substring(4, 7) + (numbers.length > 7 ? ' ' : '') + numbers.substring(7, 11); 
    setTelefonHata(!telefonRegex.test(value) && value !== '');
    setTelefon(value);
  }; 
  const handleSehirChange = (event) => {
    setSecilenSehirId(event.target.value);
  };  

  const handleIlceChange = (event) => {
    setSecilenIlceId(event.target.value);
  };

  const handleSehirChangeDuzenle = (event) => {
    const yeniSehirId = event.target.value; 
    setDuzenlenenOgretmen(prev => ({ ...prev, sehirId: yeniSehirId }));  
    const secilenSehir = sehirListesi.find(sehir => sehir._id === yeniSehirId);
    const ilceler = secilenSehir ? secilenSehir.ilceler : [];  
    setDuzenlenenOgretmen(prev => ({ ...prev, ilceId: '', ilceler: ilceler }));
  };

  const handleIlceChangeDuzenle = (event) => {
    const yeniIlceId = event.target.value; 
    setDuzenlenenOgretmen(prev => ({ ...prev, ilceId: yeniIlceId }));
  };

  const secilenSehirinIlceleri = sehirListesi.find(sehir => sehir._id === secilenSehirId)?.ilceler || [];

  const handleEkle = async () => {
    let eksikAlanlar = [];
  
    if (!ad) eksikAlanlar.push('Adı');
    if (!soyad) eksikAlanlar.push('Soyadı');
    if (!email) eksikAlanlar.push('Email');
    if (!sifre) eksikAlanlar.push('Şifre');
    if (!telefon) eksikAlanlar.push('Telefon');
    if (!secilenSehirId) eksikAlanlar.push('Şehir');
    if (!secilenIlceId) eksikAlanlar.push('İlçe');
  
    if (eksikAlanlar.length > 0) {
      setDialogSeverity('error');
      setDialogContent(`Lütfen ${eksikAlanlar.join(', ')} alanlarını doldurunuz.`);
      setEklemeBasariliDialogOpen(true);
      return;
    }  
 
    if (telefonHata) {
      setDialogSeverity('error');
      setDialogContent('Lütfen geçerli bir telefon numarası giriniz.');
      setEklemeBasariliDialogOpen(true);
      return;
    }

    try {  
      const response = await fetch(API_URLS.KURUMSAL_URL + 'teachers', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ad:ad,
          soyad:soyad,
          email: email,
          password : sifre,
          password_open : sifre,
          okul_adi:okulAdi,
          telefon : telefon,
          sehir: secilenSehirId,
          ilce: secilenIlceId, 
          kurumId: userData?._id    
        }),
      });

      const responseData = await response.json();
      if (response.ok) {
        const yeniOgretmen = {
          ...responseData,
          sehirAdi: sehirListesi.find(sehir => sehir._id === secilenSehirId)?.sehir_adi || '',
          ilceAdi: secilenSehirinIlceleri.find(ilce => ilce._id === secilenIlceId)?.ilce_adi || ''
        };

        setOgretmenListesi([...ogretmenListesi, yeniOgretmen]);
        
        setDialogSeverity('success');
        setDialogContent('Yeni Öğretmen başarıyla eklendi.');
        setEklemeBasariliDialogOpen(true);         

        setAd('');
        setSoyad('');
        setOkulAdi('');
        setEmail('');
        setSifre('');     
        setTelefon('');
        setSecilenSehirId('');
        setSecilenIlceId('');
      
      } else {
        throw new Error(responseData.message || 'Bir hata oluştu');
      }
    } catch (err) {
      setDialogSeverity('error');
      setDialogContent(err.message);
      setEklemeBasariliDialogOpen(true); 
    }
  };

  const handleDeleteOgretmen = async (ogretmenId) => {
    try {
      const response = await fetch(`${API_URLS.KURUMSAL_URL}teachers/${ogretmenId}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`,
          'Content-Type': 'application/json',
        },
      });
  
      if (!response.ok) throw new Error('Öğretmen silinirken bir hata oluştu.');  
     
      setOgretmenListesi(ogretmenListesi.filter((ogretmen) => ogretmen._id !== ogretmenId));
  
      setResultDialogMessage('Öğretmen başarıyla silindi.');
      setResultDialogSeverity('success');
      setResultDialogOpen(true);
    } catch (error) {
      setResultDialogMessage(error.message);
      setResultDialogSeverity('error');
      setResultDialogOpen(true);
    }
  };

  const handleEdit = (ogretmenId) => {
    const seciliOgretmen = ogretmenListesi.find(ogretmen => ogretmen._id === ogretmenId);

    if (seciliOgretmen) {
      const sehirId = seciliOgretmen.sehir;
      setDuzenlenenOgretmen(prev => ({
        ...prev,
        _id: seciliOgretmen._id,
        ad:seciliOgretmen.ad,
        soyad:seciliOgretmen.soyad,
        okulAdi: seciliOgretmen.okul_adi,
        email: seciliOgretmen.email,
        telefon: seciliOgretmen.telefon,
        sehirId: sehirId, 
        ilceId: seciliOgretmen.ilce, 
      }));  
      fetchIlceler(sehirId);   
      setDuzenlemeDialogAcik(true);
    } else {
      console.error('Seçilen öğretmen bulunamadı.');
    }
  }; 

  const handleGuncelle = async () => {
    const { ad, soyad, okulAdi, email, telefon, sehirId, ilceId } = duzenlenenOgretmen;
    let eksikAlanlar = [];
  
    if (!ad) eksikAlanlar.push('Adı');
    if (!soyad) eksikAlanlar.push('Soyadı');
    if (!email) eksikAlanlar.push('Email');
    if (!telefon) eksikAlanlar.push('Telefon');
    if (!sehirId) eksikAlanlar.push('Şehir');
    if (!ilceId) eksikAlanlar.push('İlçe');
    if (!okulAdi) eksikAlanlar.push('Okul Adı');
  
    if (eksikAlanlar.length > 0) {
      setDialogSeverity('error');
      setDialogContent(`Lütfen ${eksikAlanlar.join(', ')} alanlarını doldurunuz.`);
      setDialogOpen(true);
      return;
    }
  
    try {
      const response = await fetch(`${API_URLS.KURUMSAL_URL}teachers/${duzenlenenOgretmen._id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          ad, 
          soyad, 
          okul_adi: okulAdi,
          email, 
          telefon, 
          sehir: sehirId, 
          ilce: ilceId
        }),
      });
  
      const data = await response.json();
      if (!response.ok) throw new Error(data.message || 'Bir hata oluştu');

      const guncellenmisOgretmen = {
        ...data.ogretmen,
        sehirAdi: sehirListesi.find(sehir => sehir._id === data.ogretmen.sehir)?.sehir_adi || '',
        ilceAdi: sehirListesi.find(sehir => sehir._id === data.ogretmen.sehir)?.ilceler.find(ilce => ilce._id === data.ogretmen.ilce)?.ilce_adi || ''
      };
  
      setOgretmenListesi(ogretmenListesi.map(ogretmen => ogretmen._id === duzenlenenOgretmen._id ? guncellenmisOgretmen : ogretmen));

      setDialogSeverity('success');
      setDialogContent('Öğretmen başarıyla güncellendi.');
      setDialogOpen(true);
      setDuzenlemeDialogAcik(false);
    } catch (err) {
      setDialogSeverity('error');
      setDialogContent(err.message);
      setDialogOpen(true);
    }
  };

  // Bakiye transferi için state'ler
  const [transferDialogOpen, setTransferDialogOpen] = useState(false);
  const [selectedTeacherId, setSelectedTeacherId] = useState('');
  const [transferAmount, setTransferAmount] = useState(0);
  const [packageType, setPackageType] = useState('bakiye3Ay');

  // Bakiye transferi fonksiyonu
  const handleTransfer = async () => {
    try {
      const response = await fetch(`${API_URLS.KURUMSAL_URL}teachers/transferBalance/${selectedTeacherId}`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          amount: transferAmount,
          packageType: packageType,
        }),
      });

      const data = await response.json();
      if (!response.ok) throw new Error(data.message || 'Bir hata oluştu');

      // Öğretmen listesini yeniden fetch ediyoruz
      await fetchOgretmenListesi();

      setDialogSeverity('success');
      setDialogContent('Bakiye başarıyla transfer edildi.');
      setDialogOpen(true);
      setTransferDialogOpen(false);
    } catch (err) {
      setDialogSeverity('error');
      setDialogContent(err.message);
      setDialogOpen(true);
    }
  };

  // Bakiye geri alma için state'ler
  const [revokeDialogOpen, setRevokeDialogOpen] = useState(false);
  const [revokeAmount, setRevokeAmount] = useState(0);
  const [revokePackageType, setRevokePackageType] = useState('bakiye3Ay');

  // Bakiye geri alma fonksiyonu
  const handleGeriAl = async () => {
    try {
      const response = await fetch(`${API_URLS.KURUMSAL_URL}teachers/revokeBalance/${selectedTeacherId}`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          amount: revokeAmount,
          packageType: revokePackageType,
        }),
      });

      const data = await response.json();
      if (!response.ok) throw new Error(data.message || 'Bir hata oluştu');

      // Öğretmen listesini yeniden fetch ediyoruz
      await fetchOgretmenListesi();

      setDialogSeverity('success');
      setDialogContent('Bakiye başarıyla geri alındı.');
      setDialogOpen(true);
      setRevokeDialogOpen(false);
    } catch (err) {
      setDialogSeverity('error');
      setDialogContent(err.message);
      setDialogOpen(true);
    }
  };

  return (
    <Paper style={{ padding: 20 }}>
      <Grid container spacing={3} direction="column">

        <Grid item xs={12}>
          <Card style={{ padding: 20, marginBottom: 20 }}>
            <Typography variant="h5" component="h3" marginBottom={2}>
              Öğretmen Kayıt Formu
            </Typography>
            <TeacherForm
              ad={ad} setAd={setAd}
              soyad={soyad} setSoyad={setSoyad}
              okulAdi={okulAdi} setOkulAdi={setOkulAdi}
              telefon={telefon} setTelefon={setTelefon}
              telefonHata={telefonHata} setTelefonHata={setTelefonHata}
              email={email} setEmail={setEmail}
              sifre={sifre} setSifre={setSifre}
              sehirListesi={sehirListesi}
              secilenSehirId={secilenSehirId} setSecilenSehirId={setSecilenSehirId}
              secilenIlceId={secilenIlceId} setSecilenIlceId={setSecilenIlceId}
              secilenSehirinIlceleri={secilenSehirinIlceleri}
              handleTelefonChange={handleTelefonChange}
              handleSehirChange={handleSehirChange}
              handleIlceChange={handleIlceChange}
              handleEkle={handleEkle}
            />
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card style={{ padding: 20, marginBottom: 20 }}>
            <TeacherFilters
              searchQuery={searchQuery} setSearchQuery={setSearchQuery}
              filterSehirId={filterSehirId} setFilterSehirId={setFilterSehirId}
              filterPackageType={filterPackageType} setFilterPackageType={setFilterPackageType}
              sehirListesi={sehirListesi}
            />
          </Card>
        </Grid>

        <Grid item xs={12}>
          <TeacherTable
            ogretmenListesi={filteredOgretmenListesi}
            handleEdit={handleEdit}
            setSelectedTeacherId={setSelectedTeacherId}
            setTransferDialogOpen={setTransferDialogOpen}
            setRevokeDialogOpen={setRevokeDialogOpen}
          />
        </Grid>
      </Grid>

      <ResultDialog
        open={eklemeBasariliDialogOpen}
        onClose={() => setEklemeBasariliDialogOpen(false)}
        title={dialogSeverity === 'error' ? "Hata Oluştu" : "İşlem Başarılı"}
        message={dialogContent}
        severity={dialogSeverity}
      />
      <ResultDialog
        open={resultDialogOpen}
        onClose={handleDialogClose}
        title={"İşlem Sonucu"}
        message={resultDialogMessage}
        severity={resultDialogSeverity}
      />

      <BalanceTransferDialog
        open={transferDialogOpen}
        onClose={() => setTransferDialogOpen(false)}
        packageType={packageType}
        setPackageType={setPackageType}
        transferAmount={transferAmount}
        setTransferAmount={setTransferAmount}
        handleTransfer={handleTransfer}
      />

      <RevokeBalanceDialog
        open={revokeDialogOpen}
        onClose={() => setRevokeDialogOpen(false)}
        revokePackageType={revokePackageType}
        setRevokePackageType={setRevokePackageType}
        revokeAmount={revokeAmount}
        setRevokeAmount={setRevokeAmount}
        handleGeriAl={handleGeriAl}
      />

      <TeacherEditDialog
        open={duzenlemeDialogAcik}
        onClose={() => setDuzenlemeDialogAcik(false)}
        duzenlenenOgretmen={duzenlenenOgretmen}
        setDuzenlenenOgretmen={setDuzenlenenOgretmen}
        sehirListesi={sehirListesi}
        handleSehirChangeDuzenle={handleSehirChangeDuzenle}
        handleIlceChangeDuzenle={handleIlceChangeDuzenle}
        handleGuncelle={handleGuncelle}
      />
    </Paper>
  );
};

export default TeacherPage;

// MessageDisplay.js

import React from 'react';
import '../styles/KelimeBulmaOyunu.css';

const MessageDisplay = ({ message }) => {
  return message ? (
    <div className="kayu-message-display">
      {message}
    </div>
  ) : null;
};

export default MessageDisplay;

// KurumFormu.js
import React from 'react';
import {
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Typography,
} from '@mui/material';

const KurumFormu = ({
  sehirListesi,
  secilenSehirId,
  handleSehirChange,
  secilenIlceId,
  handleIlceChange,
  secilenSehirinIlceleri,
  kurumAdi,
  setKurumAdi,
  telefon,
  handleTelefonChange,
  telefonHata,
  secilenKurumTurleri,
  setSecilenKurumTurleri,
  durum,
  setDurum,
  email,
  setEmail,
  sifre,
  setSifre,
  handleEkle,
  kurumTurleri,
}) => {
  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h5" component="h3" marginBottom={2}>
          Kurum Kayıt Formu
        </Typography>
      </Grid>

      {/* Şehir ve İlçe Seçimi */}
      <Grid item xs={12} sm={6}>
        <FormControl fullWidth margin="normal">
          <InputLabel id="sehir-select-label">Şehir</InputLabel>
          <Select
            labelId="sehir-select-label"
            value={secilenSehirId}
            onChange={handleSehirChange}
            displayEmpty
            renderValue={(selected) => {
              if (selected === '') {
                return <em>Şehir Seçiniz</em>;
              }
              const secilenSehir = sehirListesi.find((sehir) => sehir._id === selected);
              return secilenSehir ? secilenSehir.sehir_adi : <em>Şehir Seçiniz</em>;
            }}
          >
            <MenuItem disabled value="">
              <em>Şehir Seçiniz</em>
            </MenuItem>
            {sehirListesi.map((sehir) => (
              <MenuItem key={sehir._id} value={sehir._id}>
                {sehir.sehir_adi}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6}>
        {secilenSehirId && (
          <FormControl fullWidth margin="normal">
            <InputLabel id="ilce-select-label">İlçe</InputLabel>
            <Select
              labelId="ilce-select-label"
              id="ilce-select"
              value={secilenIlceId}
              onChange={handleIlceChange}
              displayEmpty
              renderValue={(selected) => {
                if (!selected) {
                  return <em>İlçe Seçiniz</em>;
                }
                const secilenIlce = secilenSehirinIlceleri.find((ilce) => ilce._id === selected);
                return secilenIlce ? secilenIlce.ilce_adi : <em>İlçe Seçiniz</em>;
              }}
            >
              <MenuItem disabled value="">
                <em>İlçe Seçiniz</em>
              </MenuItem>
              {secilenSehirinIlceleri.map((ilce) => (
                <MenuItem key={ilce._id} value={ilce._id}>
                  {ilce.ilce_adi}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </Grid>

      {/* Kurum Adı ve Telefon */}
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          label="Kurum Adı"
          value={kurumAdi}
          onChange={(e) => setKurumAdi(e.target.value)}
          margin="normal"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          label="Telefon"
          value={telefon}
          onChange={handleTelefonChange}
          error={telefonHata}
          helperText={telefonHata ? 'Telefon numarası 0XXX XXX XXXX formatında olmalıdır.' : ''}
          margin="normal"
        />
      </Grid>

      {/* Kurum Türü ve Durum */}
      <Grid item xs={12} sm={6}>
        <FormControl fullWidth margin="normal">
          <InputLabel id="kurum-turu-label">Kurum Türü</InputLabel>
          <Select
            labelId="kurum-turu-label"
            id="kurum-turu-select"
            multiple
            value={secilenKurumTurleri}
            onChange={(event) => setSecilenKurumTurleri(event.target.value)}
            renderValue={(selected) => selected.join(', ')}
          >
            {kurumTurleri.map((tur) => (
              <MenuItem key={tur.id} value={tur.adi}>
                {tur.adi}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControl fullWidth margin="normal">
          <InputLabel id="status-select-label">Durum</InputLabel>
          <Select
            labelId="status-select-label"
            id="status-select"
            value={durum}
            onChange={(e) => setDurum(e.target.value)}
          >
            <MenuItem value="active">Aktif</MenuItem>
            <MenuItem value="inactive">Pasif</MenuItem>
          </Select>
        </FormControl>
      </Grid>

      {/* Email ve Şifre */}
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          label="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          margin="normal"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          label="Şifre"
          value={sifre}
          onChange={(e) => setSifre(e.target.value)}
          margin="normal"
          type="password"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Button
          onClick={handleEkle}
          variant="contained"
          color="primary"
          fullWidth
          size="middle"
          sx={{
            padding: '16px 0',
            fontSize: '1rem',
            borderRadius: '8px',
            boxShadow: 3,
            '&:hover': {
              boxShadow: 6,
            },
          }}
        >
          KAYIT OLUŞTUR
        </Button>
      </Grid>
    </>
  );
};

export default KurumFormu;

// src/components/Ogretmen/BakiyeGuncelleDialog.js
import React from 'react';
import { Dialog, DialogTitle, DialogContent, Typography, FormControl, InputLabel, Select, MenuItem, TextField, DialogActions, Button } from '@mui/material';

const BakiyeGuncelleDialog = ({
  open,
  handleClose,
  selectedKurum,
  selectedPackageType,
  setSelectedPackageType,
  currentPackageBalance,
  setCurrentPackageBalance,
  balanceAmount,
  setBalanceAmount,
  balanceReason,
  setBalanceReason,
  handleBalanceSubmit
}) => {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Bakiye Güncelle</DialogTitle>
      <DialogContent>
        <Typography>Öğretmen: {selectedKurum?.ad} {selectedKurum?.soyad}</Typography>
        <FormControl fullWidth margin="normal">
          <InputLabel id="package-select-label">Paket Seçiniz</InputLabel>
          <Select
            labelId="package-select-label"
            value={selectedPackageType}
            onChange={(e) => {
              setSelectedPackageType(e.target.value);
              // Mevcut paket bakiyesini al
              if (e.target.value === 3) {
                setCurrentPackageBalance(selectedKurum.bakiye3Ay || 0);
              } else if (e.target.value === 6) {
                setCurrentPackageBalance(selectedKurum.bakiye6Ay || 0);
              } else if (e.target.value === 12) {
                setCurrentPackageBalance(selectedKurum.bakiye12Ay || 0);
              }
            }}
          >
            <MenuItem value="">
              <em>Paket Seçiniz</em>
            </MenuItem>
            <MenuItem value={3}>3 Aylık</MenuItem>
            <MenuItem value={6}>6 Aylık</MenuItem>
            <MenuItem value={12}>12 Aylık</MenuItem>
          </Select>
        </FormControl>
        {selectedPackageType && (
          <>
            <Typography>Mevcut Paket Bakiyesi: {currentPackageBalance}</Typography>
            <TextField
              margin="dense"
              label="Yeni Bakiye"
              type="number"
              fullWidth
              value={balanceAmount}
              onChange={(e) => setBalanceAmount(Number(e.target.value))}
            />
          </>
        )}
        <TextField
          margin="dense"
          label="Neden"
          type="text"
          fullWidth
          value={balanceReason}
          onChange={(e) => setBalanceReason(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>İptal</Button>
        <Button onClick={handleBalanceSubmit} disabled={!selectedPackageType}>Güncelle</Button>
      </DialogActions>
    </Dialog>
  );
};

export default BakiyeGuncelleDialog;

import React from 'react';
import { Typography, Button, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper, IconButton,} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { API_URLS } from '../../../../config/config';

const ResourcesTable = ({
  resources,
  setResourceDialogOpen,
  setEditingResource,
  showResultDialog,
  triggerRefetch,
}) => {
  const handleEditResourceClick = (resource) => {
    setEditingResource(resource);
    setResourceDialogOpen(true);
  };

  const handleDeleteResource = async (resourceId) => {
    const isConfirmed = window.confirm('Bu kaynağı silmek istediğinize emin misiniz?');
    if (!isConfirmed) {
      return;
    }

    try {
      const response = await fetch(`${API_URLS.ADMIN_URL}roles/resources/${resourceId}`, {
        method: 'DELETE',
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Kaynak silinirken bir hata oluştu.');
      }

      triggerRefetch();
      showResultDialog('Kaynak başarıyla silindi.', 'success');
    } catch (error) {
      console.error(error);
      showResultDialog(error.message, 'error');
    }
  };

  return (
    <>
      <Typography variant="h6">Kaynaklar</Typography>
      <Button
        startIcon={<AddIcon />}
        variant="contained"
        color="primary"
        onClick={() => {
          setEditingResource(null);
          setResourceDialogOpen(true);
        }}
      >
        Kaynak Ekle
      </Button>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Kaynak Adı</TableCell>
              <TableCell>Endpoint'ler</TableCell>
              <TableCell>Metodlar</TableCell>
              <TableCell align="right">İşlemler</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {resources.map((resource) => (
              <TableRow key={resource._id}>
                <TableCell>{resource.name}</TableCell>
                <TableCell>{resource.endpoints.join(', ')}</TableCell>
                <TableCell>{resource.methods.join(', ')}</TableCell>
                <TableCell align="right">
                  <IconButton color="primary" onClick={() => handleEditResourceClick(resource)}>
                    <EditIcon />
                  </IconButton>
                  <IconButton color="secondary" onClick={() => handleDeleteResource(resource._id)}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default ResourcesTable;

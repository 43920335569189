import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow, Paper, IconButton, Typography, Box, Modal, TextField, Button, Input } from '@mui/material';
import { Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { API_URLS } from '../../../config/config';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

function BlogList() {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);

  // Güncelleme ile ilgili state'ler
  const [openEditModal, setOpenEditModal] = useState(false);
  const [editingBlog, setEditingBlog] = useState(null);
  const [editTitle, setEditTitle] = useState('');
  const [editContent, setEditContent] = useState('');
  const [editImageFile, setEditImageFile] = useState(null);

  const fetchBlogs = async () => {
    try {
      const response = await fetch(`${API_URLS.ADMIN_URL}blog`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`
        }
      });
      const data = await response.json();
      if (data.success) {
        setBlogs(data.data);
      }
    } catch (error) {
      console.error('Bloglar alınamadı:', error);
    } finally {
      setLoading(false);
    }
  };

  const deleteBlog = async (id) => {
    if (!window.confirm('Bu blogu silmek istediğinize emin misiniz?')) return;
    try {
      const response = await fetch(`${API_URLS.ADMIN_URL}blog/${id}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`
        }
      });
      const data = await response.json();
      if (data.success) {
        setBlogs(blogs.filter(blog => blog._id !== id));
      } else {
        alert(data.message);
      }
    } catch (error) {
      console.error('Blog silinirken hata:', error);
    }
  };

  const handleEditClick = (blog) => {
    setEditingBlog(blog);
    setEditTitle(blog.title);
    setEditContent(blog.content);
    setEditImageFile(null);
    setOpenEditModal(true);
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    if (!editingBlog) return;

    const formData = new FormData();
    formData.append('title', editTitle);
    formData.append('content', editContent);
    if (editImageFile) {
      formData.append('image', editImageFile);
    }

    try {
      const response = await fetch(`${API_URLS.ADMIN_URL}blog/${editingBlog._id}`, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`
        },
        body: formData
      });
      const data = await response.json();
      if (data.success) {
        // Başarılı güncelleme sonrası listeyi güncelle
        const updated = data.data;
        setBlogs(blogs.map(b => b._id === updated._id ? updated : b));
        alert('Blog başarıyla güncellendi!');
        handleCloseModal();
      } else {
        alert(data.message);
      }
    } catch (error) {
      console.error('Blog güncelleme hatası:', error);
    }
  };

  const handleCloseModal = () => {
    setOpenEditModal(false);
    setEditingBlog(null);
    setEditTitle('');
    setEditContent('');
    setEditImageFile(null);
  };

  useEffect(() => {
    fetchBlogs();
  }, []);

  if (loading) {
    return <Typography>Yükleniyor...</Typography>;
  }

  return (
    <Box>
      <Typography variant="h4" gutterBottom>Blog Listesi</Typography>
      <Paper sx={{ p: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell><strong>Başlık</strong></TableCell>
              <TableCell><strong>Oluşturan</strong></TableCell>
              <TableCell><strong>Oluşturma Tarihi</strong></TableCell>
              <TableCell><strong>Düzenle / Sil</strong></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {blogs.map(blog => (
              <TableRow key={blog._id}>
                <TableCell>{blog.title}</TableCell>
                <TableCell>{blog.createdBy ? blog.createdBy.fullname : 'N/A'}</TableCell>
                <TableCell>{new Date(blog.createdAt).toLocaleString()}</TableCell>
                <TableCell>
                  <IconButton onClick={() => handleEditClick(blog)}>
                    <EditIcon color="primary" />
                  </IconButton>
                  <IconButton onClick={() => deleteBlog(blog._id)}>
                    <DeleteIcon color="error" />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
            {blogs.length === 0 && (
              <TableRow>
                <TableCell colSpan={4}>Hiç blog bulunamadı.</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Paper>

      {/* Güncelleme Modalı */}
      <Modal
        open={openEditModal}
        onClose={handleCloseModal}
        aria-labelledby="edit-modal-title"
        aria-describedby="edit-modal-description"
      >
        <Box 
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            width: '80%',
            maxWidth: 600,
          }}
        >
          <Typography variant="h5" gutterBottom>Blog Güncelle</Typography>
          <form onSubmit={handleUpdate} encType="multipart/form-data">
            <Box mb={2}>
              <TextField
                label="Başlık"
                variant="outlined"
                fullWidth
                value={editTitle}
                onChange={(e) => setEditTitle(e.target.value)}
              />
            </Box>
            <Box mb={2}>
              <Typography variant="subtitle1" sx={{ mb: 1 }}>İçerik</Typography>
              <ReactQuill
                theme="snow"
                value={editContent}
                onChange={setEditContent}
                style={{ minHeight: '300px', marginBottom: '20px', backgroundColor: '#fff' }}
              />
            </Box>
            <Box mb={2}>
              <Typography variant="subtitle1" sx={{ mb: 1 }}>Resim Güncelle (Opsiyonel)</Typography>
              <Input
                type="file"
                inputProps={{ accept: 'image/*' }}
                onChange={(e) => setEditImageFile(e.target.files[0])}
              />
            </Box>
            <Box display="flex" justifyContent="flex-end" gap={2}>
              <Button variant="contained" color="secondary" onClick={handleCloseModal}>İptal</Button>
              <Button type="submit" variant="contained" color="primary">Güncelle</Button>
            </Box>
          </form>
        </Box>
      </Modal>
    </Box>
  );
}

export default BlogList;

// useTachistoscopicGameLogic.js

import { useState, useEffect, useRef } from 'react';
import axiosInstance from '../../../../utils/axiosInstance'; // axios yerine axiosInstance import edildi
import { API_URLS } from '../../../../config/config';

const useTachistoscopicGameLogic = (userData, userRole) => {
  const [difficulty, setDifficulty] = useState('easy');
  const [words, setWords] = useState([]);
  const [currentWord, setCurrentWord] = useState('');
  const [input, setInput] = useState('');
  const [displayedWords, setDisplayedWords] = useState([]);
  const [enteredWords, setEnteredWords] = useState([]);
  const [showingTime, setShowingTime] = useState(20);
  const [writingTime, setWritingTime] = useState(30);
  const [totalShowingTime, setTotalShowingTime] = useState(20);
  const [totalWritingTime, setTotalWritingTime] = useState(30);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isFinished, setIsFinished] = useState(false);
  const [phase, setPhase] = useState('showing');
  const [speed, setSpeed] = useState(3000);
  const [usedIndices, setUsedIndices] = useState([]);
  const [showWord, setShowWord] = useState(false);

  const wordTimeoutRef = useRef(null);
  const showingTimerRef = useRef(null);
  const writingTimerRef = useRef(null);

  const determineTiming = (difficultyLevel) => {
    let initialShowingTime;
    let initialWritingTime;
    let initialSpeed;

    // Default timings based on difficulty
    if (difficultyLevel === 'easy') {
      initialShowingTime = 30;
      initialWritingTime = 30;
      initialSpeed = 3000; // in milliseconds
    } else if (difficultyLevel === 'medium') {
      initialShowingTime = 20;
      initialWritingTime = 45;
      initialSpeed = 2000;
    } else if (difficultyLevel === 'hard') {
      initialShowingTime = 15;
      initialWritingTime = 30;
      initialSpeed = 1000;
    }

    // Adjust timings if user is not logged in or is inactive
    if (!userData || userData.status === 'inactive') {
      initialShowingTime = 15;
      initialWritingTime = 15;
    }

    return {
      showingTime: initialShowingTime,
      writingTime: initialWritingTime,
      speed: initialSpeed,
    };
  };

  const fetchWords = async (difficulty) => {
    try {
      const url = `${API_URLS.HIZLIOKUMA_URL}akanyazi/${difficulty}`;
      const response = await axiosInstance.get(url); // axios yerine axiosInstance kullanımı
      if (response.data && response.data.length > 0) {
        const wordsArray = response.data
          .flatMap((paragraph) => paragraph.content.split(' '))
          .map((word) =>
            word
              .replace(/<\/?[^>]+(>|$)/g, '') 
              .replace(/[^\p{L}\p{N}\s]/gu, '') 
              .trim()
          )
          .filter((word) => word.length >= 3); 
        setWords(wordsArray);
      } else {
        setWords([]);
      }
    } catch (error) {
      console.error('Error fetching words:', error);
      setWords([]);
    }
  };

  useEffect(() => {
    fetchWords(difficulty);

    const { showingTime, writingTime, speed } = determineTiming(difficulty);
    setShowingTime(showingTime);
    setWritingTime(writingTime);
    setTotalShowingTime(showingTime);
    setTotalWritingTime(writingTime);
    setSpeed(speed);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [difficulty, userData]);

  useEffect(() => {
    if (isPlaying && words.length > 0 && phase === 'showing') {
      let usedIndicesSet = new Set();

      const wordDisplayDuration = speed; // Kelime gösterim süresi kullanıcı ayarına göre

      const displayWord = () => {
        if (showingTime <= 0 || usedIndicesSet.size >= words.length) {
          setPhase('writing');
          setIsPlaying(true);
          setShowWord(false);
          return;
        }

        let randomIndex;
        do {
          randomIndex = Math.floor(Math.random() * words.length);
        } while (usedIndicesSet.has(randomIndex));

        usedIndicesSet.add(randomIndex);
        const randomWord = words[randomIndex];

        setCurrentWord(randomWord);
        setShowWord(true);
        setDisplayedWords((prevWords) => [...prevWords, randomWord]);

        wordTimeoutRef.current = setTimeout(() => {
          setShowWord(false);
          setShowingTime((prevTime) => prevTime - wordDisplayDuration / 1000);

          // Bir sonraki kelimeyi göster
          displayWord();
        }, wordDisplayDuration);
      };

      // İlk kelimeyi göster
      displayWord();

      // Gösterim süresi sayacı
      showingTimerRef.current = setInterval(() => {
        setShowingTime((prevTime) => {
          if (prevTime <= 1) {
            clearInterval(showingTimerRef.current);
            return 0;
          }
          return prevTime - 1;
        });
      }, 1000);
    }

    return () => {
      clearTimeout(wordTimeoutRef.current);
      clearInterval(showingTimerRef.current);
    };
  }, [isPlaying, words, phase, speed, showingTime]);

  useEffect(() => {
    if (isPlaying && phase === 'writing') {
      writingTimerRef.current = setInterval(() => {
        setWritingTime((prevTime) => {
          if (prevTime <= 1) {
            clearInterval(writingTimerRef.current);
            setIsPlaying(false);
            setIsFinished(true);
            return 0;
          }
          return prevTime - 1;
        });
      }, 1000);
    }

    return () => {
      clearInterval(writingTimerRef.current);
    };
  }, [isPlaying, phase]);

  const handlePlayPause = () => {
    if (isFinished) return; // Oyun bittiyse işlem yapma
    setIsPlaying((prev) => !prev);
  };

  const handleReset = () => {
    const { showingTime, writingTime, speed } = determineTiming(difficulty);
    setIsPlaying(false);
    setIsFinished(false);
    setInput('');
    setCurrentWord('');
    setShowWord(false);
    setPhase('showing');
    setShowingTime(showingTime);
    setWritingTime(writingTime);
    setTotalShowingTime(showingTime);
    setTotalWritingTime(writingTime);
    setDisplayedWords([]);
    setEnteredWords([]);
    setSpeed(speed);
    setUsedIndices([]);
    clearTimeout(wordTimeoutRef.current);
    clearInterval(showingTimerRef.current);
    clearInterval(writingTimerRef.current);
  };

  const handleSubmit = () => {
    setIsFinished(true);
    setIsPlaying(false);
    clearInterval(writingTimerRef.current);
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInput(value);

    if (value.endsWith(' ')) {
      const word = value.trim();
      if (word.length > 0) {
        setEnteredWords((prevWords) => [...prevWords, word]);
      }
      setInput('');
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      const word = input.trim();
      if (word.length > 0) {
        setEnteredWords((prevWords) => [...prevWords, word]);
      }
      setInput('');
    }
  };

  const handleDifficultyChange = (e) => {
    const newDifficulty = e.target.value;
    setDifficulty(newDifficulty);
    handleReset();
  };

  const handleSpeedChange = (e) => {
    const newSpeed = Number(e.target.value) * 1000;
    setSpeed(newSpeed);
  };

  const getSpeedInSeconds = (speedMs) => {
    return (speedMs / 1000).toFixed(1);
  };

  return {
    difficulty,
    words,
    currentWord: showWord ? currentWord : '',
    input,
    displayedWords,
    enteredWords,
    showingTime,
    writingTime,
    totalShowingTime,
    totalWritingTime,
    isPlaying,
    isFinished,
    phase,
    speed,
    handlePlayPause,
    handleReset,
    handleSubmit,
    handleInputChange,
    handleKeyPress,
    handleDifficultyChange,
    handleSpeedChange,
    getSpeedInSeconds,
    setIsFinished,
  };
};

export default useTachistoscopicGameLogic;

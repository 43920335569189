import { useEffect } from 'react';
import { API_URLS } from '../../config/config';
import axios from '../../utils/axiosInstance';

const useFetchStudentDataForHome = (setUserData) => {
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        // Artık token localStorage'dan alınmıyor, axiosInstance cookie'leri otomatik olarak kullanacak
        const response = await axios.get(`${API_URLS.STUDENT_URL}me`);
        setUserData(response.data);
      } catch (error) {
        console.error('Veri alınırken bir hata oluştu:', error);
      }
    };

    fetchUserData();
  }, [setUserData]);
};

export default useFetchStudentDataForHome;
 
import React from 'react';
import '../styles/rasgeleKelimelerStyle.css';

const GameControls = ({
  difficulty,
  paragraphsList,
  selectedParagraphIndex,
  speed,
  fontSize,
  theme,
  handleDifficultyChange,
  handleParagraphChange,
  handleSpeedChange,
  handleFontSizeChange,
  handleThemeChange,
}) => {
  const getWordCount = (htmlContent) => {
    if (!htmlContent) return 0;
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = htmlContent;
    const textContent = tempDiv.textContent || tempDiv.innerText || '';
    const words = textContent.trim().split(/\s+/);
    return words.filter(w => w).length;
  };

  return (
    <div className="rasgele-kelimeler-game-controls">
      <div>
        <label htmlFor="difficultySelect">Zorluk Seç:</label>
        <select
          id="difficultySelect"
          value={difficulty}
          onChange={handleDifficultyChange}
          aria-label="Zorluk Seç"
        >
          <option value="easy">Kolay</option>
          <option value="medium">Orta</option>
          <option value="hard">Zor</option>
        </select>
      </div>
      <div>
        <label htmlFor="paragraphSelect">Paragraf Seç:</label>
        <select
          id="paragraphSelect"
          value={selectedParagraphIndex}
          onChange={handleParagraphChange}
          aria-label="Paragraf Seç"
        >
          {paragraphsList.map((paragraph, index) => {
            const wordCount = getWordCount(paragraph.content);
            return (
              <option key={index} value={index}>
                {paragraph.title} - {wordCount} kelime
              </option>
            );
          })}
        </select>
      </div>
      <div>
        <label htmlFor="speed">Hız:</label>
        <input
          type="range"
          id="speed"
          min="1"
          max="10"
          step="0.1"
          value={speed}
          onChange={handleSpeedChange}
          aria-label="Hız"
        />
        <span>{speed}</span>
      </div>
      <div>
        <label htmlFor="fontSizeSelect">Yazı Boyutu:</label>
        <select
          id="fontSizeSelect"
          value={fontSize}
          onChange={handleFontSizeChange}
          aria-label="Yazı Boyutu"
        >
          <option value="1rem">Küçük</option>
          <option value="1.5rem">Orta</option>
          <option value="2rem">Büyük</option>
        </select>
      </div>
      <div>
        <label htmlFor="themeSelect">Tema Seç:</label>
        <select
          id="themeSelect"
          value={theme}
          onChange={handleThemeChange}
          aria-label="Tema Seç"
        >
          <option value="dark">Koyu</option>
          <option value="light">Açık</option>
          <option value="blue">Mavi</option>
          <option value="green">Yeşil</option>
        </select>
      </div>
    </div>
  );
};

export default GameControls;

// GameControls.js

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faPause, faRedo } from '@fortawesome/free-solid-svg-icons';
import '../styles/KelimeBulmaOyunu.css';

const GameControls = ({ isPlaying, handlePlayPause, handleReset }) => {
  return (
    <div className="kayu-game-controls">
      <button className="kayu-btn" onClick={handlePlayPause}>
        <FontAwesomeIcon icon={isPlaying ? faPause : faPlay} />{' '}
        {isPlaying ? 'Duraklat' : 'Başlat'}
      </button>
      <button className="kayu-btn" onClick={handleReset}>
        <FontAwesomeIcon icon={faRedo} /> Yeniden Başlat
      </button>
    </div>
  );
};

export default GameControls;

import axios from 'axios';
import { API_URLS } from '../config/config';

const axiosInstance = axios.create({
  baseURL: API_URLS,
  withCredentials: true
});

axiosInstance.interceptors.response.use(
  response => response,
  error => {
    if (
      error.response && 
      error.response.status === 403 && 
      error.response.data.message === 'Başka bir cihazdan giriş yapıldığı için bu oturum sonlandırıldı.'
    ) {
      // Eğer bu uyarı daha önce verilmediyse:
      if (!sessionStorage.getItem('sessionTerminated')) {
        alert('Başka bir cihazda oturum açtınız. Bu oturum sonlandırıldı.');
        sessionStorage.setItem('sessionTerminated', 'true');
        window.location.href = '/ogrenci-giris';
      } else {
        // Daha önce uyarı verildiyse tekrar uyarı vermeden direkt yönlendirme veya hiçbir şey yapmama tercih edilebilir.
        // İsterseniz yönlendirmeyi tamamen kaldırabilirsiniz. Burada örnek olarak sadece yönlendirmeyi koymadık:
        // window.location.href = '/ogrenci-giris';
      }
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;

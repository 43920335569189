import React from 'react';
import {Typography, Button, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper, IconButton, Chip,} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { API_URLS } from '../../../../config/config';

const RolesTable = ({
  roles,
  permissions,
  setRoleDialogOpen,
  setEditingRole,
  showResultDialog,
  triggerRefetch,
}) => {
  const handleEditRoleClick = (role) => {
    setEditingRole(role);
    setRoleDialogOpen(true);
  };

  const handleDeleteRole = async (roleId) => {
    const isConfirmed = window.confirm('Bu rolü silmek istediğinize emin misiniz?');
    if (!isConfirmed) {
      return;
    }

    try {
      if (!roleId) {
        throw new Error('Geçersiz rol ID');
      }

      const response = await fetch(`${API_URLS.ADMIN_URL}roles/${roleId}`, {
        method: 'DELETE',
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Rol silinirken bir hata oluştu.');
      }

      triggerRefetch();
      showResultDialog('Rol başarıyla silindi.', 'success');
    } catch (error) {
      console.error(error);
      showResultDialog(error.message, 'error');
    }
  };

  return (
    <>
      <Typography variant="h6">Roller</Typography>
      <Button
        startIcon={<AddIcon />}
        variant="contained"
        color="primary"
        onClick={() => {
          setEditingRole(null);
          setRoleDialogOpen(true);
        }}
      >
        Rol Ekle
      </Button>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Rol Adı</TableCell>
              <TableCell>Yetkiler</TableCell>
              <TableCell align="right">İşlemler</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {roles.map((role) => (
              <TableRow key={role._id}>
                <TableCell>{role.name}</TableCell>
                <TableCell>
                  {role.permissions.map((permission) => (
                    <Chip key={permission._id} label={permission.name} style={{ margin: 2 }} />
                  ))}
                </TableCell>
                <TableCell align="right">
                  <IconButton color="primary" onClick={() => handleEditRoleClick(role)}>
                    <EditIcon />
                  </IconButton>
                  <IconButton color="secondary" onClick={() => handleDeleteRole(role._id)}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default RolesTable;

import React from 'react';
import '../styles/kayanYazilarUcerStyle.css';

const GameInstructions = () => {
  return (
    <div className="kayan-yazilar-ucer-instructions">
      <h3 className="kayan-yazilar-ucer-instructions-title">Nasıl Oynanır?</h3>
      <ul className="kayan-yazilar-ucer-instructions-list">
        <li>
          <strong>1. Zorluk ve Paragraf Seçin:</strong> Zorluk seviyesini ve okumak istediğiniz paragrafı seçin.
        </li>
        <li>
          <strong>2. Çalışmayı Başlatın:</strong> "Başlat" butonuna tıklayarak çalışmayı başlatın.
        </li>
        <li>
          <strong>3. Kelimeleri Okuyun:</strong> Ekranda beliren üçlü kelimeleri takip edin ve okuyun.
        </li>
        <li>
          Hızı değiştirebilir ve farklı paragraflar seçerek çalışmayı kişiselleştirebilirsiniz.
        </li>
      </ul>
    </div>
  );
};

export default GameInstructions;

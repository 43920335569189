// useKayanYazilarOyunu.js

import { useState, useEffect, useCallback } from 'react';
import { API_URLS } from '../../../../config/config';
import axiosInstance from '../../../../utils/axiosInstance';

export const useKayanYazilarOyunu = (navigate, userData, token) => {
  const [difficulty, setDifficulty] = useState('easy');
  const [paragraphsList, setParagraphsList] = useState([]);
  const [selectedParagraphIndex, setSelectedParagraphIndex] = useState(0);
  const [displayedWords, setDisplayedWords] = useState('');
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [speed, setSpeed] = useState(2);
  const [elapsedTime, setElapsedTime] = useState(0);
  const [gameTime, setGameTime] = useState(0);
  const [timeLeft, setTimeLeft] = useState(0); // Milisaniye cinsinden
  const [message, setMessage] = useState('');
  const [gameOver, setGameOver] = useState(false);
  const [allWords, setAllWords] = useState([]);
  const [intervalDuration, setIntervalDuration] = useState(1000 / speed);

  useEffect(() => {
    const newIntervalDuration = Math.max(1000 / speed, 50);
    setIntervalDuration(newIntervalDuration);
  }, [speed]);

  const prepareWords = (content) => {
    const words = content.match(/(\s+|\S+)/g) || [];
    setAllWords(words);
  };

  const fetchParagraphs = async () => {
    try {
      const url = `${API_URLS.HIZLIOKUMA_URL}akanyazi/${difficulty}`; 
      const response = await axiosInstance.get(url);
      const data = response.data;
      
      setParagraphsList(data);
      setSelectedParagraphIndex(0);

      if (data[0]) {
        prepareWords(data[0].content);
      }
      handleReset();
    } catch (error) {
      console.error('Paragraflar alınırken hata oluştu:', error);
    }
  };

  useEffect(() => {
    fetchParagraphs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [difficulty, token]);

  useEffect(() => {
    if (paragraphsList[selectedParagraphIndex]) {
      prepareWords(paragraphsList[selectedParagraphIndex].content);
      handleReset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedParagraphIndex, paragraphsList]);

  useEffect(() => {
    if (allWords.length > 0 && intervalDuration > 0) {
      const estimatedTime = allWords.length * intervalDuration; // Milisaniye cinsinden
      const maxTime = userData
        ? userData.status === 'inactive'
          ? Math.min(15 * 1000, estimatedTime)
          : estimatedTime
        : Math.min(15 * 1000, estimatedTime);

      setGameTime(maxTime);
      setTimeLeft(maxTime);
    }
  }, [allWords.length, intervalDuration, userData]);

  const updateWords = useCallback(() => {
    if (!isPlaying || allWords.length === 0) return;

    if (currentWordIndex >= allWords.length) {
      setIsPlaying(false);
      setGameOver(true);
      return;
    }

    setDisplayedWords((prev) => prev + allWords[currentWordIndex]);
    setCurrentWordIndex((prev) => prev + 1);
    setElapsedTime((prev) => prev + intervalDuration);

    setTimeLeft((prevTimeLeft) => {
      const newTimeLeft = prevTimeLeft - intervalDuration;
      if (newTimeLeft <= 0) {
        setIsPlaying(false);
        setGameOver(true);
        return 0;
      }
      return newTimeLeft;
    });
  }, [isPlaying, allWords, currentWordIndex, intervalDuration]);

  useEffect(() => {
    let wordInterval;
    if (isPlaying) {
      wordInterval = setInterval(() => {
        updateWords();
      }, intervalDuration);
    }

    return () => clearInterval(wordInterval);
  }, [isPlaying, intervalDuration, updateWords]);

  const handlePlayPause = () => {
    if (gameOver) return;
    if (isPlaying) {
      setMessage(`Skorunuz: ${currentWordIndex} kelime`);
    } else {
      setMessage('');
    }
    setIsPlaying(!isPlaying);
  };

  const handleReset = () => {
    setIsPlaying(false);
    setDisplayedWords('');
    setCurrentWordIndex(0);
    setElapsedTime(0);
    setMessage('');
    setGameOver(false);

    if (allWords.length > 0 && intervalDuration > 0) {
      const estimatedTime = allWords.length * intervalDuration;
      const maxTime = userData
        ? userData.status === 'inactive'
          ? Math.min(15 * 1000, estimatedTime)
          : estimatedTime
        : Math.min(15 * 1000, estimatedTime);

      setGameTime(maxTime);
      setTimeLeft(maxTime);
    }
  };

  const handleSpeedChange = (e) => {
    setSpeed(Number(e.target.value));
  };

  const handleParagraphChange = (e) => {
    setSelectedParagraphIndex(Number(e.target.value));
  };

  const handleDifficultyChange = (e) => {
    setDifficulty(e.target.value);
  };

  const onTimeUp = () => {
    setIsPlaying(false);
    setGameOver(true);
  };

  return {
    difficulty,
    paragraphsList,
    selectedParagraphIndex,
    displayedWords,
    currentWordIndex,
    isPlaying,
    speed,
    elapsedTime,
    gameTime,
    timeLeft,
    message,
    gameOver,
    setGameOver,
    allWords,
    handlePlayPause,
    handleReset,
    handleSpeedChange,
    handleParagraphChange,
    handleDifficultyChange,
    onTimeUp,
    updateWords,
  };
};

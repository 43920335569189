import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { fetchParagraphs } from './hooks/apiService';
import { useGameLogic } from './hooks/useGameLogic';
import NavigationButtons from './components/NavigationButtons';
import GameControls from './components/GameControls';
import TimerDisplay from './components/TimerDisplay';
import DropdownSelectors from './components/DropdownSelectors';
import GameBoard from './components/GameBoard';
import MessageDisplay from './components/MessageDisplay';
import GameExplanation from './components/GameExplanation';
import GameOverModal from './components/GameOverModal';
import './styles/KelimeBulmaOyunu.css';
import { API_URLS } from '../../../config/config';
import axiosInstance from '../../../utils/axiosInstance';

const KelimeBulmaOyunu = () => {
  const [showInstructions, setShowInstructions] = useState(true);
  const [showGameOverModal, setShowGameOverModal] = useState(false);
  const [difficulty, setDifficulty] = useState('easy');
  const [paragraphsList, setParagraphsList] = useState([]);
  const [selectedParagraphIndex, setSelectedParagraphIndex] = useState(0);
  const [paragraph, setParagraph] = useState('');
  const [targetWords, setTargetWords] = useState([]);
  const [userData, setUserData] = useState(null);
  const [userRole, setUserRole] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const { stage, exercise } = location.state || {};
  const token = localStorage.getItem('userToken');

  // Kullanıcı verilerini çek
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const studentResponse = await axiosInstance.get(`${API_URLS.STUDENT_URL}/me`);
        if (
          studentResponse.status === 200 &&
          studentResponse.data &&
          studentResponse.data.status === 'active'
        ) {
          const studentData = studentResponse.data;
          setUserData(studentData);
          setUserRole('student');
          return;
        } else {
          setUserData(null);
          setUserRole(null);
        }
      } catch (error) {
        console.error('Öğrenci verisi alınırken hata oluştu:', error);
      }

      // Öğrenci yoksa veya aktif değilse öğretmen verisine bak
      try {
        const teacherResponse = await fetch(`${API_URLS.TEACHER_URL}/me`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        if (teacherResponse.ok) {
          const teacherData = await teacherResponse.json();
          setUserData(teacherData);
          setUserRole('teacher');
          return;
        }
      } catch (error) {
        console.error('Öğretmen verisi alınırken hata oluştu:', error);
      }

      setUserData(null);
      setUserRole(null);
    };

    fetchUserData();
  }, [token]);

  // Zorluk derecesi değiştiğinde paragrafları getir
  useEffect(() => {
    async function fetchData() {
      try {
        const data = await fetchParagraphs(difficulty);
        setParagraphsList(data);
        if (data.length > 0) {
          setSelectedParagraphIndex(0);
        } else {
          setParagraph('');
          setTargetWords([]);
        }
      } catch (error) {
        console.error('Paragraflar alınırken hata oluştu:', error);
      }
    }
    fetchData();
  }, [difficulty]);

  // Paragraf veya seçili indeks değiştiğinde paragrafı ve hedef kelimeleri güncelle
  useEffect(() => {
    if (paragraphsList.length > 0) {
      const rawParagraphContent =
        paragraphsList[selectedParagraphIndex]?.content || '';

      setParagraph(rawParagraphContent);

      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = rawParagraphContent;
      const plainTextParagraph =
        tempDiv.textContent || tempDiv.innerText || '';

      const wordsArray = plainTextParagraph.match(/[\p{L}\p{N}]+/gu) || [];

      const words = new Set(
        wordsArray.map((word) =>
          word
            .toLowerCase()
            .replace(/[^\p{L}\p{N}_]+/gu, '')
        )
      );

      // Yalnızca 3 veya daha fazla harfli kelimeler hedef olarak alınacak
      setTargetWords(
        Array.from(words)
          .filter((word) => word.length >= 3)
          .sort(() => 0.5 - Math.random())
          .slice(0, 5)
      );
    } else {
      setParagraph('');
      setTargetWords([]);
    }
  }, [paragraphsList, selectedParagraphIndex]);

  const {
    selectedWords,
    correctWords,
    incorrectWords,
    isPlaying,
    timeRemaining,
    score,
    remainingAttempts,
    message,
    handleWordClick,
    handlePlayPause,
    handleReset,
  } = useGameLogic(
    paragraphsList,
    selectedParagraphIndex,
    targetWords,
    userData,
    paragraph
  );

  const startGame = () => {
    setShowInstructions(false);
    handleReset();
  };

  const handleRestart = () => {
    setShowGameOverModal(false);
    handleReset();
  };

  const onGameOver = () => {
    setShowGameOverModal(true);
    updateProgress();
  };

  useEffect(() => {
    if (
      timeRemaining === 0 ||
      remainingAttempts === 0 ||
      (correctWords.length === targetWords.length && targetWords.length > 0)
    ) {
      onGameOver();
    }
  }, [timeRemaining, remainingAttempts, correctWords, targetWords]);

  const updateProgress = async () => {
    try {
      if (userData && userRole) {
        const payload = {
          stageNumber: stage?.number || 1,
          gameName: exercise?.gameName || 'Kelime Bulma ve Seçme',
          score: score,
          time: (userData && userData.status === 'inactive' ? 15 : 60) - timeRemaining,
          wordCount: correctWords.length,
          completed: true,
        };

        if (userRole === 'student') {
          // Öğrenci: cookie üzerinden token otomatik, axiosInstance kullan
          const apiUrl = `${API_URLS.STUDENT_URL}akillikurs/progress/update-game`;
          await axiosInstance.post(apiUrl, payload);
        } else {
          // Öğretmen: localStorage token, Authorization header ile axios kullan
          const apiUrl = `${API_URLS.TEACHER_URL}akillikurs/progress/update-game`;
          const teacherToken = localStorage.getItem('userToken');
          await axios.post(apiUrl, payload, {
            headers: {
              Authorization: `Bearer ${teacherToken}`,
              'Content-Type': 'application/json',
            },
          });
        }
      }
    } catch (error) {
      console.error('İlerleme güncellenirken hata oluştu:', error);
    }
  };

  const handleReturnToPreviousStage = () => {
    if (stage) {
      navigate(
        userRole === 'student' ? '/ogrenci-dashboard' : '/ogretmen-dashboard',
        {
          state: {
            selectedMenu: 'ExerciseList',
            selectedStage: stage,
          },
        }
      );
    } else {
      alert('Aşama bilgisi bulunmamaktadır.');
    }
  };

  const handleDifficultyChange = (e) => {
    setDifficulty(e.target.value);
    setSelectedParagraphIndex(0);
  };

  const handleParagraphChange = (e) => {
    setSelectedParagraphIndex(parseInt(e.target.value));
  };

  const handleClose = () => {
    setShowGameOverModal(false);
  };

  return (
    <div className="kayu-oyun-container">
      <NavigationButtons handleReturnToPreviousStage={handleReturnToPreviousStage} />
      {showInstructions ? (
        <div className="kayu-card text-center">
          <div className="kayu-card-body">
            <div className="kayu-oyun-title">
              <h1 className="kayu-title">Kelime Bulma ve Seçme Çalışması</h1>
            </div>
            <GameExplanation />
            <button className="kayu-btn" onClick={startGame}>
              BAŞLAT
            </button>
          </div>
        </div>
      ) : (
        <div className="kayu-card">
          <div className="kayu-card-body">
            <div className="kayu-control-timer-container">
              <GameControls
                isPlaying={isPlaying}
                handlePlayPause={handlePlayPause}
                handleReset={handleReset}
              />
              <TimerDisplay timeRemaining={timeRemaining} />
            </div>
            <DropdownSelectors
              difficulty={difficulty}
              selectedParagraphIndex={selectedParagraphIndex}
              paragraphsList={paragraphsList}
              handleDifficultyChange={handleDifficultyChange}
              handleParagraphChange={handleParagraphChange}
            />
            <GameBoard
              paragraph={paragraph}
              selectedWords={selectedWords}
              targetWords={targetWords}
              handleWordClick={handleWordClick}
              correctWords={correctWords}
            />
            <MessageDisplay message={message} />
          </div>
        </div>
      )}

      {showGameOverModal && (
        <GameOverModal
          score={score}
          onRestart={handleRestart}
          onClose={handleClose}
          userData={userData}
        />
      )}
    </div>
  );
};

export default KelimeBulmaOyunu;

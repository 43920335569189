import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {AppBar, Toolbar, IconButton, Typography, Drawer, List, ListItem, ListItemIcon, ListItemText, CssBaseline, Collapse, Divider, Grid, Card, CardContent, Avatar, Box,
  useTheme, useMediaQuery,} from '@mui/material';
import {Menu as MenuIcon, Dashboard as DashboardIcon, School as SchoolIcon, Settings as SettingsIcon, Logout as LogoutIcon, ExpandLess, ExpandMore, Payment as PaymentIcon,
  Report as ReportIcon, Business as BranchIcon, Person as TeacherIcon, Event as EventIcon, List as ListIcon, ContactPhone as ContactPhoneIcon, AccountCircle as AccountCircleIcon,
  AdminPanelSettings as AdminPanelSettingsIcon, Group as StudentsIcon, LockReset as LockResetIcon, FormatListBulleted as FormatListBulletedIcon, LibraryBooks as LibraryBooksIcon,
  AddBox as AddBoxIcon, Assignment as AssignmentIcon, LocationCity as LocationCityIcon,} from '@mui/icons-material';
import { styled, keyframes } from '@mui/system';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import { API_URLS } from '../../config/config';
import OkullarContent from './Okullar';
import Ogretmen from './ogretmen/Ogretmen';
import Ogrenci from './ogrenci/Ogrenci';
import BookUploadForm from './ogretmen/kutuphane/Add';
import BookListe from './ogretmen/kutuphane/Liste';
import Tests from './ogretmen/kutuphane/Test';
import Password from './ayarlar/Password';
import Profil from './ayarlar/Profil';
import useFetchUserData from '../../hooks/kurum/useFetchUserData';
import PaymentForm from './payment/PaymentForm';
import PaymentHistory from './payment/PaymentHistory';
import { motion } from 'framer-motion';

// Define drawer width
const drawerWidth = 240;

// Styled Components with Enhanced Styling and New Color Scheme
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.standard,
    }),
    marginLeft: open ? `${drawerWidth}px` : 0,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
    },
    marginTop: '64px',
    overflow: 'auto',
    height: `calc(100vh - 64px)`,
    backgroundColor: '#f0e7ff', // Light purple background
  })
);

const AppBarStyled = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  backgroundColor: '#6a1b9a', // Dark purple
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.easeOut,
    duration: theme.transitions.duration.standard,
  }),
  ...(open && {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: `${drawerWidth}px`,
    },
  }),
}));

const DrawerStyled = styled(Drawer)(({ theme }) => ({
  '& .MuiDrawer-paper': {
    width: drawerWidth,
    background: 'linear-gradient(135deg, #4a148c 0%, #6a1b9a 100%)', // Purple gradient
    color: '#fff',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.standard,
    }),
  },
}));

// Animation Variants for Framer Motion
const containerVariants = {
  hidden: { opacity: 0, x: 50 },
  visible: { opacity: 1, x: 0 },
  exit: { opacity: 0, x: -50 },
};

const KurumsalDashboard = () => {
  const [drawerOpen, setDrawerOpen] = useState(true);
  const [userData, setUserData] = useState(null);
  const navigate = useNavigate();
  const [open, setOpen] = useState({});
  const [selectedMenu, setSelectedMenu] = useState('Anasayfa');

  useFetchUserData(setUserData);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleClick = (menu) => {
    setOpen((prevOpen) => ({ ...prevOpen, [menu]: !prevOpen[menu] }));
  };

  const handleLogout = async () => {
    try {
      await fetch(API_URLS.KURUMSAL_URL + 'logout', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`,
          'Content-Type': 'application/json',
        },
      });
      localStorage.removeItem('userToken');
      navigate('/kurumsal-giris');
    } catch (error) {
      console.error('Çıkış işlemi sırasında bir hata oluştu', error);
    }
  };

  const menuItems = [
    { text: 'Anasayfa', icon: <DashboardIcon />, action: () => setSelectedMenu('Anasayfa') },
    {
      text: 'Bakiye Yükle',
      icon: <PaymentIcon />,
      action: () => setSelectedMenu('BakiyeYukle'),
    },

    {
      text: 'Satın Alınan Paketler',
      icon: <PaymentIcon />,
      action: () => setSelectedMenu('PaymentHistory'),
    },
    {
      text: 'Kurumlar',
      icon: <SchoolIcon />,
      subMenus: [
        { text: 'Okullar', icon: <BranchIcon />, action: () => setSelectedMenu('Okullar') },
      ],
      action: () => handleClick('Kurumlar'),
    },
    { text: 'Öğretmenler', icon: <TeacherIcon />, action: () => setSelectedMenu('Öğretmenler') },
    { text: 'Öğrenciler', icon: <StudentsIcon />, action: () => setSelectedMenu('Öğrenciler') },
    {
      text: 'Kütüphane',
      icon: <LibraryBooksIcon />,
      subMenus: [
        { text: 'Kitap Ekle', icon: <AddBoxIcon />, action: () => setSelectedMenu('KitapEkle') },
        { text: 'Tüm Liste', icon: <FormatListBulletedIcon />, action: () => setSelectedMenu('KitapListe') },
        { text: 'Testler', icon: <AssignmentIcon />, action: () => setSelectedMenu('Testler') },
      ],
      action: () => handleClick('Kütüphane'),
    },
    {
      text: 'Ayarlar',
      icon: <SettingsIcon />,
      subMenus: [
        { text: 'Şifre Güncelle', icon: <LockResetIcon />, action: () => setSelectedMenu('SifreSet') },
        { text: 'Profil', icon: <AccountCircleIcon />, action: () => setSelectedMenu('Profil') },
        { text: 'Yetkilendirme Rolleri', icon: <AdminPanelSettingsIcon />, action: () => setSelectedMenu('YetkilendirmeRolleri') },
      ],
      action: () => handleClick('Ayarlar'),
    },
    { text: 'Çıkış Yap', icon: <LogoutIcon />, action: handleLogout },
  ];

  const renderContent = () => {
    if (!userData) {
      return <Typography paragraph>Yükleniyor...</Typography>;
    }
    switch (selectedMenu) {
      case 'BakiyeYukle':
        return <PaymentForm userType="Kurumsal" userId={userData._id} />;   
      case 'PaymentHistory':
        return <PaymentHistory />;
      case 'Okullar':
        return <OkullarContent />;
      case 'Öğretmenler':
        return <Ogretmen />;
      case 'Öğrenciler':
        return <Ogrenci />;
      case 'KitapEkle':
        return <BookUploadForm />;
      case 'KitapListe':
        return <BookListe />;
      case 'Testler':
        return <Tests />;
      case 'SifreSet':
        return <Password />;
      case 'Profil':
        return <Profil />;
      default:
        return (
          <motion.div
            variants={containerVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
            transition={{ duration: 0.5 }}
          >
            <Card
              sx={{
                background: 'linear-gradient(135deg, #6a1b9a 0%, #8e24aa 100%)', // Gradient background
                color: '#fff',
                mb: 3,
                boxShadow: 4,
                borderRadius: 3,
                padding: 2,
              }}
            >
              <CardContent>
                <Typography
                  variant="h5"
                  sx={{
                    textAlign: 'center',
                    fontWeight: 'bold',
                    mb: 2,
                    fontFamily: 'Roboto, sans-serif',
                    color: '#fff',
                  }}
                >
                  KURUM PROFİLİ
                </Typography>
                <Divider sx={{ borderColor: '#ffffff33' }} />
                <Grid container spacing={2} justifyContent="center" sx={{ mt: 2 }}>
                  <Grid item>
                    <Avatar
                      sx={{
                        backgroundColor: '#8e24aa',
                        color: '#fff',
                        width: 100,
                        height: 100,
                        boxShadow: 4,
                        border: '3px solid #fff',
                      }}
                      component={motion.div}
                      whileHover={{ scale: 1.1 }}
                      transition={{ type: 'spring', stiffness: 300 }}
                    >
                      <SchoolIcon sx={{ fontSize: '3rem' }} />
                    </Avatar>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="h6"
                      align="center"
                      sx={{ fontFamily: 'Roboto, sans-serif', color: '#fff', fontWeight: '500' }}
                    >
                      {userData.kurum_adi}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="body1"
                      align="center"
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        color: '#e0e0e0',
                      }}
                    >
                      <ContactPhoneIcon sx={{ mr: 1, fontSize: '1.5rem' }} />
                      {userData.telefon}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="body1"
                      align="center"
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        color: '#e0e0e0',
                      }}
                    >
                      <LocationCityIcon sx={{ mr: 1, fontSize: '1.5rem' }} />
                      {userData.sehirAdi} / {userData.ilceAdi}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="body1"
                      align="center"
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        color: '#e0e0e0',
                      }}
                    >
                      <AccountBalanceWalletIcon sx={{ mr: 1, fontSize: '1.5rem' }} />
                      Bakiye: {userData.bakiye} 
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography variant="body1" align="center" sx={{ color: '#fff' }}>
                      Hesap Durumu: {userData.status === 'active' ? 'Aktif' : 'Pasif'}
                    </Typography>
                  </Grid>

                  {userData.abonelik && (
                    <>
                      <Grid item xs={12}>
                        <Typography variant="body1" align="center" sx={{ color: '#fff' }}>
                          Abonelik Başlangıç: {new Date(userData.abonelik.baslangicTarihi).toLocaleDateString()}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="body1" align="center" sx={{ color: '#fff' }}>
                          Abonelik Bitiş: {new Date(userData.abonelik.bitisTarihi).toLocaleDateString()}
                        </Typography>
                      </Grid>
                    </>
                  )}
                </Grid>
              </CardContent>
            </Card>

            <Grid container spacing={2} sx={{ mt: 2 }}>
          {/* 3 Aylık Bakiye */}
          <Grid item xs={12} sm={6} md={4}>
            <Card sx={{ backgroundColor: '#e3f2fd' }}>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  3 Aylık Bakiye
                </Typography>
                <Typography variant="h4" color="primary">
                  {userData.bakiye3Ay} 
                </Typography>
              </CardContent>
            </Card>
            </Grid>

          {/* 6 Aylık Bakiye */}
          <Grid item xs={12} sm={6} md={4}>
            <Card sx={{ backgroundColor: '#e8f5e9' }}>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  6 Aylık Bakiye
                </Typography>
                <Typography variant="h4" color="success.main">
                  {userData.bakiye6Ay} 
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          {/* 12 Aylık Bakiye */}
          <Grid item xs={12} sm={6} md={4}>
            <Card sx={{ backgroundColor: '#fff3e0' }}>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  12 Aylık Bakiye
                </Typography>
                <Typography variant="h4" color="warning.main">
                  {userData.bakiye12Ay} 
                </Typography>
              </CardContent>
            </Card>
          </Grid>

            </Grid>
          
          </motion.div>
        );
    }
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBarStyled position="fixed" open={drawerOpen}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer}
            edge="start"
            sx={{ mr: 2, ...(drawerOpen && { display: 'none' }) }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div" sx={{ fontFamily: 'Roboto, sans-serif' }}>
            Kurumsal Panel
          </Typography>
        </Toolbar>
      </AppBarStyled>
      <DrawerStyled
        variant={isMobile ? 'temporary' : 'persistent'}
        anchor="left"
        open={drawerOpen}
        onClose={toggleDrawer}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
      >
        <Toolbar />
        <Divider />
        <List>
          {menuItems.map((item, index) => (
            <React.Fragment key={index}>
              <ListItem
                button
                onClick={() => {
                  item.action && item.action();
                  if (isMobile) toggleDrawer();
                }}
                sx={{
                  '&:hover': {
                    backgroundColor: '#8e24aa', // Lighter purple on hover
                  },
                  backgroundColor: selectedMenu === item.text ? '#8e24aa' : 'inherit',
                  transition: 'background-color 0.3s',
                }}
              >
                <ListItemIcon sx={{ color: '#fff' }}>{item.icon}</ListItemIcon>
                <ListItemText primary={item.text} />
                {item.subMenus ? (open[item.text] ? <ExpandLess /> : <ExpandMore />) : null}
              </ListItem>
              {item.subMenus && (
                <Collapse in={open[item.text]} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {item.subMenus.map((subItem, subIndex) => (
                      <ListItem
                        button
                        key={subIndex}
                        onClick={() => {
                          subItem.action();
                          if (isMobile) toggleDrawer();
                        }}
                        sx={{
                          pl: 4,
                          '&:hover': {
                            backgroundColor: '#8e24aa',
                          },
                          backgroundColor: selectedMenu === subItem.text ? '#8e24aa' : 'inherit',
                          transition: 'background-color 0.3s',
                        }}
                      >
                        <ListItemIcon sx={{ color: '#fff' }}>{subItem.icon}</ListItemIcon>
                        <ListItemText primary={subItem.text} />
                      </ListItem>
                    ))}
                  </List>
                </Collapse>
              )}
            </React.Fragment>
          ))}
        </List>
      </DrawerStyled>
      <Main open={drawerOpen}>
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          {renderContent()}
        </motion.div>
      </Main>
    </Box>
  );
};

export default KurumsalDashboard;

// Header.js

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import '../styles/tachistoscopicReadingStyle.css';

const Header = ({ handleReturnToPreviousStage }) => {
  const navigate = useNavigate();

  return (
    <div className="tachisto-header">
      <button onClick={() => navigate(-1)} aria-label="Geri Dön">
        <FontAwesomeIcon icon={faArrowLeft} /> Geri Dön
      </button>
      <button onClick={() => navigate('/')} aria-label="Ana Sayfa">
        <FontAwesomeIcon icon={faHome} /> Ana Sayfa
      </button>
      <button onClick={handleReturnToPreviousStage} aria-label="Önceki Aşamaya Dön">
        <FontAwesomeIcon icon={faArrowLeft} /> Önceki Aşamaya Dön
      </button>
    </div>
  );
};

export default Header;

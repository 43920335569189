import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Select, MenuItem, TextField } from '@mui/material';

const BalanceTransferDialog = ({ open, onClose, packageType, setPackageType, transferAmount, setTransferAmount, onTransfer }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Bakiye Transferi</DialogTitle>
      <DialogContent>
        <Select
          fullWidth
          value={packageType}
          onChange={(e) => setPackageType(e.target.value)}
          margin="normal"
        >
          <MenuItem value="bakiye3Ay">3 Ay</MenuItem>
          <MenuItem value="bakiye6Ay">6 Ay</MenuItem>
          <MenuItem value="bakiye12Ay">12 Ay</MenuItem>
        </Select>
        <TextField
          fullWidth
          label="Tutar"
          type="number"
          value={transferAmount}
          onChange={(e) => setTransferAmount(Number(e.target.value))}
          margin="normal"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>İptal</Button>
        <Button onClick={onTransfer} color="primary" variant="contained">
          Transfer Et
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BalanceTransferDialog;

// MessageAlert.js

import React from 'react';
import '../styles/harfIzgarasiStyle.css';

const MessageAlert = ({ message }) => {
  return (
    message && (
      <div className="harf-izgarasi-message">
        {message === 'Doğru!' || message.includes('Tüm harfleri') ? (
          <span style={{ color: '#4CAF50' }}>{message}</span>
        ) : (
          <span style={{ color: '#F44336' }}>{message}</span>
        )}
      </div>
    )
  );
};

export default MessageAlert;

import React from 'react';
import '../styles/kayanYazilarUcerStyle.css';

const GameTimer = ({ timeLeft, isPlaying }) => {
  const formatTime = (seconds) => {
    if (seconds < 0) seconds = 0;
    const totalSeconds = Math.ceil(seconds);
    const minutes = Math.floor(totalSeconds / 60)
      .toString()
      .padStart(2, '0');
    const remainingSeconds = (totalSeconds % 60).toString().padStart(2, '0');
    return `${minutes}:${remainingSeconds}`;
  };

  return (
    <div className="kayan-yazilar-ucer-timer">
      <span>Kalan Süre: {formatTime(timeLeft)}</span>
    </div>
  );
};

export default GameTimer;

import React from 'react';
import { Grid, TextField, FormControl, InputLabel, Select, MenuItem, Button } from '@mui/material';

const TeacherForm = ({
  ad, setAd,
  soyad, setSoyad,
  telefonHata,
  email, setEmail,
  sifre, setSifre,
  sehirListesi,
  secilenSehirId, setSecilenSehirId,
  secilenIlceId, setSecilenIlceId,
  secilenSehirinIlceleri,
  handleTelefonChange,
  handleSehirChange,
  handleIlceChange,
  handleEkle,
  telefon, setTelefon // telefon state'i hala gerekli
}) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <TextField fullWidth label="Adı" value={ad} onChange={(e) => setAd(e.target.value)} margin="normal" />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField fullWidth label="Soyadı" value={soyad} onChange={(e) => setSoyad(e.target.value)} margin="normal" />
      </Grid>
      
      <Grid item xs={12} sm={6}>
        <FormControl fullWidth margin="normal">
          <InputLabel id="sehir-select-label">Şehir</InputLabel>
          <Select
            labelId="sehir-select-label"
            value={secilenSehirId}
            onChange={handleSehirChange}
          >
            {sehirListesi.map((sehir) => (
              <MenuItem key={sehir._id} value={sehir._id}>
                {sehir.sehir_adi}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      {secilenSehirId && (
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth margin="normal">
            <InputLabel id="ilce-select-label">İlçe</InputLabel>
            <Select
              labelId="ilce-select-label"
              value={secilenIlceId}
              onChange={handleIlceChange}
            >
              {secilenSehirinIlceleri.map((ilce) => (
                <MenuItem key={ilce._id} value={ilce._id}>
                  {ilce.ilce_adi}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      )}

      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          label="Telefon"
          value={telefon}
          onChange={handleTelefonChange}
          error={telefonHata}
          helperText={telefonHata ? 'Telefon numarası 05XX XXX XXXX formatında olmalıdır.' : ''}
          margin="normal"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField fullWidth label="Email" value={email} onChange={(e) => setEmail(e.target.value)} margin="normal" />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField fullWidth label="Şifre" value={sifre} onChange={(e) => setSifre(e.target.value)} margin="normal" type="password" />
      </Grid>
      <Grid item xs={12}>
        <Button onClick={handleEkle} variant="contained" color="primary" fullWidth>
          Ekle
        </Button>
      </Grid>
    </Grid>
  );
};

export default TeacherForm;

import { useState, useEffect, useCallback } from 'react';
import { API_URLS } from '../../../../config/config';
import axiosInstance from '../../../../utils/axiosInstance';

export const useKayanYazilarIkiserOyunu = (navigate, userData, token) => {
  const [difficulty, setDifficulty] = useState('easy');
  const [paragraphsList, setParagraphsList] = useState([]);
  const [selectedParagraphIndex, setSelectedParagraphIndex] = useState(0);
  const [displayedWords, setDisplayedWords] = useState([]);
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [speed, setSpeed] = useState(2); // Hız tam sayı ve saniyedeki kelime çifti
  const [elapsedTime, setElapsedTime] = useState(0);
  const [gameTime, setGameTime] = useState(0);
  const [timeLeft, setTimeLeft] = useState(0);
  const [message, setMessage] = useState('');
  const [gameOver, setGameOver] = useState(false);
  const [allWords, setAllWords] = useState([]);

  // İçeriği kelimelere ayıran fonksiyon
  const prepareWords = (content) => {
    const words = content.trim().split(/\s+/);
    setAllWords(words);
  };

  // Zorluk seviyesine göre paragrafları çekiyoruz
  useEffect(() => {
    const fetchParagraphs = async () => {
      try {
        const url = `${API_URLS.HIZLIOKUMA_URL}akanyazi/${difficulty}`;
        const response = await axiosInstance.get(url);
        const data = response.data;

        setParagraphsList(data);
        setSelectedParagraphIndex(0);

        if (data[0]) {
          prepareWords(data[0].content);
        }
        handleReset();
      } catch (error) {
        console.error('Paragraflar alınırken hata oluştu:', error);
      }
    };

    fetchParagraphs();
  }, [difficulty, token]); // token değişirse tekrar çek

  // Paragraf değiştiğinde kelimeleri güncelliyoruz
  useEffect(() => {
    if (paragraphsList[selectedParagraphIndex]) {
      prepareWords(paragraphsList[selectedParagraphIndex].content);
      handleReset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedParagraphIndex, paragraphsList]);

  // Oyun süresini kelime sayısına ve hıza göre ayarlıyoruz
  useEffect(() => {
    if (paragraphsList[selectedParagraphIndex]) {
      const words = allWords;
      const wordCount = words.length;
      const totalWordPairs = Math.ceil(wordCount / 2);

      const estimatedTime = totalWordPairs / speed; // Zamanı ondalıklı olarak hesaplıyoruz

      const maxTime = userData
        ? userData.status === 'inactive'
          ? 15
          : estimatedTime
        : 15;

      setGameTime(maxTime);
      setTimeLeft(maxTime);
    }
  }, [paragraphsList, selectedParagraphIndex, speed, userData, allWords]);

  // Kelimeleri güncelleyen fonksiyon
  const updateWords = useCallback(() => {
    if (!isPlaying || !paragraphsList[selectedParagraphIndex]) return;

    const words = allWords;

    if (currentWordIndex >= words.length) {
      setIsPlaying(false);
      setGameOver(true);
      return;
    }

    const nextWords = words.slice(currentWordIndex, currentWordIndex + 2);
    setDisplayedWords((prev) => [...prev, nextWords.join(' ')]);
    setCurrentWordIndex((prev) => prev + 2);

    const intervalInSeconds = 1 / speed;

    setElapsedTime((prev) => prev + intervalInSeconds);
    setTimeLeft((prev) => prev - intervalInSeconds);

    if (timeLeft - intervalInSeconds <= 0) {
      setIsPlaying(false);
      setGameOver(true);
    }
  }, [
    isPlaying,
    paragraphsList,
    selectedParagraphIndex,
    currentWordIndex,
    speed,
    timeLeft,
    allWords,
  ]);

  // Zamanlayıcıyı ayarlıyoruz
  useEffect(() => {
    let wordInterval;
    if (isPlaying) {
      const intervalDuration = 1000 / speed;
      wordInterval = setInterval(() => {
        updateWords();
      }, intervalDuration);
    }

    return () => clearInterval(wordInterval);
  }, [isPlaying, speed, updateWords]);

  // Oyun başlatma/duraklatma fonksiyonu
  const handlePlayPause = () => {
    if (gameOver) return;
    if (isPlaying) {
      setMessage(`Skorunuz: ${currentWordIndex} kelime`);
    } else {
      setMessage('');
    }
    setIsPlaying(!isPlaying);
  };

  // Oyunu sıfırlama fonksiyonu
  const handleReset = () => {
    setIsPlaying(false);
    setDisplayedWords([]);
    setCurrentWordIndex(0);
    setElapsedTime(0);
    setMessage('');
    setGameOver(false);

    // Oyun süresini yeniden hesaplıyoruz
    if (paragraphsList[selectedParagraphIndex]) {
      const words = allWords;
      const wordCount = words.length;
      const totalWordPairs = Math.ceil(wordCount / 2);
      const estimatedTime = totalWordPairs / speed;

      const maxTime = userData
        ? userData.status === 'inactive'
          ? 15
          : estimatedTime
        : 15;

      setGameTime(maxTime);
      setTimeLeft(maxTime);
    }
  };

  // Hız değiştiğinde zamanı güncelliyoruz
  const handleSpeedChange = (e) => {
    const newSpeed = Number(e.target.value);
    setSpeed(newSpeed);

    if (paragraphsList[selectedParagraphIndex]) {
      const words = allWords;
      const wordCount = words.length;
      const totalWordPairs = Math.ceil(wordCount / 2);
      const wordPairsLeft = totalWordPairs - Math.floor(currentWordIndex / 2);
      const estimatedTime = wordPairsLeft / newSpeed;

      setTimeLeft(estimatedTime);
    }
  };

  // Paragraf değişikliği
  const handleParagraphChange = (e) => {
    setSelectedParagraphIndex(Number(e.target.value));
  };

  // Zorluk seviyesi değişikliği
  const handleDifficultyChange = (e) => {
    setDifficulty(e.target.value);
  };

  return {
    difficulty,
    paragraphsList,
    selectedParagraphIndex,
    displayedWords,
    currentWordIndex,
    isPlaying,
    speed,
    elapsedTime,
    gameTime,
    timeLeft,
    message,
    gameOver,
    setGameOver,
    handlePlayPause,
    handleReset,
    handleSpeedChange,
    handleParagraphChange,
    handleDifficultyChange,
    updateWords,
  };
};

import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button,} from '@mui/material';
import { API_URLS } from '../../../../config/config';

const ResourceDialog = ({
  open,
  setOpen,
  resources,
  editingResource,
  setEditingResource,
  showResultDialog,
  triggerRefetch,
}) => {
  const [resourceName, setResourceName] = useState('');
  const [resourceEndpoints, setResourceEndpoints] = useState('');
  const [resourceMethods, setResourceMethods] = useState('');

  useEffect(() => {
    if (editingResource) {
      setResourceName(editingResource.name);
      setResourceEndpoints(editingResource.endpoints.join(', '));
      setResourceMethods(editingResource.methods.join(', '));
    } else {
      setResourceName('');
      setResourceEndpoints('');
      setResourceMethods('');
    }
  }, [editingResource]);

  const handleSubmit = async () => {
    if (!resourceName.trim()) {
      showResultDialog('Kaynak adı boş bırakılamaz', 'error');
      return;
    }

    const resourceData = {
      name: resourceName,
      endpoints: resourceEndpoints.split(',').map((endpoint) => endpoint.trim()),
      methods: resourceMethods.split(',').map((method) => method.trim()),
    };

    try {
      let response;
      if (editingResource) {
        response = await fetch(`${API_URLS.ADMIN_URL}roles/resources/${editingResource._id}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(resourceData),
        });
      } else {
        response = await fetch(`${API_URLS.ADMIN_URL}roles/resources`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(resourceData),
        });
      }

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Bir hata oluştu');
      }

      triggerRefetch();
      setOpen(false);
      setEditingResource(null);
      showResultDialog('Kaynak başarıyla kaydedildi.', 'success');
    } catch (error) {
      console.error(error);
      showResultDialog(error.message, 'error');
    }
  };

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>{editingResource ? 'Kaynak Düzenle' : 'Yeni Kaynak Ekle'}</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Kaynak Adı"
          type="text"
          fullWidth
          value={resourceName}
          onChange={(e) => setResourceName(e.target.value)}
        />
        <TextField
          margin="dense"
          label="Endpoint'ler"
          type="text"
          fullWidth
          helperText="Virgülle ayrılmış endpoint'ler (örn: /users,/users/:id)"
          value={resourceEndpoints}
          onChange={(e) => setResourceEndpoints(e.target.value)}
        />
        <TextField
          margin="dense"
          label="Metodlar"
          type="text"
          fullWidth
          helperText="Virgülle ayrılmış HTTP metodları (örn: GET,POST,DELETE)"
          value={resourceMethods}
          onChange={(e) => setResourceMethods(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)}>İptal</Button>
        <Button onClick={handleSubmit}>{editingResource ? 'Güncelle' : 'Ekle'}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ResourceDialog;

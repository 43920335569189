// Description.js

import React from 'react';
import '../styles/KelimeTamamlamaOyunu.css';

const Description = () => {
  return (
    <div className="kayu-game-explanation">
      <h4>Talimatlar</h4>
      <ul className="kayu-instructions-list">
        <li>Çalışmayı başlatmak için "Başlat" butonuna basın.</li>
        <li>Size verilen paragrafta eksik kelimeleri tamamlayın.</li>
        <li>Seçeneklerden doğru kelimeleri seçin ve boşluklara yerleştirin.</li>
        <li>Çalışma süresi dolmadan tüm kelimeleri tamamlamaya çalışın.</li>
      </ul>
    </div>
  );
};

export default Description;

import React, { useState, useEffect } from 'react';
import { 
  Table, TableBody, TableCell, TableContainer, 
  TableHead, TableRow, Paper, Card, CardContent, Typography, 
  Collapse, Box, TextField, FormControl, InputLabel, Select, MenuItem, 
  Stack
} from '@mui/material';
import DOMPurify from 'dompurify';
import parse, { domToReact } from 'html-react-parser';
import { API_URLS } from '../../../../config/config';
import useFetchUserData from '../../../../hooks/ogretmen/useFetchTeacherUserData';

const AllBooks = () => {
  const [books, setBooks] = useState([]);
  const [userData, setUserData] = useState(null);
  const [expandedBookId, setExpandedBookId] = useState(null);

  // Filtreleme için eklenen state'ler
  const [searchQuery, setSearchQuery] = useState('');
  const [difficultyFilter, setDifficultyFilter] = useState('');

  // Boş elementleri tanımlıyoruz
  const voidElements = new Set([
    'area', 'base', 'br', 'col', 'embed', 'hr', 'img', 'input', 
    'keygen', 'link', 'meta', 'param', 'source', 'track', 'wbr'
  ]);

  useFetchUserData(setUserData);

  const fetchBooks = async () => {
    if (!userData || !userData._id) {
      console.log("Kullanıcı bilgisi yüklenemedi veya kullanıcı ID'si bulunamadı.");
      return; 
    }

    const userId = userData._id;  
    console.log("Kullanıcı ID: " + userId);

    try {
      const response = await fetch(`${API_URLS.TEACHER_URL}kutuphane/teacher-admin/${userId}`);
      const data = await response.json();
      if (response.ok) {
        setBooks(data);
      } else {
        console.error('Kitapları yüklerken bir sorun oluştu: ' + data.message);
      }
    } catch (error) {
      console.error('Fetch işlemi sırasında hata oluştu:', error);
    }
  }; 

  useEffect(() => {
    fetchBooks();
  }, [userData]); 

  const handleRowClick = (bookId) => {
    setExpandedBookId((prev) => (prev === bookId ? null : bookId));
  };

  // HTML içeriği için güvenli render seçenekleri
  const htmlRenderOptions = {
    replace: (domNode) => {
      if (domNode.type === 'tag') {
        if (voidElements.has(domNode.name)) {
          // Boş elementleri olduğu gibi bırakıyoruz
          return React.createElement(
            domNode.name,
            { key: domNode.name, ...domNode.attribs },
            null
          );
        } else {
          // Diğer elementlerin çocuklarını işlemeye devam ediyoruz
          return React.createElement(
            domNode.name,
            { key: domNode.name, ...domNode.attribs },
            domToReact(domNode.children, htmlRenderOptions)
          );
        }
      }
    },
  };

  // Filtrelenmiş kitap listesi
  const filteredBooks = books.filter((book) => {
    const matchesSearch = book.title?.toLowerCase().includes(searchQuery.toLowerCase());
    const matchesDifficulty = difficultyFilter
      ? book.difficulty === difficultyFilter
      : true;
    return matchesSearch && matchesDifficulty;
  });

  return (
    <Card sx={{ mt: 2 }}>
      <CardContent>
        <Typography variant="h5" sx={{ mb: 2, fontWeight: 'bold', textAlign: 'center' }}>
          Tüm Liste
        </Typography>

        {/* Filtreleme Alanları */}
        <Stack direction="row" spacing={2} sx={{ mb: 2, justifyContent: 'center' }}>
          <TextField
            label="Kitap Ara"
            variant="outlined"
            size="small"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <FormControl variant="outlined" size="small" sx={{ minWidth: 120 }}>
            <InputLabel>Zorluk</InputLabel>
            <Select
              label="Zorluk"
              value={difficultyFilter}
              onChange={(e) => setDifficultyFilter(e.target.value)}
            >
              <MenuItem value="">Tümü</MenuItem>
              <MenuItem value="easy">Kolay</MenuItem>
              <MenuItem value="medium">Orta</MenuItem>
              <MenuItem value="hard">Zor</MenuItem>
            </Select>
          </FormControl>
        </Stack>

        <TableContainer component={Paper}>
          <Table>
            <TableHead sx={{ backgroundColor: '#f5f5f5' }}>
              <TableRow>
                <TableCell sx={{ fontWeight: 'bold' }}>Kitap Adı</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>Zorluk</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredBooks.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={2} align="center">
                    Aradığınız kriterlere uygun kitap bulunmuyor.
                  </TableCell>
                </TableRow>
              ) : (
                filteredBooks.map((book) => {
                  const isExpanded = expandedBookId === book._id;
                  const sanitizedContent = DOMPurify.sanitize(book.content || '');
                  
                  return (
                    <React.Fragment key={book._id}>
                      <TableRow 
                        hover 
                        onClick={() => handleRowClick(book._id)} 
                        sx={{ 
                          cursor: 'pointer', 
                          transition: 'background-color 0.3s', 
                          '&:hover': { backgroundColor: '#f0f0f0' } 
                        }}
                      >
                        <TableCell>{book.title}</TableCell>
                        <TableCell>
                          {book.difficulty === 'easy'
                            ? 'Kolay'
                            : book.difficulty === 'medium'
                            ? 'Orta'
                            : book.difficulty === 'hard'
                            ? 'Zor'
                            : book.difficulty}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell colSpan={2} sx={{ p:0 }}>
                          <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                            <Box 
                              sx={{ 
                                p: 2, 
                                backgroundColor: '#fefaf2', 
                                fontFamily: 'serif', 
                                lineHeight: '1.6', 
                                borderLeft: '4px solid #cab18c', 
                                mt: 1
                              }}
                            >
                              <Typography variant="h6" sx={{ fontFamily: 'serif', mb: 2 }}>
                                Kitap İçeriği
                              </Typography>
                              <Typography 
                                variant="body1" 
                                sx={{ whiteSpace: 'pre-wrap' }}
                                component="div"
                              >
                                {parse(sanitizedContent, htmlRenderOptions)}
                              </Typography>
                            </Box>
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  );
                })
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
};

export default AllBooks;

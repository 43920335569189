// useFetchUserData.js
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { API_URLS } from '../../config/config';
import axios from '../../utils/axiosInstance';

const useFetchUserData = (setUserData) => {
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        // Artık token localStorage'da değil, cookie üzerinden gidiyor.
        // Bu yüzden header veya token kontrolü yapmaya gerek yok.
        const response = await axios.get(`${API_URLS.STUDENT_URL}/me`);
        
        // Eğer istek başarılıysa user data'yı set et
        setUserData(response.data);
      } catch (error) {
        // Hata durumunda login sayfasına yönlendir
        navigate('/ogrenci-giris');
      }
    };

    fetchUserData();
  }, [navigate, setUserData]);
};

export default useFetchUserData;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import { useRasgeleKelimelerOyunu } from './hooks/useRasgeleKelimelerOyunu';
import HeaderSection from './components/HeaderSectionRandomWords';
import GameControls from './components/GameControlsRandomWords';
import GameDisplay from './components/GameDisplayRandomWords';
import GameOverModal from './components/GameOverModalRandomWords';
import GameTimer from './components/GameTimerRandomWords';
import { API_URLS } from '../../../config/config';
import './styles/rasgeleKelimelerStyle.css';
import axiosInstance from '../../../utils/axiosInstance';

const RasgeleKelimelerOyunu = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { stage, exercise } = location.state || {};
  const token = localStorage.getItem('userToken');

  const [userData, setUserData] = useState(null);
  const [userRole, setUserRole] = useState(null);

  const [showInstructions, setShowInstructions] = useState(true);
  const [showGameOver, setShowGameOver] = useState(false);

  // Tema ve font boyutu
  const [fontSize, setFontSize] = useState('1.5rem');
  const [theme, setTheme] = useState('dark');

  const handleFontSizeChange = (e) => {
    setFontSize(e.target.value);
  };

  const handleThemeChange = (e) => {
    setTheme(e.target.value);
  };

  // Kullanıcı verileri
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const studentResponse = await axiosInstance.get(`${API_URLS.STUDENT_URL}/me`);
        if (
          studentResponse.status === 200 &&
          studentResponse.data &&
          studentResponse.data.status === 'active'
        ) {
          const studentData = studentResponse.data;
          setUserData(studentData);
          setUserRole('student');
          return;
        } else {
          setUserData(null);
          setUserRole(null);
        }
      } catch (error) {
        console.error('Öğrenci verisi alınırken hata oluştu:', error);
      }

      try {
        const teacherResponse = await fetch(`${API_URLS.TEACHER_URL}/me`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        if (teacherResponse.ok) {
          const teacherData = await teacherResponse.json();
          setUserData(teacherData);
          setUserRole('teacher');
          return;
        }
      } catch (error) {
        console.error('Öğretmen verisi alınırken hata oluştu:', error);
      }

      setUserData(null);
      setUserRole(null);
    };

    fetchUserData();
  }, [token]);

  const {
    difficulty,
    paragraphsList,
    selectedParagraphIndex,
    isPlaying,
    speed,
    elapsedTime,
    timeLeft,
    message,
    gameOver,
    setGameOver: setGameOverFromHook,
    handlePlayPause,
    handleReset,
    handleSpeedChange,
    handleParagraphChange,
    handleDifficultyChange,
    displayedWordsGroup,
    allWords,
    currentWordIndex,
  } = useRasgeleKelimelerOyunu(navigate, userData, token);

  const startGame = () => {
    handleReset();
    setShowInstructions(false);
  };

  useEffect(() => {
    if (gameOver && !showGameOver) {
      setShowGameOver(true);
    }
  }, [gameOver, showGameOver]);

  useEffect(() => {
    if (gameOver) {
      const updateProgress = async () => {
        try {
          if (userData && userRole) {
            const totalWords = allWords.length;
            const payload = {
              stageNumber: stage?.number || 1,
              gameName: exercise?.gameName || 'Rasgele Kelimeler',
              score: totalWords,
              time: Math.floor(elapsedTime / 1000),
              wordCount: totalWords,
              completed: true,
            };

            let apiUrl;
            if (userRole === 'student') {
              apiUrl = `${API_URLS.STUDENT_URL}akillikurs/progress/update-game`;
              await axiosInstance.post(apiUrl, payload);
            } else {
              apiUrl = `${API_URLS.TEACHER_URL}akillikurs/progress/update-game`;
              const teacherToken = localStorage.getItem('userToken');
              await axios.post(apiUrl, payload, {
                headers: {
                  Authorization: `Bearer ${teacherToken}`,
                  'Content-Type': 'application/json',
                },
              });
            }
          }
        } catch (error) {
          console.error('İlerleme güncellenirken hata oluştu:', error);
        }
      };
      updateProgress();
    }
  }, [gameOver, allWords, elapsedTime, userData, userRole, stage, exercise, token]);

  const handleRestart = () => {
    setShowGameOver(false);
    setGameOverFromHook(false);
    handleReset();
  };

  const handleReturnToPreviousStage = () => {
    if (stage) {
      navigate(
        userRole === 'student' ? '/ogrenci-dashboard' : '/ogretmen-dashboard',
        {
          state: {
            selectedMenu: 'ExerciseList',
            selectedStage: stage,
          },
        }
      );
    } else {
      alert('Aşama bilgisi bulunmamaktadır.');
    }
  };

  const handleClose = () => {
    setShowGameOver(false);
    setGameOverFromHook(false);
  };

  return (
    <>
      {showInstructions ? (
        <div className={`rasgele-kelimeler-intro-container theme-${theme}`}>
          <div className="rasgele-kelimeler-intro">
            <div className="rasgele-kelimeler-card">
              <div className="rasgele-kelimeler-card-body">
                <HeaderSection
                  handleReturnToPreviousStage={handleReturnToPreviousStage}
                />
                <h1 className="rasgele-kelimeler-title">Rasgele Kelimeler</h1>
                <p className="rasgele-kelimeler-description">
                  Bu çalışmada ekrana rastgele konumlarda kelime veya ikili kelime grupları belirecek. Amacınız bu kelimeleri en hızlı şekilde okumak.
                </p>
                <h3 className="rasgele-kelimeler-instructions-title">
                  Talimatlar
                </h3>
                <ul className="rasgele-kelimeler-instructions-list">
                  <li>
                    <strong>1. Zorluk ve Paragraf Seçin:</strong> Zorluk
                    seviyesini ve okumak istediğiniz paragrafı seçin.
                  </li>
                  <li>
                    <strong>2. Çalışmayı Başlatın:</strong> "Başlat" butonuna
                    tıklayarak çalışmayı başlatın.
                  </li>
                  <li>
                    <strong>3. Kelimeleri Okuyun:</strong> Ekranda beliren kelime veya kelime çiftlerini takip edip okuyun.
                  </li>
                </ul>
                <button className="rasgele-kelimeler-button" onClick={startGame}>
                  BAŞLAT
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className={`rasgele-kelimeler-game-container theme-${theme}`}>
          <div className="rasgele-kelimeler-gameplay">
            <HeaderSection
              handleReturnToPreviousStage={handleReturnToPreviousStage}
            />
        
            <GameTimer timeLeft={timeLeft} isPlaying={isPlaying} />
            <GameDisplay
              isPlaying={isPlaying}
              speed={speed}
              paragraphsList={paragraphsList}
              selectedParagraphIndex={selectedParagraphIndex}
              displayedWordsGroup={displayedWordsGroup}
              handlePlayPause={handlePlayPause}
              handleReset={handleReset}
              message={message}
              gameOver={gameOver}
              fontSize={fontSize}
              currentWordIndex={currentWordIndex}
            />

        <GameControls
              difficulty={difficulty}
              paragraphsList={paragraphsList}
              selectedParagraphIndex={selectedParagraphIndex}
              speed={speed}
              fontSize={fontSize}
              theme={theme}
              handleDifficultyChange={handleDifficultyChange}
              handleParagraphChange={handleParagraphChange}
              handleSpeedChange={handleSpeedChange}
              handleFontSizeChange={handleFontSizeChange}
              handleThemeChange={handleThemeChange}
            />
          </div>
        </div>
      )}

      {showGameOver && (
        <GameOverModal
          score={allWords.length}
          elapsedTime={Math.floor(elapsedTime / 1000)}
          onRestart={handleRestart}
          onClose={handleClose}
          userData={userData}
        />
      )}
    </>
  );
};

export default RasgeleKelimelerOyunu;

import React, { useState } from 'react';
import { Typography, Button, Card, CardContent, Grid, FormControl, InputLabel, Select, MenuItem,} from '@mui/material';
import { API_URLS } from '../../../config/config';

function ActivateAccountForm() {
  const [message, setMessage] = useState('');
  const [selectedPackageDuration, setSelectedPackageDuration] = useState('');

  const handleActivate = async () => {
    try {
      const response = await fetch(API_URLS.TEACHER_URL + 'activate', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          selectedPackageDuration, // Ensure this matches the server-side code
        }),
      });

      const data = await response.json();

      if (response.ok) {
        setMessage('Hesabınız başarılı bir şekilde aktif edildi.');
      } else {
        setMessage('Hesap aktif etme başarısız: ' + data.message);
      }
    } catch (error) {
      console.error('Hesap aktif etme işlemi sırasında bir hata oluştu', error);
      setMessage('Hesap aktif etme işlemi sırasında bir hata oluştu.');
    }
  };

  return (
    <Card>
      <CardContent>
        <Typography variant="h5" gutterBottom>
          Hesabınızı Aktif Edin
        </Typography>
        <Typography variant="body1" gutterBottom>
          Hesabınızı aktif etmek için aşağıdaki paketi seçip butona tıklayın.
        </Typography>
        <Grid container spacing={2}>
          {/* Paket Listesi */}
          <Grid item xs={12}>
            <FormControl fullWidth margin="normal">
              <InputLabel id="package-select-label">Paket Süresi</InputLabel>
              <Select
                labelId="package-select-label"
                value={selectedPackageDuration}
                onChange={(e) => setSelectedPackageDuration(e.target.value)}
              >
                <MenuItem value="">
                  <em>Paket Süresi Seçiniz</em>
                </MenuItem>
                <MenuItem value={3}>3 Aylık</MenuItem>
                <MenuItem value={6}>6 Aylık</MenuItem>
                <MenuItem value={12}>12 Aylık</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleActivate}
              disabled={!selectedPackageDuration}
            >
              Hesabı Aktif Et
            </Button>
          </Grid>
        </Grid>
        {message && (
          <Typography variant="body1" color="error" sx={{ mt: 2 }}>
            {message}
          </Typography>
        )}
      </CardContent>
    </Card>
  );
}

export default ActivateAccountForm;

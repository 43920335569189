// GameDisplay.js

import React from 'react';
import parse, { domToReact } from 'html-react-parser';
import '../styles/kayanYazilarStyle.css';
import ProgressBar from './ProgressBar'; // İlerleme Çubuğu bileşeni

const GameDisplay = ({
  isPlaying,
  paragraphsList,
  selectedParagraphIndex,
  displayedWords,
  allWords,
  handlePlayPause,
  handleReset,
  message,
  gameOver,
  fontSize,
}) => {
  const voidElements = new Set([
    'area',
    'base',
    'br',
    'col',
    'embed',
    'hr',
    'img',
    'input',
    'keygen',
    'link',
    'meta',
    'param',
    'source',
    'track',
    'wbr',
  ]);

  const options = {
    replace: (node) => {
      if (node.type === 'tag') {
        if (voidElements.has(node.name)) {
          return React.createElement(
            node.name,
            { key: node.name, ...node.attribs },
            null
          );
        } else {
          return React.createElement(
            node.name,
            { key: node.name, ...node.attribs },
            domToReact(node.children, options)
          );
        }
      }
    },
  };

  // İlerleme yüzdesini hesapla
  const progress =
    allWords.length > 0
      ? (displayedWords.length / allWords.join('').length) * 100
      : 0;

  return (
    <>
      <div className="kayan-yazilar-controls">
        <button
          onClick={handlePlayPause}
          disabled={gameOver}
          className="kayan-yazilar-button"
          aria-label={isPlaying ? 'Duraklat' : 'Başlat'}
        >
          {isPlaying ? 'Duraklat' : 'Başlat'}
        </button>
        <button
          onClick={handleReset}
          className="kayan-yazilar-button"
          aria-label="Yeniden Başlat"
        >
          Yeniden Başlat
        </button>
      </div>
      {/* İlerleme Çubuğu */}
      <ProgressBar progress={progress} />
      <div className="kayan-yazilar-game-display" style={{ fontSize }}>
        {!paragraphsList[selectedParagraphIndex] ? (
          <div className="kayan-yazilar-message">
            Paragraf bulunamadı. Lütfen farklı bir paragraf veya zorluk
            seviyesi seçin.
          </div>
        ) : (
          <div className="kayan-yazilar-content">
            {/* Kelimeleri soldan sağa doğru birleştirerek gösteriyoruz */}
            {parse(displayedWords, options)}
          </div>
        )}
      </div>
      {message && <div className="kayan-yazilar-message">{message}</div>}
    </>
  );
};

export default GameDisplay;

// components/ExamArea.js

import React, { useEffect, useRef } from 'react';
import DOMPurify from 'dompurify';

const ExamArea = ({
  isRunning,
  readingTime,
  paragraf,
  handlePauseResume,
  handleFinishReading,
  loading,
}) => {
  const paragraphRef = useRef(null);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes} dk ${seconds} sn`;
  };

  // Otomatik kaydırma fonksiyonu
  useEffect(() => {
    if (isRunning && paragraphRef.current) {
      const paragraphElement = paragraphRef.current;
      const totalScrollHeight =
        paragraphElement.scrollHeight - paragraphElement.clientHeight;

      // Paragrafın kelime sayısını hesaplayalım
      const wordCount = paragraf.split(' ').length;

      // Ortalama okuma hızı (kelime/dakika)
      const averageReadingSpeed = 200; // İsterseniz bu değeri dinamik yapabilirsiniz

      // Kullanıcının bu paragrafı okuması için gereken süre (saniye)
      const totalTimeInSeconds = (wordCount / averageReadingSpeed) * 60;

      const scrollInterval = 100; // Her 100 milisaniyede bir kaydır
      const scrollAmount =
        totalScrollHeight / (totalTimeInSeconds * (1000 / scrollInterval));

      const scrollTimer = setInterval(() => {
        if (paragraphElement.scrollTop < totalScrollHeight) {
          paragraphElement.scrollTop += scrollAmount;
        } else {
          clearInterval(scrollTimer);
        }
      }, scrollInterval);

      return () => clearInterval(scrollTimer);
    }
  }, [isRunning, paragraf]);

  return (
    <div className="okuma-seviye-testi-exam-area">
    <div className="okuma-seviye-testi-timer">
  Geçen Süre: {formatTime(readingTime)}
</div>
      <div className="okuma-seviye-testi-paragraph" ref={paragraphRef}>
        {loading ? (
          <div>Yükleniyor...</div>
        ) : (
          <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(paragraf) }} />
        )}
      </div>
      <div className="okuma-seviye-testi-controls">
        <button className="okuma-seviye-testi-button" onClick={handlePauseResume}>
          {isRunning ? 'Duraklat' : 'Devam Et'}
        </button>
        <button className="okuma-seviye-testi-button" onClick={handleFinishReading}>
          Okumayı Bitirdim
        </button>
      </div>
    </div>
  );
};

export default ExamArea;

// src/components/AboutUs.js

import React from 'react';
import { Typography, Container, Grid, Card, CardContent, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { CheckCircle as CheckCircleIcon } from '@mui/icons-material';
import Header from '../Header';
import Footer from '../Footer';

function AboutUs() {
  return (
    <div>
    <Header />
    <Container maxWidth="md" sx={{ mt: 5, mb: 5 }}>
      <Card>
        <CardContent>
          <Typography variant="h4" align="center" gutterBottom>
            Hakkımızda
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>Hızlı Okuma Programımız</strong> ile öğrencilerin okuma hızlarını 3-4 katına çıkarıyoruz. 21 derslik yapay zeka destekli programımız sayesinde, öğrenciler seviyelerine uygun metinlerle göz egzersizleri yapmaktadır. Bu sayede okuduğunu anlama yeteneği gelişmekte ve hafıza teknikleri öğrenilmektedir.
          </Typography>
          <Typography variant="body1" paragraph>
           Özellikle sınavlara katılacak öğrenciler için tasarlanmış olan programımız, uzun ve karmaşık soruları hızlı ve kolay bir şekilde anlamalarını sağlamaktadır.
          </Typography>
          <Typography variant="h5" gutterBottom>
            Programımızın Özellikleri:
          </Typography>
          <List>
            <ListItem>
              <ListItemIcon>
                <CheckCircleIcon color="primary" />
              </ListItemIcon>
              <ListItemText primary="Haftada 2 ders, toplam 21 derslik program" />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <CheckCircleIcon color="primary" />
              </ListItemIcon>
              <ListItemText primary="Okuma hızını 150-200 kelimeden 350-400 kelimeye çıkarma" />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <CheckCircleIcon color="primary" />
              </ListItemIcon>
              <ListItemText primary="Okuduğunu anlama ve hafıza teknikleri" />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <CheckCircleIcon color="primary" />
              </ListItemIcon>
              <ListItemText primary="Online veya yüz yüze eğitim seçenekleri" />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <CheckCircleIcon color="primary" />
              </ListItemIcon>
              <ListItemText primary="Programımız öğrenciler ve adaylar tarafından kendileri de yapabilmektedir" />
            </ListItem>
          </List>
          <Typography variant="body1" paragraph>
          Göz egzersizleri ve alıştırmaları bilgisayar veya tablet üzerinden yapılması gerekmektedir.
          </Typography>
        
          <Typography variant="h5" gutterBottom>
            İletişim
          </Typography>
          <Typography variant="body1" paragraph>
            Eğitim danışmanlarımızdan bilgi almak için bizimle iletişime geçebilirsiniz.
          </Typography>
          <Typography variant="h6" align="center">
            Telefon - 1: +90 530 303 35 39
          </Typography>
          <Typography variant="h6" align="center">
            Telefon - 2: +90 850 304 98 29
          </Typography>
        </CardContent>
      </Card>
    </Container>

    <Footer />
    </div>
  );
}

export default AboutUs;

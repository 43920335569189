import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button, FormControl, InputLabel, Select, OutlinedInput, Chip, MenuItem,} from '@mui/material';
import { API_URLS } from '../../../../config/config';

const RoleDialog = ({
  open,
  setOpen,
  roles,
  permissions,
  editingRole,
  setEditingRole,
  showResultDialog,
  triggerRefetch,
}) => {
  const [roleName, setRoleName] = useState('');
  const [selectedPermissions, setSelectedPermissions] = useState([]);

  useEffect(() => {
    if (editingRole) {
      setRoleName(editingRole.name);
      setSelectedPermissions(editingRole.permissions.map((perm) => perm._id));
    } else {
      setRoleName('');
      setSelectedPermissions([]);
    }
  }, [editingRole]);

  const handlePermissionChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedPermissions(typeof value === 'string' ? value.split(',') : value);
  };

  const handleSubmit = async () => {
    if (!roleName.trim()) {
      showResultDialog('Rol adı boş bırakılamaz', 'error');
      return;
    }

    const roleData = {
      name: roleName,
      permissions: selectedPermissions,
    };

    try {
      let response;
      if (editingRole) {
        response = await fetch(`${API_URLS.ADMIN_URL}roles/${editingRole._id}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(roleData),
        });
      } else {
        response = await fetch(`${API_URLS.ADMIN_URL}roles`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(roleData),
        });
      }

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Bir hata oluştu');
      }

      triggerRefetch();
      setOpen(false);
      setEditingRole(null);
      showResultDialog('Rol başarıyla kaydedildi.', 'success');
    } catch (error) {
      console.error(error);
      showResultDialog(error.message, 'error');
    }
  };

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>{editingRole ? 'Rol Düzenle' : 'Yeni Rol Ekle'}</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Rol Adı"
          type="text"
          fullWidth
          value={roleName}
          onChange={(e) => setRoleName(e.target.value)}
        />
        <FormControl fullWidth margin="normal">
          <InputLabel>Yetkiler</InputLabel>
          <Select
            multiple
            value={selectedPermissions}
            onChange={handlePermissionChange}
            input={<OutlinedInput label="Yetkiler" />}
            renderValue={(selected) => (
              <div>
                {selected.map((permissionId) => (
                  <Chip
                    key={permissionId}
                    label={permissions.find((p) => p._id === permissionId)?.name}
                    style={{ margin: 2 }}
                  />
                ))}
              </div>
            )}
          >
            {permissions.map((permission) => (
              <MenuItem key={permission._id} value={permission._id}>
                {permission.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)}>İptal</Button>
        <Button onClick={handleSubmit}>{editingRole ? 'Güncelle' : 'Ekle'}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default RoleDialog;

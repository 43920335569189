import { useState, useEffect } from 'react';
import { API_URLS } from '../../../../config/config';
import axiosInstance from '../../../../utils/axiosInstance';

export const useRasgeleTamMetinOyunu = (navigate, userData, token) => {
  const [difficulty, setDifficulty] = useState('easy');
  const [paragraphsList, setParagraphsList] = useState([]);
  const [selectedParagraphIndex, setSelectedParagraphIndex] = useState(0);

  const [isPlaying, setIsPlaying] = useState(false);
  const [speed, setSpeed] = useState(2);
  const [elapsedTime, setElapsedTime] = useState(0);
  const [timeLeft, setTimeLeft] = useState(0);
  const [message, setMessage] = useState('');
  const [gameOver, setGameOver] = useState(false);

  const [totalWords, setTotalWords] = useState(0);
  const [currentWordIndex, setCurrentWordIndex] = useState(0); 

  const prepareWords = (content) => {
    const words = content
      .replace(/\n/g, ' ')
      .split(/\s+/)
      .filter(w => w.trim().length > 0);
    setTotalWords(words.length);
  };

  const fetchParagraphs = async () => {
    try {
      const url = `${API_URLS.HIZLIOKUMA_URL}akanyazi/${difficulty}`;
      const response = await axiosInstance.get(url);
      const data = response.data;
      setParagraphsList(data);
      setSelectedParagraphIndex(0);

      if (data[0]) {
        prepareWords(data[0].content);
      }
      handleReset();
    } catch (error) {
      console.error('Paragraflar alınırken hata:', error);
    }
  };

  useEffect(() => {
    fetchParagraphs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [difficulty, token]);

  useEffect(() => {
    if (paragraphsList[selectedParagraphIndex]) {
      prepareWords(paragraphsList[selectedParagraphIndex].content);
      handleReset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedParagraphIndex, paragraphsList]);

  useEffect(() => {
    if (totalWords > 0) {
      const estimatedTime = totalWords * (1000 / speed);
      const maxTime = userData
        ? userData.status === 'inactive'
          ? Math.min(15 * 1000, estimatedTime)
          : estimatedTime
        : Math.min(15 * 1000, estimatedTime);

      setTimeLeft(maxTime);
    }
  }, [totalWords, speed, userData]);

  useEffect(() => {
    if (isPlaying && !gameOver) {
      setCurrentWordIndex(totalWords); 
    }
  }, [isPlaying, gameOver, totalWords]);

  useEffect(() => {
    let timerInterval;
    if (isPlaying && !gameOver) {
      timerInterval = setInterval(() => {
        setElapsedTime((prev) => prev + 50);
        setTimeLeft((prev) => {
          const newTime = prev - 50;
          if (newTime <= 0) {
            setIsPlaying(false);
            setGameOver(true);
            return 0;
          }
          return newTime;
        });
      }, 50);
    }

    return () => clearInterval(timerInterval);
  }, [isPlaying, gameOver]);

  const handlePlayPause = () => {
    if (gameOver) return;
    if (isPlaying) {
      setMessage(`Şu ana kadar ${currentWordIndex} kelime gösterildi.`);
    } else {
      setMessage('');
    }
    setIsPlaying(!isPlaying);
  };

  const handleReset = () => {
    setIsPlaying(false);
    setElapsedTime(0);
    setMessage('');
    setGameOver(false);
    if (totalWords > 0) {
      setCurrentWordIndex(0);
      const estimatedTime = totalWords * (1000 / speed);
      const maxTime = userData
        ? userData.status === 'inactive'
          ? Math.min(15 * 1000, estimatedTime)
          : estimatedTime
        : Math.min(15 * 1000, estimatedTime);

      setTimeLeft(maxTime);
    }
  };

  const handleSpeedChange = (e) => {
    setSpeed(Number(e.target.value));
  };

  const handleParagraphChange = (e) => {
    setSelectedParagraphIndex(Number(e.target.value));
  };

  const handleDifficultyChange = (e) => {
    setDifficulty(e.target.value);
  };

  return {
    difficulty,
    paragraphsList,
    selectedParagraphIndex,
    isPlaying,
    speed,
    elapsedTime,
    timeLeft,
    message,
    gameOver,
    setGameOver,
    totalWords,
    currentWordIndex,
    handlePlayPause,
    handleReset,
    handleSpeedChange,
    handleParagraphChange,
    handleDifficultyChange,
  };
};

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, CardContent, Typography, Grid, Box, Container, CardMedia, Button } from '@mui/material';
import ImageNotSupportedIcon from '@mui/icons-material/ImageNotSupported';
import { API_URLS } from '../../config/config';
import Header from '../components/Header';
import Footer from '../components/Footer';

function AllBlogsSection() {
  const [blogs, setBlogs] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetch(`${API_URLS.HOME_URL}/blog`)
      .then(response => response.json())
      .then(data => {
        if (data.success && Array.isArray(data.data)) {
          setBlogs(data.data);
        }
      })
      .catch(error => {
        console.error('Bloglar alınırken hata:', error);
      });
  }, []);

  const handleBlogClick = (id) => {
    navigate(`/blog/${id}`);
  };

  return (
    <>
      <Header />
      <Box 
        sx={{ 
          py: { xs: 6, md: 10 }, 
          backgroundColor: '#f0f4f8',
          fontFamily: 'Lato, sans-serif', 
        }}
      >
        <Container maxWidth="lg">
          <Typography 
            variant="h3" 
            align="center" 
            gutterBottom 
            sx={{ 
              fontWeight: '700', 
              mb: { xs: 4, md: 6 },
              color: '#222',
              fontFamily: 'Playfair Display, serif', 
              letterSpacing: '1px',
              fontSize: { xs: '2.2rem', md: '3rem' }, 
              textShadow: '1px 1px 2px rgba(0,0,0,0.3)',
            }}
          >
           Blog Yazıları
          </Typography>
          <Grid container spacing={4} justifyContent="center">
            {blogs.map((blog) => {
              const imageUrl = blog.image ? `${API_URLS.BLOG_RESİM_URL}/${blog.image.replace(/\\/g, '/')}` : null;
              const plainContent = blog.content.replace(/<[^>]+>/g, '');

              return (
                <Grid item xs={12} sm={6} md={4} key={blog._id}>
                  <Card
                    elevation={6}
                    sx={{
                      borderRadius: 3,
                      overflow: 'hidden',
                      background: 'linear-gradient(135deg, #FFC371 0%, #FF5F6D 100%)',
                      display: 'flex',
                      flexDirection: 'column',
                      height: '100%',
                      position: 'relative',
                      transition: 'transform 0.3s, box-shadow 0.3s',
                      '&:hover': {
                        transform: 'translateY(-8px)',
                        boxShadow: '0 12px 28px rgba(0,0,0,0.2)',
                      },
                    }}
                  >
                    {imageUrl ? (
                      <CardMedia
                        component="img"
                        height="220"
                        image={imageUrl}
                        alt={blog.title}
                        sx={{
                          objectFit: 'cover',
                          filter: 'brightness(0.95)',
                          transition: 'filter 0.3s',
                          '&:hover': {
                            filter: 'brightness(1)',
                          }
                        }}
                      />
                    ) : (
                      <Box
                        sx={{
                          height: 220,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          backgroundColor: 'rgba(255,255,255,0.3)',
                        }}
                      >
                        <ImageNotSupportedIcon sx={{ fontSize: 60, color: '#fff', textShadow: '1px 1px 2px rgba(0,0,0,0.3)' }} />
                      </Box>
                    )}
                    <CardContent 
                      sx={{ 
                        p: 3, 
                        display: 'flex', 
                        flexDirection: 'column', 
                        flexGrow: 1, 
                        background: 'rgba(255, 255, 255, 0.8)',
                        backdropFilter: 'blur(5px)',
                        borderRadius: '0 0 3px 3px'
                      }}
                    >
                      <Typography 
                        variant="h5"
                        sx={{ 
                          fontWeight: '700',
                          mb: 2,
                          fontFamily: 'Playfair Display, serif',
                          fontSize: { xs: '1.8rem', md: '2rem' },
                          color: '#333',
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                          textAlign: 'center',
                          textShadow: '1px 1px 2px rgba(0,0,0,0.3)'
                        }}
                        title={blog.title}
                      >
                        {blog.title}
                      </Typography>
                      <Typography 
                        variant="body1" 
                        sx={{ 
                          fontFamily: 'Lato, sans-serif',
                          fontWeight: '500',
                          fontSize: { xs: '1.05rem', md: '1.2rem' },
                          color: '#444',
                          overflow: 'hidden',
                          display: '-webkit-box',
                          WebkitBoxOrient: 'vertical',
                          WebkitLineClamp: 3,
                          mb: 3,
                          textAlign: 'center',
                        }}
                      >
                        {plainContent.length > 120 ? plainContent.slice(0,120) + '...' : plainContent}
                      </Typography>
                      <Box sx={{ mt: 'auto', textAlign: 'center' }}>
                        <Button 
                          variant="contained" 
                          onClick={() => handleBlogClick(blog._id)}
                          sx={{
                            textTransform: 'none',
                            fontWeight: '600',
                            fontFamily: 'Lato, sans-serif',
                            borderRadius: 2,
                            background: 'linear-gradient(135deg, #FF5F6D 0%, #FFC371 100%)',
                            color: '#fff',
                            boxShadow: '0 4px 10px rgba(0,0,0,0.15)',
                            '&:hover': {
                              background: 'linear-gradient(135deg, #FF5F6D 10%, #FFC371 90%)',
                              boxShadow: '0 4px 14px rgba(0,0,0,0.3)',
                            },
                            width: '100%',
                          }}
                        >
                          Devamını Oku
                        </Button>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
              );
            })}
            {blogs.length === 0 && (
              <Grid item xs={12}>
                <Typography 
                  align="center" 
                  variant="body1" 
                  sx={{ 
                    mt: 4, 
                    color: '#666', 
                    fontStyle: 'italic', 
                    fontFamily: 'Lato, sans-serif',
                    fontSize: '1.5rem',
                    fontWeight: '500'
                  }}
                >
                  Henüz blog yazısı bulunmuyor.
                </Typography>
              </Grid>
            )}
          </Grid>
        </Container>
      </Box>

      <Footer />
    </>
  );
}

export default AllBlogsSection;

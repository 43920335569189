// hooks/useFetchUserData.js
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { API_URLS } from '../../config/config';

const useFetchTeacherDataForHome = (setUserData) => {
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      const token = localStorage.getItem('userToken');
      if (!token) {
        navigate('/ogretmen-giris');
      } else {
        try {
          const response = await fetch(`${API_URLS.TEACHER_URL}/me`, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          });
          if (response.ok) {
            
            const data = await response.json();
            setUserData(data);
          } else {
            throw new Error('Veri alınırken bir hata oluştu');
          }
        } catch (error) {
          console.error(error);
          navigate('/ogretmen-giris');
        }
      }
    };

    fetchUserData();
  }, [navigate, setUserData]);
};

export default useFetchTeacherDataForHome;

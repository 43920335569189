// useRotatingRectangleGame.js

import { useRef, useState, useCallback, useEffect } from 'react';

const CANVAS_WIDTH = 800;
const CANVAS_HEIGHT = 600;

export default function useGameLogic(userData) {
  const canvasRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [speed, setSpeed] = useState(1);

  const [score, setScore] = useState(0);
  const [isGameOver, setIsGameOver] = useState(false);

  // Ölçekleme faktörleri - x ve y eksenlerinde daha fazla ölçeklendirme
  const scalingFactors = [
    [0.2, 0.2],
    [0.4, 0.4],
    [0.6, 0.6],
    [0.8, 0.8],
    [1.0, 1.0],
    [1.2, 1.4],
    [1.4, 1.8],
    [1.6, 2.2],
    [1.8, 2.6],
    [2.0, 3.0],
    [2.2, 3.4],
    [2.4, 3.8],
    [2.6, 4.2],
    [2.8, 4.6],
    [3.0, 5.0],
  ];

  const [animationStep, setAnimationStep] = useState(0);

  const getInitialGameTime = () => {
    if (userData) {
      return userData.status === 'inactive' ? 15 : 60;
    }
    return 15;
  };

  const [initialGameTime, setInitialGameTime] = useState(getInitialGameTime());
  const [gameTime, setGameTime] = useState(initialGameTime);

  useEffect(() => {
    if (userData) {
      const newGameTime = userData.status === 'inactive' ? 15 : 60;
      setGameTime(newGameTime);
      setInitialGameTime(newGameTime);
      setSpeed(1);
    } else {
      setGameTime(15);
      setInitialGameTime(15);
      setSpeed(1);
    }
  }, [userData]);

  const updateLetters = useCallback(() => {
    const turkishAlphabet = [
      'A', 'B', 'C', 'Ç', 'D', 'E', 'F', 'G',
      'Ğ', 'H', 'I', 'İ', 'J', 'K', 'L', 'M',
      'N', 'O', 'Ö', 'P', 'R', 'S', 'Ş', 'T',
      'U', 'Ü', 'V', 'Y', 'Z',
    ];
    return Array.from({ length: 4 }, () =>
      turkishAlphabet[Math.floor(Math.random() * turkishAlphabet.length)]
    );
  }, []);

  const [letters, setLetters] = useState(updateLetters);

  // Oyun süresi geri sayımı
  useEffect(() => {
    let gameTimeInterval;
    if (isPlaying && gameTime > 0) {
      gameTimeInterval = setInterval(() => {
        setGameTime((prevTime) => prevTime - 1);
      }, 1000);
    } else if (gameTime === 0) {
      setIsPlaying(false);
      setIsGameOver(true);
    }

    return () => clearInterval(gameTimeInterval);
  }, [isPlaying, gameTime]);

  // Harfleri ve animasyon adımını güncelle
  useEffect(() => {
    let lettersInterval;
    if (isPlaying && gameTime > 0) {
      const updateInterval = Math.max(600 / speed, 100);
      lettersInterval = setInterval(() => {
        setLetters(updateLetters());
        setScore((prevScore) => prevScore + 1);
        setAnimationStep((prevStep) => (prevStep + 1) % scalingFactors.length);
      }, updateInterval);
    }

    return () => clearInterval(lettersInterval);
  }, [isPlaying, speed, gameTime, updateLetters]);

  const drawRectangle = useCallback(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext('2d');
    const baseWidth = canvas.width / 4;
    const baseHeight = canvas.height / 4;
    const centerX = canvas.width / 2;
    const centerY = canvas.height / 2;

    const [scaleX, scaleY] = scalingFactors[animationStep];

    const scaledWidth = baseWidth * scaleX;
    const scaledHeight = baseHeight * scaleY;

    // Dikdörtgenin canvas sınırlarını aşmamasını sağla
    const maxWidth = canvas.width - 100; // Kenar boşluğu
    const maxHeight = canvas.height - 100;

    const adjustedWidth = Math.min(scaledWidth, maxWidth);
    const adjustedHeight = Math.min(scaledHeight, maxHeight);

    ctx.clearRect(0, 0, canvas.width, canvas.height);

    // Arka plan
    const gradient = ctx.createLinearGradient(0, 0, canvas.width, canvas.height);
    gradient.addColorStop(0, '#ffecd2');
    gradient.addColorStop(1, '#fcb69f');
    ctx.fillStyle = gradient;
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    ctx.save();
    ctx.translate(centerX, centerY);

    ctx.lineWidth = 2;
    ctx.strokeStyle = '#ffffff';
    ctx.fillStyle = '#ffcc5c';

    // Dikdörtgen çizimi
    ctx.beginPath();
    ctx.rect(-adjustedWidth / 2, -adjustedHeight / 2, adjustedWidth, adjustedHeight);
    ctx.fill();
    ctx.stroke();

    // Harflerin konumları - Köşeler
    const points = [
      { x: -adjustedWidth / 2, y: -adjustedHeight / 2 }, // Sol üst
      { x: adjustedWidth / 2, y: -adjustedHeight / 2 },  // Sağ üst
      { x: adjustedWidth / 2, y: adjustedHeight / 2 },   // Sağ alt
      { x: -adjustedWidth / 2, y: adjustedHeight / 2 },  // Sol alt
    ];

    // Harfleri ve daireleri çiz
    points.forEach((point, index) => {
      const radius = 30;
      ctx.beginPath();
      ctx.arc(point.x, point.y, radius, 0, Math.PI * 2);
      ctx.fillStyle = '#fff';
      ctx.strokeStyle = '#ffffff';
      ctx.lineWidth = 1;
      ctx.fill();
      ctx.stroke();

      ctx.font = `28px "Comic Sans MS", cursive, sans-serif`;
      ctx.fillStyle = '#000';
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.fillText(letters[index], point.x, point.y);
    });

    ctx.restore();

    // Merkezdeki siyah nokta
    ctx.beginPath();
    ctx.arc(centerX, centerY, 15, 0, Math.PI * 2);
    ctx.fillStyle = '#000000';
    ctx.fill();
  }, [letters, animationStep, scalingFactors]);

  useEffect(() => {
    let animationFrameId;

    const render = () => {
      drawRectangle();
      animationFrameId = requestAnimationFrame(render);
    };

    if (isPlaying) {
      animationFrameId = requestAnimationFrame(render);
    }

    return () => cancelAnimationFrame(animationFrameId);
  }, [isPlaying, drawRectangle]);

  const resetGame = () => {
    setIsPlaying(false);
    setScore(0);
    setGameTime(initialGameTime);
    setLetters(updateLetters());
    setIsGameOver(false);
    setAnimationStep(0);
  };

  const setSpeedWithLimit = (newSpeed) => {
    setSpeed(Math.max(newSpeed, 0.01));
  };

  return {
    canvasRef,
    isPlaying,
    setIsPlaying,
    speed,
    setSpeed: setSpeedWithLimit,
    score,
    gameTime,
    initialGameTime,
    drawRectangle,
    resetGame,
    isGameOver,
    setIsGameOver,
  };
}

// components/Header.js

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faHome } from '@fortawesome/free-solid-svg-icons';

const Header = () => {
  const navigate = useNavigate();

  return (
    <div className="okuma-seviye-testi-header">
      <h1>Okuma Seviye Sınavı</h1>
      <div className="header-buttons">
        <button
          className="header-button"
          onClick={() => navigate(-1)}
          aria-label="Geri Dön"
        >
          <FontAwesomeIcon icon={faArrowLeft} /> Geri Dön
        </button>

        <button
          className="header-button"
          onClick={() => navigate('/')}
          aria-label="Ana Sayfa"
        >
          <FontAwesomeIcon icon={faHome} /> Ana Sayfa
        </button>
      </div>
    </div>
  );
};

export default Header;

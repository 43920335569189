// src/components/Ogretmen/SonucDialogu.js
import React from 'react';
import ResultDialog from './Dialog';

const SonucDialogu = ({
  open,
  onClose,
  title,
  message,
  severity
}) => {
  return (
    <ResultDialog
      open={open}
      onClose={onClose}
      title={title}
      message={message}
      severity={severity}
    />
  );
};

export default SonucDialogu;

import React from 'react';
import {Container, Typography, Box, Stack, Card, CardActionArea, CardContent,} from '@mui/material';
import Header from '../home/components/Header';
import Footer from '../home/components/Footer';
import { useNavigate } from 'react-router-dom';

// İkonları içe aktarın
import TextFieldsIcon from '@mui/icons-material/TextFields';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import EditIcon from '@mui/icons-material/Edit';
import GridOnIcon from '@mui/icons-material/GridOn';
import VisibilityIcon from '@mui/icons-material/Visibility';

function HizliOku() {
  const navigate = useNavigate();

  const menuItems = [
/*     {
      title: 'Sayfa Tarama',
      description:
        'Vurgulanan kelime veya kelime gruplarına ayak uydurun',
      path: '/sayfa-tarama',
      icon: <TextFieldsIcon sx={{ fontSize: 60, color: '#fff' }} />,
    }, */
    {
      title: 'Rastgele Kelimeler',
      description:
        'Ekranda beliren kelime veya kelime gruplarına ayak uydurun',
      path: '/rastgele-kelime',
      icon: <TextFieldsIcon sx={{ fontSize: 60, color: '#fff' }} />,
    },
    {
      title: 'Kayan Yazılar 1',
      description:
        'Sıra ile gelen kelime veya kelime gruplarına ayak uydurun. Bu çalışma okumanıza tempo kazandıracaktır.',
      path: '/kayan-yazilar',
      icon: <TextFieldsIcon sx={{ fontSize: 60, color: '#fff' }} />,
    },
    {
      title: 'Kayan Yazılar 2',
      description:
        'Sıra ile ikişer gelen kelime veya kelime gruplarına ayak uydurun. Bu çalışma okumanıza tempo kazandıracaktır.',
      path: '/kayan-yazilar-ikiser',
      icon: <TextFieldsIcon sx={{ fontSize: 60, color: '#fff' }} />,
    },
    {
      title: 'Kayan Yazılar 3',
      description:
        'Sıra ile üçer gelen kelime veya kelime gruplarına ayak uydurun. Bu çalışma okumanıza tempo kazandıracaktır.',
      path: '/kayan-yazilar-ucer',
      icon: <TextFieldsIcon sx={{ fontSize: 60, color: '#fff' }} />,
    },
    {
      title: 'Kelime Bulma ve Seçme',
      description:
        'Bu çalışma, kullanıcının hızlı okuma ve dikkat becerilerini geliştirmeyi amaçlar.',
      path: '/kelime-secme-ve-bulma-oyunu',
      icon: <FindInPageIcon sx={{ fontSize: 60, color: '#fff' }} />,
    },
    {
      title: 'Kelime Tamamlama ',
      description:
        'Belirli bir süre içinde bir paragraftaki eksik kelimeleri doğru seçeneklerden seçerek tamamlamanızı gerektiren bir çalışmadır.',
      path: '/kelime-tamamlama-oyunu',
      icon: <EditIcon sx={{ fontSize: 60, color: '#fff' }} />,
    },
    {
      title: 'Harf Izgara',
      description:
        'Bu çalışma, belirli bir süre içinde verilen hedef harfi ızgara üzerindeki harfler arasından bulmanızı gerektirir.',
      path: '/harf-izgara-oyunu',
      icon: <GridOnIcon sx={{ fontSize: 60, color: '#fff' }} />,
    },
    {
      title: 'Takistoskopik Okuma',
      description:
        'Ekranda beliren kelime veya kelime gruplarına ayak uydurun. Bu çalışma okumanıza tempo kazandıracaktır.',
      path: '/takistoskopik-okuma-oyunu',
      icon: <VisibilityIcon sx={{ fontSize: 60, color: '#fff' }} />,
    },
  ];

  const handleCardClick = (path) => {
    navigate(path);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <Header />
      <div className="bg-main">
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            backgroundColor: 'transparent',
            py: 5,
            px: 2,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Container
            maxWidth="md"
            className="main-container"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              borderRadius: '12px',
              color: '#fff', // Yazı rengini beyaz yaptık
              backgroundColor: '#1a1a1a', // Arka plan rengini siyah yaptık
              boxShadow: '0 4px 12px rgba(0,0,0,0.5)', // Hafif gölge
              p: { xs: 2, sm: 4 },
              width: '100%',
              maxWidth: '100%',
            }}
          >
            <Typography
              variant="h4"
              component="h1"
              sx={{
                mb: 4,
                fontWeight: 'bold',
                color: '#ff6600', // Başlık rengini turuncu yaptık
              }}
              align="center"
            >
              Hızlı Okuma Uygulamaları
            </Typography>

            {/* Scrollbar eklemek için Box ile sarmalama */}
            <Box
              sx={{
                maxHeight: '60vh',
                overflowY: 'auto',
                width: '100%',
                '&::-webkit-scrollbar': {
                  width: '8px',
                },
                '&::-webkit-scrollbar-track': {
                  background: '#333',
                  borderRadius: '10px',
                },
                '&::-webkit-scrollbar-thumb': {
                  background: '#ff6600',
                  borderRadius: '10px',
                },
              }}
            >
              <Stack
                direction="column"
                spacing={3}
                sx={{
                  width: '100%',
                }}
              >
                {menuItems.map((item, index) => (
                  <Card
                    key={index}
                    sx={{
                      borderRadius: '15px',
                      backgroundColor: '#2c2c2c', // Kart arka planı koyu gri
                      boxShadow: '0 4px 12px rgba(0,0,0,0.3)',
                      transition: 'transform 0.3s, box-shadow 0.3s',
                      '&:hover': {
                        transform: 'translateY(-5px)',
                        boxShadow: '0 8px 20px rgba(0,0,0,0.5)',
                      },
                    }}
                  >
                    <CardActionArea
                      onClick={() => handleCardClick(item.path)}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <Box
                        sx={{
                          p: 2,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          background: '#ff6600', // İkon arka planı turuncu
                          borderRadius: '15px 0 0 15px',
                          minWidth: '100px',
                        }}
                      >
                        {item.icon}
                      </Box>
                      <CardContent sx={{ flexGrow: 1, padding: '16px' }}>
                        <Typography
                          variant="h6"
                          sx={{
                            fontWeight: '600',
                            color: '#ffcc00', // Başlık rengini açık turuncu yaptık
                          }}
                        >
                          {item.title}
                        </Typography>
                        <Typography variant="body2" sx={{ color: '#ddd', mt: 1 }}>
                          {item.description}
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                ))}
              </Stack>
            </Box>
          </Container>
        </Box>
      </div>
      <Footer />
    </Box>
  );
}

export default HizliOku;

// components/ResultsModal.js

import React from 'react';
import { useNavigate } from 'react-router-dom';

const ResultsModal = ({
  wordCount,
  readingTime,
  readingSpeed,
  userData,
  fromStage,
  stage,
  bookId,
  initialTest,
  userRole,
  onClose,
}) => {
  const navigate = useNavigate();

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes} dakika ${seconds} saniye`;
  };

  return (
    <div className="modal-backdrop">
      <div className="modal-content">
        <button className="close-button" onClick={onClose}>
          &times;
        </button>
        {userData ? (
          <>
            <h2>Sonuçlarınız</h2>
            <p>Okuduğunuz Kelime Sayısı: {wordCount}</p>
            <p>Toplam Süre: {formatTime(readingTime)}</p>
            <p>Okuma Hızınız: {readingSpeed} kelime/dakika</p>
            <p>
              Ortalama bir insanın okuma hızı yaklaşık 200-250
              kelime/dakikadır.
            </p>

            {fromStage && stage && bookId && (
              <button
                className="okuma-seviye-testi-button"
                onClick={() =>
                  navigate(`/testsayfasi/${bookId}`, {
                    state: {
                      fromStage: true,
                      stageNumber: stage.number,
                      wordCount,
                      totalTime: readingTime,
                      readingSpeed,
                      bookId,
                    },
                  })
                }
              >
                Test Sayfasına Geç
              </button>
            )}

            {initialTest && (
              <button
                className="okuma-seviye-testi-button"
                onClick={() => {
                  navigate(
                    userRole === 'student' ? '/ogrenci-dashboard' : '/ogretmen-dashboard',
                    {
                      state: {
                        selectedMenu: 'AkilliKurs',
                      },
                    }
                  );
                }}
              >
                Akıllı Kursa Git
              </button>
            )}

            <button
              className="okuma-seviye-testi-button"
              onClick={() => {
                if (fromStage && stage) {
                  navigate(
                    userRole === 'student' ? '/ogrenci-dashboard' : '/ogretmen-dashboard',
                    {
                      state: {
                        selectedMenu: 'AkilliKurs',
                      },
                    }
                  );
                } else if (initialTest) {
                  navigate(
                    userRole === 'student' ? '/ogrenci-dashboard' : '/ogretmen-dashboard',
                    {
                      state: {
                        selectedMenu: 'AkilliKurs',
                      },
                    }
                  );
                } else {
                  navigate('/');
                }
              }}
            >
              Ana Sayfaya Dön
            </button>
          </>
        ) : (
          <>
            <h2>Sonuçları Görmek İçin Kayıt Yapınız</h2>
            <button
              className="okuma-seviye-testi-button"
              onClick={() => navigate('/register')}
            >
              Giriş Yap
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default ResultsModal;

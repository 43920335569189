import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Toolbar, IconButton, Typography, List, ListItemText, CssBaseline, Collapse, Divider, Grid, CardContent, Avatar, Box, useMediaQuery, Card} from '@mui/material';
import { Menu as MenuIcon, Settings as SettingsIcon, Logout as LogoutIcon, School as SchoolIcon, Group as StudentsIcon,  ExpandLess, ExpandMore, LibraryBooks as LibraryBooksIcon, AddBox as AddBoxIcon, FormatListBulleted as FormatListBulletedIcon, Assignment as AssignmentIcon, LockReset as LockResetIcon,
  AccountCircle as AccountCircleIcon, Person as PersonIcon, Phone as PhoneIcon, LocationCity as LocationCityIcon, Payment as PaymentIcon, Report as ReportIcon,
  FitnessCenter as FitnessCenterIcon, Speed as SpeedIcon,  Home as HomeIcon } from '@mui/icons-material';
import { useTheme, ThemeProvider, createTheme } from '@mui/material/styles';
import { green } from '@mui/material/colors';
import { API_URLS } from '../../config/config';
import useFetchUserData from '../../hooks/ogretmen/useFetchTeacherUserData';
import BookUploadForm from './kutuphane/Add';
import BenimListe from './kutuphane/Liste/BenimListe';
import AllBooks from './kutuphane/Liste/AllBooks';


import Tests from './kutuphane/Test';
import Ogrenci from './ogrenci/Ogrenci';
import Password from './ayarlar/Password';
import Profil from './ayarlar/Profil';
import StageList from './akillikurs/StageList';
import ExerciseList from './akillikurs/ExerciseList';
import PaymentForm from './payment/PaymentForm';
import ActivateAccountForm from './activation/ActivateAccountForm';
import PaymentHistory from './payment/PaymentHistory';
import { Main, AppBarStyled, DrawerStyled, ProfileCard, StyledListItem, StyledListItemIcon } from './styles/OgretmenDashboardStyles';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import PeopleIcon from '@mui/icons-material/People';
import TeacherProgressReport from './raporlar/TeacherProgressReport';



function OgretmenDashboard() {
  const [drawerOpen, setDrawerOpen] = useState(true);
  const [userData, setUserData] = useState(null);
  const [open, setOpen] = useState({});
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedStage, setSelectedStage] = useState(null);
  const [selectedMenu, setSelectedMenu] = useState('Dashboard');
  const [studentCount, setStudentCount] = useState(0);

  useFetchUserData(setUserData);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleClick = (menu) => {
    setOpen((prevOpen) => ({ ...prevOpen, [menu]: !prevOpen[menu] }));
  };

  const handleLogout = async () => {
    try {
      await fetch(API_URLS.TEACHER_URL + 'logout', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`,
          'Content-Type': 'application/json',
        },
      });
      localStorage.removeItem('userToken');
      navigate('/ogretmen-giris');
    } catch (error) {
      console.error('Çıkış işlemi sırasında bir hata oluştu', error);
    }
  };

  useEffect(() => {
    // Update selectedMenu and selectedStage from location.state
    if (location.state) {
      if (location.state.selectedMenu) {
        setSelectedMenu(location.state.selectedMenu);
      }
      if (location.state.selectedStage) {
        setSelectedStage(location.state.selectedStage);
      }
    }
  }, [location.state]);

  useEffect(() => {
    const fetchStudentCount = async () => {
      try {
        const response = await fetch(`${API_URLS.TEACHER_URL}rapor/student-count`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('userToken')}`,
          },
        });
        if (response.ok) {
          const data = await response.json();
          setStudentCount(data.studentCount);
        } else {
          console.error('Öğrenci sayısı alınamadı.');
        }
      } catch (error) {
        console.error('Öğrenci sayısını fetch ederken hata:', error);
      }
    };

    fetchStudentCount();
  }, []);

  const menuItems = [
    { text: 'Anasayfa', icon: <HomeIcon />, action: () => navigate('/') },
    {
      text: 'Hızlı Oku',
      icon: <SpeedIcon />,
      action: () => { navigate('/hizlioku'); }
    },
    {
      text: 'Egzersizler',
      icon: <FitnessCenterIcon />,
      action: () => { navigate('/egsersizler'); }
    },

    {
      text: 'Kütüphane',
      icon: <LibraryBooksIcon />,
      subMenus: [
        { 
          text: 'Kitap Ekle', 
          icon: <AddBoxIcon />, 
          action: () => setSelectedMenu('KitapEkle') 
        },
        {
          text: 'Liste',
          icon: <FormatListBulletedIcon />,
          subMenus: [
            { 
              text: 'Benim Listem', 
              icon: <FormatListBulletedIcon />, 
              action: () => setSelectedMenu('BenimListem') 
            },
            { 
              text: 'Tüm Liste', 
              icon: <FormatListBulletedIcon />, 
              action: () => setSelectedMenu('KitapListe') 
            },
          ],
          action: () => handleClick('Liste'),
        },
        {
          text: 'Testler', 
          icon: <AssignmentIcon />, 
          action: () => setSelectedMenu('Testler') 
        },
      ],
      action: () => handleClick('Kütüphane'),
    },
    {
      text: 'Bakiye Yükle',
      icon: <PaymentIcon />,
      action: () => setSelectedMenu('BakiyeYukle'),
    },
    {
      text: 'Hesabı Aktif Et',
      icon: <PaymentIcon />,
      action: () => setSelectedMenu('HesapAktifEt'),
    },
    {
      text: 'Öğrenciler',
      icon: <StudentsIcon />,
      action: () => setSelectedMenu('Öğrenciler'),
    },
    
    {
      text: 'Akıllı Kurs',
      icon: <SchoolIcon />,
      action: () => setSelectedMenu('AkilliKurs'),
    },
    {
      text: 'Raporlar',
      icon: <ReportIcon />,
      action: () => setSelectedMenu('Raporlar'),
    },
     {
      text: 'Satın Alınan Paketler',
      icon: <PaymentIcon />,
      action: () => setSelectedMenu('PaymentHistory'),
    },
    {
      text: 'Ayarlar',
      icon: <SettingsIcon />,
      subMenus: [
        {
          text: 'Şifre Güncelle',
          icon: <LockResetIcon />,
          action: () => setSelectedMenu('SifreSet'),
        },
        { text: 'Profil', icon: <AccountCircleIcon />, action: () => setSelectedMenu('Profil') },
      ],
      action: () => handleClick('Ayarlar'),
    },
    { text: 'Çıkış Yap', icon: <LogoutIcon />, action: handleLogout },
  ];

  const renderContent = () => {
    if (!userData) {
      return <div>Yükleniyor...</div>;
    }

    switch (selectedMenu) {
      case 'BakiyeYukle':
        return <PaymentForm userType="Ogretmen" userId={userData._id} />;

      case 'HesapAktifEt':
        return <ActivateAccountForm />;

      case 'Öğrenciler':
        return <Ogrenci />;

      case 'AkilliKurs':
        return (
          <StageList
            onStageSelect={(stage) => {
              setSelectedStage(stage);
              setSelectedMenu('ExerciseList');
            }}
            userData={userData}
          />
        );

      case 'ExerciseList':
        return (
          <ExerciseList
            stage={selectedStage}
            userData={userData}
            onBack={() => setSelectedMenu('AkilliKurs')}
          />
        );

      case 'Raporlar':
        return <TeacherProgressReport userData={userData} />;

      case 'KitapEkle':
        return <BookUploadForm />;

      case 'KitapListe':
        return <AllBooks />; 

      case 'BenimListem':
        return <BenimListe />;

      case 'Testler':
        return <Tests />;

      case 'SifreSet':
        return <Password />;

      case 'Profil':
        return <Profil />;

      case 'PaymentHistory':
        return <PaymentHistory />;

      default:
        // Calculate total balance safely
        const totalBalance =
          (parseFloat(userData.bakiye3Ay) || 0) +
          (parseFloat(userData.bakiye6Ay) || 0) +
          (parseFloat(userData.bakiye12Ay) || 0);

        return (
          <Box sx={{ padding: 2 }}>
            <ProfileCard>
              <CardContent>
                <Typography
                  variant="h4"
                  sx={{
                    textAlign: 'center',
                    fontWeight: 'bold',
                    mb: 2,
                    fontFamily: 'Comic Sans MS',
                  }}
                >
                  PROFİL BİLGİLERİ
                </Typography>
                <Divider />
                <Grid container spacing={2} justifyContent="center" sx={{ mt: 2 }}>
                  <Grid item>
                    <Avatar
                      sx={{
                        backgroundColor: '#ffffff',
                        color: '#004d40',
                        width: 100,
                        height: 100,
                        animation: 'spin 5s linear infinite',
                      }}
                    >
                      <PersonIcon sx={{ fontSize: '3rem' }} />
                    </Avatar>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="h5"
                      align="center"
                      sx={{ fontFamily: 'Comic Sans MS', color: '#004d40' }}
                    >
                      {userData.ad} {userData.soyad}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="body1"
                      align="center"
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        color: '#004d40',
                      }}
                    >
                      <PhoneIcon sx={{ mr: 1, fontSize: '1.5rem' }} />
                      {userData.telefon}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="body1"
                      align="center"
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        color: '#004d40',
                      }}
                    >
                      <LocationCityIcon sx={{ mr: 1, fontSize: '1.5rem' }} />
                      {userData.sehirAdi} / {userData.ilceAdi}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="body1"
                      align="center"
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        color: '#004d40',
                      }}
                    >
                      <AccountBalanceWalletIcon sx={{ mr: 1, fontSize: '1.5rem' }} />
                      Bakiye: {totalBalance}
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography variant="body1" align="center" sx={{ color: '#004d40' }}>
                      Hesap Durumu: {userData.status === 'active' ? 'Aktif' : 'Pasif'}
                    </Typography>
                  </Grid>
                  {userData.abonelikler && userData.abonelikler.length > 0 && (
                    <>
                      <Grid item xs={12}>
                        <Typography variant="body1" align="center" sx={{ color: '#004d40' }}>
                          Abonelik Başlangıç Tarihi:{' '}
                          {new Date(
                            userData.abonelikler[userData.abonelikler.length - 1].baslangicTarihi
                          ).toLocaleDateString()}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="body1" align="center" sx={{ color: '#004d40' }}>
                          Abonelik Bitiş Tarihi:{' '}
                          {new Date(
                            userData.abonelikler[userData.abonelikler.length - 1].bitisTarihi
                          ).toLocaleDateString()}
                        </Typography>
                      </Grid>
                    </>
                  )}
                </Grid>
              </CardContent>
            </ProfileCard>
            {/* Updated Balance Cards */}
            <Grid container spacing={2} sx={{ mt: 2 }}>
              {/* 3 Aylık Bakiye */}
              <Grid item xs={12} sm={6} md={3}>
                <Card
                  sx={{
                    background: 'linear-gradient(135deg, #70e1f5 0%, #ffd194 100%)',
                    color: '#fff',
                    boxShadow: 3,
                  }}
                >
                  <CardContent>
                    <Box display="flex" alignItems="center">
                      <MonetizationOnIcon sx={{ fontSize: 40, mr: 2 }} />
                      <Typography variant="h6" gutterBottom>
                        3 Aylık Bakiye
                      </Typography>
                    </Box>
                    <Typography variant="h4" sx={{ mt: 2 }}>
                      {userData.bakiye3Ay}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>

              {/* 6 Aylık Bakiye */}
              <Grid item xs={12} sm={6} md={3}>
                <Card
                  sx={{
                    background: 'linear-gradient(135deg, #ff9a9e 0%, #fad0c4 100%)',
                    color: '#fff',
                    boxShadow: 3,
                  }}
                >
                  <CardContent>
                    <Box display="flex" alignItems="center">
                      <MonetizationOnIcon sx={{ fontSize: 40, mr: 2 }} />
                      <Typography variant="h6" gutterBottom>
                        6 Aylık Bakiye
                      </Typography>
                    </Box>
                    <Typography variant="h4" sx={{ mt: 2 }}>
                      {userData.bakiye6Ay}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>

              {/* 12 Aylık Bakiye */}
              <Grid item xs={12} sm={6} md={3}>
                <Card
                  sx={{
                    background: 'linear-gradient(135deg, #fbc2eb 0%, #a6c1ee 100%)',
                    color: '#fff',
                    boxShadow: 3,
                  }}
                >
                  <CardContent>
                    <Box display="flex" alignItems="center">
                      <MonetizationOnIcon sx={{ fontSize: 40, mr: 2 }} />
                      <Typography variant="h6" gutterBottom>
                        12 Aylık Bakiye
                      </Typography>
                    </Box>
                    <Typography variant="h4" sx={{ mt: 2 }}>
                      {userData.bakiye12Ay}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>

              {/* Toplam Öğrenci Sayısı */}
              <Grid item xs={12} sm={6} md={3}>
                <Card
                  sx={{
                    background: 'linear-gradient(135deg, #a1c4fd 0%, #c2e9fb 100%)',
                    color: '#fff',
                    boxShadow: 3,
                  }}
                >
                  <CardContent>
                    <Box display="flex" alignItems="center">
                      <PeopleIcon sx={{ fontSize: 40, mr: 2 }} />
                      <Typography variant="h6" gutterBottom>
                        Toplam Öğrenci
                      </Typography>
                    </Box>
                    <Typography variant="h4" sx={{ mt: 2 }}>
                      {studentCount}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Box>
        );
    }
  };

  // Custom theme creation
  const customTheme = createTheme({
    palette: {
      primary: {
        main: green[500],
      },
      secondary: {
        main: green[700],
      },
    },
  });

  return (
    <ThemeProvider theme={customTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBarStyled position="fixed" open={drawerOpen}>
          <Toolbar>
            <IconButton color="inherit" aria-label="open drawer" onClick={toggleDrawer} edge="start">
              <MenuIcon />
            </IconButton>
            {userData ? (
              <Typography
                variant="h6"
                noWrap
                component="div"
                sx={{ fontFamily: 'Comic Sans MS', color: 'white' }}
              >
                Hoşgeldiniz {userData.ad} {userData.soyad}, (Öğretmen)
              </Typography>
            ) : (
              <Typography
                variant="h6"
                noWrap
                component="div"
                sx={{ fontFamily: 'Comic Sans MS', color: 'white' }}
              >
                Öğretmen Paneli
              </Typography>
            )}
          </Toolbar>
        </AppBarStyled>
        <DrawerStyled
          variant={isMobile ? 'temporary' : 'persistent'}
          anchor="left"
          open={drawerOpen}
          onClose={toggleDrawer}
        >
          <Toolbar />
          <Divider />
          <List>
            {menuItems.map((item, index) => (
              <React.Fragment key={index}>
                <StyledListItem
                  selected={selectedMenu === item.text}
                  onClick={() => {
                    item.action && item.action();
                    if (isMobile && !item.subMenus) toggleDrawer();
                  }}
                >
                  <StyledListItemIcon>{item.icon}</StyledListItemIcon>
                  <ListItemText primary={item.text} />
                  {item.subMenus ? (open[item.text] ? <ExpandLess /> : <ExpandMore />) : null}
                </StyledListItem>

                {item.subMenus && (
                  <Collapse in={open[item.text]} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      {item.subMenus.map((subItem, subIndex) => (
                        <React.Fragment key={subIndex}>
                          <StyledListItem
                            onClick={() => {
                              subItem.action && subItem.action();
                              if (isMobile && !subItem.subMenus) toggleDrawer();
                            }}
                            sx={{ pl: 4 }}
                          >
                            <StyledListItemIcon>{subItem.icon}</StyledListItemIcon>
                            <ListItemText primary={subItem.text} />
                            {subItem.subMenus ? (open[subItem.text] ? <ExpandLess /> : <ExpandMore />) : null}
                          </StyledListItem>

                          {subItem.subMenus && (
                            <Collapse in={open[subItem.text]} timeout="auto" unmountOnExit>
                              <List component="div" disablePadding>
                                {subItem.subMenus.map((nestedSubItem, nestedIndex) => (
                                  <StyledListItem
                                    key={nestedIndex}
                                    onClick={() => {
                                      nestedSubItem.action && nestedSubItem.action();
                                      if (isMobile) toggleDrawer();
                                    }}
                                    sx={{ pl: 6 }}
                                  >
                                    <StyledListItemIcon>{nestedSubItem.icon}</StyledListItemIcon>
                                    <ListItemText primary={nestedSubItem.text} />
                                  </StyledListItem>
                                ))}
                              </List>
                            </Collapse>
                          )}
                        </React.Fragment>
                      ))}
                    </List>
                  </Collapse>
                )}
              </React.Fragment>
            ))}
          </List>
        </DrawerStyled>
        <Main open={drawerOpen}>{renderContent()}</Main>
      </Box>
    </ThemeProvider>
  );
}

export default OgretmenDashboard;

import React from 'react';
import DOMPurify from 'dompurify';
import parse, { domToReact } from 'html-react-parser';
import '../styles/KelimeBulmaOyunu.css';

const GameBoard = ({
  paragraph,
  selectedWords,
  targetWords,
  handleWordClick,
  correctWords,
}) => {
  // Boş elementleri tanımlıyoruz
  const voidElements = new Set([
    'area',
    'base',
    'br',
    'col',
    'embed',
    'hr',
    'img',
    'input',
    'keygen',
    'link',
    'meta',
    'param',
    'source',
    'track',
    'wbr',
  ]);

  let wordCounter = 0;

  const options = {
    replace: (domNode) => {
      if (domNode.type === 'tag') {
        if (voidElements.has(domNode.name)) {
          // Boş elementleri olduğu gibi bırakıyoruz
          return React.createElement(
            domNode.name,
            { key: domNode.name + wordCounter, ...domNode.attribs },
            null
          );
        } else {
          // Diğer elementlerin çocuklarını işlemeye devam ediyoruz
          return React.createElement(
            domNode.name,
            { key: domNode.name + wordCounter, ...domNode.attribs },
            domToReact(domNode.children, options)
          );
        }
      } else if (domNode.type === 'text') {
        const textContent = domNode.data;
        const words = textContent.split(/(\s+)/); // Boşlukları da dahil ediyoruz
        const newNodes = [];

        words.forEach((word) => {
          if (word.trim() === '') {
            // Boşluk karakterleri
            newNodes.push(word);
          } else {
            const originalWord = word;
            const normalizedWord = word
              .toLowerCase()
              .replace(/[^\p{L}\p{N}_]+/gu, '');

            const isSelected = selectedWords.includes(normalizedWord);
            const isCorrect = targetWords.includes(normalizedWord);
            const className = `kayu-word ${
              isSelected ? (isCorrect ? 'correct' : 'incorrect') : ''
            }`;

            newNodes.push(
              <span
                key={`word-${wordCounter}`}
                className={className}
                onClick={() => handleWordClick(normalizedWord)}
              >
                {originalWord}
              </span>
            );
            wordCounter++;
          }
        });

        return <>{newNodes}</>;
      }
    },
  };

  // Paragrafı HTML olarak render et
  const sanitizedHTML = DOMPurify.sanitize(paragraph);

  return (
    <div>
      {/* Hedef Kelimeler Listesi */}
      <div className="kayu-target-words-container">
        {targetWords.map((word, index) => {
          const isFound = correctWords.includes(word);
          return (
            <span
              key={index}
              className={`kayu-target-word ${isFound ? 'found' : ''}`}
            >
              {word}
            </span>
          );
        })}
      </div>

      {/* Paragrafı HTML olarak render et */}
      <div className="kayu-game-container">
        {paragraph ? (
          <div>{parse(sanitizedHTML, options)}</div>
        ) : (
          <div className="kayu-message-display">
            Seçili paragrafta içerik bulunamadı. Lütfen farklı bir paragraf seçin.
          </div>
        )}
      </div>
    </div>
  );
};

export default GameBoard;

// NesneyiTakipEtGame.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import useGameLogic from './hooks/useNesneyiTakipEtGame';
import GameControls from './components/GameControls';
import ScoreDisplay from './components/ScoreDisplay';
import GameTimerDisplay from './components/GameTimerDisplay';
import GameOverModal from './components/GameOverModal';
import GameHeader from './components/GameHeader';
import { API_URLS } from '../../../config/config';
import './styles/nesneyiTakipEt.css';
import axiosInstance from '../../../utils/axiosInstance';

const NesneyiTakipEtGame = () => {
  const navigate = useNavigate();
  const [userData, setUserData] = useState(null);
  const [userRole, setUserRole] = useState(null);
  const location = useLocation();
  const { stage, exercise } = location.state || {};
  const token = localStorage.getItem('userToken');

  // Kullanıcı verilerini alırken önce öğrenci, sonra öğretmen kontrolü
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const studentResponse = await axiosInstance.get(`${API_URLS.STUDENT_URL}/me`);
        if (
          studentResponse.status === 200 &&
          studentResponse.data &&
          studentResponse.data.status === 'active'
        ) {
          const studentData = studentResponse.data;
          setUserData(studentData);
          setUserRole('student');
          return;
        } else {
          setUserData(null);
          setUserRole(null);
        }
      } catch (error) {
        console.error('Öğrenci verisi alınırken hata oluştu:', error);
      }

      // Öğrenci yoksa veya aktif değilse öğretmen verisine bak
      try {
        const teacherResponse = await fetch(`${API_URLS.TEACHER_URL}/me`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        if (teacherResponse.ok) {
          const teacherData = await teacherResponse.json();
          setUserData(teacherData);
          setUserRole('teacher');
          return;
        }
      } catch (error) {
        console.error('Öğretmen verisi alınırken hata oluştu:', error);
      }

      setUserData(null);
      setUserRole(null);
    };

    fetchUserData();
  }, [token]);

  const {
    canvasRef,
    isPlaying,
    setIsPlaying,
    speed,
    setSpeed,
    score,
    gameTime,
    initialGameTime,
    resetGame,
    isGameOver,
    setIsGameOver,
    shapeSides,
    increaseSides,
    decreaseSides,
    setCanvasReady,
  } = useGameLogic(userData);

  const updateProgress = async () => {
    if (!userData || !userRole) return;
    try {
      const payload = {
        stageNumber: stage?.number || 1,
        gameName: exercise?.gameName || 'Nesneyi Takip Et',
        score: score,
        time: (userData && userData.status === 'inactive' ? 15 : 60) - gameTime,
        completed: true,
      };

      if (userRole === 'student') {
        // Öğrenci: token cookie üzerinden gidiyor, axiosInstance kullan
        const apiUrl = `${API_URLS.STUDENT_URL}akillikurs/progress/update-game`;
        await axiosInstance.post(apiUrl, payload);
      } else {
        // Öğretmen: localStorage token ile Authorization header ekle
        const apiUrl = `${API_URLS.TEACHER_URL}akillikurs/progress/update-game`;
        const teacherToken = localStorage.getItem('userToken');
        await axios.post(apiUrl, payload, {
          headers: {
            Authorization: `Bearer ${teacherToken}`,
            'Content-Type': 'application/json',
          },
        });
      }

      console.log('İlerleme başarıyla güncellendi.');
    } catch (error) {
      console.error('İlerleme güncellenirken hata oluştu:', error);
    }
  };

  const onGameOver = () => {
    setIsPlaying(false);
    setIsGameOver(true);
    updateProgress();
  };

  useEffect(() => {
    if (gameTime === 0 && isPlaying) {
      onGameOver();
    }
  }, [gameTime, isPlaying]);

  const [showIntroduction, setShowIntroduction] = useState(true);

  const handleStartGame = () => {
    setShowIntroduction(false);
  };

  const handleReturnToPreviousStage = () => {
    if (stage) {
      navigate(
        userRole === 'student' ? '/ogrenci-dashboard' : '/ogretmen-dashboard',
        {
          state: {
            selectedMenu: 'ExerciseList',
            selectedStage: stage,
          },
        }
      );
    } else {
      alert('Aşama bilgisi bulunmamaktadır.');
    }
  };

  return (
    <div className="nesneyi-takip-et-container">
      <GameHeader handleReturnToPreviousStage={handleReturnToPreviousStage} />

      {showIntroduction ? (
        <div className="nesneyi-takip-et-intro">
          <div className="nesneyi-takip-et-card">
            <div className="nesneyi-takip-et-card-body">
              <h1 className="nesneyi-takip-et-title">Nesneyi Takip Et</h1>
              <p className="nesneyi-takip-et-description">
                Bu oyunda amaç, ekranda hareket eden nesneyi gözlerinizle takip etmektir.
              </p>
              <div className="nesneyi-takip-et-instructions-container">
                <h3 className="nesneyi-takip-et-instructions-title">Talimatlar</h3>
                <ul className="nesneyi-takip-et-instructions-list">
                  <li>
                    <strong>1. Nesneyi Takip Edin:</strong> Ekrandaki hareketli nesneyi gözlerinizle izleyin.
                  </li>
                  <li>
                    <strong>2. Hız ve Dikkat:</strong> Nesnenin hızını artırarak zorluğu yükseltebilirsiniz.
                  </li>
                  <li>
                    <strong>3. Zorluk Seviyesi:</strong> Şekil köşe sayısını artırarak farklı desenler deneyin.
                  </li>
                </ul>
              </div>
              <button className="nesneyi-takip-et-button" onClick={handleStartGame}>
               BAŞLAT
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div className="nesneyi-takip-et-gameplay">
          <div className="nesneyi-takip-et-status">
            <GameTimerDisplay
              gameTime={gameTime}
              initialGameTime={initialGameTime}
              userRole={userRole}
            />
            {userData && <ScoreDisplay score={score} />}
          </div>

          <div className="nesneyi-takip-et-canvas-container">
            <canvas
              ref={(ref) => {
                canvasRef.current = ref;
                if (ref) {
                  setCanvasReady(true);
                }
              }}
              width="800"
              height="600"
              className="nesneyi-takip-et-game-canvas"
            />
          </div>

          <GameControls
            speed={speed}
            setSpeed={setSpeed}
            isPlaying={isPlaying}
            setIsPlaying={setIsPlaying}
            resetGame={resetGame}
            shapeSides={shapeSides}
            increaseSides={increaseSides}
            decreaseSides={decreaseSides}
          />
        </div>
      )}
      {isGameOver && (
        <GameOverModal
          score={score}
          onRestart={resetGame}
          userData={userData}
          onClose={() => setIsGameOver(false)}
        />
      )}
    </div>
  );
};

export default NesneyiTakipEtGame;

// GameDisplay.js

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faPause, faRedo } from '@fortawesome/free-solid-svg-icons';
import parse, { domToReact } from 'html-react-parser';
import '../styles/kayanYazilarUcerStyle.css';

const GameDisplay = ({
  isPlaying,
  paragraphsList,
  selectedParagraphIndex,
  displayedWords,
  handlePlayPause,
  handleReset,
  message,
  gameOver,
}) => {
  // Define self-closing HTML tags
  const voidElements = new Set([
    'area',
    'base',
    'br',
    'col',
    'embed',
    'hr',
    'img',
    'input',
    'keygen',
    'link',
    'meta',
    'param',
    'source',
    'track',
    'wbr',
  ]);

  const options = {
    replace: (node) => {
      if (node.type === 'tag') {
        if (voidElements.has(node.name)) {
          return React.createElement(
            node.name,
            { key: node.name, ...node.attribs },
            null
          );
        } else {
          return React.createElement(
            node.name,
            { key: node.name, ...node.attribs },
            domToReact(node.children, options)
          );
        }
      }
    },
  };

  return (
    <>
      <div className="kayan-yazilar-ucer-controls">
        <button
          onClick={handlePlayPause}
          disabled={gameOver}
          className="kayan-yazilar-ucer-button"
        >
          <FontAwesomeIcon icon={isPlaying ? faPause : faPlay} />{' '}
          {isPlaying ? 'Duraklat' : 'Başlat'}
        </button>
        <button onClick={handleReset} className="kayan-yazilar-ucer-button">
          <FontAwesomeIcon icon={faRedo} /> Yeniden Başlat
        </button>
      </div>
      <div className="kayan-yazilar-ucer-game-display">
        {!paragraphsList[selectedParagraphIndex] ? (
          <div className="kayan-yazilar-ucer-message">
            Paragraf bulunamadı. Lütfen farklı bir paragraf veya zorluk seviyesi seçin.
          </div>
        ) : (
          <div className="kayan-yazilar-ucer-content">
            {parse(displayedWords.join(' '), options)}
          </div>
        )}
      </div>
      {message && (
        <div className="kayan-yazilar-ucer-message">
          {message}
        </div>
      )}
    </>
  );
};

export default GameDisplay;

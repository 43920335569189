// Controls.js

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faPause, faRedo } from '@fortawesome/free-solid-svg-icons';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import '../styles/KelimeTamamlamaOyunu.css';

const Controls = ({
  isPlaying,
  handlePlayPause,
  handleReset,
  timeRemaining,
  difficulty,
  handleDifficultyChange,
  selectedParagraphIndex,
  handleParagraphChange,
  paragraphsList,
}) => {
  // Kelime sayısını hesaplayan fonksiyon
  const getWordCount = (htmlContent) => {
    if (!htmlContent) return 0;
    // HTML içeriğini düz metne dönüştür
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = htmlContent;
    const textContent = tempDiv.textContent || tempDiv.innerText || '';
    // Kelimeleri boşluklara göre böl ve say
    const words = textContent.trim().split(/\s+/);
    return words.length;
  };

  const formatTime = (seconds) => {
    if (seconds < 0) seconds = 0;
    const minutes = Math.floor(seconds / 60)
      .toString()
      .padStart(2, '0');
    const remainingSeconds = (seconds % 60).toString().padStart(2, '0');
    return `${minutes}:${remainingSeconds}`;
  };

  return (
    <>
      <div className="kayu-control-timer-container">
        <div className="kayu-game-controls">
          <button className="kayu-btn" onClick={handlePlayPause}>
            <FontAwesomeIcon icon={isPlaying ? faPause : faPlay} />{' '}
            {isPlaying ? 'Duraklat' : 'Başlat'}
          </button>
          <button className="kayu-btn" onClick={handleReset}>
            <FontAwesomeIcon icon={faRedo} /> Yeniden Başlat
          </button>
        </div>
        <div className="kayu-timer-display">
          <span>Kalan Süre: {formatTime(timeRemaining)}</span>
        </div>
      </div>

      <div className="row mb-3">
        <div className="col-12 col-md-6 mb-2 mb-md-0">
          <FormControl variant="outlined" fullWidth className="kayu-select">
            <InputLabel id="difficulty-select-label" style={{ color: '#fff' }}>Zorluk Seç</InputLabel>
            <Select
              labelId="difficulty-select-label"
              id="difficulty-select"
              value={difficulty}
              onChange={handleDifficultyChange}
              label="Zorluk Seç"
              style={{ color: '#fff' }}
              classes={{ icon: 'kayu-select-icon' }}
              MenuProps={{
                classes: { paper: 'kayu-menu-paper' },
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left',
                },
                getContentAnchorEl: null,
              }}
            >
              <MenuItem value="easy">Kolay</MenuItem>
              <MenuItem value="medium">Orta</MenuItem>
              <MenuItem value="hard">Zor</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="col-12 col-md-6">
          <FormControl variant="outlined" fullWidth className="kayu-select">
            <InputLabel id="paragraph-select-label" style={{ color: '#fff' }}>Paragraf Seç</InputLabel>
            <Select
              labelId="paragraph-select-label"
              id="paragraph-select"
              value={selectedParagraphIndex}
              onChange={handleParagraphChange}
              label="Paragraf Seç"
              style={{ color: '#fff' }}
              classes={{ icon: 'kayu-select-icon' }}
              MenuProps={{
                classes: { paper: 'kayu-menu-paper' },
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left',
                },
                getContentAnchorEl: null,
              }}
            >
              {paragraphsList.map((paragraph, index) => {
                // Kelime sayısını hesapla
                const wordCount = getWordCount(paragraph.content);

                return (
                  <MenuItem key={index} value={index}>
                    {paragraph.title} - {wordCount} kelime
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </div>
      </div>
    </>
  );
};

export default Controls;

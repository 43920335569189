import React, { useState, useEffect } from 'react';
import { Button, Typography, Paper, Card, CardContent, Grid, TextField, InputAdornment, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import PhoneIcon from '@mui/icons-material/Phone';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import 'bootstrap/dist/css/bootstrap.min.css';
import { API_URLS } from '../../../config/config';
import useFetchUserData from '../../../hooks/ogretmen/useFetchTeacherUserData';
import ResultDialog from '../../../pages/components/Auth/Dialog';

const ProfilGuncelle = () => {
  const [ad, setAd] = useState('');
  const [soyad, setSoyad] = useState('');
  const [telefon, setTelefon] = useState('');
  const [telefonHata, setTelefonHata] = useState(false);
  const [sehirListesi, setSehirListesi] = useState([]);
  const [secilenSehirId, setSecilenSehirId] = useState('');
  const [secilenIlceId, setSecilenIlceId] = useState('');
  const [mevcutSehirAdi, setMevcutSehirAdi] = useState('');
  const [mevcutIlceAdi, setMevcutIlceAdi] = useState('');
  const [loading, setLoading] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState('');
  const [dialogSeverity, setDialogSeverity] = useState('error');
  const [userData, setUserData] = useState(null);

  useFetchUserData(setUserData);

  useEffect(() => {
    if (userData) {
      setAd(userData.ad || '');
      setSoyad(userData.soyad || '');
      setTelefon(userData.telefon || '');
      setSecilenSehirId(userData.sehir || '');
      setSecilenIlceId(userData.ilce || '');
      setMevcutSehirAdi(userData.sehirAdi || '');
      setMevcutIlceAdi(userData.ilceAdi || '');
    }
  }, [userData]);

  useEffect(() => {
    const fetchSehirler = async () => {
      try {
        const response = await fetch(`${API_URLS.ADMIN_URL}sehirler`);
        if (!response.ok) {
          throw new Error('Veri çekme işlemi başarısız');
        }
        const sehirler = await response.json();
        setSehirListesi(sehirler);
      } catch (err) {
        console.error("Şehirler yüklenirken bir hata oluştu:", err.message);
      }
    };

    fetchSehirler();
  }, []);

  const handleSehirChange = (event) => {
    const selectedSehirId = event.target.value;
    setSecilenSehirId(selectedSehirId);
    const secilenSehir = sehirListesi.find(sehir => sehir._id === selectedSehirId);
    setMevcutSehirAdi(secilenSehir ? secilenSehir.sehir_adi : '');
    setSecilenIlceId('');
    setMevcutIlceAdi('');
  };

  const handleIlceChange = (event) => {
    const selectedIlceId = event.target.value;
    setSecilenIlceId(selectedIlceId);
    const secilenIlce = sehirListesi
      .find(sehir => sehir._id === secilenSehirId)?.ilceler
      .find(ilce => ilce._id === selectedIlceId);
    setMevcutIlceAdi(secilenIlce ? secilenIlce.ilce_adi : '');
  };

  const handleTelefonChange = (event) => {
    const telefonRegex = /^0(\d{3}) (\d{3}) (\d{4})$/;
    let { value } = event.target;
    const numbers = value.replace(/\D/g, '');
    value = numbers.substring(0, 4) + (numbers.length > 4 ? ' ' : '') + numbers.substring(4, 7) + (numbers.length > 7 ? ' ' : '') + numbers.substring(7, 11);
    setTelefonHata(!telefonRegex.test(value) && value !== '');
    setTelefon(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await fetch(`${API_URLS.TEACHER_URL}ayarlar/profil/${userData._id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('userToken')}`, // JWT token
        },
        body: JSON.stringify({
          ad,
          soyad,
          telefon,
          sehir: secilenSehirId,
          ilce: secilenIlceId,
        }),
      });

      const data = await response.json();
      setLoading(false);

      if (!response.ok) {
        throw new Error(data.message || 'Profil güncellenirken bir hata oluştu.');
      }

      setDialogSeverity('success');
      setDialogContent('Profil başarıyla güncellendi!');
      setDialogOpen(true);
    } catch (error) {
      setLoading(false);
      setDialogSeverity('error');
      setDialogContent(error.message || 'Bir hata oluştu.');
      setDialogOpen(true);
    }
  };

  return (
    <Paper style={{ padding: 20 }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card className="bg-info text-white mb-3">
            <div className="card-header text-center font-weight-bold" style={{ fontSize: '1.25rem', letterSpacing: '0.05rem' }}>
              PROFİL GÜNCELLEME
            </div>
            <hr style={{ margin: '0', borderColor: 'white' }} />
            <CardContent>
              <Typography variant="h6" component="h2" style={{ display: 'flex', alignItems: 'center' }}>
                <PersonIcon style={{ marginRight: '8px', fontSize: '1.5rem' }} />
                Bilgilerinizi Güncelleyin
              </Typography>
              <form onSubmit={handleSubmit}>
                <TextField
                  fullWidth
                  label="Ad"
                  margin="normal"
                  variant="outlined"
                  value={ad}
                  onChange={(e) => setAd(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PersonIcon style={{ fontSize: '1.2rem' }} />
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  fullWidth
                  label="Soyad"
                  margin="normal"
                  variant="outlined"
                  value={soyad}
                  onChange={(e) => setSoyad(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PersonIcon style={{ fontSize: '1.2rem' }} />
                      </InputAdornment>
                    ),
                  }}
                />
                <FormControl fullWidth margin="normal">
                  <InputLabel id="sehir-select-label">Şehir</InputLabel>
                  <Select
                    labelId="sehir-select-label"
                    value={secilenSehirId}
                    onChange={handleSehirChange}
                    displayEmpty
                    renderValue={(selected) => {
                      if (selected === "") {
                        return <em>Şehir Seçiniz</em>;
                      }
                      const secilenSehir = sehirListesi.find(sehir => sehir._id === selected);
                      return secilenSehir ? secilenSehir.sehir_adi : <em>Şehir Seçiniz</em>;
                    }}
                    startAdornment={
                      <InputAdornment position="start">
                        <LocationCityIcon style={{ fontSize: '1.2rem' }} />
                      </InputAdornment>
                    }
                  >
                    <MenuItem disabled value="">
                      <em>Şehir Seçiniz</em>
                    </MenuItem>
                    {sehirListesi.map((sehir) => (
                      <MenuItem key={sehir._id} value={sehir._id}>
                        {sehir.sehir_adi}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                {secilenSehirId && (
                  <FormControl fullWidth margin="normal">
                    <InputLabel id="ilce-select-label">İlçe</InputLabel>
                    <Select
                      labelId="ilce-select-label"
                      id="ilce-select"
                      value={secilenIlceId}
                      onChange={handleIlceChange}
                      displayEmpty
                      renderValue={(selected) => {
                        if (!selected) {
                          return <em>İlçe Seçiniz</em>;
                        }
                        const secilenIlce = sehirListesi
                          .find(sehir => sehir._id === secilenSehirId)?.ilceler
                          .find(ilce => ilce._id === selected);
                        return secilenIlce ? secilenIlce.ilce_adi : <em>İlçe Seçiniz</em>;
                      }}
                      startAdornment={
                        <InputAdornment position="start">
                          <LocationCityIcon style={{ fontSize: '1.2rem' }} />
                        </InputAdornment>
                      }
                    >
                      <MenuItem disabled value="">
                        <em>İlçe Seçiniz</em>
                      </MenuItem>
                      {sehirListesi
                        .find(sehir => sehir._id === secilenSehirId)?.ilceler
                        .map((ilce) => (
                          <MenuItem key={ilce._id} value={ilce._id}>
                            {ilce.ilce_adi}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                )}
                <TextField
                  fullWidth
                  label="Telefon"
                  margin="normal"
                  variant="outlined"
                  value={telefon}
                  onChange={handleTelefonChange}
                  error={telefonHata}
                  helperText={telefonHata ? 'Telefon numarası 0XXX XXX XXXX formatında olmalıdır.' : ''}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PhoneIcon style={{ fontSize: '1.2rem' }} />
                      </InputAdornment>
                    ),
                  }}
                />
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                  style={{ marginTop: '16px' }}
                  disabled={loading}
                >
                  {loading ? 'GÜNCELLENİYOR...' : 'GÜNCELLE'}
                </Button>
              </form>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <ResultDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        title={dialogSeverity === 'error' ? "Güncelleme Başarısız" : "Güncelleme Başarılı"}
        message={dialogContent}
        severity={dialogSeverity}
      />
    </Paper>
  );
};

export default ProfilGuncelle;

import React, { useState } from 'react';
import { TextField, Button, Box, Typography, Input, Paper } from '@mui/material';
import { API_URLS } from '../../../config/config';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

function BlogAdd() {
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [imageFile, setImageFile] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('title', title);
    formData.append('content', content);
    if (imageFile) {
      formData.append('image', imageFile);
    }

    try {
      const response = await fetch(`${API_URLS.ADMIN_URL}blog`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`
        },
        body: formData
      });
      const data = await response.json();
      if (data.success) {
        alert('Blog başarıyla oluşturuldu!');
        setTitle('');
        setContent('');
        setImageFile(null);
      } else {
        alert(data.message);
      }
    } catch (error) {
      console.error('Blog oluşturma hatası:', error);
    }
  };

  return (
    <Box>
      <Typography variant="h4" gutterBottom>Yeni Blog Ekle</Typography>
      <Paper sx={{ p: 3, mt: 2 }}>
        <form onSubmit={handleSubmit} encType="multipart/form-data">
          <Box mb={2}>
            <TextField
              label="Başlık"
              variant="outlined"
              fullWidth
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </Box>
          <Box mb={2}>
            <Typography variant="subtitle1" sx={{ mb: 1 }}>İçerik</Typography>
            <ReactQuill
              theme="snow"
              value={content}
              onChange={setContent}
              style={{ minHeight: '200px', marginBottom: '20px', backgroundColor: '#fff' }}
            />
          </Box>
          <Box mb={2}>
            <Typography variant="subtitle1" sx={{ mb: 1 }}>Resim Seç</Typography>
            <Input
              type="file"
              inputProps={{ accept: 'image/*' }}
              onChange={(e) => setImageFile(e.target.files[0])}
            />
          </Box>
          <Button type="submit" variant="contained" color="primary">Oluştur</Button>
        </form>
      </Paper>
    </Box>
  );
}

export default BlogAdd;

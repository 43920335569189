import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import Header from './components/Header';
import Instructions from './components/Instructions';
import ExamArea from './components/ExamArea'; 
import ResultsModal from './components/ResultsModal';
import './styles/okumaSeviyeTestiStyle.css';
import { API_URLS } from '../../config/config';
import axiosInstance from '../../utils/axiosInstance';

const OkumaSeviyeTesti = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { stage, fromStage, initialTest } = location.state || {};

  const [paragraf, setParagraf] = useState('');
  const [loading, setLoading] = useState(true);
  const [readingTime, setReadingTime] = useState(0);
  const [readingSpeed, setReadingSpeed] = useState(null);
  const [step, setStep] = useState(0);
  const [isRunning, setIsRunning] = useState(false);
  const timerRef = useRef(null);
  const [bookId, setBookId] = useState(null);

  const [wordCount, setWordCount] = useState(0);

  const [userData, setUserData] = useState(null);
  const [userRole, setUserRole] = useState(null);
  const [showResultsModal, setShowResultsModal] = useState(false);

  // Kullanıcı verilerini ve rolünü getirme
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        // Öğrenci verisini çek
        const studentResponse = await axiosInstance.get(`${API_URLS.STUDENT_URL}me`);
        if (
          studentResponse.status === 200 &&
          studentResponse.data &&
          studentResponse.data.status === 'active'
        ) {
          const studentData = studentResponse.data;
          setUserData(studentData);
          setUserRole('student');
          return;
        } else {
          setUserData(null);
          setUserRole(null);
        }
      } catch (error) {
        console.error('Öğrenci verisi alınırken hata oluştu:', error);
      }

      // Öğrenci yoksa öğretmene bak
      const token = localStorage.getItem('userToken');
      if (token) {
        try {
          const teacherResponse = await axios.get(`${API_URLS.TEACHER_URL}/me`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          if (teacherResponse.status === 200 && teacherResponse.data) {
            setUserData(teacherResponse.data);
            setUserRole('teacher');
            return;
          }
        } catch (error) {
          console.error('Öğretmen verisi alınırken hata oluştu:', error);
        }

        // Her iki durumda da başarısızsa
        setUserData(null);
        setUserRole(null);
      }
    };
    fetchUserData();
  }, []);

  // Paragrafı API'den getirme
  useEffect(() => {
    const fetchParagraf = async () => {
      try {
        const response = await axiosInstance.get(`${API_URLS.TESTLER_URL}seviye/medium`);
        const { content, _id } = response.data;
        setParagraf(content);
        setBookId(_id);
        setLoading(false);
      } catch (error) {
        console.error('Paragraf alınırken hata oluştu:', error);
        setLoading(false);
      }
    };

    fetchParagraf();
  }, []);

  // Zamanlayıcı kontrolü
  useEffect(() => {
    if (isRunning) {
      timerRef.current = setInterval(() => {
        setReadingTime((prevTime) => prevTime + 1);
      }, 1000);
    } else if (!isRunning && timerRef.current) {
      clearInterval(timerRef.current);
    }

    return () => clearInterval(timerRef.current);
  }, [isRunning]);

  const handleStart = () => {
    setIsRunning(true);
    setStep(2);
  };

  const handlePauseResume = () => {
    setIsRunning((prev) => !prev);
  };

  const handleFinishReading = async () => {
    setIsRunning(false);

    // Okuma hızını hesapla
    const wc = paragraf.split(' ').length;
    const timeInMinutes = readingTime / 60;
    const spd = wc / timeInMinutes;

    setWordCount(wc);
    setReadingSpeed(Math.round(spd));
    setStep(3);
    setShowResultsModal(true);

    // Öğrenci isek cookie otomatik gider, header gerekmez
    // Öğretmen isek localStorage tokeni ile header at
    const headers = userRole === 'teacher'
      ? { Authorization: `Bearer ${localStorage.getItem('userToken')}` }
      : undefined;

    if (userData && userRole) {
      try {
        let apiUrl = '';
        let payload = {
          wordCount: wc,
          totalTime: readingTime,
          readingSpeed: Math.round(spd),
        };

        if (fromStage && stage) {
          apiUrl = userRole === 'student'
            ? `${API_URLS.STUDENT_URL}akillikurs/progress/update-stage-test`
            : `${API_URLS.TEACHER_URL}akillikurs/progress/update-stage-test`;
          payload.stageNumber = stage.number;
          payload.bookId = bookId;
        } else if (initialTest) {
          apiUrl = userRole === 'student'
            ? `${API_URLS.STUDENT_URL}akillikurs/test/initial-test`
            : `${API_URLS.TEACHER_URL}akillikurs/test/initial-test`;
        } else {
          // Eğer ne fromStage ne initialTest ise hiçbir işlem yapma
          return;
        }
   
        if (userRole === 'student') {
          await axiosInstance.post(apiUrl, payload);
        } else {
          await axios.post(apiUrl, payload, { headers });
        }
      } catch (error) {
        console.error('Sonuç kaydedilirken hata oluştu:', error);
      }
    }
  };

  const handleCloseResultsModal = () => {
    setShowResultsModal(false);
  };

  return (
    <div className="okuma-seviye-testi-container">
      <Header />

      {step === 0 && <Instructions onStart={() => setStep(1)} />}

      {step === 1 && (
        <div className="okuma-seviye-testi-instructions">
          <h2>Hazır mısınız?</h2>
          <p>"Sınavı Başlat" butonuna bastığınızda zamanlayıcı başlayacaktır.</p>
          <button className="okuma-seviye-testi-button" onClick={handleStart}>
            Sınavı Başlat
          </button>
        </div>
      )}

      {step === 2 && (
        <ExamArea
          isRunning={isRunning}
          readingTime={readingTime}
          paragraf={paragraf}
          handlePauseResume={handlePauseResume}
          handleFinishReading={handleFinishReading}
          loading={loading}
        />
      )}

      {showResultsModal && (
        <ResultsModal
          wordCount={wordCount}
          readingTime={readingTime}
          readingSpeed={readingSpeed}
          userData={userData}
          fromStage={fromStage}
          stage={stage}
          bookId={bookId}
          initialTest={initialTest}
          userRole={userRole}
          onClose={handleCloseResultsModal}
        />
      )}
    </div>
  );
};

export default OkumaSeviyeTesti;

// GameHeader.js

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faHome } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import '../styles/hangiHarfiGordun.css'; // Stil dosyasını güncelledik

const GameHeader = ({ handleReturnToPreviousStage }) => {
  const navigate = useNavigate();

  return (
    <div className="game-header">
      <button className="game-header-button" onClick={() => navigate(-1)}>
        <FontAwesomeIcon icon={faArrowLeft} /> Geri Dön
      </button>
      <button className="game-header-button" onClick={() => navigate('/')}>
        <FontAwesomeIcon icon={faHome} /> Ana Sayfa
      </button>
      <button className="game-header-button" onClick={handleReturnToPreviousStage}>
        <FontAwesomeIcon icon={faArrowLeft} /> Önceki Aşamaya Dön
      </button>
    </div>
  );
};

export default GameHeader;

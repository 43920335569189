import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faTimes } from '@fortawesome/free-solid-svg-icons';
import '../styles/nesneyiTakipEt.css';

const GameOverModal = ({ score, onRestart, userData, onClose }) => {
  const navigate = useNavigate();

  // ESC tuşunu dinleyerek modal'ı kapatma
  useEffect(() => {
    const handleEsc = (event) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };

    window.addEventListener('keydown', handleEsc);

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, [onClose]);

  return (
    <div className="modal-backdrop" onClick={onClose} aria-modal="true" role="dialog">
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        {/* Kapatma Butonu */}
        <button className="close-button" onClick={onClose} aria-label="Close Modal">
          <FontAwesomeIcon icon={faTimes} />
        </button>

        <h2 className="modal-title">Çalışma Bitti!</h2>

        {userData ? (
          <>
            <p className="modal-score">Toplam Skorunuz: {Math.floor(score)}</p>
            <div className="button-group">
              <button className="btn btn-outline" onClick={() => {}}>
                Bitti
              </button>
              <button className="btn btn-secondary" onClick={() => navigate(-1)}>
                <FontAwesomeIcon icon={faArrowLeft} /> Geri Dön
              </button>
            </div>
          </>
        ) : (
          <>
            <p className="modal-message">Skoru görmek için giriş yapmalısınız.</p>
            <div className="button-group">
              <button className="btn btn-primary" onClick={() => navigate('/register')}>
                Kayıt Ol
              </button>
              <button className="btn btn-secondary" onClick={() => navigate(-1)}>
                <FontAwesomeIcon icon={faArrowLeft} /> Geri Dön
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default GameOverModal;

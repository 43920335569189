import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button,} from '@mui/material';
import { API_URLS } from '../../../../config/config';

const PermissionDialog = ({
  open,
  setOpen,
  permissions,
  editingPermission,
  setEditingPermission,
  showResultDialog,
  triggerRefetch,
}) => {
  const [permissionName, setPermissionName] = useState('');
  const [permissionDescription, setPermissionDescription] = useState('');

  useEffect(() => {
    if (editingPermission) {
      setPermissionName(editingPermission.name);
      setPermissionDescription(editingPermission.description || '');
    } else {
      setPermissionName('');
      setPermissionDescription('');
    }
  }, [editingPermission]);

  const handleSubmit = async () => {
    if (!permissionName.trim()) {
      showResultDialog('Yetki adı boş bırakılamaz', 'error');
      return;
    }

    const permissionData = {
      name: permissionName,
      description: permissionDescription,
    };

    try {
      let response;
      if (editingPermission) {
        response = await fetch(`${API_URLS.ADMIN_URL}roles/permissions/${editingPermission._id}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(permissionData),
        });
      } else {
        response = await fetch(`${API_URLS.ADMIN_URL}roles/permissions`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(permissionData),
        });
      }

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Bir hata oluştu');
      }

      triggerRefetch();
      setOpen(false);
      setEditingPermission(null);
      showResultDialog('Yetki başarıyla kaydedildi.', 'success');
    } catch (error) {
      console.error(error);
      showResultDialog(error.message, 'error');
    }
  };

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>{editingPermission ? 'Yetki Düzenle' : 'Yeni Yetki Ekle'}</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Yetki Adı"
          type="text"
          fullWidth
          value={permissionName}
          onChange={(e) => setPermissionName(e.target.value)}
        />
        <TextField
          margin="dense"
          label="Açıklama"
          type="text"
          fullWidth
          multiline
          rows={4}
          value={permissionDescription}
          onChange={(e) => setPermissionDescription(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)}>İptal</Button>
        <Button onClick={handleSubmit}>{editingPermission ? 'Güncelle' : 'Ekle'}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default PermissionDialog;

// GameOverModal.js

import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faTimes } from '@fortawesome/free-solid-svg-icons';
import '../styles/tachistoscopicReadingStyle.css';

const GameOverModal = ({
  displayedWords,
  enteredWords,
  onRestart,
  userData,
  onClose,
}) => {
  const navigate = useNavigate();

  // ESC tuşunu dinleyerek modal'ı kapatma
  useEffect(() => {
    const handleEsc = (event) => {
      if (event.key === 'Escape') {
        onRestart();
      }
    };

    window.addEventListener('keydown', handleEsc);

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, [onRestart]);

  // Skor hesaplama
  const normalizeWord = (word) =>
    word
      .toLowerCase()
      .trim()
      .replace(/[^\p{L}\p{N}\s]/gu, ''); // Noktalama işaretlerini kaldır

  const normalizedDisplayedWords = displayedWords.map(normalizeWord);
  const normalizedEnteredWords = enteredWords.map(normalizeWord);

  const displayedWordsSet = new Set(normalizedDisplayedWords);

  const correctWords = normalizedEnteredWords.filter((word) =>
    displayedWordsSet.has(word)
  );
  const incorrectWords = normalizedEnteredWords.filter(
    (word) => !displayedWordsSet.has(word)
  );
  const missedWords = normalizedDisplayedWords.filter(
    (word) => !normalizedEnteredWords.includes(word)
  );

  const handleLoginRedirect = () => {
    navigate('/register');
  };

  return (
    <div className="modal-backdrop" onClick={onRestart} aria-modal="true" role="dialog">
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        {/* Kapatma Butonu */}
        <button className="close-button" onClick={onRestart} aria-label="Close Modal">
          <FontAwesomeIcon icon={faTimes} />
        </button>

        <h2 className="modal-title">Çalışma Bitti!</h2>

        {userData ? (
          <>
            <p>
              Toplam Skorunuz: <strong>{correctWords.length}</strong> kelime
            </p>
            <p>
              Yanlış Kelimeler: <strong>{incorrectWords.length}</strong>
            </p>
            <p>
              Kaçırdığınız Kelimeler: <strong>{missedWords.length}</strong>
            </p>

            <h3>Doğru Girdiğiniz Kelimeler:</h3>
            <p>{correctWords.join(', ')}</p>

            <h3>Yanlış Girdiğiniz Kelimeler:</h3>
            <p>{incorrectWords.join(', ')}</p>

            <h3>Kaçırdığınız Kelimeler:</h3>
            <p>{missedWords.join(', ')}</p>

            <div className="button-group">
              <button className="tachisto-button" onClick={onRestart}>
                Bitti
              </button>
              <button
                className="tachisto-button"
                onClick={() => navigate(-1)}
                aria-label="Geri Dön"
              >
                <FontAwesomeIcon icon={faArrowLeft} /> Geri Dön
              </button>
            </div>
          </>
        ) : (
          <>
            <p>Giriş yapmadığınız için skoru göremiyorsunuz.</p>
            <div className="button-group">
              <button className="tachisto-button" onClick={handleLoginRedirect}>
                Kayıt Ol
              </button>
              <button
                className="tachisto-button"
                onClick={() => navigate(-1)}
                aria-label="Geri Dön"
              >
                <FontAwesomeIcon icon={faArrowLeft} /> Geri Dön
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default GameOverModal;

import React from 'react';
import { Paper, Grid, TextField, FormControl, InputLabel, Select, MenuItem, Button } from '@mui/material';

const StudentForm = ({
  ad,
  setAd,
  soyad,
  setSoyad,
  okulAdi,
  setOkulAdi,
  sinif,
  setSinif,
  yas,
  setYas,
  sehirListesi,
  secilenSehirId,
  handleSehirChange,
  secilenIlceId,
  handleIlceChange,
  secilenSehirinIlceleri,
  telefon,
  handleTelefonChange,
  telefonHata,
  durum,
  setDurum,
  email,
  setEmail,
  sifre,
  setSifre,
  handleEkle,
}) => {
  return (
    <Paper elevation={3} style={{ padding: 20 }}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={4}>
          <TextField fullWidth label="Adı" value={ad} onChange={(e) => setAd(e.target.value)} variant="outlined" />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            fullWidth
            label="Soyadı"
            value={soyad}
            onChange={(e) => setSoyad(e.target.value)}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            fullWidth
            label="Okul Adı (isteğe bağlı)"
            value={okulAdi}
            onChange={(e) => setOkulAdi(e.target.value)}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            fullWidth
            label="Sınıf"
            value={sinif}
            onChange={(e) => setSinif(e.target.value)}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField fullWidth label="Yaş" value={yas} onChange={(e) => setYas(e.target.value)} variant="outlined" />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FormControl fullWidth variant="outlined">
            <InputLabel id="sehir-select-label">Şehir</InputLabel>
            <Select
              labelId="sehir-select-label"
              value={secilenSehirId}
              onChange={handleSehirChange}
              label="Şehir"
            >
              <MenuItem value="">
                <em>Şehir Seçiniz</em>
              </MenuItem>
              {sehirListesi.map((sehir) => (
                <MenuItem key={sehir._id} value={sehir._id}>
                  {sehir.sehir_adi}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {secilenSehirId && (
          <Grid item xs={12} sm={6} md={4}>
            <FormControl fullWidth variant="outlined">
              <InputLabel id="ilce-select-label">İlçe</InputLabel>
              <Select
                labelId="ilce-select-label"
                id="ilce-select"
                value={secilenIlceId}
                onChange={handleIlceChange}
                label="İlçe"
                renderValue={(selected) => {
                  if (!selected) {
                    return <em>İlçe Seçiniz</em>;
                  }
                  const secilenIlce = secilenSehirinIlceleri.find((ilce) => ilce._id === selected);
                  return secilenIlce ? secilenIlce.ilce_adi : 'İlçe bulunamadı';
                }}
              >
                <MenuItem value="">
                  <em>İlçe Seçiniz</em>
                </MenuItem>
                {secilenSehirinIlceleri.map((ilce) => (
                  <MenuItem key={ilce._id} value={ilce._id}>
                    {ilce.ilce_adi}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        )}

        <Grid item xs={12} sm={6} md={4}>
          <TextField
            fullWidth
            label="Telefon"
            value={telefon}
            onChange={handleTelefonChange}
            error={telefonHata}
            helperText={telefonHata ? 'Telefon numarası 05XX XXX XXXX formatında olmalıdır.' : ''}
            variant="outlined"
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <FormControl fullWidth variant="outlined">
            <InputLabel id="status-select-label">Durum</InputLabel>
            <Select
              labelId="status-select-label"
              id="status-select"
              value={durum}
              onChange={(e) => setDurum(e.target.value)}
              label="Durum"
            >
              <MenuItem value="active">Aktif</MenuItem>
              <MenuItem value="inactive">Pasif</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <TextField fullWidth label="Email" value={email} onChange={(e) => setEmail(e.target.value)} variant="outlined" />
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <TextField
            fullWidth
            label="Şifre"
            value={sifre}
            onChange={(e) => setSifre(e.target.value)}
            variant="outlined"
            type="password"
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4} display="flex" alignItems="center">
          <Button onClick={handleEkle} variant="contained" color="primary" fullWidth>
            Ekle
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default StudentForm;

// GameDisplay.js

import React from 'react';
import '../styles/KelimeTamamlamaOyunu.css';

const GameDisplay = ({
  paragraph,
  getParagraphWithBlanks,
  options,
  selectedWord,
  userSelections,
  handleWordSelect,
  handleBlankClick,
}) => {
  return (
    <>
      <div className="options-container">
        {options.map((word, index) => (
          <button
            key={index}
            className={`btn ${
              selectedWord === word ? 'btn-primary' : 'btn-outline-primary'
            }`}
            onClick={() => handleWordSelect(word)}
            disabled={
              Object.values(userSelections.filledWords).some(
                (filled) => filled.word === word && filled.isCorrect
              )
            }
          >
            {word}
          </button>
        ))}
      </div>

      <div className="kayu-game-display">
        {paragraph ? getParagraphWithBlanks() : 'Paragraf yükleniyor...'}
      </div>
    </>
  );
};

export default GameDisplay;

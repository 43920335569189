import React, { useState, useEffect } from 'react';
import {
  Button, Typography, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Grid, TextField, Dialog, DialogTitle, DialogContent, DialogActions,
  FormControl, InputLabel, Select, MenuItem, Stack
} from '@mui/material';
import ResultDialog from '../../../pages/components/Auth/Dialog';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import { API_URLS } from '../../../config/config';

const Users = () => {
  const [adminListesi, setAdminListesi] = useState([]);
  const [roles, setRoles] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [resultDialogOpen, setResultDialogOpen] = useState(false);
  const [resultDialogMessage, setResultDialogMessage] = useState('');
  const [resultDialogSeverity, setResultDialogSeverity] = useState('info');
  const [yeniAdmin, setYeniAdmin] = useState({ fullname: '', email: '', password: '' });
  const [seciliAdminId, setSeciliAdminId] = useState(null);
  const [selectedRole, setSelectedRole] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const filteredAdmins = adminListesi.filter(admin =>
    admin.fullname.toLowerCase().includes(searchTerm.toLowerCase()) ||
    admin.email.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });

  const sortAdmins = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });

    setAdminListesi(adminListesi.slice().sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === 'ascending' ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === 'ascending' ? 1 : -1;
      }
      return 0;
    }));
  };

  useEffect(() => {
    const fetchAdminListesi = async () => {
      try {
        const response = await fetch(`${API_URLS.ADMIN_URL}/users`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('userToken')}`,
            'Content-Type': 'application/json',
          },
        });
        if (!response.ok) {
          throw new Error('Admin listesi yüklenirken bir hata oluştu.');
        }
        const data = await response.json();
        setAdminListesi(data);
      } catch (error) {
        console.error(error);
      }
    };

    const fetchRoles = async () => {
      try {
        const response = await fetch(`${API_URLS.ADMIN_URL}roles`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('userToken')}`,
            'Content-Type': 'application/json',
          },
        });
        if (!response.ok) {
          throw new Error('Roller yüklenirken bir hata oluştu');
        }
        const data = await response.json();
        setRoles(data.roles);
      } catch (error) {
        console.error(error);
      }
    };

    fetchRoles();
    fetchAdminListesi();
  }, []);

  const handleEkleGuncelleDialogOpen = (admin = null) => {
    if (admin) {
      setYeniAdmin({ fullname: admin.fullname, email: admin.email, password: '' });
      setSeciliAdminId(admin._id);
      setSelectedRole(admin.roles[0]?._id || '');
    } else {
      setYeniAdmin({ fullname: '', email: '', password: '' });
      setSeciliAdminId(null);
      setSelectedRole('');
    }
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setResultDialogOpen(false);
  };

  const handleEkleGuncelle = async () => {
    const adminData = {
      fullname: yeniAdmin.fullname,
      email: yeniAdmin.email,
      password: yeniAdmin.password,
      roles: [selectedRole],
    };

    try {
      const endpoint = seciliAdminId ? `${API_URLS.ADMIN_URL}users/admin_update/${seciliAdminId}` : `${API_URLS.ADMIN_URL}`;
      const method = seciliAdminId ? 'PUT' : 'POST';
      const response = await fetch(endpoint, {
        method: method,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(adminData),
      });

      if (!response.ok) {
        const errorDetail = await response.text();
        throw new Error(`Admin işlemi sırasında bir hata oluştu: ${errorDetail}`);
      }

      const responseData = await response.json();

      if (seciliAdminId) {
        const updatedAdminListesi = adminListesi.map((admin) =>
          admin._id === seciliAdminId ? responseData : admin
        );
        setAdminListesi(updatedAdminListesi);
      } else {
        setAdminListesi(prevAdminListesi => [...prevAdminListesi, responseData]);
      }

      setResultDialogMessage(`Admin başarıyla ${seciliAdminId ? 'güncellendi' : 'eklendi'}.`);
      setResultDialogSeverity('success');
      setResultDialogOpen(true);
    } catch (error) {
      setResultDialogMessage(error.message);
      setResultDialogSeverity('error');
      setResultDialogOpen(true);
    }

    setDialogOpen(false);
    setYeniAdmin({ fullname: '', email: '', password: '' });
    setSeciliAdminId(null);
    setSelectedRole('');
  };

  const handleDeleteAdmin = async (adminId) => {
    try {
      const response = await fetch(`${API_URLS.ADMIN_URL}/users/${adminId}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`,
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) throw new Error('Admin silinirken bir hata oluştu.');

      setAdminListesi(adminListesi.filter((admin) => admin._id !== adminId));

      setResultDialogMessage('Admin başarıyla silindi.');
      setResultDialogSeverity('success');
      setResultDialogOpen(true);
    } catch (error) {
      setResultDialogMessage(error.message);
      setResultDialogSeverity('error');
      setResultDialogOpen(true);
    }
  };

  const renderDialogForm = () => (
    <Dialog open={dialogOpen} onClose={handleDialogClose}>
      <DialogTitle>{seciliAdminId ? 'Admin Güncelle' : 'Yeni Admin Ekle'}</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Ad Soyad"
          type="text"
          fullWidth
          variant="outlined"
          value={yeniAdmin.fullname}
          onChange={(e) => setYeniAdmin({ ...yeniAdmin, fullname: e.target.value })}
        />
        <TextField
          margin="dense"
          label="E-posta"
          type="email"
          fullWidth
          variant="outlined"
          value={yeniAdmin.email}
          onChange={(e) => setYeniAdmin({ ...yeniAdmin, email: e.target.value })}
        />
        <TextField
          margin="dense"
          label="Şifre"
          type="password"
          fullWidth
          variant="outlined"
          value={yeniAdmin.password}
          onChange={(e) => setYeniAdmin({ ...yeniAdmin, password: e.target.value })}
        />

        <FormControl fullWidth margin="normal">
          <InputLabel>Rol</InputLabel>
          <Select
            value={selectedRole}
            onChange={(e) => setSelectedRole(e.target.value)}
          >
            {roles.map((role) => (
              <MenuItem key={role._id} value={role._id}>{role.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDialogClose}>İptal</Button>
        <Button onClick={handleEkleGuncelle}>{seciliAdminId ? 'Güncelle' : 'Ekle'}</Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <Paper style={{ padding: 20 }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h5" marginBottom={2}>Admin Yönetimi</Typography>
          <Button variant="contained" color="primary" onClick={() => handleEkleGuncelleDialogOpen()}>
            <AddIcon /> Yeni Admin Ekle
          </Button>

          <TextField
            variant="outlined"
            placeholder="Arama yap..."
            fullWidth
            margin="normal"
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell onClick={() => sortAdmins('fullname')}>
                    Ad Soyad {sortConfig.key === 'fullname' ? (sortConfig.direction === 'ascending' ? '🔼' : '🔽') : ''}
                  </TableCell>
                  <TableCell onClick={() => sortAdmins('email')}>
                    E-posta {sortConfig.key === 'email' ? (sortConfig.direction === 'ascending' ? '🔼' : '🔽') : ''}
                  </TableCell>
                  <TableCell>Rol</TableCell>
                  <TableCell align="right">İşlemler</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {filteredAdmins.map((admin) => {
                  const roleNames = admin.roles.map(role => role.name).join(', ');
                  return (
                    <TableRow key={admin._id}>
                      <TableCell>{admin.fullname}</TableCell>
                      <TableCell>{admin.email}</TableCell>
                      <TableCell>{roleNames}</TableCell>
                      <TableCell align="right">
                        <Stack direction="row" spacing={1}>
                          <Button
                            variant="contained"
                            color="primary"
                            startIcon={<EditIcon />}
                            onClick={() => handleEkleGuncelleDialogOpen(admin)}
                          >
                            Düzenle
                          </Button>
                          <Button
                            variant="contained"
                            color="secondary"
                            startIcon={<DeleteIcon />}
                            onClick={() => handleDeleteAdmin(admin._id)}
                          >
                            Sil
                          </Button>
                        </Stack>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      {renderDialogForm()}

      <ResultDialog
        open={resultDialogOpen}
        onClose={handleDialogClose}
        title={"İşlem Sonucu"}
        message={resultDialogMessage}
        severity={resultDialogSeverity}
      />
    </Paper>
  );
};

export default Users;

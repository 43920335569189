import React from 'react';
import { Typography, Button, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper, IconButton, Chip,} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { API_URLS } from '../../../../config/config';

const AccessControlsTable = ({
  accessControls,
  roles,
  resources,
  setAccessControlDialogOpen,
  setEditingAccessControl,
  showResultDialog,
  triggerRefetch,
}) => {
  const handleEditAccessControlClick = (accessControl) => {
    setEditingAccessControl(accessControl);
    setAccessControlDialogOpen(true);
  };

  const handleDeleteAccessControl = async (accessControlId) => {
    const isConfirmed = window.confirm('Bu erişim kontrolünü silmek istediğinize emin misiniz?');
    if (!isConfirmed) {
      return;
    }

    try {
      const response = await fetch(`${API_URLS.ADMIN_URL}roles/access-controls/${accessControlId}`, {
        method: 'DELETE',
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Erişim kontrolü silinirken bir hata oluştu.');
      }

      triggerRefetch();
      showResultDialog('Erişim kontrolü başarıyla silindi.', 'success');
    } catch (error) {
      console.error(error);
      showResultDialog(error.message, 'error');
    }
  };

  return (
    <>
      <Typography variant="h6">Erişim Kontrolleri</Typography>
      <Button
        startIcon={<AddIcon />}
        variant="contained"
        color="primary"
        onClick={() => {
          setEditingAccessControl(null);
          setAccessControlDialogOpen(true);
        }}
        style={{ marginBottom: '20px' }}
      >
        Erişim Kontrolü Ekle
      </Button>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Rol</TableCell>
              <TableCell>Kaynaklar</TableCell>
              <TableCell align="right">İşlemler</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {accessControls?.map((accessControl) => (
              <TableRow key={accessControl._id}>
                <TableCell>{accessControl.role?.name || 'Loading...'}</TableCell>
                <TableCell>
                  {accessControl.resources?.map((resource) => (
                    <Chip key={resource._id} label={resource.name} style={{ margin: '5px' }} />
                  )) || 'No Resources'}
                </TableCell>
                <TableCell align="right">
                  <IconButton color="primary" onClick={() => handleEditAccessControlClick(accessControl)}>
                    <EditIcon />
                  </IconButton>
                  <IconButton color="secondary" onClick={() => handleDeleteAccessControl(accessControl._id)}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default AccessControlsTable;

// HeaderSection.js

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faHome } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import '../styles/zigzagTrackerStyle.css'; 

const HeaderSection = ({ handleReturnToPreviousStage }) => {
  const navigate = useNavigate();

  return (
    <div className="zigzag-header">
      <button className="zigzag-button" onClick={() => navigate(-1)}>
        <FontAwesomeIcon icon={faArrowLeft} /> Geri
      </button>
      <button className="zigzag-button" onClick={() => navigate('/')}>
        <FontAwesomeIcon icon={faHome} /> Ana Sayfa
      </button>
      <button className="zigzag-button" onClick={handleReturnToPreviousStage}>
        <FontAwesomeIcon icon={faArrowLeft} /> Önceki Aşama
      </button>
    </div>
  );
};

export default HeaderSection;

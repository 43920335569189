import axiosInstance from '../../../../utils/axiosInstance';
import { API_URLS } from '../../../../config/config';

export const fetchParagraphs = async (difficulty) => {
  try {
    const url = `${API_URLS.HIZLIOKUMA_URL}kelimebulmavesecme/${difficulty}`;
    const response = await axiosInstance.get(url);
    return response.data;
  } catch (error) {
    console.error('Error fetching paragraphs:', error);
    throw new Error('Paragraflar yüklenirken bir hata oluştu.');
  }
};

import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Select, MenuItem, TextField } from '@mui/material';

const RevokeBalanceDialog = ({
  open,
  onClose,
  revokePackageType,
  setRevokePackageType,
  revokeAmount,
  setRevokeAmount,
  handleGeriAl
}) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Bakiye Geri Alma</DialogTitle>
      <DialogContent>
        <Select
          fullWidth
          value={revokePackageType}
          onChange={(e) => setRevokePackageType(e.target.value)}
          margin="normal"
        >
          <MenuItem value="bakiye3Ay">3 Ay</MenuItem>
          <MenuItem value="bakiye6Ay">6 Ay</MenuItem>
          <MenuItem value="bakiye12Ay">12 Ay</MenuItem>
        </Select>
        <TextField
          fullWidth
          label="Tutar"
          type="number"
          value={revokeAmount}
          onChange={(e) => setRevokeAmount(Number(e.target.value))}
          margin="normal"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>İptal</Button>
        <Button onClick={handleGeriAl} color="primary" variant="contained">
          Geri Al
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RevokeBalanceDialog;

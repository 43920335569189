import React, { useState, useEffect } from 'react';
import {
  IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, TextField, Dialog, DialogTitle, DialogContent, DialogActions, FormControl,
  InputLabel, Select, MenuItem, Grid, Box, Typography, CircularProgress, Collapse,
} from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
// import RefreshIcon from '@mui/icons-material/Refresh'; // Kaldırıldı
import FilterListIcon from '@mui/icons-material/FilterList';
import useFetchUserData from '../../../../hooks/admin/useFetchUserData';
import { API_URLS } from '../../../../config/config';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

// HTML etiketlerini kaldırmak için yardımcı fonksiyon
const kisalt = (content) => {
  const strippedContent = content.replace(/<[^>]+>/g, '');
  const kelimeler = strippedContent.split(' ');
  return kelimeler.slice(0, 100).join(' ') + (kelimeler.length > 100 ? '...' : '');
};

const BookListe = () => {
  const [books, setBooks] = useState([]);
  const [userData, setUserData] = useState(null);
  const [duzenlenenKitap, setDuzenlenenKitap] = useState({
    _id: '',
    title: '',
    content: '',
    difficulty: '',
  });

  const [duzenlemeDialogAcik, setDuzenlemeDialogAcik] = useState(false);

  // Test ekleme ile ilgili state'ler
  const [testFormAcik, setTestFormAcik] = useState(false);
  const [secilenKitapId, setSecilenKitapId] = useState('');
  const [testSorusu, setTestSorusu] = useState('');
  const [dogruCevap, setDogruCevap] = useState(null);
  const [testAciklamasi, setTestAciklamasi] = useState('');
  const [testSiklari, setTestSiklari] = useState([]);

  // Filtreleme state'leri
  const [filterTitle, setFilterTitle] = useState('');
  const [filterDifficulty, setFilterDifficulty] = useState('');
  const [filterStartDate, setFilterStartDate] = useState(null);
  const [filterEndDate, setFilterEndDate] = useState(null);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [filterOpen, setFilterOpen] = useState(false); // Filtreleme bölümünü açıp kapatmak için

  useFetchUserData(setUserData);

  const handleFilterTitleChange = (event) => {
    setFilterTitle(event.target.value);
  };

  const handleFilterDifficultyChange = (event) => {
    setFilterDifficulty(event.target.value);
  };

  const handleFilterStartDateChange = (date) => {
    setFilterStartDate(date);
  };

  const handleFilterEndDateChange = (date) => {
    setFilterEndDate(date);
  };

  const fetchBooks = async () => {
    if (!userData || !userData._id) {
      console.log("Kullanıcı bilgisi yüklenemedi veya kullanıcı ID'si bulunamadı.");
      return;
    }

    const userId = userData._id;
    console.log("Kullanıcı ID: " + userId);

    // Filtre parametrelerini oluşturma
    const params = new URLSearchParams();
    if (filterTitle) params.append('title', filterTitle);
    if (filterDifficulty) params.append('difficulty', filterDifficulty);
    if (filterStartDate) params.append('createdAt_gte', filterStartDate.toISOString());
    if (filterEndDate) params.append('createdAt_lte', filterEndDate.toISOString());
    // Admin ID'yi filtreye ekleyin
    params.append('adminId', userId);

    setLoading(true);
    setError(null);

    console.log(`${API_URLS.ADMIN_URL}kutuphane?${params.toString()}`);

    try {
      const response = await fetch(`${API_URLS.ADMIN_URL}kutuphane?${params.toString()}`);
      const data = await response.json();
      if (response.ok) {
        setBooks(data);
      } else {
        setError(data.message || 'Kitapları yüklerken bir sorun oluştu.');
        console.error('Kitapları yüklerken bir sorun oluştu: ' + data.message);
      }
    } catch (error) {
      setError('Fetch işlemi sırasında bir hata oluştu.');
      console.error('Fetch işlemi sırasında hata oluştu:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBooks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  // Filtre değerleri değiştiğinde kitapları yeniden çekmek için
  useEffect(() => {
    fetchBooks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterTitle, filterDifficulty, filterStartDate, filterEndDate]);

  const handleEditBook = (bookId) => {
    const seciliKitap = books.find((kitap) => kitap._id === bookId);

    if (seciliKitap) {
      setDuzenlenenKitap({
        _id: seciliKitap._id,
        title: seciliKitap.title,
        content: seciliKitap.content,
        difficulty: seciliKitap.difficulty,
      });
      setDuzenlemeDialogAcik(true);
    } else {
      console.error('Seçilen kitap bulunamadı.');
    }
  };

  const handleKitapDegisiklikleri = (e) => {
    setDuzenlenenKitap({
      ...duzenlenenKitap,
      [e.target.name]: e.target.value,
    });
  };

  const handleGuncelle = async () => {
    try {
      const response = await fetch(`${API_URLS.ADMIN_URL}kutuphane/${duzenlenenKitap._id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          title: duzenlenenKitap.title,
          content: duzenlenenKitap.content,
          difficulty: duzenlenenKitap.difficulty,
        }),
      });

      const data = await response.json();
      if (!response.ok) throw new Error(data.message || 'Bir hata oluştu');

      setBooks(books.map((kitap) => (kitap._id === duzenlenenKitap._id ? data : kitap)));
      setDuzenlemeDialogAcik(false);
    } catch (err) {
      console.error(err);
    }
  };

  const handleDeleteBook = async (bookId) => {
    try {
      const response = await fetch(`${API_URLS.ADMIN_URL}kutuphane/${bookId}`, {
        method: 'DELETE',
      });
      const data = await response.json();
      if (response.ok) {
        console.log(data.message);
        fetchBooks();
      } else {
        console.error('Kitap silme işlemi sırasında bir sorun oluştu: ' + data.message);
      }
    } catch (error) {
      console.error('Fetch işlemi sırasında hata oluştu:', error);
    }
  };

  // Test ekleme ile ilgili fonksiyonlar
  const handleSikEkle = () => {
    if (testSiklari.length < 5) {
      setTestSiklari([...testSiklari, '']);
    }
  };

  const handleSikSil = (index) => {
    const yeniSiklar = [...testSiklari];
    yeniSiklar.splice(index, 1);
    setTestSiklari(yeniSiklar);
  };

  const handleCreateTest = (bookId) => {
    setSecilenKitapId(bookId);
    setTestFormAcik(true);
  };

  const handleTestFormKapat = () => {
    setTestFormAcik(false);
    setTestSorusu('');
    setTestSiklari([]);
    setDogruCevap(0);
    setTestAciklamasi('');
  };

  const handleTestFormSubmit = async (e) => {
    e.preventDefault();

    if (testSiklari.length < 2 || testSiklari.length > 5) {
      alert('En az 2, en fazla 5 seçenek girilmelidir.');
      return;
    }

    if (dogruCevap < 0 || dogruCevap >= testSiklari.length) {
      alert('Geçerli bir doğru cevap seçin.');
      return;
    }

    try {
      const response = await fetch(`${API_URLS.ADMIN_URL}kutuphane/tests`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          bookId: secilenKitapId,
          question: testSorusu,
          options: testSiklari,
          correctAnswer: dogruCevap,
          explanation: testAciklamasi,
        }),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || 'Bir hata oluştu');
      }

      alert('Test başarıyla oluşturuldu.');
      handleTestFormKapat();
    } catch (err) {
      console.error(err);
      alert(`Hata: ${err.message}`);
    }
  };

  const renderDuzenlemeFormuDialog = () => (
    <Dialog
      open={duzenlemeDialogAcik}
      onClose={() => setDuzenlemeDialogAcik(false)}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>Kitap Düzenle</DialogTitle>
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Kitap Adı"
              name="title"
              value={duzenlenenKitap.title}
              onChange={handleKitapDegisiklikleri}
              variant="outlined"
            />
          </Grid>

          <Grid item xs={12}>
            <ReactQuill
              theme="snow"
              value={duzenlenenKitap.content}
              onChange={(value) =>
                handleKitapDegisiklikleri({ target: { name: 'content', value } })
              }
              placeholder="Kitap içeriğinizi buraya yazın..."
              modules={{
                toolbar: [
                  [{ header: [1, 2, 3, false] }],
                  ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                  [{ list: 'ordered' }, { list: 'bullet' }],
                  ['link', 'image'],
                  ['clean'],
                ],
              }}
              formats={[
                'header',
                'bold',
                'italic',
                'underline',
                'strike',
                'blockquote',
                'list',
                'bullet',
                'link',
                'image',
              ]}
              style={{ height: '200px', marginBottom: '20px' }}
            />
          </Grid>

          <Grid item xs={12}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="difficulty-label">Zorluk Seviyesi</InputLabel>
              <Select
                labelId="difficulty-label"
                id="difficulty"
                name="difficulty"
                value={duzenlenenKitap.difficulty}
                label="Zorluk Seviyesi"
                onChange={(e) =>
                  handleKitapDegisiklikleri({ target: { name: 'difficulty', value: e.target.value } })
                }
              >
                <MenuItem value="easy">Kolay</MenuItem>
                <MenuItem value="medium">Orta</MenuItem>
                <MenuItem value="hard">Zor</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setDuzenlemeDialogAcik(false)} color="secondary" variant="outlined">
          İptal
        </Button>
        <Button onClick={handleGuncelle} color="primary" variant="contained">
          Güncelle
        </Button>
      </DialogActions>
    </Dialog>
  );

  const renderTestForm = () => (
    <Dialog open={testFormAcik} onClose={handleTestFormKapat} maxWidth="md" fullWidth>
      <DialogTitle>Test Oluştur</DialogTitle>
      <DialogContent>
        <TextField
          fullWidth
          label="Soru"
          value={testSorusu}
          onChange={(e) => setTestSorusu(e.target.value)}
          margin="normal"
          variant="outlined"
        />
        {testSiklari.map((sik, index) => (
          <Box key={index} display="flex" alignItems="center" mb={2}>
            <TextField
              fullWidth
              label={`Şık ${index + 1}`}
              value={sik}
              onChange={(e) => {
                const yeniSiklar = [...testSiklari];
                yeniSiklar[index] = e.target.value;
                setTestSiklari(yeniSiklar);
              }}
              margin="normal"
              variant="outlined"
            />
            <IconButton onClick={() => handleSikSil(index)} color="error">
              <DeleteIcon />
            </IconButton>
          </Box>
        ))}

        <Button
          onClick={handleSikEkle}
          color="primary"
          variant="contained"
          style={{ marginTop: '10px', marginBottom: '20px' }}
          disabled={testSiklari.length >= 5}
        >
          Şık Ekle
        </Button>
        <FormControl variant="outlined" fullWidth margin="normal">
          <InputLabel>Doğru Cevap</InputLabel>
          <Select
            value={dogruCevap}
            onChange={(e) => setDogruCevap(e.target.value)}
            label="Doğru Cevap"
          >
            <MenuItem value="" disabled>
              Doğru cevabı seçin
            </MenuItem>
            {testSiklari.map((_, index) => (
              <MenuItem key={index} value={index}>
                {index + 1}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <TextField
          fullWidth
          label="Açıklama"
          value={testAciklamasi}
          onChange={(e) => setTestAciklamasi(e.target.value)}
          margin="normal"
          multiline
          rows={4}
          variant="outlined"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleTestFormKapat} color="secondary" variant="outlined">
          İptal
        </Button>
        <Button onClick={handleTestFormSubmit} color="primary" variant="contained">
          Kaydet
        </Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <Box sx={{ marginTop: 2, padding: 2 }}>
      {/* Yenile ve Yeni Kitap Ekle Butonları */}
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        {/* Yenile Butonu Kaldırıldı */}
        <Box>
          <Button
            startIcon={<FilterListIcon />}
            onClick={() => setFilterOpen(!filterOpen)}
            variant="outlined"
            color="default"
            sx={{ mr: 2 }}
          >
            Filtrele
          </Button>
          <Button
            startIcon={<AddCircleIcon />}
            onClick={() => {
              /* Yeni kitap ekleme formunu açmak için fonksiyon ekleyebilirsiniz */
            }}
            variant="contained"
            color="secondary"
          >
            Yeni Kitap Ekle
          </Button>
        </Box>
      </Box>

      {/* Filtreleme Bölümü */}
      <Collapse in={filterOpen}>
        <Box mb={3}>
          <Typography variant="h6" gutterBottom>
            Filtrele
          </Typography>
          <Box
            sx={{
              padding: 2,
              backgroundColor: '#f9f9f9',
              borderRadius: 2,
              boxShadow: 1,
            }}
          >
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  label="Kitap Adı"
                  variant="outlined"
                  fullWidth
                  value={filterTitle}
                  onChange={handleFilterTitleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="filter-difficulty-label">Zorluk Seviyesi</InputLabel>
                  <Select
                    labelId="filter-difficulty-label"
                    id="filter-difficulty"
                    value={filterDifficulty}
                    onChange={handleFilterDifficultyChange}
                    label="Zorluk Seviyesi"
                  >
                    <MenuItem value="">
                      <em>Tümü</em>
                    </MenuItem>
                    <MenuItem value="easy">Kolay</MenuItem>
                    <MenuItem value="medium">Orta</MenuItem>
                    <MenuItem value="hard">Zor</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <DatePicker
                  selected={filterStartDate}
                  onChange={handleFilterStartDateChange}
                  dateFormat="dd/MM/yyyy"
                  placeholderText="Başlangıç Tarihi"
                  customInput={
                    <TextField
                      fullWidth
                      variant="outlined"
                      size="small"
                      label="Başlangıç Tarihi"
                    />
                  }
                  isClearable
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <DatePicker
                  selected={filterEndDate}
                  onChange={handleFilterEndDateChange}
                  dateFormat="dd/MM/yyyy"
                  placeholderText="Bitiş Tarihi"
                  customInput={
                    <TextField
                      fullWidth
                      variant="outlined"
                      size="small"
                      label="Bitiş Tarihi"
                    />
                  }
                  isClearable
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={fetchBooks}
                  fullWidth
                  startIcon={<FilterListIcon />}
                  disabled={loading}
                  sx={{ height: '100%' }}
                >
                  Filtrele
                </Button>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => {
                    // Filtreleri sıfırlama
                    setFilterTitle('');
                    setFilterDifficulty('');
                    setFilterStartDate(null);
                    setFilterEndDate(null);
                    fetchBooks();
                  }}
                  fullWidth
                  sx={{ height: '100%' }}
                >
                  Filtreyi Sıfırla
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Collapse>

      {/* Hata Mesajı */}
      {error && (
        <Box mb={2}>
          <Typography color="error">{error}</Typography>
        </Box>
      )}

      {/* Kitap Tablosu */}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Ekleyen/Ünvan</TableCell>
              <TableCell>Kitap Adı</TableCell>
              <TableCell>İçerik</TableCell>
              <TableCell>Zorluk</TableCell>
              <TableCell>Oluşturma Tarihi</TableCell>
              <TableCell>İşlem</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {books.length === 0 && !loading ? (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  <Typography variant="body1">Gösterilecek kitap bulunamadı.</Typography>
                </TableCell>
              </TableRow>
            ) : (
              books.map((book) => (
                <TableRow key={book._id}>
                  <TableCell>{book.ekleyen}</TableCell>
                  <TableCell>{book.title}</TableCell>
                  <TableCell>{kisalt(book.content || 'İçerik yok')}</TableCell>

                  <TableCell>
                    {book.difficulty === 'easy'
                      ? 'Kolay'
                      : book.difficulty === 'medium'
                      ? 'Orta'
                      : book.difficulty === 'hard'
                      ? 'Zor'
                      : book.difficulty}
                  </TableCell>
                  <TableCell>
                    {book.createdAt
                      ? new Date(book.createdAt).toLocaleDateString('tr-TR', {
                          year: 'numeric',
                          month: 'long',
                          day: 'numeric',
                          hour: '2-digit',
                          minute: '2-digit',
                        })
                      : 'Tarih yok'}
                  </TableCell>
                  <TableCell>
                    <IconButton onClick={() => handleEditBook(book._id)} color="primary">
                      <EditIcon />
                    </IconButton>
                    <IconButton onClick={() => handleDeleteBook(book._id)} color="error">
                      <DeleteIcon />
                    </IconButton>
                    <IconButton onClick={() => handleCreateTest(book._id)} color="secondary">
                      <AddCircleIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>

        {/* Yükleniyor Spinner'ı */}
        {loading && (
          <Box display="flex" justifyContent="center" mt={2}>
            <CircularProgress />
          </Box>
        )}
      </TableContainer>

      {/* Güncelleme ve Test Oluşturma Dialogları */}
      {renderDuzenlemeFormuDialog()}
      {renderTestForm()}
    </Box>
  );
};

export default BookListe;

// components/PaymentForm.js

import React, { useState, useEffect } from 'react';
import {
  Typography,
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Card,
  CardContent,
  Grid,
  CircularProgress,
} from '@mui/material';
import { API_URLS } from '../../../config/config';
import axios from '../../../utils/axiosInstance';

function PaymentForm({ userType, userId }) {
  const [paketSuresi, setPaketSuresi] = useState('');
  const [toplamTutar, setToplamTutar] = useState(0);
  const [message, setMessage] = useState('');
  const [prices, setPrices] = useState({});
  const [loading, setLoading] = useState(true);
  const [priceError, setPriceError] = useState('');

  useEffect(() => {
    const fetchPrices = async () => {
      try {
        // Artık token localStorage'da değil, cookie üzerinden doğrulama yapılıyor.
        // Bu yüzden herhangi bir Authorization başlığı gerekmez. 
        // axiosInstance withCredentials sayesinde cookie üzerinden işlem yapacak.
        const response = await axios.get(`${API_URLS.STUDENT_URL}fiyat/prices`);

        const data = response.data;
        console.log('Fetched Prices:', data);

        const priceMap = {};
        data.forEach((price) => {
          const paketSuresiNum = Number(price.paketSuresi);
          const basePriceNum = Number(price.basePrice);
          const campaignPriceNum = price.campaignPrice
            ? Number(price.campaignPrice)
            : null;

          if (!isNaN(paketSuresiNum)) {
            priceMap[paketSuresiNum] = campaignPriceNum || basePriceNum;
          } else {
            console.warn(`Geçersiz paket süresi: ${price.paketSuresi}`);
          }
        });

        console.log('Mapped Price Map:', priceMap);
        setPrices(priceMap);

        if (paketSuresi === '' && Object.keys(priceMap).length > 0) {
          const firstPaketSuresi = Number(Object.keys(priceMap)[0]);
          setPaketSuresi(firstPaketSuresi);
        }

        setLoading(false);
      } catch (error) {
        console.error(error);
        setPriceError(error.message);
        setLoading(false);
      }
    };

    fetchPrices();
  }, [paketSuresi]);

  useEffect(() => {
    if (Object.keys(prices).length === 0 || paketSuresi === '') {
      setToplamTutar(0);
      return;
    }

    const basePricePerUnit = prices[paketSuresi];
    if (!basePricePerUnit) {
      setMessage('Geçersiz paket süresi.');
      setToplamTutar(0);
      return;
    }

    const totalPrice = basePricePerUnit;
    setToplamTutar(totalPrice);
  }, [paketSuresi, prices]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Ödeme isteğini cookie üzerinden kimlik doğrulama ile gönderiyoruz.
      const response = await axios.post(API_URLS.STUDENT_PAYMENT_URL, {
        userType,
        paketSuresi,
        userId,
      });

      const data = response.data;

      if (response.status === 200 || response.status === 201) {
        // paymentPageUrl'yi al ve yönlendir
        window.location.href = data.paymentPageUrl;
      } else {
        setMessage('Ödeme başlatılamadı: ' + data.message);
      }
    } catch (error) {
      console.error('Ödeme işlemi sırasında bir hata oluştu', error);
      setMessage('Ödeme işlemi sırasında bir hata oluştu.');
    }
  };

  if (loading) {
    return (
      <Card>
        <CardContent>
          <Typography variant="h5" gutterBottom>
            Hesabınızı Aktif Et
          </Typography>
          <CircularProgress />
        </CardContent>
      </Card>
    );
  }

  if (priceError) {
    return (
      <Card>
        <CardContent>
          <Typography variant="h5" gutterBottom>
            Hesabınızı Aktif Et
          </Typography>
          <Typography variant="body1" color="error">
            {priceError}
          </Typography>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card>
      <CardContent>
        <Typography variant="h5" gutterBottom>
          Hesabınızı Aktif Et
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="paket-suresi-label">Paket Süresi</InputLabel>
                <Select
                  labelId="paket-suresi-label"
                  value={paketSuresi}
                  onChange={(e) => setPaketSuresi(e.target.value)}
                  label="Paket Süresi"
                >
                  {Object.keys(prices).map((suresi) => (
                    <MenuItem key={suresi} value={Number(suresi)}>
                      {suresi} Aylık - {prices[suresi]} TL
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">Toplam Tutar: {toplamTutar} TL</Typography>
            </Grid>
            <Grid item xs={12}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
              >
                {userType === 'Ogrenci' && paketSuresi
                  ? `Ödeme Yap ve ${paketSuresi} Aylık Süreyi Uzat`
                  : 'Ödeme Yap ve Hesabı Aktif Et'}
              </Button>
            </Grid>
          </Grid>
        </form>
        {message && (
          <Typography variant="body1" color="error" sx={{ mt: 2 }}>
            {message}
          </Typography>
        )}
      </CardContent>
    </Card>
  );
}

export default PaymentForm;

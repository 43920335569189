import React, { useEffect, useState } from 'react';
import { API_URLS } from '../../../config/config';
import {Typography, Card, CardContent, Grid, Paper, Avatar, MenuItem, FormControl, InputLabel, Select, Button, TextField, Divider, CircularProgress,} from '@mui/material';
import { styled } from '@mui/system';
import { Person as PersonIcon, Business as BusinessIcon, School as SchoolIcon,} from '@mui/icons-material';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ResultDialog from '../../../pages/components/Auth/Dialog';
import useFetchUserData from '../../../hooks/admin/useFetchUserData';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginBottom: theme.spacing(3),
  backgroundColor: '#fff',
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[2],
}));

const FilterContainer = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(4),
  padding: theme.spacing(2),
  backgroundColor: '#f5f5f5',
  borderRadius: theme.shape.borderRadius,
}));

function PaymentList() {
  const [payments, setPayments] = useState([]);
  const [userData, setUserData] = useState(null);
  useFetchUserData(setUserData);

  const [userTypeFilter, setUserTypeFilter] = useState('');
  const [startDateFilter, setStartDateFilter] = useState(null);
  const [endDateFilter, setEndDateFilter] = useState(null);

  const [sehirListesi, setSehirListesi] = useState([]);
  const [secilenSehirId, setSecilenSehirId] = useState('');
  const [secilenIlceId, setSecilenIlceId] = useState('');
  const [ilceler, setIlceler] = useState([]);

  const [newEndDatesForward, setNewEndDatesForward] = useState({});
  const [newEndDatesBackward, setNewEndDatesBackward] = useState({});

  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState('');
  const [dialogSeverity, setDialogSeverity] = useState('info');

  // Yeni eklenen loading state'i
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPayments = async () => {
      try {
        const response = await fetch(API_URLS.ADMIN_URL + 'payments/paymenthistory', {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('userToken')}`,
            'Content-Type': 'application/json',
          },
        });
        const data = await response.json();

        if (response.ok) {
          setPayments(data);
        } else {
          console.error('Ödeme kayıtları alınamadı:', data.message);
        }
      } catch (error) {
        console.error('Ödeme kayıtları alınırken bir hata oluştu:', error);
      } finally {
        setLoading(false); 
      }
    };

    fetchPayments();
    fetchSehirler();
  }, []);

  const fetchSehirler = async () => {
    try {
      const response = await fetch(API_URLS.ADMIN_URL + 'sehirler');
      if (!response.ok) throw new Error('Şehirler yüklenirken bir hata oluştu.');
      const sehirler = await response.json();
      setSehirListesi(sehirler);
    } catch (err) {
      console.error('Şehirler yüklenirken bir hata oluştu:', err.message);
    }
  };

  const fetchIlceler = async (sehirId) => {
    try {
      const response = await fetch(`${API_URLS.ADMIN_URL}sehirler/ilceler/${sehirId}`);
      if (!response.ok) throw new Error('İlçeler yüklenirken bir hata oluştu.');
      const data = await response.json();
      const ilceler = data.ilceler ? data.ilceler : [];
      setIlceler(ilceler);
    } catch (error) {
      console.error('İlçeleri yüklerken bir hata oluştu:', error);
    }
  };

  const handleSehirChange = (event) => {
    const sehirId = event.target.value;
    setSecilenSehirId(sehirId);
    setSecilenIlceId('');
    if (sehirId) {
      fetchIlceler(sehirId);
    } else {
      setIlceler([]);
    }
  };

  const handleIlceChange = (event) => {
    setSecilenIlceId(event.target.value);
  };

  const getUserName = (payment) => {
    const user = payment.userId;
    if (!user) return 'Bilinmiyor';
    if (payment.userType === 'Ogretmen' || payment.userType === 'Ogrenci') {
      return `${user.ad} ${user.soyad}`;
    } else if (payment.userType === 'Kurumsal') {
      return user.kurum_adi;
    } else if (payment.userType === 'School') {
      return user.okul_adi;
    } else {
      return 'Bilinmiyor';
    }
  };

  const getUserIcon = (userType) => {
    if (userType === 'Ogretmen' || userType === 'Ogrenci') {
      return <PersonIcon />;
    } else if (userType === 'Kurumsal') {
      return <BusinessIcon />;
    } else if (userType === 'School') {
      return <SchoolIcon />;
    } else {
      return <PersonIcon />;
    }
  };

  const getUserBalance = (payment) => {
    const user = payment.userId;
    return user && user.bakiye !== undefined ? user.bakiye : 'Bilinmiyor';
  };

  const getUserCity = (payment) => {
    const user = payment.userId;
    return user && user.sehirAdi ? user.sehirAdi : 'Bilinmiyor';
  };

  const getUserDistrict = (payment) => {
    const user = payment.userId;
    return user && user.ilceAdi ? user.ilceAdi : 'Bilinmiyor';
  };

  const getSubscriptionEndDate = (payment) => {
    const user = payment.userId;
    if (!user) return 'Bilinmiyor';

    let abonelik = null;

    if (user.abonelikler && user.abonelikler.length > 0) {
      abonelik = user.abonelikler[user.abonelikler.length - 1];
    } else if (user.abonelik) {
      abonelik = user.abonelik;
    }

    if (abonelik && abonelik.bitisTarihi) {
      const bitisTarihi = new Date(abonelik.bitisTarihi);
      return bitisTarihi.toLocaleDateString('tr-TR');
    }

    return 'Bilinmiyor';
  };

  const filteredPayments = payments.filter((payment) => {
    const user = payment.userId;

    if (userTypeFilter && payment.userType !== userTypeFilter) {
      return false;
    }

    const paymentDate = new Date(payment.odemeTarihi);
    if (startDateFilter && paymentDate < startDateFilter) {
      return false;
    }
    if (endDateFilter && paymentDate > endDateFilter) {
      return false;
    }

    if (secilenSehirId) {
      if (user && user.sehir && user.sehir._id) {
        if (user.sehir._id.toString() !== secilenSehirId) {
          return false;
        }
      } else {
        return false;
      }
    }

    if (secilenIlceId) {
      if (user && user.ilce) {
        if (user.ilce.toString() !== secilenIlceId) {
          return false;
        }
      } else {
        return false;
      }
    }

    return true;
  });

  const getSubscriptionEndDateAsDate = (payment) => {
    const user = payment.userId;
    if (!user) return null;

    let abonelik = null;

    if (user.abonelikler && user.abonelikler.length > 0) {
      abonelik = user.abonelikler[user.abonelikler.length - 1];
    } else if (user.abonelik) {
      abonelik = user.abonelik;
    }

    if (abonelik && abonelik.bitisTarihi) {
      return new Date(abonelik.bitisTarihi);
    }

    return null;
  };

  // handleUpdateEndDate fonksiyonunu güncelleyerek date parametresi almasını sağlıyoruz
  const handleUpdateEndDate = async (payment, selectedDate) => {
    if (!selectedDate) {
      setDialogSeverity('error');
      setDialogContent('Lütfen yeni bitiş tarihini seçin.');
      setDialogOpen(true);
      return;
    }

    try {
      const response = await fetch(API_URLS.ADMIN_URL + 'payments/updateSubscriptionEndDate', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userId: payment.userId._id,
          userType: payment.userType,
          newEndDate: selectedDate.toISOString(),
        }),
      });

      const data = await response.json();

      if (response.ok) {
        setDialogSeverity('success');
        setDialogContent('Abonelik bitiş tarihi başarıyla güncellendi.');
        setDialogOpen(true);

        // payments durumunu güncelle
        setPayments((prevPayments) =>
          prevPayments.map((p) => {
            if (p._id === payment._id) {
              const updatedUser = { ...p.userId };
              if (updatedUser.abonelikler && updatedUser.abonelikler.length > 0) {
                const updatedAbonelikler = updatedUser.abonelikler.map((abonelik, index) => {
                  if (index === updatedUser.abonelikler.length - 1) {
                    return { ...abonelik, bitisTarihi: selectedDate.toISOString() };
                  }
                  return abonelik;
                });
                updatedUser.abonelikler = updatedAbonelikler;
              } else if (updatedUser.abonelik) {
                updatedUser.abonelik = { ...updatedUser.abonelik, bitisTarihi: selectedDate.toISOString() };
              }

              return { ...p, userId: updatedUser };
            }
            return p;
          })
        );

        // Yeni bitiş tarihlerini temizle
        setNewEndDatesForward((prevDates) => ({
          ...prevDates,
          [payment._id]: null,
        }));
        setNewEndDatesBackward((prevDates) => ({
          ...prevDates,
          [payment._id]: null,
        }));
      } else {
        setDialogSeverity('error');
        setDialogContent(data.message || 'Abonelik bitiş tarihi güncellenemedi.');
        setDialogOpen(true);
        console.error('Abonelik bitiş tarihi güncellenemedi:', data.message);
      }
    } catch (error) {
      setDialogSeverity('error');
      setDialogContent('Abonelik bitiş tarihi güncellenirken bir hata oluştu.');
      setDialogOpen(true);
      console.error('Abonelik bitiş tarihi güncellenirken bir hata oluştu:', error);
    }
  };

  return (
    <>
      <Card>
        <CardContent>
          <Typography variant="h5" gutterBottom>
            Ödeme Kayıtları
          </Typography>

          {/* Filtreleme Alanı */}
          <FilterContainer>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} sm={3}>
                <FormControl fullWidth variant="outlined" size="small">
                  <InputLabel>Kullanıcı Türü</InputLabel>
                  <Select
                    value={userTypeFilter}
                    onChange={(e) => setUserTypeFilter(e.target.value)}
                    label="Kullanıcı Türü"
                  >
                    <MenuItem value="">Tümü</MenuItem>
                    <MenuItem value="Ogretmen">Öğretmen</MenuItem>
                    <MenuItem value="Kurumsal">Kurumsal</MenuItem>
                    <MenuItem value="School">Okul</MenuItem>
                    <MenuItem value="Ogrenci">Öğrenci</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={3}>
                <DatePicker
                  selected={startDateFilter}
                  onChange={(date) => setStartDateFilter(date)}
                  dateFormat="dd/MM/yyyy"
                  placeholderText="Başlangıç Tarihi"
                  customInput={
                    <TextField
                      fullWidth
                      variant="outlined"
                      size="small"
                      label="Başlangıç Tarihi"
                    />
                  }
                />
              </Grid>

              <Grid item xs={12} sm={3}>
                <DatePicker
                  selected={endDateFilter}
                  onChange={(date) => setEndDateFilter(date)}
                  dateFormat="dd/MM/yyyy"
                  placeholderText="Bitiş Tarihi"
                  customInput={
                    <TextField fullWidth variant="outlined" size="small" label="Bitiş Tarihi" />
                  }
                />
              </Grid>

              <Grid item xs={12} sm={3}>
                <FormControl fullWidth variant="outlined" size="small">
                  <InputLabel id="sehir-select-label">Şehir</InputLabel>
                  <Select
                    labelId="sehir-select-label"
                    value={secilenSehirId}
                    onChange={handleSehirChange}
                    label="Şehir"
                  >
                    <MenuItem value="">
                      <em>Tümü</em>
                    </MenuItem>
                    {sehirListesi.map((sehir) => (
                      <MenuItem key={sehir._id} value={sehir._id}>
                        {sehir.sehir_adi}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              {secilenSehirId && (
                <Grid item xs={12} sm={3}>
                  <FormControl fullWidth variant="outlined" size="small">
                    <InputLabel id="ilce-select-label">İlçe</InputLabel>
                    <Select
                      labelId="ilce-select-label"
                      value={secilenIlceId}
                      onChange={handleIlceChange}
                      label="İlçe"
                    >
                      <MenuItem value="">
                        <em>Tümü</em>
                      </MenuItem>
                      {ilceler.map((ilce) => (
                        <MenuItem key={ilce._id} value={ilce._id}>
                          {ilce.ilce_adi}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}
            </Grid>
          </FilterContainer>

          {/* Loading göstergesi */}
          {loading ? (
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              style={{ minHeight: '200px' }}
            >
              <CircularProgress />
            </Grid>
          ) : (
            /* Ödeme Listesi */
            filteredPayments.map((payment) => (
              <StyledPaper key={payment._id}>
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={12}
                    sm={1}
                    container
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Avatar sx={{ bgcolor: '#3f51b5' }}>{getUserIcon(payment.userType)}</Avatar>
                  </Grid>
                  <Grid item xs={12} sm={11}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6} md={3}>
                        <Typography variant="subtitle1" gutterBottom>
                          <strong>Kullanıcı:</strong> {getUserName(payment)}
                        </Typography>
                        <Typography variant="body2">
                          <strong>Email:</strong>{' '}
                          {payment.userId ? payment.userId.email : 'Bilinmiyor'}
                        </Typography>
                       
                        <Typography variant="body2">
                          <strong>Şehir:</strong> {getUserCity(payment)}
                        </Typography>
                        <Typography variant="body2">
                          <strong>İlçe:</strong> {getUserDistrict(payment)}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <Typography variant="subtitle1" gutterBottom>
                          <strong>Paket Bilgisi</strong>
                        </Typography>
                        <Typography variant="body2">
                          <strong>Süre:</strong> {payment.paketSuresi} Ay
                        </Typography>
                        {payment.adet && payment.adet > 0 && (
                          <Typography variant="body2">
                            <strong>Satın Alınan Miktar:</strong> {payment.adet}
                          </Typography>
                        )}
                        <Typography variant="body2">
                          <strong>Toplam Tutar:</strong> {payment.toplamTutar} TL
                        </Typography>
                        <Typography variant="body2">
                          <strong>Abonelik Bitiş:</strong> {getSubscriptionEndDate(payment)}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <Typography variant="subtitle1" gutterBottom>
                          <strong>Ödeme Detayları</strong>
                        </Typography>
                        <Typography variant="body2">
                          <strong>Satın Alma:</strong>{' '}
                          {new Date(payment.odemeTarihi).toLocaleDateString('tr-TR')}
                        </Typography>
                        <Typography variant="body2">
                          <strong>Kullanıcı Tipi:</strong> {payment.userType}
                        </Typography>
                        <Typography variant="body2">
                          <strong>Paket Tipi:</strong> {payment.packageType}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                      <Typography variant="subtitle1" gutterBottom>
                          <strong>İyzico Bilgileri</strong>
                        </Typography>
                        <Typography variant="body2">
                          <strong>Ödeme ID:</strong> {payment.iyzicoPaymentId || 'Bilinmiyor'}
                        </Typography>
                        <Typography variant="body2">
                          <strong>Durum:</strong> {payment.iyzicoStatus || 'Bilinmiyor'}
                        </Typography>
                </Grid>

                      <Grid item xs={12} sm={6} md={12}>
                  
                        <Divider sx={{ marginY: 1 }} />

                        {/* Tarih Güncellemeleri Yan Yana */}
                        <Grid container spacing={2}>
                          {/* İleri Tarihli Bitiş Tarihi Güncelleme */}
                          <Grid item xs={12} sm={6}>
                            <Typography variant="subtitle2" gutterBottom>
                              <strong>Yeni Bitiş Tarihi (İleri)</strong>
                            </Typography>
                            <DatePicker
                              selected={newEndDatesForward[payment._id] || null}
                              onChange={(date) =>
                                setNewEndDatesForward({ ...newEndDatesForward, [payment._id]: date })
                              }
                              dateFormat="dd/MM/yyyy"
                              placeholderText="Yeni Bitiş Tarihi (İleri)"
                              minDate={getSubscriptionEndDateAsDate(payment) || new Date()}
                              customInput={
                                <TextField
                                fullWidth
                                  variant="outlined"
                                  size="small"
                                  label="Yeni Bitiş Tarihi (İleri)"
                              
                                />
                              }
                            />
                            <Button
                              variant="contained"
                              color="primary"
                              fullWidth
                              sx={{ marginTop: 1 }}
                              onClick={() => handleUpdateEndDate(payment, newEndDatesForward[payment._id])}
                            >
                              İleri Tarihi Güncelle
                            </Button>
                          </Grid>

                          {/* Geriye Yönelik Bitiş Tarihi Güncelleme */}
                          <Grid item xs={12} sm={6}>
                            <Typography variant="subtitle2" gutterBottom>
                              <strong>Yeni Bitiş Tarihi (Geri)</strong>
                            </Typography>
                            <DatePicker
                              selected={newEndDatesBackward[payment._id] || null}
                              onChange={(date) =>
                                setNewEndDatesBackward({ ...newEndDatesBackward, [payment._id]: date })
                              }
                              dateFormat="dd/MM/yyyy"
                              placeholderText="Yeni Bitiş Tarihi (Geri)"
                              maxDate={new Date()}
                              customInput={
                                <TextField
                                fullWidth
                             
                                  variant="outlined"
                                  size="small"
                                  label="Yeni Bitiş Tarihi (Geri)"
                                />
                              }
                            />
                            <Button
                              variant="contained"
                              color="secondary"
                              fullWidth
                              sx={{ marginTop: 1 }}
                              onClick={() => handleUpdateEndDate(payment, newEndDatesBackward[payment._id])}
                            >
                              Geri Tarihi Güncelle
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </StyledPaper>
            ))
          )}
        </CardContent>
      </Card>

      <ResultDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        title={dialogSeverity === 'error' ? 'Hata Oluştu' : 'İşlem Başarılı'}
        message={dialogContent}
        severity={dialogSeverity}
      />
    </>
  );
}

export default PaymentList;

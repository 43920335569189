// DuzenlemeFormuDialog.js
import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  DialogActions,
  Button,
} from '@mui/material';

const DuzenlemeFormuDialog = ({
  duzenlemeDialogAcik,
  setDuzenlemeDialogAcik,
  duzenlenenKurum,
  setDuzenlenenKurum,
  sehirListesi,
  handleSehirChangeDuzenle,
  handleIlceChangeDuzenle,
  handleGuncelle,
  kurumTurleri,
}) => {
  return (
    <Dialog open={duzenlemeDialogAcik} onClose={() => setDuzenlemeDialogAcik(false)}>
      <DialogTitle>Kurum Düzenle</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="kurumAdi"
          label="Kurum Adı"
          type="text"
          fullWidth
          variant="outlined"
          value={duzenlenenKurum.kurumAdi}
          onChange={(e) => setDuzenlenenKurum({ ...duzenlenenKurum, kurumAdi: e.target.value })}
        />
        {/* Email */}
        <TextField
          margin="dense"
          id="email"
          label="Email"
          type="email"
          fullWidth
          variant="outlined"
          value={duzenlenenKurum.email}
          onChange={(e) => setDuzenlenenKurum({ ...duzenlenenKurum, email: e.target.value })}
        />
        {/* Telefon */}
        <TextField
          margin="dense"
          id="telefon"
          label="Telefon"
          type="tel"
          fullWidth
          variant="outlined"
          value={duzenlenenKurum.telefon}
          onChange={(e) => setDuzenlenenKurum({ ...duzenlenenKurum, telefon: e.target.value })}
        />

        <FormControl fullWidth margin="dense">
          <InputLabel id="sehir-select-label-duzenle">Şehir</InputLabel>
          <Select
            labelId="sehir-select-label-duzenle"
            id="sehir-select-duzenle"
            value={duzenlenenKurum.sehirId}
            onChange={handleSehirChangeDuzenle}
            displayEmpty
          >
            <MenuItem value="">
              <em>Şehir Seçiniz</em>
            </MenuItem>
            {sehirListesi.map((sehir) => (
              <MenuItem key={sehir._id} value={sehir._id}>
                {sehir.sehir_adi}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {duzenlenenKurum.sehirId && (
          <FormControl fullWidth margin="dense">
            <InputLabel id="ilce-select-label-duzenle">İlçe</InputLabel>
            <Select
              labelId="ilce-select-label-duzenle"
              id="ilce-select-duzenle"
              value={duzenlenenKurum.ilceId}
              onChange={handleIlceChangeDuzenle}
              displayEmpty
              renderValue={(selected) => {
                if (selected) {
                  const secilenIlce = duzenlenenKurum.ilceler.find((ilce) => ilce._id === selected);
                  return secilenIlce ? secilenIlce.ilce_adi : 'İlçe bulunamadı';
                }
                return <em>İlçe Seçiniz</em>;
              }}
            >
              {duzenlenenKurum.ilceler?.map((ilce) => (
                <MenuItem key={ilce._id} value={ilce._id}>
                  {ilce.ilce_adi}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        <FormControl fullWidth margin="dense">
          <InputLabel id="kurum-turu-label">Kurum Türü</InputLabel>
          <Select
            labelId="kurum-turu-label"
            id="kurum-turu-select"
            multiple
            value={duzenlenenKurum.kurumTuru ? duzenlenenKurum.kurumTuru : []}
            onChange={(event) => {
              setDuzenlenenKurum((prevDuzenlenenKurum) => ({
                ...prevDuzenlenenKurum,
                kurumTuru: event.target.value,
              }));
            }}
            renderValue={(selected) => selected.join(', ')}
          >
            {kurumTurleri.map((tur) => (
              <MenuItem key={tur.id} value={tur.adi}>
                {tur.adi}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Durum Seçimi */}
        <FormControl fullWidth margin="normal">
          <InputLabel id="status-select-label-duzenle">Durum</InputLabel>
          <Select
            labelId="status-select-label-duzenle"
            id="status-select-duzenle"
            value={duzenlenenKurum.status}
            onChange={(e) => setDuzenlenenKurum({ ...duzenlenenKurum, status: e.target.value })}
          >
            <MenuItem value="active">Aktif</MenuItem>
            <MenuItem value="inactive">Pasif</MenuItem>
          </Select>
        </FormControl>

        {/* Şifre Güncelleme Alanı */}
        <TextField
          fullWidth
          label="Yeni Şifre (isteğe bağlı)"
          type="password"
          value={duzenlenenKurum.password}
          onChange={(e) => setDuzenlenenKurum({ ...duzenlenenKurum, password: e.target.value })}
          margin="normal"
          helperText="Şifreyi güncellemek istiyorsanız buraya yeni şifreyi giriniz."
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setDuzenlemeDialogAcik(false)}>İptal</Button>
        <Button onClick={handleGuncelle}>Güncelle</Button>
      </DialogActions>
    </Dialog>
  );
};

export default DuzenlemeFormuDialog;

import React, { useState, useEffect } from 'react';
import { 
    IconButton, Table, TableBody, TableCell, TableContainer, 
    TableHead, TableRow, Paper, Button, TextField, Dialog, 
    DialogTitle, DialogContent, DialogActions, FormControl, 
    InputLabel, Select, MenuItem, Toolbar, Typography, Box, 
    Stack, Tooltip
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import RefreshIcon from '@mui/icons-material/Refresh';
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'; 
import { API_URLS } from '../../../config/config';
import useFetchUserData from '../../../hooks/ogretmen/useFetchTeacherUserData';

const Tests = () => {
    const [tests, setTests] = useState([]);
    const [userData, setUserData] = useState(null);
    const [books, setBooks] = useState([]); 
    const [editedTest, setEditedTest] = useState({
        _id: '',
        question: '',
        options: [],
        correctAnswer: '',
        explanation: '',
        bookTitle: '',
    });

    const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
    const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);

    const [newTest, setNewTest] = useState({
        question: '',
        options: ['', ''],
        correctAnswer: '',
        explanation: '',
        bookId: '', 
    });

    // Test oluşturma formu state'leri
    const [testSorusu, setTestSorusu] = useState('');
    const [testSiklari, setTestSiklari] = useState(['', '']);
    const [dogruCevap, setDogruCevap] = useState('');
    const [testAciklamasi, setTestAciklamasi] = useState('');

    // Filtreler için state'ler
    const [questionFilter, setQuestionFilter] = useState(''); // Soru veya kitap adına göre arama
    const [bookFilter, setBookFilter] = useState(''); // Belirli bir kitaba göre filtreleme

    useFetchUserData(setUserData);

    // Kitapları çekmek için fonksiyon
    const fetchBooks = async () => {
        if (!userData || !userData._id) {
            console.log("Kullanıcı bilgisi yüklenemedi veya kullanıcı ID'si bulunamadı.");
            return;
        }

        const userId = userData._id;
        console.log("Kullanıcı ID: " + userId);

        try {
            const response = await fetch(`${API_URLS.TEACHER_URL}kutuphane/${userId}`);
            const data = await response.json();
            if (response.ok) {
                setBooks(data);
            } else {
                console.error('Kitapları yüklerken bir sorun oluştu: ' + data.message);
            }
        } catch (error) {
            console.error('Fetch işlemi sırasında hata oluştu:', error);
        }
    };

    // Testleri çekmek için fonksiyon
    const fetchTests = async () => {
        if (!userData || !userData._id) {
            console.log("Kullanıcı bilgisi yüklenemedi veya kullanıcı ID'si bulunamadı.");
            return;
        }

        const userId = userData._id;
        console.log("Kullanıcı ID: " + userId);

        try {
            const response = await fetch(`${API_URLS.TEACHER_URL}kutuphane/tests/${userId}`);
            const data = await response.json();
            if (response.ok) {
                setTests(data);
            } else {
                console.error('Testleri yüklerken bir sorun oluştu: ' + data.message);
            }
        } catch (error) {
            console.error('Fetch işlemi sırasında hata oluştu:', error);
        }
    };

    useEffect(() => {
        fetchTests();
        fetchBooks(); 
    }, [userData]);

    // Test Düzenleme Fonksiyonları
    const handleEditTest = (testId) => {
        const selectedTest = tests.find((test) => test._id === testId);
        if (selectedTest) {
            setEditedTest({
                _id: selectedTest._id,
                question: selectedTest.question,
                options: selectedTest.options,
                correctAnswer: selectedTest.correctAnswer,
                explanation: selectedTest.explanation,
                bookTitle: selectedTest.bookTitle,
            });
            setIsEditDialogOpen(true);
        } else {
            console.error('Seçilen test bulunamadı.');
        }
    };

    const handleTestChange = (e) => {
        const { name, value } = e.target;
        setEditedTest(prevTest => ({
            ...prevTest,
            [name]: value
        }));
    };

    const handleUpdate = async () => {
        try {
            if (!editedTest.question || editedTest.options.length < 2 || editedTest.correctAnswer === '') {
                alert('Lütfen tüm gerekli alanları doldurun ve en az 2 şık ekleyin.');
                return;
            }

            if (editedTest.options.some(option => option.trim() === '')) {
                alert('Boş şıklar olamaz. Lütfen tüm şıkları doldurun.');
                return;
            }

            const correctAnswerIndex = parseInt(editedTest.correctAnswer);

            const updatedTest = {
                ...editedTest,
                correctAnswer: correctAnswerIndex
            };

            const response = await fetch(`${API_URLS.TEACHER_URL}kutuphane/tests/${editedTest._id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(updatedTest),
            });

            const data = await response.json();
            if (!response.ok) throw new Error(data.message || 'Bir hata oluştu');

            setTests(prevTests => prevTests.map(test => 
                test._id === editedTest._id ? { ...test, ...data } : test
            ));

            setIsEditDialogOpen(false);
            alert('Test başarıyla güncellendi.');
        } catch (err) {
            console.error('Test güncelleme hatası:', err);
            alert(`Test güncellenirken bir hata oluştu: ${err.message}`);
        }
    };

    const handleDeleteTest = async (testId) => {
        if (window.confirm('Bu testi silmek istediğinizden emin misiniz? Bu işlem geri alınamaz.')) {
            try {
                const response = await fetch(`${API_URLS.TEACHER_URL}kutuphane/tests/${testId}`, {
                    method: 'DELETE',
                });
                if (response.ok) {
                    const data = await response.json();
                    console.log(data.message);
                    setTests(prevTests => prevTests.filter(test => test._id !== testId));
                } else {
                    const errorData = await response.json();
                    console.error('Test silme işlemi sırasında bir sorun oluştu: ' + errorData.message);
                }
            } catch (error) {
                console.error('Fetch işlemi sırasında hata oluştu:', error);
            }
        }
    };

    // Şık ekleme/silme (Edit)
    const handleSikEkleEdit = () => {
        if (editedTest.options.length < 5) {
            setEditedTest({ ...editedTest, options: [...editedTest.options, ''] });
        }
    };

    const handleSikSilEdit = (index) => {
        const yeniSiklar = [...editedTest.options];
        yeniSiklar.splice(index, 1);
        setEditedTest({ ...editedTest, options: yeniSiklar });
    };

    // Şık ekleme/silme (Add)
    const handleSikEkle = () => {
        if (testSiklari.length < 5) {
            setTestSiklari([...testSiklari, '']);
        }
    };

    const handleSikSil = (index) => {
        const yeniSiklar = [...testSiklari];
        yeniSiklar.splice(index, 1);
        setTestSiklari(yeniSiklar);
    };

    // Test Oluşturma
    const handleTestFormKapat = () => {
        setIsAddDialogOpen(false);
        setTestSorusu('');
        setTestSiklari(['', '']);
        setDogruCevap('');
        setTestAciklamasi('');
        setNewTest({
            question: '',
            options: ['', ''],
            correctAnswer: '',
            explanation: '',
            bookId: '',
        });
    };

    const handleTestFormSubmit = async (e) => {
        e.preventDefault();

        if (testSiklari.length < 2 || testSiklari.length > 5) {
            alert('En az 2, en fazla 5 seçenek girilmelidir.');
            return;
        }

        if (dogruCevap === '' || dogruCevap < 0 || dogruCevap >= testSiklari.length) {
            alert('Geçerli bir doğru cevap seçin.');
            return;
        }

        if (!newTest.bookId) {
            alert('Lütfen bir kitap seçin.');
            return;
        }

        try {
            const response = await fetch(`${API_URLS.TEACHER_URL}kutuphane/tests`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    bookId: newTest.bookId,
                    question: testSorusu,
                    options: testSiklari,
                    correctAnswer: testSiklari[dogruCevap],
                    explanation: testAciklamasi,
                }),
            });

            const data = await response.json();

            if (!response.ok) {
                throw new Error(data.message || 'Bir hata oluştu');
            }

            alert('Test başarıyla oluşturuldu.');
            handleTestFormKapat();
            fetchTests(); 
        } catch (err) {
            console.error(err);
            alert(`Hata: ${err.message}`);
        }
    };

    // Filtreleme
    const filteredTests = tests.filter((test) => {
        const lowerQuestionFilter = questionFilter.toLowerCase();
        const matchesQuestion = test.question.toLowerCase().includes(lowerQuestionFilter) 
            || (test.bookTitle && test.bookTitle.toLowerCase().includes(lowerQuestionFilter));
        const matchesBook = bookFilter === '' ? true : (test.bookTitle === bookFilter);
        return matchesQuestion && matchesBook;
    });

    // Edit Form Dialog
    const renderEditFormDialog = () => (
        <Dialog open={isEditDialogOpen} onClose={() => setIsEditDialogOpen(false)} maxWidth="md" fullWidth>
            <DialogTitle>Test Düzenle</DialogTitle>
            <DialogContent>
                <Stack spacing={2} mt={1}>
                    <TextField
                        fullWidth
                        label="Soru"
                        name="question"
                        value={editedTest.question}
                        onChange={handleTestChange}
                    />
                    {editedTest.options.map((option, index) => (
                        <Box key={index} display="flex" alignItems="center" gap={1}>
                            <TextField
                                fullWidth
                                label={`Şık ${index + 1}`}
                                value={option}
                                onChange={(e) => {
                                    const newOptions = [...editedTest.options];
                                    newOptions[index] = e.target.value;
                                    setEditedTest({ ...editedTest, options: newOptions });
                                }}
                            />
                            <IconButton onClick={() => handleSikSilEdit(index)}>
                                <DeleteIcon />
                            </IconButton>
                        </Box>
                    ))}
                    <Button
                        onClick={handleSikEkleEdit}
                        color="primary"
                        variant="contained"
                        disabled={editedTest.options.length >= 5}
                    >
                        Şık Ekle
                    </Button>
                    <FormControl fullWidth>
                        <InputLabel>Doğru Cevap</InputLabel>
                        <Select
                            name="correctAnswer"
                            value={editedTest.correctAnswer}
                            onChange={handleTestChange}
                            displayEmpty
                        >
                            <MenuItem value="" disabled>Doğru cevabı seçin</MenuItem>
                            {editedTest.options.map((option, index) => (
                                <MenuItem key={index} value={index.toString()}>
                                    {String.fromCharCode(65 + index)}. {option}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <TextField
                        fullWidth
                        label="Açıklama"
                        name="explanation"
                        value={editedTest.explanation}
                        onChange={handleTestChange}
                        multiline
                        rows={4}
                    />
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setIsEditDialogOpen(false)}>İptal</Button>
                <Button onClick={handleUpdate} color="primary" variant="contained">
                    Güncelle
                </Button>
            </DialogActions>
        </Dialog>
    );

    // Add Test Form Dialog
    const renderAddFormDialog = () => (
        <Dialog open={isAddDialogOpen} onClose={handleTestFormKapat} maxWidth="md" fullWidth>
            <DialogTitle>Test Oluştur</DialogTitle>
            <DialogContent>
                <Stack spacing={2} mt={1}>
                    <TextField
                        fullWidth
                        label="Soru"
                        value={testSorusu}
                        onChange={(e) => setTestSorusu(e.target.value)}
                        required
                    />
                    {testSiklari.map((sik, index) => (
                        <Box key={index} display="flex" alignItems="center" gap={1}>
                            <TextField
                                fullWidth
                                label={`Şık ${index + 1}`}
                                value={sik}
                                onChange={(e) => {
                                    const yeniSiklar = [...testSiklari];
                                    yeniSiklar[index] = e.target.value;
                                    setTestSiklari(yeniSiklar);
                                }}
                                required
                            />
                            <IconButton onClick={() => handleSikSil(index)}>
                                <DeleteOutlineIcon />
                            </IconButton>
                        </Box>
                    ))}
                    <Button
                        onClick={handleSikEkle}
                        color="primary"
                        variant="contained"
                        disabled={testSiklari.length >= 5}
                    >
                        Şık Ekle
                    </Button>
                    <FormControl fullWidth>
                        <InputLabel>Doğru Cevap</InputLabel>
                        <Select
                            value={dogruCevap}
                            onChange={(e) => setDogruCevap(e.target.value)}
                            displayEmpty
                            required
                        >
                            <MenuItem value="" disabled>Doğru cevabı seçin</MenuItem>
                            {testSiklari.map((_, index) => (
                                <MenuItem key={index} value={index}>
                                    {`Şık ${index + 1}`}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel>Kitap Seçin</InputLabel>
                        <Select
                            value={newTest.bookId}
                            onChange={(e) => setNewTest({ ...newTest, bookId: e.target.value })}
                            displayEmpty
                            required
                        >
                            <MenuItem value="" disabled>Kitap seçin</MenuItem>
                            {books.map((book) => (
                                <MenuItem key={book._id} value={book._id}>
                                    {book.title}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <TextField
                        fullWidth
                        label="Açıklama"
                        value={testAciklamasi}
                        onChange={(e) => setTestAciklamasi(e.target.value)}
                        multiline
                        rows={4}
                    />
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleTestFormKapat}>İptal</Button>
                <Button onClick={handleTestFormSubmit} color="primary" variant="contained">
                    Kaydet
                </Button>
            </DialogActions>
        </Dialog>
    );

    const handleClearFilters = () => {
        setQuestionFilter('');
        setBookFilter('');
    };

    return (
        <Box sx={{ mt: 4 }}>
            <Typography variant="h4" sx={{ mb: 2, textAlign: 'center' }}>Test Yönetimi</Typography>
            <Typography variant="subtitle1" sx={{ mb: 4, textAlign: 'center', color: 'text.secondary' }}>
                Buradan testlerinizi görüntüleyebilir, yeni testler ekleyebilir, düzenleyebilir veya silebilirsiniz.
            </Typography>

            <Paper elevation={3} sx={{ pb: 2 }}>
                <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', gap: 2 }}>
                    <Stack direction="row" spacing={2} alignItems="center" flexWrap="wrap">
                        <TextField
                            label="Soru veya Kitap Adında Ara"
                            variant="outlined"
                            size="small"
                            value={questionFilter}
                            onChange={(e) => setQuestionFilter(e.target.value)}
                        />
                        <FormControl variant="outlined" size="small" sx={{ minWidth: 160 }}>
                            <InputLabel>Kitap Filtrele</InputLabel>
                            <Select
                                label="Kitap Filtrele"
                                value={bookFilter}
                                onChange={(e) => setBookFilter(e.target.value)}
                            >
                                <MenuItem value="">Tümü</MenuItem>
                                {Array.from(new Set(tests.map(test => test.bookTitle).filter(Boolean))).map((title, index) => (
                                    <MenuItem key={index} value={title}>{title}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <Button variant="text" color="primary" onClick={handleClearFilters}>
                            Temizle
                        </Button>
                    </Stack>
                    <Stack direction="row" spacing={2}>
                        <Button
                            startIcon={<RefreshIcon />}
                            onClick={fetchTests}
                            variant="outlined"
                            color="primary"
                        >
                            Yenile
                        </Button>
                        <Button
                            startIcon={<AddIcon />}
                            onClick={() => setIsAddDialogOpen(true)}
                            variant="contained"
                            color="secondary"
                        >
                            Yeni Test Ekle
                        </Button>
                    </Stack>
                </Toolbar>
                <TableContainer>
                    <Table>
                        <TableHead sx={{ backgroundColor: '#f5f5f5' }}>
                            <TableRow>
                                <TableCell sx={{ fontWeight: 'bold' }}>Kitap Adı</TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }}>Soru</TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }}>Şıklar</TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }}>Doğru Cevap</TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }}>İşlem</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filteredTests.map((test) => (
                                <TableRow key={test._id} hover>
                                    <TableCell>{test.bookTitle || 'Bilinmiyor'}</TableCell>
                                    <TableCell>{test.question}</TableCell>
                                    <TableCell>
                                        {test.options && test.options.map((option, index) => (
                                            <div key={index}>
                                                {String.fromCharCode(65 + index)}. {option}
                                            </div>
                                        ))}
                                    </TableCell>
                                    <TableCell>
                                        {test.options && test.correctAnswer !== undefined && test.correctAnswer >= 0 && test.correctAnswer < test.options.length
                                            ? `${String.fromCharCode(65 + test.correctAnswer)}. ${test.options[test.correctAnswer]}`
                                            : 'Geçersiz'}
                                    </TableCell>
                                    <TableCell>
                                        <Stack direction="row" spacing={1}>
                                            <Tooltip title="Düzenle">
                                                <IconButton onClick={() => handleEditTest(test._id)}>
                                                    <EditIcon />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Sil">
                                                <IconButton onClick={() => handleDeleteTest(test._id)}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </Stack>
                                    </TableCell>
                                </TableRow>
                            ))}
                            {filteredTests.length === 0 && (
                                <TableRow>
                                    <TableCell colSpan={5} align="center" sx={{ color: 'text.secondary' }}>
                                        Filtre kriterlerinize uyan test bulunamadı.
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
            {renderEditFormDialog()}
            {renderAddFormDialog()}
        </Box>
    );
};

export default Tests;

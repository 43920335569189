import React, { useState, useEffect } from 'react';
import { 
  IconButton, Table, TableBody, TableCell, TableContainer, 
  TableHead, TableRow, Paper, Button, TextField, Dialog, DialogTitle, 
  DialogContent, DialogActions, FormControl, InputLabel, Select, MenuItem, 
  Card, CardContent, Typography, Box, Stack, Tooltip, Radio, RadioGroup, FormControlLabel, Grid
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import RefreshIcon from '@mui/icons-material/Refresh';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'; 
import { API_URLS } from '../../../../config/config';
import useFetchUserData from '../../../../hooks/ogretmen/useFetchTeacherUserData';
import ResultDialog from '../../../../pages/components/Auth/Dialog';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const Books = () => {
  const [books, setBooks] = useState([]);
  const [userData, setUserData] = useState(null);

  const [duzenlenenKitap, setDuzenlenenKitap] = useState({
    _id: '',
    title: '',
    difficulty: '',
  });
      
  const [duzenlemeDialogAcik, setDuzenlemeDialogAcik] = useState(false);
  const [testFormAcik, setTestFormAcik] = useState(false);
  const [secilenKitapId, setSecilenKitapId] = useState('');
  const [testSorusu, setTestSorusu] = useState(''); 
  const [dogruCevap, setDogruCevap] = useState(null);
  const [testAciklamasi, setTestAciklamasi] = useState('');
  const [testSiklari, setTestSiklari] = useState([]);

  // Filtreleme için state'ler
  const [searchTerm, setSearchTerm] = useState('');
  const [filterDifficulty, setFilterDifficulty] = useState('');

  // Yeni kitap ekleme formu için state'ler
  const [yeniKitapDialogAcik, setYeniKitapDialogAcik] = useState(false);
  const [bookTitle, setBookTitle] = useState('');
  const [difficulty, setDifficulty] = useState('');
  const [file, setFile] = useState(null);
  const [content, setContent] = useState('');
  const [uploadType, setUploadType] = useState('file');
  
  const [dialogContent, setDialogContent] = useState('');
  const [dialogSeverity, setDialogSeverity] = useState('info');
  const [eklemeBasariliDialogOpen, setEklemeBasariliDialogOpen] = useState(false);

  useFetchUserData(setUserData);

  const fetchBooks = async () => {
    if (!userData || !userData._id) {
      console.log("Kullanıcı bilgisi yüklenemedi veya kullanıcı ID'si bulunamadı.");    
      return; 
    }

    const userId = userData._id;  
    try {
      const response = await fetch(`${API_URLS.TEACHER_URL}kutuphane/${userId}`);
      const data = await response.json();
      if (response.ok) {
          setBooks(data);
      } else {
          console.error('Kitapları yüklerken bir sorun oluştu: ' + data.message);
      }
    } catch (error) {
      console.error('Fetch işlemi sırasında hata oluştu:', error);
    }
  }; 

  useEffect(() => {
      fetchBooks();
  }, [userData]); 

  const handleEditBook = (bookId) => {
    const seciliKitap = books.find((kitap) => kitap._id === bookId);
  
    if (seciliKitap) {
      setDuzenlenenKitap({
        _id: seciliKitap._id,
        title: seciliKitap.title,
        difficulty: seciliKitap.difficulty,
      });
      setDuzenlemeDialogAcik(true);
    } else {
      console.error('Seçilen kitap bulunamadı.');
    }
  };

  const handleKitapDegisiklikleri = (e) => {
    setDuzenlenenKitap({
      ...duzenlenenKitap,
      [e.target.name]: e.target.value,
    });
  };

  const handleGuncelle = async () => {
    try {
      const response = await fetch(`${API_URLS.TEACHER_URL}kutuphane/${duzenlenenKitap._id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          title: duzenlenenKitap.title,
          difficulty: duzenlenenKitap.difficulty,
        }),
      });
  
      const data = await response.json();
      if (!response.ok) throw new Error(data.message || 'Bir hata oluştu');
  
      setBooks(books.map((kitap) => (kitap._id === duzenlenenKitap._id ? data : kitap)));
      setDuzenlemeDialogAcik(false);
    } catch (err) {
      console.error(err);
    }
  };

  const handleDeleteBook = async (bookId) => {
    if (window.confirm('Bu kitabı silmek istediğinize emin misiniz? Bu işlem geri alınamaz.')) {
      try {
        const response = await fetch(`${API_URLS.TEACHER_URL}kutuphane/${bookId}`, {
          method: 'DELETE',
        });
        const data = await response.json();
        if (response.ok) {
          console.log(data.message);
          fetchBooks();
        } else {
          console.error('Kitap silme işlemi sırasında bir sorun oluştu: ' + data.message);
        }
      } catch (error) {
        console.error('Fetch işlemi sırasında hata oluştu:', error);
      }
    }
  };

  //TEST MODÜLÜ
  const handleSikEkle = () => {
    if (testSiklari.length < 5) {
        setTestSiklari([...testSiklari, '']);
    }
  };
  const handleSikSil = (index) => {
    const yeniSiklar = [...testSiklari];
    yeniSiklar.splice(index, 1);
    setTestSiklari(yeniSiklar);
  };

  const handleCreateTest = (bookId) => {
    setSecilenKitapId(bookId);
    setTestFormAcik(true);
    setTestSiklari(['', '']); 
    setDogruCevap(null);
    setTestSorusu('');
    setTestAciklamasi('');
  };

  const handleTestFormKapat = () => {
    setTestFormAcik(false);
    setTestSorusu('');
    setTestSiklari(['', '']);
    setDogruCevap(null);
    setTestAciklamasi('');
  };

  const handleTestFormSubmit = async (e) => {
    e.preventDefault();

    if (testSiklari.length < 2 || testSiklari.length > 5) {
        alert('En az 2, en fazla 5 seçenek girilmelidir.');
        return;
    }

    if (dogruCevap === null || dogruCevap < 0 || dogruCevap >= testSiklari.length) {
        alert('Geçerli bir doğru cevap seçin.');
        return;
    }

    try {
        const response = await fetch(`${API_URLS.TEACHER_URL}kutuphane/tests`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                bookId: secilenKitapId,
                question: testSorusu,
                options: testSiklari,
                correctAnswer: testSiklari[dogruCevap],
                explanation: testAciklamasi,
            }),
        });

        const data = await response.json();

        if (!response.ok) {
            throw new Error(data.message || 'Bir hata oluştu');
        }

        alert('Test başarıyla oluşturuldu.');
        handleTestFormKapat();
    } catch (err) {
        console.error(err);
        alert(`Hata: ${err.message}`);
    }
  };

  // Filtreleme işlemi
  const filteredBooks = books.filter(book => {
    const titleMatch = book.title.toLowerCase().includes(searchTerm.toLowerCase());
    const difficultyMatch = filterDifficulty === '' ? true : book.difficulty === filterDifficulty;
    return titleMatch && difficultyMatch;
  });

  const renderDuzenlemeFormuDialog = () => (
    <Dialog open={duzenlemeDialogAcik} onClose={() => setDuzenlemeDialogAcik(false)} maxWidth="sm" fullWidth>
      <DialogTitle>Kitap Düzenle</DialogTitle>
      <DialogContent>
        <Stack spacing={2} mt={1}>
          <TextField
            fullWidth
            label="Kitap Adı"
            name="title"
            value={duzenlenenKitap.title}
            onChange={handleKitapDegisiklikleri}
          />
          <FormControl fullWidth>
            <InputLabel id="difficulty-label">Zorluk Seviyesi</InputLabel>
            <Select
              labelId="difficulty-label"
              id="difficulty"
              value={duzenlenenKitap.difficulty}
              label="Zorluk Seviyesi"
              onChange={(e) => handleKitapDegisiklikleri({ target: { name: 'difficulty', value: e.target.value } })}
            >
              <MenuItem value="easy">Kolay</MenuItem>
              <MenuItem value="medium">Orta</MenuItem>
              <MenuItem value="hard">Zor</MenuItem>
            </Select>
          </FormControl>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setDuzenlemeDialogAcik(false)}>İptal</Button>
        <Button onClick={handleGuncelle} variant="contained" color="primary">Güncelle</Button>
      </DialogActions>
    </Dialog>
  );


  const renderTestForm = () => (
    <Dialog open={testFormAcik} onClose={handleTestFormKapat} maxWidth="md" fullWidth>
      <DialogTitle>Test Oluştur</DialogTitle>
      <DialogContent>
        <Stack spacing={2} mt={1}>
          <TextField
            fullWidth
            label="Soru"
            value={testSorusu}
            onChange={(e) => setTestSorusu(e.target.value)}
          />
          {testSiklari.map((sik, index) => (
            <Box key={index} display="flex" alignItems="center" gap={1}>
              <TextField
                fullWidth
                label={`Şık ${index + 1}`}
                value={sik}
                onChange={(e) => {
                    const yeniSiklar = [...testSiklari];
                    yeniSiklar[index] = e.target.value;
                    setTestSiklari(yeniSiklar);
                }}
              />
              <IconButton onClick={() => handleSikSil(index)}>
                <DeleteIcon />
              </IconButton>
            </Box>
          ))}
          <Button
            onClick={handleSikEkle}
            color="primary"
            variant="contained"
            disabled={testSiklari.length >= 5}
          >
            Şık Ekle
          </Button>
          <FormControl fullWidth>
            <InputLabel>Doğru Cevap</InputLabel>
            <Select
              value={dogruCevap !== null ? dogruCevap : ''}
              onChange={(e) => setDogruCevap(e.target.value)}
              displayEmpty
            >
              <MenuItem value="" disabled>Doğru cevabı seçin</MenuItem>
              {testSiklari.map((_, index) => (
                <MenuItem key={index} value={index}>
                  {`Şık ${index + 1}`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            fullWidth
            label="Açıklama"
            value={testAciklamasi}
            onChange={(e) => setTestAciklamasi(e.target.value)}
            multiline
            rows={4}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleTestFormKapat}>İptal</Button>
        <Button onClick={handleTestFormSubmit} variant="contained" color="primary">
          Kaydet
        </Button>
      </DialogActions>
    </Dialog>
  );

  // Yeni Kitap Ekleme Fonksiyonları
  const handleUploadTypeChange = (event) => {
    setUploadType(event.target.value);
  };

  const handleTitleChange = (event) => {
    setBookTitle(event.target.value);
  };

  const handleDifficultySelection = (event) => {
    setDifficulty(event.target.value);
  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleContentChange = (value) => {
    setContent(value);
  };

  const handleYeniKitapSubmit = async (event) => {
    event.preventDefault();

    let missingFields = [];

    if (!bookTitle) missingFields.push('Kitap Adı');
    if (!difficulty) missingFields.push('Zorluk Seviyesi');
    if (uploadType === 'file' ? !file : !content) missingFields.push('İçerik');
    if (!userData?._id) missingFields.push('Öğretmen ID');

    if (missingFields.length > 0) {
        setDialogContent(`Lütfen ${missingFields.join(', ')} alanlarını doldurunuz.`);
        setDialogSeverity('error');
        setEklemeBasariliDialogOpen(true);
        return;
    }

    const headers = {};
    let bodyData;

    if (uploadType === 'file') {
        const formData = new FormData();
        formData.append('title', bookTitle);
        formData.append('difficulty', difficulty);
        formData.append('teacherId', userData?._id);
        formData.append('file', file);
        bodyData = formData;
    } else {
        headers['Content-Type'] = 'application/json';
        bodyData = JSON.stringify({
            title: bookTitle,
            difficulty: difficulty,
            content: content,
            teacherId: userData?._id
        });
    }

    try {
        const response = await fetch(`${API_URLS.TEACHER_URL}kutuphane`, {
            method: 'POST',
            headers: headers,
            body: bodyData,
        });

        const responseData = await response.json();
        if (response.ok) {
            setDialogSeverity('success');
            setDialogContent('Yeni Kitap başarıyla eklendi.');
            setEklemeBasariliDialogOpen(true);
            setBookTitle('');
            setDifficulty('');
            setContent('');
            setFile(null);
            setYeniKitapDialogAcik(false);
            fetchBooks(); // Kitap listesini yenile
        } else {
            throw new Error(responseData.message || 'Kitap eklenirken bir hata oluştu');
        }
    } catch (err) {
        setDialogSeverity('error');
        setDialogContent(err.message);
        setEklemeBasariliDialogOpen(true);
    }
  };

  const renderYeniKitapDialog = () => (
    <Dialog open={yeniKitapDialogAcik} onClose={() => setYeniKitapDialogAcik(false)} maxWidth="md" fullWidth>
      <DialogTitle>Yeni Kitap Ekle</DialogTitle>
      <DialogContent>
        <Box component="form" onSubmit={handleYeniKitapSubmit}>
          <Grid container spacing={3}>
              <Grid item xs={12}>
                  <TextField
                      variant="outlined"
                      required
                      fullWidth
                      id="title"
                      label="Kitap Adı"
                      name="title"
                      value={bookTitle}
                      onChange={handleTitleChange}
                      autoFocus
                  />
              </Grid>
              <Grid item xs={12} sm={6}>
                  <FormControl variant="outlined" fullWidth required>
                      <InputLabel id="difficulty-label">Zorluk Seviyesi</InputLabel>
                      <Select
                          labelId="difficulty-label"
                          id="difficulty"
                          value={difficulty}
                          label="Zorluk Seviyesi"
                          onChange={handleDifficultySelection}
                      >
                          <MenuItem value="easy">Kolay</MenuItem>
                          <MenuItem value="medium">Orta</MenuItem>
                          <MenuItem value="hard">Zor</MenuItem>
                      </Select>
                  </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                  <FormControl component="fieldset" fullWidth>
                      <RadioGroup row aria-label="uploadType" name="uploadType" value={uploadType} onChange={handleUploadTypeChange}>
                          <FormControlLabel value="file" control={<Radio color="primary" />} label="Dosya Yükle" />
                          <FormControlLabel value="text" control={<Radio color="primary" />} label="Metin Gir" />
                      </RadioGroup>
                  </FormControl>
              </Grid>
              {uploadType === 'file' ? (
                  <Grid item xs={12}>
                      <Box display="flex" alignItems="center">
                          <input
                              accept=".txt, .docx"
                              style={{ display: 'none' }}
                              id="file-upload"
                              type="file"
                              onChange={handleFileChange}
                          />
                          <label htmlFor="file-upload">
                              <Button variant="contained" color="primary" component="span">
                                  Dosya Yükle
                              </Button>
                          </label>
                          {file && (
                              <Typography variant="body2" sx={{ ml: 2 }}>
                                  {file.name}
                              </Typography>
                          )}
                      </Box>
                  </Grid>
              ) : (
                  <Grid item xs={12}>
                      <FormControl fullWidth>
                          <ReactQuill
                              theme="snow"
                              value={content}
                              onChange={handleContentChange}
                              placeholder="Metin içeriğinizi buraya yazın..."
                              modules={{
                                  toolbar: [
                                      [{ 'header': [1, 2, 3, false] }],
                                      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                                      ['link', 'image'],
                                      ['clean']
                                  ],
                              }}
                              formats={[
                                  'header',
                                  'bold', 'italic', 'underline', 'strike', 'blockquote',
                                  'list', 'bullet',
                                  'link', 'image'
                              ]}
                              style={{ height: '200px', marginBottom: '50px' }}
                          />
                      </FormControl>
                  </Grid>
              )}
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setYeniKitapDialogAcik(false)}>İptal</Button>
        <Button onClick={handleYeniKitapSubmit} variant="contained" color="primary">
          Kitap Ekle
        </Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <Card sx={{ mt: 4 }}>
      <CardContent>
        <Typography variant="h4" sx={{ mb: 1, textAlign: 'center' }}>Kitaplarım</Typography>
        <Typography variant="subtitle1" sx={{ mb: 4, textAlign: 'center', color: 'text.secondary' }}>
          Buradan kitaplarınızı görüntüleyebilir, yeni kitap ekleyebilir, yeni testler oluşturabilir veya kitapları düzenleyip silebilirsiniz.
        </Typography>

        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2, flexWrap: 'wrap', gap: 2 }}>
          <Stack direction="row" spacing={2} alignItems="center" flexWrap="wrap">
            <TextField
              label="Kitap Adında Ara"
              variant="outlined"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              size="small"
            />
            <FormControl variant="outlined" size="small">
              <InputLabel id="filter-difficulty-label">Zorluk</InputLabel>
              <Select
                labelId="filter-difficulty-label"
                value={filterDifficulty}
                onChange={(e) => setFilterDifficulty(e.target.value)}
                label="Zorluk"
              >
                <MenuItem value="">Tümü</MenuItem>
                <MenuItem value="easy">Kolay</MenuItem>
                <MenuItem value="medium">Orta</MenuItem>
                <MenuItem value="hard">Zor</MenuItem>
              </Select>
            </FormControl>
          </Stack>
          <Stack direction="row" spacing={2}>
            <Button
              startIcon={<AddIcon />}
              variant="contained"
              color="primary"
              onClick={() => setYeniKitapDialogAcik(true)}
            >
              Yeni Kitap Ekle
            </Button>
            <Button
              startIcon={<RefreshIcon />}
              onClick={fetchBooks}
              variant="contained"
              color="secondary"
              sx={{ textTransform: 'none', fontWeight: 'medium' }}
            >
              Yenile
            </Button>
          </Stack>
        </Box>

        <TableContainer component={Paper} sx={{ borderRadius: 2 }}>
          <Table>
            <TableHead sx={{ backgroundColor: '#f5f5f5' }}>
              <TableRow>
                <TableCell sx={{ fontWeight: 'bold' }}>Kitap Adı</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>Zorluk</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>İşlem</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredBooks.map((book) => (
                <TableRow key={book._id} hover>
                  <TableCell>{book.title}</TableCell>
                  <TableCell>
                    {book.difficulty === 'easy'
                      ? 'Kolay'
                      : book.difficulty === 'medium'
                      ? 'Orta'
                      : book.difficulty === 'hard'
                      ? 'Zor'
                      : book.difficulty}
                  </TableCell>
                  <TableCell>
                    <Stack direction="row" spacing={1}>
                      <Tooltip title="Düzenle">
                        <IconButton onClick={() => handleEditBook(book._id)}>
                            <EditIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Sil">
                        <IconButton onClick={() => handleDeleteBook(book._id)}>
                            <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Test Ekle">
                        <IconButton onClick={() => handleCreateTest(book._id)}>
                            <AddCircleIcon />
                        </IconButton>
                      </Tooltip>
                    </Stack>
                  </TableCell>
                </TableRow>
              ))}
              {filteredBooks.length === 0 && (
                <TableRow>
                  <TableCell colSpan={3} align="center" sx={{ color: 'text.secondary' }}>
                    Filtre kriterlerinize uyan kitap bulunamadı.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        {renderDuzenlemeFormuDialog()}
        {renderTestForm()}
        {renderYeniKitapDialog()}

        <ResultDialog
            open={eklemeBasariliDialogOpen}
            onClose={() => setEklemeBasariliDialogOpen(false)}
            title={dialogSeverity === 'error' ? "Hata Oluştu" : "İşlem Başarılı"}
            message={dialogContent}
            severity={dialogSeverity}
        />
      </CardContent>
    </Card>
  );
};

export default Books;

// GameControls.js

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlay,
  faPause,
  faRedo,
  faMinus,
  faPlus,
  faHome,
  faArrowLeft,
} from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import '../styles/harfDongusu.css';

export default function GameControls({
  speed,
  setSpeed,
  isPlaying,
  setIsPlaying,
  resetGame,
}) {
  const navigate = useNavigate();

  const handleDecreaseSpeed = () => {
    setSpeed(Math.max(speed - 0.1, 0.1));
  };

  const handleIncreaseSpeed = () => {
    setSpeed(speed + 0.1);
  };

  const goToHome = () => {
    navigate('/');
  };

  const goBack = () => {
    navigate(-1);
  };

  return (
    <div className="harf-dongusu-game-controls">
      {/* Hız Kontrolleri */}
      <button onClick={handleDecreaseSpeed}>
        <FontAwesomeIcon icon={faMinus} /> Azalt
      </button>
      <span>Hız: {speed.toFixed(1)}</span>
      <button onClick={handleIncreaseSpeed}>
        <FontAwesomeIcon icon={faPlus} /> Artır
      </button>

      {/* Başlat/Duraklat ve Yeniden Başlat */}
      <button onClick={() => setIsPlaying(!isPlaying)}>
        <FontAwesomeIcon icon={isPlaying ? faPause : faPlay} />{' '}
        {isPlaying ? 'Duraklat' : 'Başlat'}
      </button>
      <button onClick={resetGame}>
        <FontAwesomeIcon icon={faRedo} /> Yeniden Başlat
      </button>


    </div>
  );
}

import { useState, useEffect, useCallback } from 'react';
import { API_URLS } from '../../../../config/config';
import axiosInstance from '../../../../utils/axiosInstance';

export const useRasgeleKelimelerOyunu = (navigate, userData, token) => {
  const [difficulty, setDifficulty] = useState('easy');
  const [paragraphsList, setParagraphsList] = useState([]);
  const [selectedParagraphIndex, setSelectedParagraphIndex] = useState(0);

  const [allWords, setAllWords] = useState([]);
  const [currentWordIndex, setCurrentWordIndex] = useState(0);

  const [isPlaying, setIsPlaying] = useState(false);
  const [speed, setSpeed] = useState(2);
  const [elapsedTime, setElapsedTime] = useState(0);
  const [timeLeft, setTimeLeft] = useState(0);
  const [message, setMessage] = useState('');
  const [gameOver, setGameOver] = useState(false);

  // Sadece tek bir grup (son gösterilen kelime/kelime grubu)
  const [displayedWordsGroup, setDisplayedWordsGroup] = useState(null);

  const [intervalDuration, setIntervalDuration] = useState(1000 / speed);

  useEffect(() => {
    const newIntervalDuration = Math.max(1000 / speed, 50);
    setIntervalDuration(newIntervalDuration);
  }, [speed]);

  const prepareWords = (content) => {
    const words = content
      .replace(/\n/g, ' ')
      .split(/\s+/)
      .filter(w => w.trim().length > 0);
    setAllWords(words);
  };

  const fetchParagraphs = async () => {
    try {
      const url = `${API_URLS.HIZLIOKUMA_URL}akanyazi/${difficulty}`;
      const response = await axiosInstance.get(url);
      const data = response.data;
      setParagraphsList(data);
      setSelectedParagraphIndex(0);

      if (data[0]) {
        prepareWords(data[0].content);
      }
      handleReset();
    } catch (error) {
      console.error('Paragraflar alınırken hata oluştu:', error);
    }
  };

  useEffect(() => {
    fetchParagraphs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [difficulty, token]);

  useEffect(() => {
    if (paragraphsList[selectedParagraphIndex]) {
      prepareWords(paragraphsList[selectedParagraphIndex].content);
      handleReset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedParagraphIndex, paragraphsList]);

  useEffect(() => {
    if (allWords.length > 0 && intervalDuration > 0) {
      const estimatedTime = allWords.length * intervalDuration;
      const maxTime = userData
        ? userData.status === 'inactive'
          ? Math.min(15 * 1000, estimatedTime)
          : estimatedTime
        : Math.min(15 * 1000, estimatedTime);

      setTimeLeft(maxTime);
    }
  }, [allWords.length, intervalDuration, userData]);

  const showNextWords = useCallback((containerWidth, containerHeight) => {
    if (currentWordIndex >= allWords.length) {
      // Tüm kelimeler gösterildi, oyun bitti
      setIsPlaying(false);
      setGameOver(true);
      return;
    }

    // 1 veya 2 kelimelik grup
    const groupSize = Math.random() < 0.3 ? 2 : 1; 
    const wordsToShow = allWords.slice(currentWordIndex, currentWordIndex + groupSize);

    // Rastgele konum
    const x = Math.floor(Math.random() * (containerWidth - 200));
    const y = Math.floor(Math.random() * (containerHeight - 50));

    const newGroup = {
      id: Date.now(),
      text: wordsToShow.join(' '),
      x,
      y
    };

    // Sadece bu yeni grubu göster
    setDisplayedWordsGroup(newGroup);

    setCurrentWordIndex((prev) => prev + wordsToShow.length);
    setElapsedTime((prev) => prev + intervalDuration);

    setTimeLeft((prevTimeLeft) => {
      const newTimeLeft = prevTimeLeft - intervalDuration;
      if (newTimeLeft <= 0) {
        setIsPlaying(false);
        setGameOver(true);
        return 0;
      }
      return newTimeLeft;
    });
  }, [allWords, currentWordIndex, intervalDuration]);

  useEffect(() => {
    let wordInterval;
    if (isPlaying) {
      wordInterval = setInterval(() => {
        const container = document.getElementById('game-display-container');
        if (container) {
          const rect = container.getBoundingClientRect();
          showNextWords(rect.width, rect.height);
        }
      }, intervalDuration);
    }

    return () => clearInterval(wordInterval);
  }, [isPlaying, intervalDuration, showNextWords]);

  const handlePlayPause = () => {
    if (gameOver) return;
    if (isPlaying) {
      setMessage(`Şu ana kadar: ${currentWordIndex} kelime okundu`);
    } else {
      setMessage('');
    }
    setIsPlaying(!isPlaying);
  };

  const handleReset = () => {
    setIsPlaying(false);
    setDisplayedWordsGroup(null);
    setCurrentWordIndex(0);
    setElapsedTime(0);
    setMessage('');
    setGameOver(false);

    if (allWords.length > 0 && intervalDuration > 0) {
      const estimatedTime = allWords.length * intervalDuration;
      const maxTime = userData
        ? userData.status === 'inactive'
          ? Math.min(15 * 1000, estimatedTime)
          : estimatedTime
        : Math.min(15 * 1000, estimatedTime);

      setTimeLeft(maxTime);
    }
  };

  const handleSpeedChange = (e) => {
    setSpeed(Number(e.target.value));
  };

  const handleParagraphChange = (e) => {
    setSelectedParagraphIndex(Number(e.target.value));
  };

  const handleDifficultyChange = (e) => {
    setDifficulty(e.target.value);
  };

  return {
    difficulty,
    paragraphsList,
    selectedParagraphIndex,
    isPlaying,
    speed,
    elapsedTime,
    timeLeft,
    message,
    gameOver,
    setGameOver,
    displayedWordsGroup,
    allWords,
    currentWordIndex,
    handlePlayPause,
    handleReset,
    handleSpeedChange,
    handleParagraphChange,
    handleDifficultyChange,
  };
};

import React, { useState, useEffect } from 'react';
import { Paper, Grid, Card, Typography, Button } from '@mui/material';
import ResultDialog from './components/ResultDialog';
import TeacherForm from './components/TeacherForm';
import TeacherFilters from './components/TeacherFilters';
import TeacherTable from './components/TeacherTable';
import TeacherEditDialog from './components/TeacherEditDialog';
import BalanceTransferDialog from './components/BalanceTransferDialog';
import RevokeBalanceDialog from './components/RevokeBalanceDialog';

import { API_URLS } from '../../../config/config';
import useFetchUserData from '../../../hooks/okul/useFetchUserData';

const TeacherPage = () => { 
  const [ogretmenListesi, setOgretmenListesi] = useState([]);
  const [sehirListesi, setSehirListesi] = useState([]);
  const [shouldFetch, setShouldFetch] = useState(true);
  const [userData, setUserData] = useState(null);

  const [dialogContent, setDialogContent] = useState('');
  const [dialogSeverity, setDialogSeverity] = useState('info');
  const [dialogOpen, setDialogOpen] = useState(false);
  const [resultDialogOpen, setResultDialogOpen] = useState(false);
  const [resultDialogMessage, setResultDialogMessage] = useState('');
  const [resultDialogSeverity, setResultDialogSeverity] = useState('info');
  const [eklemeBasariliDialogOpen, setEklemeBasariliDialogOpen] = useState(false);

  const [filterSehirId, setFilterSehirId] = useState('');
  const [filterPackageType, setFilterPackageType] = useState('');
  const [searchQuery, setSearchQuery] = useState('');

  // Düzenleme ile ilgili state'ler
  const [duzenlemeDialogAcik, setDuzenlemeDialogAcik] = useState(false);
  const [duzenlenenOgretmen, setDuzenlenenOgretmen] = useState({
    ad: '',
    soyad: '',
    okulAdi: '',
    email: '',
    telefon: '',
    sehirId: '',
    ilceId: '',
    ilceler: [],
  });

  // Bakiye transfer/günü alma ile ilgili state'ler
  const [transferDialogOpen, setTransferDialogOpen] = useState(false);
  const [revokeDialogOpen, setRevokeDialogOpen] = useState(false);
  const [selectedTeacherId, setSelectedTeacherId] = useState('');
  const [transferAmount, setTransferAmount] = useState(0);
  const [packageType, setPackageType] = useState('bakiye3Ay');
  const [revokeAmount, setRevokeAmount] = useState(0);
  const [revokePackageType, setRevokePackageType] = useState('bakiye3Ay');

  useFetchUserData(setUserData);

  const handleDialogClose = () => {
    setDialogOpen(false);
    setResultDialogOpen(false);
  };

  const fetchOgretmenListesi = async () => {
    if (!userData || !userData._id) {
      console.log("Kullanıcı bilgisi yüklenemedi veya kullanıcı ID'si bulunamadı.");
      return;
    }

    try {
      const response = await fetch(`${API_URLS.SCHOOL_URL}teachers/${userData._id}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`,
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('Öğretmen listesi yüklenirken bir hata oluştu.');
      }

      const data = await response.json();
      setOgretmenListesi(data);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchSehirler = async () => {
    if (!shouldFetch) return;
    try {
      const response = await fetch(API_URLS.ADMIN_URL + "sehirler");
      if (!response.ok) {
        throw new Error('Veri çekme işlemi başarısız');
      }
      const sehirler = await response.json();
      setSehirListesi(sehirler);
    } catch (err) {
      console.error("Şehirler yüklenirken bir hata oluştu:", err.message);
    } finally {
      setShouldFetch(false);
    }
  };

  useEffect(() => {
    fetchSehirler();
    fetchOgretmenListesi();
  }, [userData]);

  // Filtrelenmiş liste hesaplama
  const getFilteredOgretmenListesi = () => {
    return ogretmenListesi.filter((ogretmen) => {
      const matchesSearchQuery =
        ogretmen.ad.toLowerCase().includes(searchQuery.toLowerCase()) ||
        ogretmen.soyad.toLowerCase().includes(searchQuery.toLowerCase()) ||
        ogretmen.telefon.includes(searchQuery) ||
        ogretmen.email.toLowerCase().includes(searchQuery.toLowerCase()) ||
        (ogretmen.sehirAdi && ogretmen.sehirAdi.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (ogretmen.ilceAdi && ogretmen.ilceAdi.toLowerCase().includes(searchQuery.toLowerCase()));

      const matchesSehir =
        !filterSehirId || ogretmen.sehir === filterSehirId;

      const matchesPackageType =
        !filterPackageType ||
        (filterPackageType === 'bakiye3Ay' && ogretmen.bakiye3Ay > 0) ||
        (filterPackageType === 'bakiye6Ay' && ogretmen.bakiye6Ay > 0) ||
        (filterPackageType === 'bakiye12Ay' && ogretmen.bakiye12Ay > 0);

      return matchesSearchQuery && matchesSehir && matchesPackageType;
    });
  };

  const filteredOgretmenListesi = getFilteredOgretmenListesi();

  // Öğretmen Ekleme Fonksiyonu
  const handleEkle = async (yeniOgretmenData, secilenSehirId, secilenIlceId) => {
    try {
      const response = await fetch(API_URLS.SCHOOL_URL + 'teachers', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...yeniOgretmenData,
          okulId: userData?._id
        }),
      });

      const responseData = await response.json();
      if (response.ok) {
        const yeniOgretmen = {
          ...responseData,
          sehirAdi: sehirListesi.find(sehir => sehir._id === secilenSehirId)?.sehir_adi || '',
          ilceAdi: (sehirListesi.find(sehir => sehir._id === secilenSehirId)?.ilceler || [])
            .find(ilce => ilce._id === secilenIlceId)?.ilce_adi || ''
        };
        setOgretmenListesi([...ogretmenListesi, yeniOgretmen]);

        setDialogSeverity('success');
        setDialogContent('Yeni Öğretmen başarıyla eklendi.');
        setEklemeBasariliDialogOpen(true);

      } else {
        throw new Error(responseData.message || 'Bir hata oluştu');
      }
    } catch (err) {
      setDialogSeverity('error');
      setDialogContent(err.message);
      setEklemeBasariliDialogOpen(true);
    }
  };

  // Öğretmen Silme Fonksiyonu
  const handleDeleteOgretmen = async (ogretmenId) => {
    try {
      const response = await fetch(`${API_URLS.SCHOOL_URL}teachers/${ogretmenId}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`,
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) throw new Error('Öğretmen silinirken bir hata oluştu.');

      setOgretmenListesi(ogretmenListesi.filter((ogretmen) => ogretmen._id !== ogretmenId));

      setResultDialogMessage('Öğretmen başarıyla silindi.');
      setResultDialogSeverity('success');
      setResultDialogOpen(true);
    } catch (error) {
      setResultDialogMessage(error.message);
      setResultDialogSeverity('error');
      setResultDialogOpen(true);
    }
  };

  // Öğretmen Düzenleme Aç
  const handleEdit = (ogretmenId) => {
    const seciliOgretmen = ogretmenListesi.find(ogretmen => ogretmen._id === ogretmenId);

    if (seciliOgretmen) {
      const sehirId = seciliOgretmen.sehir;
      setDuzenlenenOgretmen({
        _id: seciliOgretmen._id,
        ad: seciliOgretmen.ad,
        soyad: seciliOgretmen.soyad,
        okulAdi: seciliOgretmen.okul_adi,
        email: seciliOgretmen.email,
        telefon: seciliOgretmen.telefon,
        sehirId: sehirId,
        ilceId: seciliOgretmen.ilce,
        ilceler: (sehirListesi.find(s => s._id === sehirId)?.ilceler) || []
      });
      setDuzenlemeDialogAcik(true);
    } else {
      console.error('Seçilen öğretmen bulunamadı.');
    }
  };

  // Öğretmen Güncelle
  const handleGuncelle = async (ogretmenData) => {
    try {
      const response = await fetch(`${API_URLS.ADMIN_URL}teachers/${ogretmenData._id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ad: ogretmenData.ad,
          soyad: ogretmenData.soyad,
          okul_adi: ogretmenData.okulAdi,
          email: ogretmenData.email,
          telefon: ogretmenData.telefon,
          sehir: ogretmenData.sehirId,
          ilce: ogretmenData.ilceId
        }),
      });

      const data = await response.json();
      if (!response.ok) throw new Error(data.message || 'Bir hata oluştu');

      const guncellenmisOgretmen = {
        ...data.ogretmen,
        sehirAdi: sehirListesi.find(sehir => sehir._id === data.ogretmen.sehir)?.sehir_adi || '',
        ilceAdi: sehirListesi.find(sehir => sehir._id === data.ogretmen.sehir)?.ilceler
          .find(ilce => ilce._id === data.ogretmen.ilce)?.ilce_adi || ''
      };

      setOgretmenListesi(ogretmenListesi.map(ogretmen => ogretmen._id === ogretmenData._id ? guncellenmisOgretmen : ogretmen));

      setDialogSeverity('success');
      setDialogContent('Öğretmen başarıyla güncellendi.');
      setDialogOpen(true);
      setDuzenlemeDialogAcik(false);
    } catch (err) {
      setDialogSeverity('error');
      setDialogContent(err.message);
      setDialogOpen(true);
    }
  };

  // Bakiye Transferi
  const handleTransfer = async () => {
    try {
      const response = await fetch(`${API_URLS.SCHOOL_URL}teachers/transferBalance/${selectedTeacherId}`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          amount: transferAmount,
          packageType: packageType,
        }),
      });

      const data = await response.json();
      if (!response.ok) throw new Error(data.message || 'Bir hata oluştu');

      await fetchOgretmenListesi();
      setDialogSeverity('success');
      setDialogContent('Bakiye başarıyla transfer edildi.');
      setDialogOpen(true);
      setTransferDialogOpen(false);
    } catch (err) {
      setDialogSeverity('error');
      setDialogContent(err.message);
      setDialogOpen(true);
    }
  };

  // Bakiye Geri Alma
  const handleGeriAl = async () => {
    try {
      const response = await fetch(`${API_URLS.SCHOOL_URL}teachers/revokeBalance/${selectedTeacherId}`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          amount: revokeAmount,
          packageType: revokePackageType,
        }),
      });

      const data = await response.json();
      if (!response.ok) throw new Error(data.message || 'Bir hata oluştu');

      await fetchOgretmenListesi();
      setDialogSeverity('success');
      setDialogContent('Bakiye başarıyla geri alındı.');
      setDialogOpen(true);
      setRevokeDialogOpen(false);
    } catch (err) {
      setDialogSeverity('error');
      setDialogContent(err.message);
      setDialogOpen(true);
    }
  };

  return (
    <Paper style={{ padding: 20 }}>
      <Grid container spacing={3} direction="column">

        <Grid item xs={12}>
          <Card style={{ padding: 20, marginBottom: 20 }}>
            <Typography variant="h5" component="h3" marginBottom={2}>
              Öğretmen Kayıt Formu
            </Typography>
            <TeacherForm
              sehirListesi={sehirListesi}
              onEkle={handleEkle}
              dialogSeverity={dialogSeverity}
              setDialogSeverity={setDialogSeverity}
              dialogContent={dialogContent}
              setDialogContent={setDialogContent}
              setEklemeBasariliDialogOpen={setEklemeBasariliDialogOpen}
            />
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card style={{ padding: 20, marginBottom: 20 }}>
            <TeacherFilters
              sehirListesi={sehirListesi}
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              filterSehirId={filterSehirId}
              setFilterSehirId={setFilterSehirId}
              filterPackageType={filterPackageType}
              setFilterPackageType={setFilterPackageType}
            />
          </Card>
        </Grid>

        <Grid item xs={12}>
          <TeacherTable
            ogretmenListesi={filteredOgretmenListesi}
            onEdit={handleEdit}
            onSelectTeacherForTransfer={(id) => { setSelectedTeacherId(id); setTransferDialogOpen(true); }}
            onSelectTeacherForRevoke={(id) => { setSelectedTeacherId(id); setRevokeDialogOpen(true); }}
          />
        </Grid>
      </Grid>

      <ResultDialog
        open={eklemeBasariliDialogOpen}
        onClose={() => setEklemeBasariliDialogOpen(false)}
        title={dialogSeverity === 'error' ? 'Hata Oluştu' : 'İşlem Başarılı'}
        message={dialogContent}
        severity={dialogSeverity}
      />
      <ResultDialog
        open={resultDialogOpen}
        onClose={handleDialogClose}
        title={'İşlem Sonucu'}
        message={resultDialogMessage}
        severity={resultDialogSeverity}
      />

      <TeacherEditDialog
        open={duzenlemeDialogAcik}
        onClose={() => setDuzenlemeDialogAcik(false)}
        duzenlenenOgretmen={duzenlenenOgretmen}
        setDuzenlenenOgretmen={setDuzenlenenOgretmen}
        sehirListesi={sehirListesi}
        onGuncelle={handleGuncelle}
      />

      <BalanceTransferDialog
        open={transferDialogOpen}
        onClose={() => setTransferDialogOpen(false)}
        packageType={packageType}
        setPackageType={setPackageType}
        transferAmount={transferAmount}
        setTransferAmount={setTransferAmount}
        onTransfer={handleTransfer}
      />

      <RevokeBalanceDialog
        open={revokeDialogOpen}
        onClose={() => setRevokeDialogOpen(false)}
        revokePackageType={revokePackageType}
        setRevokePackageType={setRevokePackageType}
        revokeAmount={revokeAmount}
        setRevokeAmount={setRevokeAmount}
        onRevoke={handleGeriAl}
      />
    </Paper>
  );
}

export default TeacherPage;

import React from 'react';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button, Stack } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

const TeacherTable = ({
  ogretmenListesi,
  handleEdit,
  setSelectedTeacherId,
  setTransferDialogOpen,
  setRevokeDialogOpen
}) => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Adı</TableCell>
            <TableCell>Soyadı</TableCell>
            <TableCell>Şifre</TableCell>
            <TableCell>Telefon</TableCell>
            <TableCell>E-posta</TableCell>
            <TableCell>Şehir</TableCell>
            <TableCell>İlçe</TableCell>
            <TableCell>Bakiye 3 Ay</TableCell>
            <TableCell>Bakiye 6 Ay</TableCell>
            <TableCell>Bakiye 12 Ay</TableCell>
            <TableCell>Toplam Bakiye</TableCell>
            <TableCell>İşlemler</TableCell>
          </TableRow>
        </TableHead>
        {ogretmenListesi.length > 0 ? (
          <TableBody>
            {ogretmenListesi.map((ogretmen) => (
              <TableRow key={ogretmen._id}>
                <TableCell>{ogretmen.ad}</TableCell>
                <TableCell>{ogretmen.soyad}</TableCell>
                <TableCell>{ogretmen.password_open}</TableCell>
                <TableCell>{ogretmen.telefon}</TableCell>
                <TableCell>{ogretmen.email}</TableCell>
                <TableCell>{ogretmen.sehirAdi}</TableCell>
                <TableCell>{ogretmen.ilceAdi}</TableCell>
                <TableCell>{ogretmen.bakiye3Ay || 0}</TableCell>
                <TableCell>{ogretmen.bakiye6Ay || 0}</TableCell>
                <TableCell>{ogretmen.bakiye12Ay || 0}</TableCell>
                <TableCell>
                  {(ogretmen.bakiye3Ay || 0) + (ogretmen.bakiye6Ay || 0) + (ogretmen.bakiye12Ay || 0)}
                </TableCell>
                <TableCell align="right">
                  <Stack direction="row" spacing={1}>
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={<EditIcon />}
                      onClick={() => handleEdit(ogretmen._id)}
                    >
                      Düzenle
                    </Button>

                    <Button
                      variant="contained"
                      color="success"
                      onClick={() => {
                        setSelectedTeacherId(ogretmen._id);
                        setTransferDialogOpen(true);
                      }}
                    >
                      Bakiye Transferi
                    </Button>
                    <Button
                      variant="contained"
                      color="warning"
                      onClick={() => {
                        setSelectedTeacherId(ogretmen._id);
                        setRevokeDialogOpen(true);
                      }}
                    >
                      Bakiye Geri Al
                    </Button>
                  </Stack>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        ) : (
          <TableBody>
            <TableRow>
              <TableCell colSpan={12} align="center">
                Bu kuruma ait öğretmen bulunamadı.
              </TableCell>
            </TableRow>
          </TableBody>
        )}
      </Table>
    </TableContainer>
  );
};

export default TeacherTable;

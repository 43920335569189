import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Stack } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

const TeacherTable = ({
  ogretmenListesi,
  onEdit,
  onSelectTeacherForTransfer,
  onSelectTeacherForRevoke,
}) => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Adı</TableCell>
            <TableCell>Soyadı</TableCell>
            <TableCell>Şifre</TableCell>
            <TableCell>Telefon</TableCell>
            <TableCell>E-posta</TableCell>
            <TableCell>Şehir</TableCell>
            <TableCell>İlçe</TableCell>
            <TableCell>Bakiye 3 Ay</TableCell>
            <TableCell>Bakiye 6 Ay</TableCell>
            <TableCell>Bakiye 12 Ay</TableCell>
            <TableCell>Toplam Bakiye</TableCell>
            <TableCell>İşlemler</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {ogretmenListesi.map((ogretmen) => (
            <TableRow key={ogretmen._id}>
              <TableCell>{ogretmen.ad}</TableCell>
              <TableCell>{ogretmen.soyad}</TableCell>
              <TableCell>{ogretmen.password_open}</TableCell>
              <TableCell>{ogretmen.telefon}</TableCell>
              <TableCell>{ogretmen.email}</TableCell>
              <TableCell>{ogretmen.sehirAdi}</TableCell>
              <TableCell>{ogretmen.ilceAdi}</TableCell>
              <TableCell>{ogretmen.bakiye3Ay || 0}</TableCell>
              <TableCell>{ogretmen.bakiye6Ay || 0}</TableCell>
              <TableCell>{ogretmen.bakiye12Ay || 0}</TableCell>
              <TableCell>
                {(ogretmen.bakiye3Ay || 0) +
                  (ogretmen.bakiye6Ay || 0) +
                  (ogretmen.bakiye12Ay || 0)}
              </TableCell>
              <TableCell align="right">
                <Stack direction="row" spacing={1}>
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<EditIcon />}
                    onClick={() => onEdit(ogretmen._id)}
                  >
                    Düzenle
                  </Button>

                  <Button
                    variant="contained"
                    color="success"
                    onClick={() => onSelectTeacherForTransfer(ogretmen._id)}
                  >
                    Bakiye Yükle
                  </Button>
                  <Button
                    variant="contained"
                    color="warning"
                    onClick={() => onSelectTeacherForRevoke(ogretmen._id)}
                  >
                    Bakiye Geri Al
                  </Button>
                </Stack>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TeacherTable;

// useKelimeTamamlamaOyunu.js

import { useState, useEffect } from 'react';
import parse, { domToReact } from 'html-react-parser';
import React from 'react';
import { API_URLS } from '../../../../config/config';
import axiosInstance from '../../../../utils/axiosInstance';

export const useKelimeTamamlamaOyunu = (userData) => {
  const [difficulty, setDifficulty] = useState('easy');
  const [paragraphsList, setParagraphsList] = useState([]);
  const [selectedParagraphIndex, setSelectedParagraphIndex] = useState(0);
  const [paragraph, setParagraph] = useState('');
  const [missingWordsIndexes, setMissingWordsIndexes] = useState([]);
  const [options, setOptions] = useState([]);
  const [userSelections, setUserSelections] = useState({
    selectedWord: null,
    filledWords: {},
  });
  const [isPlaying, setIsPlaying] = useState(false);

  const [timeRemaining, setTimeRemaining] = useState(null);
  const [score, setScore] = useState(0);
  const [message, setMessage] = useState('');
  
  const getInitialTime = () => {
    return !userData || userData.status === 'inactive' ? 15 : 60;
  };

  useEffect(() => {
    setTimeRemaining(getInitialTime());
  }, [userData]);

  useEffect(() => {
    const fetchParagraphs = async () => {
      try {
        const url = `${API_URLS.HIZLIOKUMA_URL}kelimetamamlama/${difficulty}`;
        const response = await axiosInstance.get(url);
        setParagraphsList(response.data);
      } catch (error) {
        console.error('Paragraflar yüklenirken hata oluştu:', error);
      }
    };

    fetchParagraphs();
  }, [difficulty]);

  useEffect(() => {
    if (paragraphsList.length > 0) {
      handleReset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paragraphsList, selectedParagraphIndex]);

  useEffect(() => {
    if (isPlaying && timeRemaining > 0) {
      const timer = setTimeout(() => {
        setTimeRemaining((prevTime) => prevTime - 1);
      }, 1000);
      return () => clearTimeout(timer);
    } else if (timeRemaining === 0) {
      setIsPlaying(false);
      setMessage('Zaman doldu!');
    }
  }, [isPlaying, timeRemaining]);

  const handleWordSelect = (word) => {
    if (!isPlaying) return;
    setUserSelections({ ...userSelections, selectedWord: word });
  };

  const handleBlankClick = (wordIndex, selectedWord) => {
    if (!isPlaying || !selectedWord) return;

    const wordsArray = getCleanWordsArray(paragraph);
    const correctWord = wordsArray[wordIndex];

    if (!correctWord) return;

    setUserSelections((prevState) => ({
      ...prevState,
      filledWords: {
        ...prevState.filledWords,
        [wordIndex]: {
          word: selectedWord,
          isCorrect: selectedWord.trim().toLowerCase() === correctWord.toLowerCase(),
        },
      },
      selectedWord: null,
    }));

    if (selectedWord.trim().toLowerCase() === correctWord.toLowerCase()) {
      setScore((prevScore) => prevScore + 1);
    }

    const totalBlanks = missingWordsIndexes.length;
    const totalFilled = Object.keys(userSelections.filledWords).length + 1; 
    if (totalFilled >= totalBlanks) {
      setIsPlaying(false);
      setMessage('Tebrikler! Tüm kelimeleri doldurdunuz.');
    }
  };

  const handlePlayPause = () => {
    if (isPlaying) {
      setMessage('Oyun duraklatıldı.');
    } else {
      setMessage('');
    }
    setIsPlaying(!isPlaying);
  };

  const handleReset = () => {
    setIsPlaying(false);
    setUserSelections({ selectedWord: null, filledWords: {} });
    setTimeRemaining(getInitialTime());
    setScore(0);
    setMessage('');

    const newParagraph = paragraphsList[selectedParagraphIndex];
    if (newParagraph && typeof newParagraph.content === 'string') {
      setParagraph(newParagraph.content);

      const sentences = splitParagraphIntoSentences(newParagraph.content);
      const wordsArray = getCleanWordsArray(newParagraph.content);

      let numberOfBlanks;
      if (difficulty === 'easy') {
        numberOfBlanks = Math.min(2, sentences.length); 
      } else if (difficulty === 'medium') {
        numberOfBlanks = Math.min(3, sentences.length);
      } else if (difficulty === 'hard') {
        numberOfBlanks = sentences.length;
      } else {
        numberOfBlanks = Math.min(2, sentences.length);
      }

      const missingIndexesSet = new Set();
      for (let i = 0; i < numberOfBlanks; i++) {
        const sentence = sentences[i];
        const sentenceWordIndexes = sentence.wordIndexes;

        const middleIndex = Math.floor(sentenceWordIndexes.length / 2);
        const wordGlobalIndex = sentenceWordIndexes[middleIndex];
        missingIndexesSet.add(wordGlobalIndex);
      }

      const missingIndexes = Array.from(missingIndexesSet);
      setMissingWordsIndexes(missingIndexes);

      const missingWords = missingIndexes.map((index) => wordsArray[index]);
      const allOptionsSet = new Set([
        ...missingWords,
        ...getRandomWordsFromArray(wordsArray, missingWords, 10),
      ]);
      setOptions(Array.from(allOptionsSet).sort(() => 0.5 - Math.random()));
    }
  };

  const handleDifficultyChange = (e) => {
    setDifficulty(e.target.value);
    setSelectedParagraphIndex(0);
  };

  const handleParagraphChange = (e) => {
    setSelectedParagraphIndex(Number(e.target.value));
  };

  const splitParagraphIntoSentences = (htmlContent) => {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = htmlContent;
    const textContent = tempDiv.textContent || tempDiv.innerText || '';

    const sentenceEnders = /(?<=[.!?])\s+/g;
    const sentencesText = textContent.split(sentenceEnders).filter((s) => s.trim() !== '');

    let wordCounter = 0;
    const sentences = sentencesText.map((sentenceText) => {
      const words = sentenceText.trim().split(/\s+/);
      const wordIndexes = words.map((_, index) => wordCounter + index);
      wordCounter += words.length;
      return { text: sentenceText, wordIndexes };
    });

    return sentences;
  };

  const getCleanWordsArray = (htmlContent) => {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = htmlContent;
    const textContent = tempDiv.textContent || tempDiv.innerText || '';
    const words = textContent.split(/\s+/);
    return words
      .map(word => {
        const cleaned = word.replace(/^[^\p{L}\p{N}]+|[^\p{L}\p{N}]+$/gu, '');
        return cleaned;
      })
      .filter(w => w.length > 0);
  };

  const getRandomWordsFromArray = (wordsArray, excludeWords, count) => {
    const filteredWords = wordsArray.filter(
      (word) => !excludeWords.includes(word)
    );
    const randomWords = [];
    const usedIndexes = new Set();

    while (randomWords.length < count && usedIndexes.size < filteredWords.length) {
      const randomIndex = Math.floor(Math.random() * filteredWords.length);
      if (!usedIndexes.has(randomIndex)) {
        usedIndexes.add(randomIndex);
        randomWords.push(filteredWords[randomIndex]);
      }
    }

    return randomWords;
  };

  const getParagraphWithBlanks = () => {
    let wordCounter = 0;

    const voidElements = new Set([
      'area',
      'base',
      'br',
      'col',
      'embed',
      'hr',
      'img',
      'input',
      'keygen',
      'link',
      'meta',
      'param',
      'source',
      'track',
      'wbr',
    ]);

    const optionsParser = {
      replace: (node) => {
        if (node.type === 'tag') {
          if (voidElements.has(node.name)) {
            return React.createElement(
              node.name,
              { key: node.name + wordCounter, ...node.attribs },
              null
            );
          } else {
            return React.createElement(
              node.name,
              { key: node.name + wordCounter, ...node.attribs },
              domToReact(node.children, optionsParser)
            );
          }
        }

        if (node.type === 'text') {
          const textContent = node.data;
          const words = textContent.split(/(\s+)/);
          const newNodes = [];

          words.forEach((word) => {
            if (word.trim() === '') {
              newNodes.push(word);
            } else {
              const currentIndex = wordCounter;
              wordCounter++;

              if (missingWordsIndexes.includes(currentIndex)) {
                const blankIndex = missingWordsIndexes.indexOf(currentIndex);
                const blankNumber = blankIndex + 1;
                const userFilled = userSelections.filledWords[currentIndex];
                const isIncorrect = userFilled && !userFilled.isCorrect;
                const isCorrect = userFilled && userFilled.isCorrect;
                newNodes.push(
                  <button
                    key={`blank-${currentIndex}`}
                    className={`blank-button ${isIncorrect ? 'incorrect' : ''} ${isCorrect ? 'correct' : ''}`}
                    onClick={() => handleBlankClick(currentIndex, userSelections.selectedWord)}
                    disabled={isCorrect}
                  >
                    {userFilled ? userFilled.word : `(${blankNumber}) _____`}
                  </button>
                );
              } else {
                newNodes.push(<span key={`word-${currentIndex}`}>{word}</span>);
              }
            }
          });

          return <>{newNodes}</>;
        }
      },
    };

    return parse(paragraph, optionsParser);
  };

  return {
    difficulty,
    setDifficulty,
    paragraphsList,
    selectedParagraphIndex,
    setSelectedParagraphIndex,
    paragraph,
    options,
    userSelections,
    isPlaying,
    timeRemaining,
    score,
    message,
    handleWordSelect,
    handleBlankClick,
    handlePlayPause,
    handleReset,
    handleDifficultyChange,
    handleParagraphChange,
    getParagraphWithBlanks,
  };
};

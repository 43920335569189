import React, { useState, useEffect } from 'react';
import { Paper, Grid, Typography, Box } from '@mui/material';
import { API_URLS } from '../../../config/config';
import ResultDialog from '../../../pages/components/Auth/Dialog';
import useFetchSehirler from './hooks/useFetchSehirler';

// Yeni oluşturulan bileşenleri import ediyoruz
import StudentForm from './components/StudentForm';
import StudentTable from './components/StudentTable';
import EditStudentDialog from './components/EditStudentDialog';
import ConfirmDeleteDialog from './components/ConfirmDeleteDialog';

const Ogrenci = () => {
  const [ogrenciListesi, setOgrenciListesi] = useState([]);
  const [sehirListesi, setSehirListesi] = useState([]);
  const [secilenSehirId, setSecilenSehirId] = useState('');
  const [secilenIlceId, setSecilenIlceId] = useState('');
  const [shouldFetch, setShouldFetch] = useState(true);
  const [ad, setAd] = useState('');
  const [soyad, setSoyad] = useState('');
  const [okulAdi, setOkulAdi] = useState('');
  const [telefon, setTelefon] = useState('');
  const [telefonHata, setTelefonHata] = useState(false);
  const [email, setEmail] = useState('');
  const [sifre, setSifre] = useState('');
  const [sinif, setSinif] = useState('');
  const [yas, setYas] = useState('');
  const [dialogContent, setDialogContent] = useState('');
  const [dialogSeverity, setDialogSeverity] = useState('info');
  const [dialogOpen, setDialogOpen] = useState(false);
  const [resultDialogOpen, setResultDialogOpen] = useState(false);
  const [resultDialogMessage, setResultDialogMessage] = useState('');
  const [resultDialogSeverity, setResultDialogSeverity] = useState('info');
  const [eklemeBasariliDialogOpen, setEklemeBasariliDialogOpen] = useState(false);
  const [duzenlemeDialogAcik, setDuzenlemeDialogAcik] = useState(false);
  const [shouldFetchList, setShouldFetchList] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [confirmDeleteDialogOpen, setConfirmDeleteDialogOpen] = useState(false);
  const [studentToDelete, setStudentToDelete] = useState(null);
  const [durum, setDurum] = useState('inactive');
  const [duzenlenenOgrenci, setDuzenlenenOgrenci] = useState({
    ad: '',
    soyad: '',
    okulAdi: '',
    email: '',
    telefon: '',
    sehirId: '',
    ilceId: '',
    ilceler: [],
    sifre: '',
  });
  const [statusFilter, setStatusFilter] = useState('');
  const [cityFilter, setCityFilter] = useState('');
  const [ilceFilter, setIlceFilter] = useState('');

  useFetchSehirler(setSehirListesi, shouldFetch, setShouldFetch);

  const fetchIlceler = async (sehirId, seciliIlceId) => {
    try {
      const response = await fetch(`${API_URLS.ADMIN_URL}sehirler/ilceler/${sehirId}`);
      if (!response.ok) throw new Error('İlçeler yüklenirken bir hata oluştu.');
      const data = await response.json();
      const ilceler = data.ilceler ? data.ilceler : [];

      setDuzenlenenOgrenci((prev) => ({
        ...prev,
        ilceler: ilceler,
        ilceId:
          seciliIlceId && ilceler.some((ilce) => ilce._id === seciliIlceId) ? seciliIlceId : prev.ilceId,
      }));
    } catch (error) {
      console.error('İlçeleri yüklerken bir hata oluştu:', error);
    }
  };

  useEffect(() => {
    const fetchOgrenciListesi = async () => {
      try {
        const response = await fetch(`${API_URLS.ADMIN_URL}student`);
        if (!response.ok) {
          throw new Error('Öğrenci listesi yüklenirken bir hata oluştu.');
        }
        const data = await response.json();

        const enhancedData = data.map((ogrenci) => {
          const sehir = sehirListesi.find((s) => s._id === ogrenci.sehir);
          const ilce = sehir?.ilceler.find((i) => i._id === ogrenci.ilce);

          return {
            ...ogrenci,
            sehirAdi: sehir ? sehir.sehir_adi : 'Bulunamadı',
            ilceAdi: ilce ? ilce.ilce_adi : 'Bulunamadı',
            sinif: ogrenci.sinif?.toString() || '',
            yas: ogrenci.yas?.toString() || '',
            status: ogrenci.status || 'inactive',
            abonelik: ogrenci.abonelik || {},
            ekleyenAdi: ogrenci.ekleyenAdi,
          };
        });

        setOgrenciListesi(enhancedData);
      } catch (error) {
        console.error(error);
      } finally {
        setShouldFetchList(false);
      }
    };

    fetchOgrenciListesi();
  }, [shouldFetchList, sehirListesi]);

  const handleDialogClose = () => {
    setDialogOpen(false);
    setResultDialogOpen(false);
  };

  const handleTelefonChange = (event) => {
    const telefonRegex = /^0(\d{3}) (\d{3}) (\d{4})$/;
    let { value } = event.target;
    const numbers = value.replace(/\D/g, '');
    value =
      numbers.substring(0, 4) +
      (numbers.length > 4 ? ' ' : '') +
      numbers.substring(4, 7) +
      (numbers.length > 7 ? ' ' : '') +
      numbers.substring(7, 11);
    setTelefonHata(!telefonRegex.test(value) && value !== '');
    setTelefon(value);
  };

  const handleSehirChange = (event) => {
    setSecilenSehirId(event.target.value);
    setSecilenIlceId('');
  };

  const handleIlceChange = (event) => {
    setSecilenIlceId(event.target.value);
  };

  const handleSehirChangeDuzenle = (event) => {
    const yeniSehirId = event.target.value;
    setDuzenlenenOgrenci((prev) => ({ ...prev, sehirId: yeniSehirId }));
    const secilenSehir = sehirListesi.find((sehir) => sehir._id === yeniSehirId);
    const ilceler = secilenSehir ? secilenSehir.ilceler : [];
    setDuzenlenenOgrenci((prev) => ({ ...prev, ilceId: '', ilceler: ilceler }));
  };

  const handleIlceChangeDuzenle = (event) => {
    const yeniIlceId = event.target.value;
    setDuzenlenenOgrenci((prev) => ({ ...prev, ilceId: yeniIlceId }));
  };

  const secilenSehirinIlceleri = sehirListesi.find((sehir) => sehir._id === secilenSehirId)?.ilceler || [];

  const handleEkle = async () => {
    let eksikAlanlar = [];

    if (!ad) eksikAlanlar.push('Adı');
    if (!soyad) eksikAlanlar.push('Soyadı');
    if (!email) eksikAlanlar.push('Email');
    if (!sifre) eksikAlanlar.push('Şifre');
    if (!telefon) eksikAlanlar.push('Telefon');
    if (!secilenSehirId) eksikAlanlar.push('Şehir');
    if (!secilenIlceId) eksikAlanlar.push('İlçe');
    if (!sinif) eksikAlanlar.push('Sınıf');
    if (!yas) eksikAlanlar.push('Yaş');

    if (eksikAlanlar.length > 0) {
      setDialogSeverity('error');
      setDialogContent(`Lütfen ${eksikAlanlar.join(', ')} alanlarını doldurunuz.`);
      setEklemeBasariliDialogOpen(true);
      return;
    }

    if (telefonHata) {
      setDialogSeverity('error');
      setDialogContent('Lütfen geçerli bir telefon numarası giriniz.');
      setEklemeBasariliDialogOpen(true);
      return;
    }

    try {
      const response = await fetch(API_URLS.ADMIN_URL + 'student', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ad: ad,
          soyad: soyad,
          email: email,
          password: sifre,
          password_open: sifre,
          okul_adi: okulAdi,
          telefon: telefon,
          sehir: secilenSehirId,
          ilce: secilenIlceId,
          sinif: sinif,
          yas: yas,
          status: durum,
        }),
      });

      const responseData = await response.json();
      if (response.ok) {
        setOgrenciListesi([...ogrenciListesi, responseData]);

        setDialogSeverity('success');
        setDialogContent('Yeni Öğrenci başarıyla eklendi.');
        setEklemeBasariliDialogOpen(true);
        setAd('');
        setSoyad('');
        setOkulAdi('');
        setEmail('');
        setSifre('');
        setTelefon('');
        setSecilenSehirId('');
        setSecilenIlceId('');
        setSinif('');
        setYas('');
      } else {
        throw new Error(responseData.message || 'Bir hata oluştu');
      }
    } catch (err) {
      setDialogSeverity('error');
      setDialogContent(err.message);
      setEklemeBasariliDialogOpen(true);
    }
  };

  const handleEdit = (ogrenciId) => {
    const seciliOgrenci = ogrenciListesi.find((ogr) => ogr._id === ogrenciId);

    if (seciliOgrenci) {
      const sehirId = seciliOgrenci.sehir;

      setDuzenlenenOgrenci((prev) => ({
        ...prev,
        _id: seciliOgrenci._id,
        ad: seciliOgrenci.ad,
        soyad: seciliOgrenci.soyad,
        okulAdi: seciliOgrenci.okul_adi,
        email: seciliOgrenci.email,
        telefon: seciliOgrenci.telefon,
        sehirId: sehirId,
        ilceId: seciliOgrenci.ilce,
        sinif: seciliOgrenci.sinif,
        yas: seciliOgrenci.yas,
        sifre: '',
      }));
      fetchIlceler(sehirId);
      setDuzenlemeDialogAcik(true);
    } else {
      console.error('Seçilen öğrenci bulunamadı.');
    }
  };

  const handleGuncelle = async () => {
    const { ad, soyad, okulAdi, email, telefon, sehirId, ilceId, sinif, yas, sifre } = duzenlenenOgrenci;
    let eksikAlanlar = [];

    if (!ad) eksikAlanlar.push('Adı');
    if (!soyad) eksikAlanlar.push('Soyadı');
    if (!email) eksikAlanlar.push('Email');
    if (!telefon) eksikAlanlar.push('Telefon');
    if (!sehirId) eksikAlanlar.push('Şehir');
    if (!ilceId) eksikAlanlar.push('İlçe');

    if (eksikAlanlar.length > 0) {
      setDialogSeverity('error');
      setDialogContent(`Lütfen ${eksikAlanlar.join(', ')} alanlarını doldurunuz.`);
      setDialogOpen(true);
      return;
    }

    try {
      const body = {
        ad,
        soyad,
        okul_adi: okulAdi,
        email,
        telefon,
        sehir: sehirId,
        ilce: ilceId,
        sinif,
        yas,
      };

      if (sifre) {
        body.password = sifre;
        body.password_open = sifre;
      }

      const response = await fetch(`${API_URLS.ADMIN_URL}student/${duzenlenenOgrenci._id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      });

      const data = await response.json();
      if (!response.ok) throw new Error(data.message || 'Bir hata oluştu');

      setOgrenciListesi((ogrenciListesi) =>
        ogrenciListesi.map((ogr) =>
          ogr._id === duzenlenenOgrenci._id ? { ...ogr, ...data.ogrenci } : ogr
        )
      );

      setShouldFetchList(true);

      setDialogSeverity('success');
      setDialogContent('Öğrenci başarıyla güncellendi.');
      setDialogOpen(true);
      setDuzenlemeDialogAcik(false);
    } catch (err) {
      setDialogSeverity('error');
      setDialogContent(err.message);
      setDialogOpen(true);
    }
  };

  const handleStatusChange = async (ogrenciId, currentStatus) => {
    const newStatus = currentStatus === 'active' ? 'inactive' : 'active';

    try {
      const response = await fetch(`${API_URLS.ADMIN_URL}student/${ogrenciId}/status`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ status: newStatus }),
      });

      if (!response.ok) throw new Error('Durum güncellenirken bir hata oluştu.');

      const updatedStudent = await response.json();

      setOgrenciListesi((prevList) =>
        prevList.map((ogrenci) =>
          ogrenci._id === ogrenciId ? { ...ogrenci, status: updatedStudent.status } : ogrenci
        )
      );

      setShouldFetchList(true);

      setResultDialogMessage('Durum başarıyla güncellendi.');
      setResultDialogSeverity('success');
      setResultDialogOpen(true);
    } catch (error) {
      setResultDialogMessage(error.message);
      setResultDialogSeverity('error');
      setResultDialogOpen(true);
    }
  };

  const handleOpenDeleteDialog = (ogrenciId) => {
    setStudentToDelete(ogrenciId);
    setConfirmDeleteDialogOpen(true);
  };

  const handleDeleteOgrenci = async (ogrenciId) => {
    try {
      const response = await fetch(`${API_URLS.ADMIN_URL}student/${ogrenciId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) throw new Error('Öğrenci silinirken bir hata oluştu.');

      setOgrenciListesi(ogrenciListesi.filter((ogrenci) => ogrenci._id !== ogrenciId));

      setResultDialogMessage('Öğrenci başarıyla silindi.');
      setResultDialogSeverity('success');
      setResultDialogOpen(true);
    } catch (error) {
      setResultDialogMessage(error.message);
      setResultDialogSeverity('error');
      setResultDialogOpen(true);
    }
  };

  // Filtrelenmiş öğrenci listesi
  const filteredOgrenciListesi = ogrenciListesi.filter((ogrenci) => {
    const matchesSearch =
      ogrenci.ad.toLowerCase().includes(searchTerm.toLowerCase()) ||
      ogrenci.soyad.toLowerCase().includes(searchTerm.toLowerCase()) ||
      ogrenci.telefon.includes(searchTerm) ||
      ogrenci.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
      ogrenci.okul_adi.toLowerCase().includes(searchTerm.toLowerCase()) ||
      ogrenci.sinif.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
      ogrenci.yas.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
      ogrenci.sehirAdi.toLowerCase().includes(searchTerm.toLowerCase()) ||
      ogrenci.ilceAdi.toLowerCase().includes(searchTerm.toLowerCase());

    const matchesStatus = statusFilter ? ogrenci.status === statusFilter : true;
    const matchesCity = cityFilter ? ogrenci.sehir === cityFilter : true;
    const matchesIlce = ilceFilter ? ogrenci.ilce === ilceFilter : true;

    return matchesSearch && matchesStatus && matchesCity && matchesIlce;
  });

  return (
    <Paper style={{ padding: 20, margin: '20px auto', maxWidth: '95%' }}>
      <Grid container spacing={4}>
        {/* Öğrenci Kayıt Formu */}
        <Grid item xs={12}>
          <Typography variant="h5" component="h3" gutterBottom>
            Öğrenci Kayıt Formu
          </Typography>
          <StudentForm
            ad={ad}
            setAd={setAd}
            soyad={soyad}
            setSoyad={setSoyad}
            okulAdi={okulAdi}
            setOkulAdi={setOkulAdi}
            sinif={sinif}
            setSinif={setSinif}
            yas={yas}
            setYas={setYas}
            sehirListesi={sehirListesi}
            secilenSehirId={secilenSehirId}
            handleSehirChange={handleSehirChange}
            secilenIlceId={secilenIlceId}
            handleIlceChange={handleIlceChange}
            secilenSehirinIlceleri={secilenSehirinIlceleri}
            telefon={telefon}
            handleTelefonChange={handleTelefonChange}
            telefonHata={telefonHata}
            durum={durum}
            setDurum={setDurum}
            email={email}
            setEmail={setEmail}
            sifre={sifre}
            setSifre={setSifre}
            handleEkle={handleEkle}
          />
        </Grid>

        {/* Filtreleme ve Öğrenci Listesi */}
        <Grid item xs={12}>
          <Typography variant="h5" component="h3" gutterBottom>
            Öğrenci Listesi
          </Typography>
          <StudentTable
            filteredOgrenciListesi={filteredOgrenciListesi}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            statusFilter={statusFilter}
            setStatusFilter={setStatusFilter}
            cityFilter={cityFilter}
            setCityFilter={setCityFilter}
            ilceFilter={ilceFilter}
            setIlceFilter={setIlceFilter}
            sehirListesi={sehirListesi}
            handleEdit={handleEdit}
            handleOpenDeleteDialog={handleOpenDeleteDialog}
            handleStatusChange={handleStatusChange}
          />
        </Grid>
      </Grid>

      {/* Dialoglar */}
      <>
        <ResultDialog
          open={eklemeBasariliDialogOpen}
          onClose={() => setEklemeBasariliDialogOpen(false)}
          title={dialogSeverity === 'error' ? 'Hata Oluştu' : 'İşlem Başarılı'}
          message={dialogContent}
          severity={dialogSeverity}
        />
        <ResultDialog
          open={resultDialogOpen}
          onClose={handleDialogClose}
          title={'İşlem Sonucu'}
          message={resultDialogMessage}
          severity={resultDialogSeverity}
        />
        <EditStudentDialog
          duzenlemeDialogAcik={duzenlemeDialogAcik}
          setDuzenlemeDialogAcik={setDuzenlemeDialogAcik}
          duzenlenenOgrenci={duzenlenenOgrenci}
          setDuzenlenenOgrenci={setDuzenlenenOgrenci}
          sehirListesi={sehirListesi}
          handleSehirChangeDuzenle={handleSehirChangeDuzenle}
          handleIlceChangeDuzenle={handleIlceChangeDuzenle}
          handleGuncelle={handleGuncelle}
        />
        <ConfirmDeleteDialog
          confirmDeleteDialogOpen={confirmDeleteDialogOpen}
          setConfirmDeleteDialogOpen={setConfirmDeleteDialogOpen}
          handleDeleteOgrenci={handleDeleteOgrenci}
          studentToDelete={studentToDelete}
          setStudentToDelete={setStudentToDelete}
        />
      </>
    </Paper>
  );
};

export default Ogrenci;

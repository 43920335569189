import React, { useState } from 'react';
import { Grid, TextField, Button, FormControl, InputLabel, Select, MenuItem } from '@mui/material';

const telefonRegex = /^0(\d{3}) (\d{3}) (\d{4})$/;

const TeacherForm = ({ sehirListesi, onEkle, dialogSeverity, setDialogSeverity, dialogContent, setDialogContent, setEklemeBasariliDialogOpen }) => {
  const [ad, setAd] = useState('');
  const [soyad, setSoyad] = useState('');
  const [telefon, setTelefon] = useState('');
  const [telefonHata, setTelefonHata] = useState(false);
  const [email, setEmail] = useState('');
  const [sifre, setSifre] = useState('');
  const [secilenSehirId, setSecilenSehirId] = useState('');
  const [secilenIlceId, setSecilenIlceId] = useState('');

  const secilenSehirinIlceleri = sehirListesi.find(sehir => sehir._id === secilenSehirId)?.ilceler || [];

  const handleTelefonChange = (event) => {
    let { value } = event.target;
    const numbers = value.replace(/\D/g, '');
    value = numbers.substring(0, 4) 
      + (numbers.length > 4 ? ' ' : '') 
      + numbers.substring(4, 7) 
      + (numbers.length > 7 ? ' ' : '') 
      + numbers.substring(7, 11);
    setTelefonHata(!telefonRegex.test(value) && value !== '');
    setTelefon(value);
  };

  const handleEkleClick = () => {
    let eksikAlanlar = [];

    if (!ad) eksikAlanlar.push('Adı');
    if (!soyad) eksikAlanlar.push('Soyadı');
    if (!email) eksikAlanlar.push('Email');
    if (!sifre) eksikAlanlar.push('Şifre');
    if (!telefon) eksikAlanlar.push('Telefon');
    if (!secilenSehirId) eksikAlanlar.push('Şehir');
    if (!secilenIlceId) eksikAlanlar.push('İlçe');

    if (eksikAlanlar.length > 0) {
      setDialogSeverity('error');
      setDialogContent(`Lütfen ${eksikAlanlar.join(', ')} alanlarını doldurunuz.`);
      setEklemeBasariliDialogOpen(true);
      return;
    }

    if (telefonHata) {
      setDialogSeverity('error');
      setDialogContent('Lütfen geçerli bir telefon numarası giriniz.');
      setEklemeBasariliDialogOpen(true);
      return;
    }

    const yeniOgretmenData = {
      ad,
      soyad,
      email,
      password: sifre,
      password_open: sifre,
      telefon,
      sehir: secilenSehirId,
      ilce: secilenIlceId
    };

    onEkle(yeniOgretmenData, secilenSehirId, secilenIlceId);

    // Başarılı ekleme sonrası alanları resetle
    setAd('');
    setSoyad('');
    setEmail('');
    setSifre('');
    setTelefon('');
    setSecilenSehirId('');
    setSecilenIlceId('');
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          label="Adı"
          value={ad}
          onChange={(e) => setAd(e.target.value)}
          margin="normal"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          label="Soyadı"
          value={soyad}
          onChange={(e) => setSoyad(e.target.value)}
          margin="normal"
        />
      </Grid>
      
      <Grid item xs={12} sm={6}>
        <FormControl fullWidth margin="normal">
          <InputLabel id="sehir-select-label">Şehir</InputLabel>
          <Select
            labelId="sehir-select-label"
            value={secilenSehirId}
            onChange={(e) => setSecilenSehirId(e.target.value)}
          >
            {sehirListesi.map((sehir) => (
              <MenuItem key={sehir._id} value={sehir._id}>
                {sehir.sehir_adi}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      
      {secilenSehirId && (
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth margin="normal">
            <InputLabel id="ilce-select-label">İlçe</InputLabel>
            <Select
              labelId="ilce-select-label"
              value={secilenIlceId}
              onChange={(e) => setSecilenIlceId(e.target.value)}
            >
              {secilenSehirinIlceleri.map((ilce) => (
                <MenuItem key={ilce._id} value={ilce._id}>
                  {ilce.ilce_adi}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      )}

      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          label="Telefon"
          value={telefon}
          onChange={handleTelefonChange}
          error={telefonHata}
          helperText={
            telefonHata ? 'Telefon numarası 05XX XXX XXXX formatında olmalıdır.' : ''
          }
          margin="normal"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          label="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          margin="normal"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          label="Şifre"
          value={sifre}
          onChange={(e) => setSifre(e.target.value)}
          margin="normal"
          type="password"
        />
      </Grid>
      <Grid item xs={12}>
        <Button
          onClick={handleEkleClick}
          variant="contained"
          color="primary"
          fullWidth
        >
          Ekle
        </Button>
      </Grid>
    </Grid>
  );
};

export default TeacherForm;

// useKayanYazilarUcerOyunu.js

import { useState, useEffect, useCallback } from 'react';
import { API_URLS } from '../../../../config/config';
import axiosInstance from '../../../../utils/axiosInstance';

export const useKayanYazilarUcerOyunu = (navigate, userData, token) => {
  const [difficulty, setDifficulty] = useState('easy');
  const [paragraphsList, setParagraphsList] = useState([]);
  const [selectedParagraphIndex, setSelectedParagraphIndex] = useState(0);
  const [displayedWords, setDisplayedWords] = useState([]);
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [speed, setSpeed] = useState(2); // Speed in groups per second
  const [elapsedTime, setElapsedTime] = useState(0);
  const [gameTime, setGameTime] = useState(0);
  const [timeLeft, setTimeLeft] = useState(0);
  const [message, setMessage] = useState('');
  const [gameOver, setGameOver] = useState(false);
  const [allWords, setAllWords] = useState([]);

  // Prepare words from the content
  const prepareWords = (content) => {
    const words = content.trim().split(/\s+/);
    setAllWords(words);
  };

  // Fetch paragraphs based on difficulty using axiosInstance
  useEffect(() => {
    const fetchParagraphs = async () => {
      try {
        const url = `${API_URLS.HIZLIOKUMA_URL}akanyazi/${difficulty}`;
        const response = await axiosInstance.get(url);
        const data = response.data;

        setParagraphsList(data);
        setSelectedParagraphIndex(0);

        if (data[0]) {
          prepareWords(data[0].content);
        }
        handleReset();
      } catch (error) {
        console.error('Error fetching paragraphs:', error);
      }
    };

    fetchParagraphs();
  }, [difficulty, token]);

  // Update words when paragraph changes
  useEffect(() => {
    if (paragraphsList[selectedParagraphIndex]) {
      prepareWords(paragraphsList[selectedParagraphIndex].content);
      handleReset();
    }
  }, [selectedParagraphIndex, paragraphsList]);

  // Calculate game time based on word count and speed
  useEffect(() => {
    if (paragraphsList[selectedParagraphIndex]) {
      const words = allWords;
      const wordCount = words.length;
      const totalWordGroups = Math.ceil(wordCount / 3); // Groups of 3 words

      const estimatedTime = totalWordGroups / speed; // Time in seconds

      const maxTime = userData
        ? userData.status === 'inactive'
          ? 15
          : estimatedTime
        : 15;

      setGameTime(maxTime);
      setTimeLeft(maxTime);
    }
  }, [paragraphsList, selectedParagraphIndex, speed, userData, allWords]);

  // Update displayed words and time
  const updateWords = useCallback(() => {
    if (!isPlaying || allWords.length === 0) return;

    if (currentWordIndex >= allWords.length) {
      setIsPlaying(false);
      setGameOver(true);
      return;
    }

    // Get the next group of three words
    const nextWords = allWords
      .slice(currentWordIndex, currentWordIndex + 3)
      .join(' ');
    setDisplayedWords((prev) => [...prev, nextWords]);
    setCurrentWordIndex((prev) => prev + 3);

    const intervalInSeconds = 1 / speed;

    setElapsedTime((prev) => prev + intervalInSeconds);
    setTimeLeft((prev) => prev - intervalInSeconds);

    if (timeLeft - intervalInSeconds <= 0) {
      setIsPlaying(false);
      setGameOver(true);
    }
  }, [
    isPlaying,
    allWords,
    currentWordIndex,
    speed,
    timeLeft,
  ]);

  // Timer to update words
  useEffect(() => {
    let wordInterval;
    if (isPlaying) {
      const intervalDuration = 1000 / speed;
      wordInterval = setInterval(() => {
        updateWords();
      }, intervalDuration);
    }

    return () => clearInterval(wordInterval);
  }, [isPlaying, speed, updateWords]);

  // Handle play/pause action
  const handlePlayPause = () => {
    if (gameOver) return;
    if (isPlaying) {
      setMessage(`Skorunuz: ${currentWordIndex} kelime`);
    } else {
      setMessage('');
    }
    setIsPlaying(!isPlaying);
  };

  // Reset the game
  const handleReset = () => {
    setIsPlaying(false);
    setDisplayedWords([]);
    setCurrentWordIndex(0);
    setElapsedTime(0);
    setMessage('');
    setGameOver(false);

    // Recalculate the game time
    if (paragraphsList[selectedParagraphIndex]) {
      const words = allWords;
      const wordCount = words.length;
      const totalWordGroups = Math.ceil(wordCount / 3);
      const estimatedTime = totalWordGroups / speed;

      const maxTime = userData
        ? userData.status === 'inactive'
          ? 15
          : estimatedTime
        : 15;

      setGameTime(maxTime);
      setTimeLeft(maxTime);
    }
  };

  // Handle speed change
  const handleSpeedChange = (e) => {
    const newSpeed = Number(e.target.value);
    setSpeed(newSpeed);

    if (paragraphsList[selectedParagraphIndex]) {
      const words = allWords;
      const wordCount = words.length;
      const totalWordGroups = Math.ceil(wordCount / 3);
      const groupsLeft = totalWordGroups - Math.floor(currentWordIndex / 3);
      const estimatedTime = groupsLeft / newSpeed;

      setTimeLeft(estimatedTime);
    }
  };

  // Handle paragraph change
  const handleParagraphChange = (e) => {
    setSelectedParagraphIndex(Number(e.target.value));
  };

  // Handle difficulty change
  const handleDifficultyChange = (e) => {
    setDifficulty(e.target.value);
  };

  // Function called when time is up
  const onTimeUp = () => {
    setIsPlaying(false);
    setGameOver(true);
  };

  return {
    difficulty,
    paragraphsList,
    selectedParagraphIndex,
    displayedWords,
    currentWordIndex,
    isPlaying,
    speed,
    elapsedTime,
    gameTime,
    timeLeft,
    message,
    gameOver,
    setGameOver,
    handlePlayPause,
    handleReset,
    handleSpeedChange,
    handleParagraphChange,
    handleDifficultyChange,
    onTimeUp,
    updateWords,
  };
};

// GameDisplayRandomWords.js

import React, { useRef } from 'react';
import parse, { domToReact } from 'html-react-parser';
import '../styles/rasgeleKelimelerStyle.css';
import ProgressBar from './ProgressBarRandomWords';

const GameDisplay = ({
  isPlaying,
  paragraphsList,
  selectedParagraphIndex,
  displayedWordsGroup,
  handlePlayPause,
  handleReset,
  message,
  gameOver,
  fontSize,
  currentWordIndex,
}) => {
  const containerRef = useRef(null);

  // HTML parse ayarları için voidElements seti:
  const voidElements = new Set([
    'area',
    'base',
    'br',
    'col',
    'embed',
    'hr',
    'img',
    'input',
    'keygen',
    'link',
    'meta',
    'param',
    'source',
    'track',
    'wbr',
  ]);

  const options = {
    replace: (node) => {
      if (node.type === 'tag') {
        if (voidElements.has(node.name)) {
          return React.createElement(
            node.name,
            { key: node.name, ...node.attribs },
            null
          );
        } else {
          return React.createElement(
            node.name,
            { key: node.name, ...node.attribs },
            domToReact(node.children, options)
          );
        }
      }
    },
  };

  const getWordCount = (htmlContent) => {
    if (!htmlContent) return 0;
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = htmlContent;
    const textContent = tempDiv.textContent || tempDiv.innerText || '';
    const words = textContent.trim().split(/\s+/);
    return words.filter((w) => w).length;
  };

  const totalWords =
    paragraphsList[selectedParagraphIndex]
      ? getWordCount(paragraphsList[selectedParagraphIndex].content)
      : 1;

  const progress = totalWords > 0 ? (currentWordIndex / totalWords) * 100 : 0;

  return (
    <>
      <div className="rasgele-kelimeler-controls">
        <button
          onClick={handlePlayPause}
          disabled={gameOver}
          className="rasgele-kelimeler-button"
          aria-label={isPlaying ? 'Duraklat' : 'Başlat'}
        >
          {isPlaying ? 'Duraklat' : 'Başlat'}
        </button>
        <button
          onClick={handleReset}
          className="rasgele-kelimeler-button"
          aria-label="Yeniden Başlat"
        >
          Yeniden Başlat
        </button>
      </div>

      <ProgressBar progress={progress} />

      <div
        className="rasgele-kelimeler-game-display"
        style={{ fontSize, position: 'relative', overflow: 'hidden' }}
        id="game-display-container"
        ref={containerRef}
      >
        {!paragraphsList[selectedParagraphIndex] ? (
          <div className="rasgele-kelimeler-message">
            Paragraf bulunamadı. Lütfen farklı bir paragraf veya zorluk seviyesi seçin.
          </div>
        ) : (
          <div
            className="rasgele-kelimeler-content"
            style={{ width: '100%', height: '100%', position: 'relative' }}
          >
            {displayedWordsGroup && (
              <div
                key={displayedWordsGroup.id}
                style={{
                  position: 'absolute',
                  left: `${displayedWordsGroup.x}px`,
                  top: `${displayedWordsGroup.y}px`,
                  whiteSpace: 'nowrap',
                  color: '#fff',
                }}
              >
                {parse(displayedWordsGroup.text, options)}
              </div>
            )}
          </div>
        )}
      </div>

      {message && <div className="rasgele-kelimeler-message">{message}</div>}
    </>
  );
};

export default GameDisplay;

// SpeedControl.js
import React from 'react';

const SpeedControl = ({ speed, setSpeed }) => {
  const incrementSpeed = () => {
    setSpeed((prevSpeed) => Math.min(prevSpeed + 0.1, 30));
  };

  const decrementSpeed = () => {
    setSpeed((prevSpeed) => Math.max(prevSpeed - 0.1, 0.1));
  };

  return (
    <div className="speed-control-container">
      <div className="speed-label">Hız Ayarı</div>
      <div className="speed-display">
        <button className="speed-button" onClick={decrementSpeed}>-</button>
        <div className="speed-value">{speed.toFixed(1)}</div>
        <button className="speed-button" onClick={incrementSpeed}>+</button>
      </div>
      <div className="speed-instructions">
        Hızı 0.1 ile 30 arasında ayarlayabilirsiniz.
      </div>
    </div>
  );
};

export default SpeedControl;

// HeaderBanner.js
import React from 'react';
import { Box, Card, ThemeProvider, createTheme, Typography } from '@mui/material';
import { styled, keyframes } from '@mui/system';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const theme = createTheme({
  palette: {
    primary: {
      main: '#00796B',
    },
    secondary: {
      main: '#4DB6AC',
    },
    background: {
      default: '#ede7f6',
      paper: '#fff',
    },
  },
});

// Fade-In + Yukarı Kayma
const fadeIn = keyframes`
  0% { opacity: 0; transform: translateY(20px);}
  100% { opacity: 1; transform: translateY(0);}
`;

// Ölçeklendirme Efekti
const scaleCycle = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.2); }
  100% { transform: scale(1); }
`;

// Okların yavaşça yukarı-aşağı hareketi
const floatArrows = keyframes`
  0% { transform: translateY(0); }
  50% { transform: translateY(-6px); }
  100% { transform: translateY(0); }
`;

// Parıldayan noktalar
const sparkle = keyframes`
  0% { opacity: 0; transform: scale(0.5); }
  50% { opacity: 1; transform: scale(1.2); }
  100% { opacity: 0; transform: scale(0.5); }
`;

// Gradient geçişleri (Yeşil -> Turuncu -> Kırmızı -> Yeşil)
const gradientShift = keyframes`
  0% { background: linear-gradient(135deg, #00e676, #76ff03); }
  33% { background: linear-gradient(135deg, #ffa726, #ffca28); }
  66% { background: linear-gradient(135deg, #ff1744, #f44336); }
  100% { background: linear-gradient(135deg, #00e676, #76ff03); }
`;

// Metni sadeleştirdik, gradient vb. kaldırıldı
const AnimatedText = styled(Typography)(({ theme }) => ({
  fontFamily: 'Montserrat, sans-serif',
  fontWeight: 700,
  fontSize: '3rem',
  lineHeight: 1.3,
  textAlign: 'center',
  color: '#fff', // Metni doğrudan beyaz yapıyoruz.
  animation: `${fadeIn} 2s ease-out forwards`,
  [theme.breakpoints.down('sm')]: {
    fontSize: '2.2rem',
  },
}));

const ArrowIcon = styled(ArrowForwardIosIcon)(({ theme }) => ({
  position: 'absolute',
  color: '#ffffff88',
  fontSize: '2rem',
  animation: `${floatArrows} 3s ease-in-out infinite`,
}));

const StyledCard = styled(Card)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: '70%',
  width: '90%',
  backdropFilter: 'blur(5px)',
  borderRadius: '32px',
  padding: '40px 20px',
  boxShadow: '0 4px 20px rgba(0, 0, 0, 0.5)',
  textAlign: 'center',
  color: '#fff',
  position: 'relative',
  overflow: 'hidden',
  // İsterseniz gradientShift'i de kaldırabilirsiniz, metni etkilemiyor.
  animation: `${gradientShift} 12s infinite ease-in-out`,

  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '10px',
    backgroundColor: 'rgba(255,255,255,0.3)',
    zIndex: 1,
  },
}));

// SparkleContainer ve SparkleElement kodları sabit kalabilir, gerekiyorsa kaldırabilirsiniz.
const SparkleContainer = styled('div')({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  pointerEvents: 'none',
  overflow: 'hidden',
});

const SparkleElement = styled('div')(({theme}) => ({
  position: 'absolute',
  width: '10px',
  height: '10px',
  backgroundColor: '#fff',
  borderRadius: '50%',
  boxShadow: `
    0 0 10px 2px rgba(255,255,255,0.7),
    0 0 20px 4px rgba(255,255,255,0.4),
    0 0 30px 6px rgba(255,255,255,0.1)
  `,
  animation: `${sparkle} 2s infinite ease-in-out`,
}));

const HeaderBanner = () => {
  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          width: '100%',
          height: '500px',
          position: 'relative',
          overflow: 'hidden',
          boxShadow: '-3px 8px 23px -2px rgba(0,0,0,0.75)',
          '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundImage: 'url("/images/header.webp")',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center -100px',
            // İsterseniz buradaki blur'u da kaldırın veya azaltın
            // filter: 'blur(1px)',
            zIndex: -1,
          },
        }}
      >
        <StyledCard>
          <ArrowIcon sx={{ top: '20%', left: '10%', transform: 'rotate(-45deg)' }} />
          <ArrowIcon sx={{ bottom: '20%', right: '10%', transform: 'rotate(135deg)' }} />

          <AnimatedText variant="h2">
            Hızlı Oku, Derin Anla
          </AnimatedText>

          <Typography
            variant="h6"
            sx={{
              marginTop: '24px',
              color: '#ffffff', // Metni beyaz yapıyoruz.
              fontWeight: 400,
              fontFamily: 'Montserrat, sans-serif',
              fontSize: '1.8rem',
              // textShadow: '1px 1px 2px rgba(0,0,0,0.3)', // İsterseniz bunu da kaldırın
              [theme.breakpoints.down('sm')]: {
                fontSize: '1.4rem',
              },
            }}
          >
            Düşüncelerini Besle, Bilgiyi İçselleştir, Başarıyı Yakala.
          </Typography>

          <SparkleContainer>
            <SparkleElement style={{top: '50%', left: '50%', transform:'translate(-50%, -50%)'}} />
            <SparkleElement style={{top: '20%', left: '30%'}} />
            <SparkleElement style={{bottom: '25%', right: '40%'}} />
            <SparkleElement style={{top: '10%', right: '20%'}} />
            <SparkleElement style={{bottom: '10%', left: '70%'}} />
            <SparkleElement style={{top: '30%', right: '10%'}} />
          </SparkleContainer>
        </StyledCard>
      </Box>
    </ThemeProvider>
  );
};

export default HeaderBanner;

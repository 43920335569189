import React, { useState, useEffect } from 'react';
import { Card, CardContent, TextField, Button, Typography, Container, CircularProgress,  FormControl, InputLabel, Select, MenuItem,} from '@mui/material';
import { useNavigate } from 'react-router-dom'; 
import Header from '../../../home/components/Header';
import Footer from '../../../home/components/Footer';
import ResultDialog from '../../components/Auth/Dialog';
import { API_URLS } from '../../../config/config';

function KurumsalRegisterForm() {
  const navigate = useNavigate(); // Initialize useNavigate
  const [schoolName, setSchoolName] = useState('');
  const [email, setEmail] = useState('');
  const [emailHata, setEmailHata] = useState(false); // E-posta hata durumu
  const [password, setPassword] = useState('');
  const [telefon, setTelefon] = useState('');
  const [telefonHata, setTelefonHata] = useState(false);
  const [sehirListesi, setSehirListesi] = useState([]);
  const [secilenSehirId, setSecilenSehirId] = useState('');
  const [secilenIlceId, setSecilenIlceId] = useState('');
  const [shouldFetch, setShouldFetch] = useState(true);
  const [loading, setLoading] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState('');
  const [dialogSeverity, setDialogSeverity] = useState('error');

  useEffect(() => {
    const fetchSehirler = async () => {
      if (!shouldFetch) return;

      try {
        const response = await fetch(`${API_URLS.ADMIN_URL}sehirler`);
        if (!response.ok) {
          throw new Error('Veri çekme işlemi başarısız');
        }
        const sehirler = await response.json();
        setSehirListesi(sehirler);
      } catch (err) {
        console.error('Şehirler yüklenirken bir hata oluştu:', err.message);
      } finally {
        setShouldFetch(false);
      }
    };

    fetchSehirler();
  }, [shouldFetch]); // Added dependency array to prevent infinite loop

  const handleTelefonChange = (event) => {
    const telefonRegex = /^0(\d{3}) (\d{3}) (\d{4})$/;
    let { value } = event.target;
    const numbers = value.replace(/\D/g, '');
    value =
      numbers.substring(0, 4) +
      (numbers.length > 4 ? ' ' : '') +
      numbers.substring(4, 7) +
      (numbers.length > 7 ? ' ' : '') +
      numbers.substring(7, 11);
    setTelefonHata(!telefonRegex.test(value) && value !== '');
    setTelefon(value);
  };

  const handleSehirChange = (event) => {
    setSecilenSehirId(event.target.value);
    setSecilenIlceId(''); // Reset İlçe when Şehir changes
  };

  const handleIlceChange = (event) => {
    setSecilenIlceId(event.target.value);
  };

  const secilenSehirinIlceleri =
    sehirListesi.find((sehir) => sehir._id === secilenSehirId)?.ilceler || [];

  // Yeni Email Değişim Handler'ı
  const handleEmailChange = (event) => {
    const { value } = event.target;
    setEmail(value);

    // Basit bir email regex kullanarak kontrol edin
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setEmailHata(!emailRegex.test(value));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // E-posta geçerliliğini kontrol edin
    if (emailHata) {
      setDialogSeverity('error');
      setDialogContent('Lütfen geçerli bir e-posta adresi giriniz.');
      setDialogOpen(true);
      return;
    }

    setLoading(true);
    try {
      const response = await fetch(`${API_URLS.SCHOOL_URL}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          okul_adi: schoolName,
          email: email,
          password: password,
          password_open: password,
          telefon: telefon,
          sehir: secilenSehirId,
          ilce: secilenIlceId,
        }),
      });

      const data = await response.json();
      setLoading(false);

      if (!response.ok) {
        // Set error message
        setDialogSeverity('error');
        setDialogContent(data.message || 'Kayıt işlemi başarısız.');
        setDialogOpen(true);
      } else {
        // Set success message
        setDialogSeverity('success');
        setDialogContent('Kayıt başarılı!');
        setDialogOpen(true);
      }
    } catch (err) {
      setLoading(false);
      setDialogSeverity('error'); // Ensure severity is set to error on catch
      setDialogContent(err.message || 'Bir hata oluştu.');
      setDialogOpen(true);
    }
  };

  // Handler for dialog close
  const handleDialogClose = () => {
    setDialogOpen(false);
    if (dialogSeverity === 'success') {
      navigate('/login'); // Redirect to /login on success
    }
  };

  return (
    <div>
      <Header />
      <div className='bg-main'>
        <Container
          maxWidth='sm'
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '100vh', // Changed from height to minHeight
            '.MuiCard-root': {
              background:
                'linear-gradient(145deg, rgba(105,105,105,0.8) 0%, rgba(220,20,60,0.8) 100%)',
              backdropFilter: 'blur(8px)',
            },
          }}
        >
          <Card
            sx={{
              minWidth: 275,
              backgroundColor: 'rgba(255, 255, 255, 0.75)',
              backdropFilter: 'blur(8px)',
              borderRadius: '16px',
              boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2)',
            }}
          >
            <CardContent
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Typography
                variant='h5'
                component='div'
                sx={{
                  marginBottom: 2,
                  color: '#fff',
                  fontWeight: 'bold',
                  textShadow: '2px 2px 4px rgba(0,0,0,0.5)',
                  letterSpacing: '0.05rem',
                  fontSize: '1.5rem',
                }}
              >
                Kurum - Kayıt Formu
              </Typography>
              <form onSubmit={handleSubmit} style={{ width: '100%' }}>
                <TextField
                  fullWidth
                  margin='normal'
                  label='Okul Adı'
                  variant='outlined'
                  value={schoolName}
                  onChange={(e) => setSchoolName(e.target.value)}
                  required
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: 'white',
                      },
                      '&:hover fieldset': {
                        borderColor: '#fff',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#fff',
                      },
                    },
                    '& .MuiInputLabel-root': {
                      color: 'white',
                    },
                    '& .MuiInputBase-input': {
                      color: 'white',
                    },
                  }}
                />

                <FormControl
                  fullWidth
                  margin='normal'
                  required
                  sx={{
                    input: { color: 'white' },
                    label: { color: 'white' },
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': { borderColor: 'white' },
                      '&:hover fieldset': {
                        borderColor: '#fff',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#fff',
                      },
                    },
                  }}
                >
                  <InputLabel id='sehir-select-label' sx={{ color: 'white' }}>
                    Şehir
                  </InputLabel>
                  <Select
                    labelId='sehir-select-label'
                    value={secilenSehirId}
                    onChange={handleSehirChange}
                    displayEmpty
                    label='Şehir'
                    sx={{
                      color: 'white',
                      '& .MuiSvgIcon-root': { color: 'white' },
                    }}
                    renderValue={(selected) => {
                      if (selected === '') {
                        return <em>Şehir Seçiniz</em>;
                      }
                      const secilenSehir = sehirListesi.find(
                        (sehir) => sehir._id === selected
                      );
                      return secilenSehir
                        ? secilenSehir.sehir_adi
                        : <em>Şehir Seçiniz</em>;
                    }}
                  >
                    <MenuItem disabled value=''>
                      <em>Şehir Seçiniz</em>
                    </MenuItem>
                    {sehirListesi.map((sehir) => (
                      <MenuItem key={sehir._id} value={sehir._id}>
                        {sehir.sehir_adi}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                {secilenSehirId && (
                  <FormControl
                    fullWidth
                    margin='normal'
                    required
                    sx={{
                      input: { color: 'white' },
                      label: { color: 'white' },
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': { borderColor: 'white' },
                        '&:hover fieldset': {
                          borderColor: '#fff',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#fff',
                        },
                      },
                    }}
                  >
                    <InputLabel id='ilce-select-label' sx={{ color: 'white' }}>
                      İlçe
                    </InputLabel>
                    <Select
                      labelId='ilce-select-label'
                      id='ilce-select'
                      value={secilenIlceId}
                      onChange={handleIlceChange}
                      displayEmpty
                      label='İlçe'
                      sx={{
                        color: 'white',
                        '& .MuiSvgIcon-root': { color: 'white' },
                      }}
                      renderValue={(selected) => {
                        if (!selected) {
                          return <em>İlçe Seçiniz</em>;
                        }
                        const secilenIlce = secilenSehirinIlceleri.find(
                          (ilce) => ilce._id === selected
                        );
                        return secilenIlce
                          ? secilenIlce.ilce_adi
                          : <em>İlçe Seçiniz</em>;
                      }}
                    >
                      <MenuItem disabled value=''>
                        <em>İlçe Seçiniz</em>
                      </MenuItem>
                      {secilenSehirinIlceleri.map((ilce) => (
                        <MenuItem key={ilce._id} value={ilce._id}>
                          {ilce.ilce_adi}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}

                <TextField
                  fullWidth
                  label='Telefon'
                  value={telefon}
                  onChange={handleTelefonChange}
                  error={telefonHata}
                  helperText={
                    telefonHata
                      ? 'Telefon numarası 05XX XXX XXXX formatında olmalıdır.'
                      : ''
                  }
                  margin='normal'
                  required
                  sx={{
                    input: { color: 'white' },
                    label: { color: 'white' },
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': { borderColor: 'white' },
                      '&:hover fieldset': {
                        borderColor: '#fff',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#fff',
                      },
                    },
                    '& .Mui-error': {
                      color: '#f44336',
                      '& fieldset': {
                        borderColor: '#f44336',
                      },
                      '& .MuiFormHelperText-root': {
                        color: '#f44336',
                        backgroundColor: 'white',
                        borderRadius: '4px',
                        padding: '2px',
                      },
                    },
                    '& .MuiFormHelperText-root.Mui-error': {
                      backgroundColor: 'white',
                    },
                  }}
                />

                <TextField
                  fullWidth
                  label='E-posta Adresi'
                  type='email'
                  variant='outlined'
                  value={email}
                  onChange={handleEmailChange} // Updated handler
                  error={emailHata} // Bind error state
                  helperText={
                    emailHata
                      ? 'Lütfen geçerli bir e-posta adresi giriniz.'
                      : ''
                  }
                  margin='normal'
                  required
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: 'white',
                      },
                      '&:hover fieldset': {
                        borderColor: '#fff',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#fff',
                      },
                    },
                    '& .MuiInputLabel-root': {
                      color: 'white',
                    },
                    '& .MuiInputBase-input': {
                      color: 'white',
                    },
                    '& .Mui-error': {
                      color: '#f44336',
                      '& fieldset': {
                        borderColor: '#f44336',
                      },
                      '& .MuiFormHelperText-root': {
                        color: '#f44336',
                        backgroundColor: 'white',
                        borderRadius: '4px',
                        padding: '2px',
                      },
                    },
                    '& .MuiFormHelperText-root.Mui-error': {
                      backgroundColor: 'white',
                    },
                  }}
                />

                <TextField
                  fullWidth
                  margin='normal'
                  label='Şifre'
                  type='password'
                  variant='outlined'
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: 'white',
                      },
                      '&:hover fieldset': {
                        borderColor: '#fff',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#fff',
                      },
                    },
                    '& .MuiInputLabel-root': {
                      color: 'white',
                    },
                    '& .MuiInputBase-input': {
                      color: 'white',
                    },
                  }}
                />
                <Button
                  type='submit'
                  variant='contained'
                  color='primary'
                  fullWidth
                  sx={{ mt: 2, py: 1.5 }}
                >
                  Kayıt Ol
                </Button>
              </form>
            </CardContent>
          </Card>
        </Container>
      </div>

      {loading && (
        <div
          style={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 9999,
          }}
        >
          <CircularProgress />
        </div>
      )}
      <ResultDialog
        open={dialogOpen}
        onClose={handleDialogClose} // Updated handler
        title={
          dialogSeverity === 'error'
            ? 'Bir Hata Oluştu!'
            : 'Başarılı bir şekilde kayıt oluşturdunuz!'
        }
        message={dialogContent}
        severity={dialogSeverity}
      />
      <Footer />
    </div>
  );
}

export default KurumsalRegisterForm;

// Message.js

import React from 'react';
import '../styles/KelimeTamamlamaOyunu.css';

const Message = ({ message, timeRemaining }) => {
  return (
    message && (
      <div className="kayu-message-display">
        <div
          className={`alert ${
            timeRemaining === 0 ? 'alert-danger' : 'alert-info'
          }`}
          role="alert"
        >
          {message}
        </div>
      </div>
    )
  );
};

export default Message;

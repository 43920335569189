// useGameLogic.js

import { useRef, useState, useCallback, useEffect } from 'react';

const CANVAS_WIDTH = 800;
const CANVAS_HEIGHT = 600;

export default function useGameLogic(userData) {
  const canvasRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [speed, setSpeed] = useState(1); // Default speed

  const [score, setScore] = useState(0);
  const [isGameOver, setIsGameOver] = useState(false);

  // Scaling factors from the first code
  const scalingFactors = [
    [0.2, 0.2],
    [0.4, 0.4],
    [0.6, 0.6],
    [0.8, 0.8],
    [1.0, 1.0],
    [1.2, 1.0],
    [1.4, 1.0],
    [1.6, 1.0],
    [1.8, 1.0],
    [2.0, 1.0],
    [2.2, 1.0],
    [2.4, 1.0],
    [2.6, 1.0],
    [2.8, 1.0],
  ];

  const [animationStep, setAnimationStep] = useState(0);

  const getInitialGameTime = () => {
    if (userData) {
      return userData.status === 'inactive' ? 15 : 60;
    }
    return 15;
  };

  const [initialGameTime, setInitialGameTime] = useState(getInitialGameTime());
  const [gameTime, setGameTime] = useState(initialGameTime);

  useEffect(() => {
    if (userData) {
      const newGameTime = userData.status === 'inactive' ? 15 : 60;
      setGameTime(newGameTime);
      setInitialGameTime(newGameTime); // Update initial game time
      setSpeed(1);
    } else {
      setGameTime(15);
      setInitialGameTime(15); // Update initial game time
      setSpeed(1);
    }
  }, [userData]);

  const updateLetters = useCallback(() => {
    const turkishAlphabet = [
      'A', 'B', 'C', 'Ç', 'D', 'E', 'F', 'G',
      'Ğ', 'H', 'I', 'İ', 'J', 'K', 'L', 'M',
      'N', 'O', 'Ö', 'P', 'R', 'S', 'Ş', 'T',
      'U', 'Ü', 'V', 'Y', 'Z',
    ];
    return Array.from({ length: 4 }, () =>
      turkishAlphabet[Math.floor(Math.random() * turkishAlphabet.length)]
    );
  }, []);

  const [letters, setLetters] = useState(updateLetters);

  // Game time countdown
  useEffect(() => {
    let gameTimeInterval;
    if (isPlaying && gameTime > 0) {
      gameTimeInterval = setInterval(() => {
        setGameTime((prevTime) => prevTime - 1);
      }, 1000);
    } else if (gameTime === 0) {
      setIsPlaying(false);
      setIsGameOver(true);
    }

    return () => clearInterval(gameTimeInterval);
  }, [isPlaying, gameTime]);

  // Update letters and animation step based on speed
  useEffect(() => {
    let lettersInterval;
    if (isPlaying && gameTime > 0) {
      const updateInterval = Math.max(600 / speed, 100);
      lettersInterval = setInterval(() => {
        setLetters(updateLetters());
        setScore((prevScore) => prevScore + 1);
        setAnimationStep((prevStep) => (prevStep + 1) % scalingFactors.length);
      }, updateInterval);
    }

    return () => clearInterval(lettersInterval);
  }, [isPlaying, speed, gameTime, updateLetters]);

  const drawDiamond = useCallback(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext('2d');
    const sideLength = Math.min(canvas.width, canvas.height) / 4; // Base side length
    const centerX = canvas.width / 2;
    const centerY = canvas.height / 2;

    const [scaleX, scaleY] = scalingFactors[animationStep];

    // Adjust side lengths based on scaling factors
    const scaledSideX = sideLength * scaleX;
    const scaledSideY = sideLength * scaleY;

    // Ensure the shape stays within canvas boundaries
    const maxSideX = canvas.width / 2 - 50;
    const maxSideY = canvas.height / 2 - 50;
    const adjustedSideX = Math.min(scaledSideX, maxSideX);
    const adjustedSideY = Math.min(scaledSideY, maxSideY);

    // Clear canvas
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    // Background gradient
    const gradient = ctx.createLinearGradient(0, 0, canvas.width, canvas.height);
    gradient.addColorStop(0, '#ffecd2'); // Light pink
    gradient.addColorStop(1, '#fcb69f'); // Coral tones
    ctx.fillStyle = gradient;
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    ctx.save();
    ctx.translate(centerX, centerY);

    ctx.lineWidth = 2;
    ctx.strokeStyle = '#ffffff'; // White border
    ctx.fillStyle = '#ffcc5c'; // Yellow fill
    ctx.shadowColor = 'rgba(0, 0, 0, 0.9)';
    ctx.shadowBlur = 0;

    const points = [
      { x: 0, y: -adjustedSideY },
      { x: adjustedSideX, y: 0 },
      { x: 0, y: adjustedSideY },
      { x: -adjustedSideX, y: 0 },
    ];

    // Draw the diamond shape
    ctx.beginPath();
    points.forEach((point, index) => {
      if (index === 0) {
        ctx.moveTo(point.x, point.y);
      } else {
        ctx.lineTo(point.x, point.y);
      }
    });
    ctx.closePath();
    ctx.fill();
    ctx.stroke();

    // Draw circles around letters
    points.forEach((point, index) => {
      const radius = 30; // Adjust as needed
      ctx.beginPath();
      ctx.arc(point.x, point.y, radius, 0, Math.PI * 2);
      ctx.fillStyle = '#fff';
      ctx.strokeStyle = '#ffffff';
      ctx.lineWidth = 1;
      ctx.fill();
      ctx.stroke();

      // Draw letters
      ctx.font = `28px "Comic Sans MS", cursive, sans-serif`;
      ctx.fillStyle = '#000';
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.fillText(letters[index], point.x, point.y);
    });

    ctx.restore();

    // Draw center black dot
    ctx.beginPath();
    ctx.arc(centerX, centerY, 15, 0, Math.PI * 2);
    ctx.fillStyle = '#000000';
    ctx.fill();
  }, [letters, animationStep, scalingFactors]);

  useEffect(() => {
    let animationFrameId;

    const render = () => {
      drawDiamond();
      animationFrameId = requestAnimationFrame(render);
    };

    if (isPlaying) {
      animationFrameId = requestAnimationFrame(render);
    }

    return () => cancelAnimationFrame(animationFrameId);
  }, [isPlaying, drawDiamond]);

  const resetGame = () => {
    setIsPlaying(false);
    setScore(0);
    setGameTime(initialGameTime);
    setLetters(updateLetters());
    setIsGameOver(false);
    setAnimationStep(0);
  };

  const setSpeedWithLimit = (newSpeed) => {
    setSpeed(Math.max(newSpeed, 0.01));
  };

  return {
    canvasRef,
    isPlaying,
    setIsPlaying,
    speed,
    setSpeed: setSpeedWithLimit,
    score,
    gameTime,
    initialGameTime,
    drawDiamond,
    resetGame,
    isGameOver,
    setIsGameOver,
  };
}

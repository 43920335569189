// src/components/PackagePromotion.js
import React, { useEffect, useState } from 'react';
import { Container, Typography, Grid, Card, CardContent, CardActions, Button, Box, useTheme,} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Link } from 'react-router-dom';
import { API_URLS } from '../../../config/config';

function PackagePromotion() {
  const theme = useTheme();
  const [packages, setPackages] = useState([]);

  useEffect(() => {
   
    fetch(`${API_URLS.HOME_URL}/paketler/fiyatlistesi`)
      .then((response) => response.json())
      .then((data) => {
        setPackages(data);
      })
      .catch((error) => {
        console.error('Paketler çekilirken hata oluştu:', error);
      });
  }, []);

  // Paket süresine göre farklı arka planlar ve tasarımlar
  const getCardStyles = (paketSuresi) => {
    switch (paketSuresi) {
      case 3:
        return {
          background: 'linear-gradient(135deg, #FFC371 0%, #FF5F6D 100%)',
        };
      case 6:
        return {
          background: 'linear-gradient(135deg, #0ED2F7 0%, #B2FEFA 100%)',
        };
      case 12:
        return {
          background: 'linear-gradient(135deg, #F9D423 0%, #FF4E50 100%)',
        };
      default:
        return {
          background: 'linear-gradient(135deg, #bdc3c7 0%, #2c3e50 100%)',
        };
    }
  };

  return (
    <Box sx={{ py: 8, backgroundColor: '#f0f2f5' }}>
      <Container maxWidth="lg">
        <Typography
          variant="h3"
          align="center"
          gutterBottom
          sx={{ fontWeight: 'bold', mb: 4, color: '#333' }}
        >
          Paketlerimiz
        </Typography>
        <Grid container spacing={4}>
          {packages.map((pkg) => {
            const { paketSuresi, basePrice } = pkg;
            const cardStyle = getCardStyles(paketSuresi);
            const features = [
              '21 Günlük Hızlı Okuma Eğitimi',
              `${paketSuresi} Ay Boyunca Aktif Hesap`,
            ];

            return (
              <Grid item xs={12} sm={6} md={4} key={paketSuresi}>
                <Card
                  elevation={6}
                  sx={{
                    borderRadius: 3,
                    ...cardStyle,
                    color: '#fff',
                    transition: 'transform 0.3s, box-shadow 0.3s',
                    '&:hover': {
                      transform: 'scale(1.05)',
                      boxShadow: '-3px 8px 23px -2px rgba(0,0,0,0.75)',
                    },
                    position: 'relative',
                    overflow: 'hidden',
                  }}
                >
                  {/* Dekoratif bir üst şerit veya simge ekleyebiliriz */}
                  <Box
                    sx={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '10px',
                      backgroundColor: 'rgba(255,255,255,0.3)',
                    }}
                  ></Box>

                  <CardContent>
                    <Typography
                      variant="h4"
                      align="center"
                      gutterBottom
                      sx={{
                        fontWeight: 'bold',
                        color: '#fff',
                        textShadow: '1px 1px 2px rgba(0,0,0,0.3)',
                      }}
                    >
                      {paketSuresi} Ay
                    </Typography>
                    <Typography
                      variant="h5"
                      align="center"
                      gutterBottom
                      sx={{
                        fontWeight: 'bold',
                        color: '#fff',
                        textShadow: '1px 1px 2px rgba(0,0,0,0.3)',
                      }}
                    >
                      {basePrice} TL
                    </Typography>
                    <Box sx={{ mt: 2 }}>
                      {features.map((feature, index) => (
                        <Box
                          key={index}
                          sx={{ display: 'flex', alignItems: 'center', mb: 1 }}
                        >
                          <CheckCircleIcon
                            sx={{
                              color: '#fff',
                              mr: 1,
                              textShadow: '1px 1px 2px rgba(0,0,0,0.3)',
                            }}
                          />
                          <Typography
                            variant="body1"
                            sx={{
                              color: '#fff',
                              textShadow: '1px 1px 2px rgba(0,0,0,0.3)',
                            }}
                          >
                            {feature}
                          </Typography>
                        </Box>
                      ))}
                    </Box>
                  </CardContent>
                  <CardActions>
                    <Button
                      component={Link}
                      to="/ogrenci-kayit"
                      variant="contained"
                      color="secondary"
                      fullWidth
                      sx={{
                        borderRadius: 0,
                        fontWeight: 'bold',
                        py: 1.5,
                        background: 'rgba(0,0,0,0.2)',
                        '&:hover': {
                          background: 'rgba(0,0,0,0.3)',
                        },
                      }}
                    >
                      Satın Al
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      </Container>
    </Box>
  );
}

export default PackagePromotion;

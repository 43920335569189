import React from 'react';
import { Route, Routes } from 'react-router-dom';
import RasgeleTamMetinOyunu from '../pages/HizliOkuma/SayfaTaramaOne/GameSetup';
import RasgeleKelimelerOyunu from '../pages/HizliOkuma/RasgeleKelime/GameSetup';
import KayanYazi from '../pages/HizliOkuma/KayanYazi/GameSetup';
import KayanYazilarIkiserOyunu from '../pages/HizliOkuma/KayanYaziSecond/GameSetup';
import KayanYazilarUcerOyunu from '../pages/HizliOkuma/KayanYaziThree/GameSetup';
import KelimeBulmaVeSecmeOyunu from '../pages/HizliOkuma/KelimeBulmaveSecme/GameSetup';
import HarfIzgarasiOyunu from '../pages/HizliOkuma/HarfIzgaraOyun/GameSetup';
import KelimeTamamlamaOyunu from '../pages/HizliOkuma/KelimeTamamlama/GameSetup';
import TachistoscopicReadingGame from '../pages/HizliOkuma/TachistoscopicReadingGame/TachistoscopicReadingGame';




function HizliOkuRouter() {
    return (
      <Routes>     
      <Route path="/sayfa-tarama" element={<RasgeleTamMetinOyunu />} />
      <Route path="/rastgele-kelime" element={<RasgeleKelimelerOyunu />} />
      <Route path="/kayan-yazilar" element={<KayanYazi />} />
      <Route path="/kayan-yazilar-ikiser" element={<KayanYazilarIkiserOyunu />} />
      <Route path="/kayan-yazilar-ucer" element={<KayanYazilarUcerOyunu />} />
      <Route path="/kelime-secme-ve-bulma-oyunu" element={<KelimeBulmaVeSecmeOyunu />} />
      <Route path="/kelime-tamamlama-oyunu" element={<KelimeTamamlamaOyunu />} />   
      <Route path="/harf-izgara-oyunu" element={<HarfIzgarasiOyunu />} />     
      <Route path="/takistoskopik-okuma-oyunu" element={<TachistoscopicReadingGame />} />        
      </Routes>
    );
  }
  
  export default HizliOkuRouter;
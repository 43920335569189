import { useEffect } from 'react';
import { API_URLS } from '../../../../config/config';

const useFetchSehirler = (setSehirListesi, shouldFetch, setShouldFetch) => {
  useEffect(() => {
    const fetchSehirler = async () => {
      if (!shouldFetch) return;

      try {
        const response = await fetch(API_URLS.ADMIN_URL + 'sehirler');
        if (!response.ok) {
          throw new Error('Veri çekme işlemi başarısız');
        }
        const sehirler = await response.json();
        setSehirListesi(sehirler);
      } catch (err) {
        console.error('Şehirler yüklenirken bir hata oluştu:', err.message);
      } finally {
        setShouldFetch(false);
      }
    };

    fetchSehirler();
  }, [shouldFetch, setSehirListesi, setShouldFetch]);
};

export default useFetchSehirler;

// GameArea.js

import React from 'react';
import '../styles/tachistoscopicReadingStyle.css';

const GameArea = ({
  phase,
  showingTime,
  writingTime,
  totalShowingTime,
  totalWritingTime,
  isPlaying,
  currentWord,
  input,
  handleInputChange,
  handleKeyPress,
  enteredWords,
  handleSubmit,
  isFinished,
}) => {
  const showingProgress = ((showingTime / totalShowingTime) * 100).toFixed(0);
  const writingProgress = ((writingTime / totalWritingTime) * 100).toFixed(0);

  return (
    <>
      <div className="tachisto-status">
        {phase === 'showing' ? (
          <>
            <p style={{ fontSize: '20px', fontWeight: 'bold', color: '#ffcc00' }}>
              Kelimeler Gösteriliyor: {Math.ceil(showingTime)} sn
            </p>
            <div className="progress-bar">
              <div
                className="progress-fill"
                style={{ width: `${(showingProgress <= 0 ? 0 : showingProgress)}%` }}
              ></div>
            </div>
          </>
        ) : (
          <>
            <p style={{ fontSize: '20px', fontWeight: 'bold', color: '#ffcc00' }}>
              Yazma Süresi: {writingTime} sn
            </p>
            <div className="progress-bar">
              <div
                className="progress-fill"
                style={{ width: `${(writingProgress <= 0 ? 0 : writingProgress)}%` }}
              ></div>
            </div>
          </>
        )}
      </div>
      {isPlaying && phase === 'showing' && (
        <div className="tachisto-word-display">{currentWord}</div>
      )}
      {phase === 'writing' && (
        <div className="tachisto-input-container">
          <input
            type="text"
            value={input}
            onChange={handleInputChange}
            onKeyDown={handleKeyPress}
            placeholder="Hatırladığınız kelimeleri girin ve boşluk bırakın"
            className="tachisto-input"
            disabled={isFinished || writingTime === 0}
          />
        </div>
      )}
      {phase === 'writing' && (
        <div className="tachisto-entered-words">
          <h5 className="tachisto-heading">Girilen Kelimeler:</h5>
          <p>{enteredWords.join(', ')}</p>
        </div>
      )}
      {phase === 'writing' && (
        <div className="tachisto-game-controls">
          <button className="tachisto-button" onClick={handleSubmit}>
            Sonucu Göster
          </button>
        </div>
      )}
    </>
  );
};

export default GameArea;

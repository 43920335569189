import React, { useState } from 'react';
import { Typography, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper, Chip, IconButton, Button, Stack, Grid, Menu, MenuItem,} from '@mui/material';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import * as XLSX from 'xlsx';

const OgretmenListesi = ({
  ogretmenListesi,
  handleStatusChange,
  handleEdit,
  handleDeleteOgretmen,
  handleAdjustBalance,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleExportClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleExportClose = () => {
    setAnchorEl(null);
  };

  // CSV indir
  const exportToCSV = () => {
    if (ogretmenListesi.length === 0) return;

    const headers = [
      'Sıra No',
      'Ekleyen',
      'Adı',
      'Soyadı',
      'Şifre',
      'Telefon',
      'Okul',
      'E-posta',
      'Şehir',
      'İlçe',
      'Bakiye (Toplam)',
      'Bakiye 3 Ay',
      'Bakiye 6 Ay',
      'Bakiye 12 Ay',
      'Abonelik Başlangıç',
      'Abonelik Bitiş',
      'Durum'
    ];

    const rows = ogretmenListesi.map((ogretmen, index) => {
      const toplamBakiye =
        (ogretmen.bakiye3Ay || 0) + (ogretmen.bakiye6Ay || 0) + (ogretmen.bakiye12Ay || 0);

      const ekleyen =
        ogretmen.adminAdi && ogretmen.adminAdi !== 'Bulunamadı' && ogretmen.adminAdi !== 'null'
          ? ogretmen.adminAdi
          : ogretmen.okulAdi && ogretmen.okulAdi !== 'Bulunamadı' && ogretmen.okulAdi !== 'null'
          ? ogretmen.okulAdi
          : ogretmen.kurumAdi && ogretmen.kurumAdi !== 'Bulunamadı' && ogretmen.kurumAdi !== 'null'
          ? ogretmen.kurumAdi
          : 'Kendi';

      // En son aboneliği bulma
      let latestSubscription = null;
      if (ogretmen.abonelikler && ogretmen.abonelikler.length > 0) {
        latestSubscription = ogretmen.abonelikler.reduce((latest, current) => {
          return new Date(current.baslangicTarihi) > new Date(latest.baslangicTarihi) ? current : latest;
        }, ogretmen.abonelikler[0]);
      }

      const abonelikBaslangic = latestSubscription
        ? new Date(latestSubscription.baslangicTarihi).toLocaleDateString()
        : 'Yok';

      const abonelikBitis = latestSubscription
        ? new Date(latestSubscription.bitisTarihi).toLocaleDateString()
        : 'Yok';

      return [
        index + 1,
        ekleyen,
        ogretmen.ad,
        ogretmen.soyad,
        ogretmen.password_open || '',
        ogretmen.telefon,
        ogretmen.okul_adi || '',
        ogretmen.email,
        ogretmen.sehirAdi || '',
        ogretmen.ilceAdi || '',
        toplamBakiye,
        ogretmen.bakiye3Ay || 0,
        ogretmen.bakiye6Ay || 0,
        ogretmen.bakiye12Ay || 0,
        abonelikBaslangic,
        abonelikBitis,
        ogretmen.status === 'active' ? 'Aktif' : 'Pasif',
      ];
    });

    const csvContent =
      'data:text/csv;charset=utf-8,' +
      [headers.join(','), ...rows.map((r) => r.join(','))].join('\n');

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.href = encodedUri;
    link.download = 'ogretmen_listesi.csv';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    handleExportClose();
  };

  // TXT indir
  const exportToTXT = () => {
    if (ogretmenListesi.length === 0) return;

    let txtContent = '';
    txtContent +=
      'Sıra No | Ekleyen | Adı | Soyadı | Şifre | Telefon | Okul | E-posta | Şehir | İlçe | Bakiye(Toplam) | Bakiye 3 Ay | Bakiye 6 Ay | Bakiye 12 Ay | Abonelik Başlangıç | Abonelik Bitiş | Durum\n';

    ogretmenListesi.forEach((ogretmen, index) => {
      const toplamBakiye =
        (ogretmen.bakiye3Ay || 0) + (ogretmen.bakiye6Ay || 0) + (ogretmen.bakiye12Ay || 0);

      const ekleyen =
        ogretmen.adminAdi && ogretmen.adminAdi !== 'Bulunamadı' && ogretmen.adminAdi !== 'null'
          ? ogretmen.adminAdi
          : ogretmen.okulAdi && ogretmen.okulAdi !== 'Bulunamadı' && ogretmen.okulAdi !== 'null'
          ? ogretmen.okulAdi
          : ogretmen.kurumAdi && ogretmen.kurumAdi !== 'Bulunamadı' && ogretmen.kurumAdi !== 'null'
          ? ogretmen.kurumAdi
          : 'Kendi';

      let latestSubscription = null;
      if (ogretmen.abonelikler && ogretmen.abonelikler.length > 0) {
        latestSubscription = ogretmen.abonelikler.reduce((latest, current) => {
          return new Date(current.baslangicTarihi) > new Date(latest.baslangicTarihi) ? current : latest;
        }, ogretmen.abonelikler[0]);
      }

      const abonelikBaslangic = latestSubscription
        ? new Date(latestSubscription.baslangicTarihi).toLocaleDateString()
        : 'Yok';

      const abonelikBitis = latestSubscription
        ? new Date(latestSubscription.bitisTarihi).toLocaleDateString()
        : 'Yok';

      txtContent += `${index + 1} | ${ekleyen} | ${ogretmen.ad} | ${ogretmen.soyad} | ${ogretmen.password_open || ''} | ${ogretmen.telefon} | ${ogretmen.okul_adi || ''} | ${ogretmen.email} | ${ogretmen.sehirAdi || ''} | ${ogretmen.ilceAdi || ''} | ${toplamBakiye} | ${ogretmen.bakiye3Ay || 0} | ${ogretmen.bakiye6Ay || 0} | ${ogretmen.bakiye12Ay || 0} | ${abonelikBaslangic} | ${abonelikBitis} | ${ogretmen.status === 'active' ? 'Aktif' : 'Pasif'}\n`;
    });

    const blob = new Blob([txtContent], { type: 'text/plain;charset=utf-8' });
    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.download = 'ogretmen_listesi.txt';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    handleExportClose();
  };

  // XLSX indir
  const exportToXLSX = () => {
    if (ogretmenListesi.length === 0) return;

    const headers = [
      'Sıra No',
      'Ekleyen',
      'Adı',
      'Soyadı',
      'Şifre',
      'Telefon',
      'Okul',
      'E-posta',
      'Şehir',
      'İlçe',
      'Bakiye (Toplam)',
      'Bakiye 3 Ay',
      'Bakiye 6 Ay',
      'Bakiye 12 Ay',
      'Abonelik Başlangıç',
      'Abonelik Bitiş',
      'Durum'
    ];

    const wsData = [];
    wsData.push(headers);

    ogretmenListesi.forEach((ogretmen, index) => {
      const toplamBakiye =
        (ogretmen.bakiye3Ay || 0) + (ogretmen.bakiye6Ay || 0) + (ogretmen.bakiye12Ay || 0);

      const ekleyen =
        ogretmen.adminAdi && ogretmen.adminAdi !== 'Bulunamadı' && ogretmen.adminAdi !== 'null'
          ? ogretmen.adminAdi
          : ogretmen.okulAdi && ogretmen.okulAdi !== 'Bulunamadı' && ogretmen.okulAdi !== 'null'
          ? ogretmen.okulAdi
          : ogretmen.kurumAdi && ogretmen.kurumAdi !== 'Bulunamadı' && ogretmen.kurumAdi !== 'null'
          ? ogretmen.kurumAdi
          : 'Kendi';

      let latestSubscription = null;
      if (ogretmen.abonelikler && ogretmen.abonelikler.length > 0) {
        latestSubscription = ogretmen.abonelikler.reduce((latest, current) => {
          return new Date(current.baslangicTarihi) > new Date(latest.baslangicTarihi) ? current : latest;
        }, ogretmen.abonelikler[0]);
      }

      const abonelikBaslangic = latestSubscription
        ? new Date(latestSubscription.baslangicTarihi).toLocaleDateString()
        : 'Yok';

      const abonelikBitis = latestSubscription
        ? new Date(latestSubscription.bitisTarihi).toLocaleDateString()
        : 'Yok';

      const row = [
        index + 1,
        ekleyen,
        ogretmen.ad,
        ogretmen.soyad,
        ogretmen.password_open || '',
        ogretmen.telefon,
        ogretmen.okul_adi || '',
        ogretmen.email,
        ogretmen.sehirAdi || '',
        ogretmen.ilceAdi || '',
        toplamBakiye,
        ogretmen.bakiye3Ay || 0,
        ogretmen.bakiye6Ay || 0,
        ogretmen.bakiye12Ay || 0,
        abonelikBaslangic,
        abonelikBitis,
        ogretmen.status === 'active' ? 'Aktif' : 'Pasif',
      ];
      wsData.push(row);
    });

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.aoa_to_sheet(wsData);
    XLSX.utils.book_append_sheet(wb, ws, 'Ogretmen Listesi');

    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([wbout], { type: 'application/octet-stream' });

    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'ogretmen_listesi.xlsx';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    handleExportClose();
  };

  return (
    <>
      <Grid container justifyContent="space-between" alignItems="center" sx={{ mb: 2, mt: 2 }}>

        <Grid item>
          <Button variant="outlined" onClick={handleExportClick}>
            Dışa Aktar
          </Button>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleExportClose}
          >
            <MenuItem onClick={exportToCSV}>CSV Olarak İndir</MenuItem>
            <MenuItem onClick={exportToTXT}>TXT Olarak İndir</MenuItem>
            <MenuItem onClick={exportToXLSX}>Excel (XLSX) Olarak İndir</MenuItem>
          </Menu>
        </Grid>
      </Grid>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow
              sx={{
                backgroundColor: 'primary.main',
              }}
            >
              <TableCell>
                <Typography variant="subtitle1" sx={{ color: 'white', fontWeight: 'bold' }}>
                  Sıra No
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle1" sx={{ color: 'white', fontWeight: 'bold' }}>
                  Ekleyen
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle1" sx={{ color: 'white', fontWeight: 'bold' }}>
                  Adı
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle1" sx={{ color: 'white', fontWeight: 'bold' }}>
                  Soyadı
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Typography variant="subtitle1" sx={{ color: 'white', fontWeight: 'bold' }}>
                  Şifre
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Typography variant="subtitle1" sx={{ color: 'white', fontWeight: 'bold' }}>
                  Telefon
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle1" sx={{ color: 'white', fontWeight: 'bold' }}>
                  Okul
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Typography variant="subtitle1" sx={{ color: 'white', fontWeight: 'bold' }}>
                  E-posta
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Typography variant="subtitle1" sx={{ color: 'white', fontWeight: 'bold' }}>
                  Şehir
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Typography variant="subtitle1" sx={{ color: 'white', fontWeight: 'bold' }}>
                  İlçe
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Typography variant="subtitle1" sx={{ color: 'white', fontWeight: 'bold' }}>
                  Bakiye (Toplam)
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Typography variant="subtitle1" sx={{ color: 'white', fontWeight: 'bold' }}>
                  Bakiye 3 Ay
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Typography variant="subtitle1" sx={{ color: 'white', fontWeight: 'bold' }}>
                  Bakiye 6 Ay
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Typography variant="subtitle1" sx={{ color: 'white', fontWeight: 'bold' }}>
                  Bakiye 12 Ay
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Typography variant="subtitle1" sx={{ color: 'white', fontWeight: 'bold' }}>
                  Abonelik Başlangıç
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Typography variant="subtitle1" sx={{ color: 'white', fontWeight: 'bold' }}>
                  Abonelik Bitiş
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Typography variant="subtitle1" sx={{ color: 'white', fontWeight: 'bold' }}>
                  Durum
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Typography variant="subtitle1" sx={{ color: 'white', fontWeight: 'bold' }}>
                  İşlemler
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {ogretmenListesi.map((ogretmen, index) => {
              const toplamBakiye =
                (ogretmen.bakiye3Ay || 0) + (ogretmen.bakiye6Ay || 0) + (ogretmen.bakiye12Ay || 0);

              const ekleyen =
                ogretmen.adminAdi && ogretmen.adminAdi !== 'Bulunamadı' && ogretmen.adminAdi !== 'null'
                  ? ogretmen.adminAdi
                  : ogretmen.okulAdi && ogretmen.okulAdi !== 'Bulunamadı' && ogretmen.okulAdi !== 'null'
                  ? ogretmen.okulAdi
                  : ogretmen.kurumAdi && ogretmen.kurumAdi !== 'Bulunamadı' && ogretmen.kurumAdi !== 'null'
                  ? ogretmen.kurumAdi
                  : 'Kendi';

              let latestSubscription = null;
              if (ogretmen.abonelikler && ogretmen.abonelikler.length > 0) {
                latestSubscription = ogretmen.abonelikler.reduce((latest, current) => {
                  return new Date(current.baslangicTarihi) > new Date(latest.baslangicTarihi) ? current : latest;
                }, ogretmen.abonelikler[0]);
              }

              const abonelikBaslangic = latestSubscription
                ? new Date(latestSubscription.baslangicTarihi).toLocaleDateString()
                : 'Yok';

              const abonelikBitis = latestSubscription
                ? new Date(latestSubscription.bitisTarihi).toLocaleDateString()
                : 'Yok';

              return (
                <TableRow key={ogretmen._id}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{ekleyen}</TableCell>
                  <TableCell>{ogretmen.ad}</TableCell>
                  <TableCell>{ogretmen.soyad}</TableCell>
                  <TableCell align="right">{ogretmen.password_open}</TableCell>
                  <TableCell align="right">{ogretmen.telefon}</TableCell>
                  <TableCell>{ogretmen.okul_adi}</TableCell>
                  <TableCell align="right">{ogretmen.email}</TableCell>
                  <TableCell align="right">{ogretmen.sehirAdi}</TableCell>
                  <TableCell align="right">{ogretmen.ilceAdi}</TableCell>
                  <TableCell align="right">{toplamBakiye}</TableCell>
                  <TableCell align="right">{ogretmen.bakiye3Ay || 0}</TableCell>
                  <TableCell align="right">{ogretmen.bakiye6Ay || 0}</TableCell>
                  <TableCell align="right">{ogretmen.bakiye12Ay || 0}</TableCell>
                  <TableCell align="right">{abonelikBaslangic}</TableCell>
                  <TableCell align="right">{abonelikBitis}</TableCell>

                  <TableCell align="right">
                    <Chip
                      label={ogretmen.status === 'active' ? 'Aktif' : 'Pasif'}
                      color={ogretmen.status === 'active' ? 'success' : 'error'}
                      onClick={() => handleStatusChange(ogretmen._id, ogretmen.status)}
                      sx={{
                        cursor: 'pointer',
                        '&:hover': {
                          backgroundColor: 'lightgray',
                        },
                      }}
                    />
                  </TableCell>
                  <TableCell align="right">
                    <Stack direction="row" spacing={1}>
                      <IconButton
                        onClick={() => handleAdjustBalance(ogretmen)}
                        color="success"
                        size="small"
                        sx={{
                          backgroundColor: '#4caf50',
                          color: '#fff',
                          '&:hover': {
                            backgroundColor: '#388e3c',
                            transform: 'scale(1.1)',
                            transition: 'transform 0.2s',
                          },
                          boxShadow: 'none',
                          borderRadius: '8px',
                          padding: '8px',
                        }}
                      >
                        <AttachMoneyIcon />
                      </IconButton>
                      <Button
                        variant="contained"
                        color="primary"
                        startIcon={<EditIcon />}
                        onClick={() => handleEdit(ogretmen._id)}
                      >
                        Düzenle
                      </Button>
                      <Button
                        variant="contained"
                        color="secondary"
                        startIcon={<DeleteIcon />}
                        onClick={() => handleDeleteOgretmen(ogretmen._id)}
                      >
                        Sil
                      </Button>
                    </Stack>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default OgretmenListesi;

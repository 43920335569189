// Instructions.js

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from '@fortawesome/free-solid-svg-icons';
import '../styles/tachistoscopicReadingStyle.css';

const Instructions = ({ startGame }) => {
  return (
    <div className="tachisto-intro-container">
      <div className="tachisto-card">
        <div className="tachisto-card-body">
          <h1 className="tachisto-title">Takistoskopik Okuma Çalışması</h1>
          <p className="tachisto-description">
            Takistoskopik Okuma Çalışması, kelimeleri kısa bir süre gösterip ardından hatırladığınız
            kelimeleri yazmanızı sağlar. Bu çalışma, hızlı okuma ve hafıza geliştirme becerilerinizi
            artırmak için tasarlanmıştır.
          </p>
          <h3 className="tachisto-instructions-title">Nasıl Oynanır?</h3>
          <ul className="tachisto-instructions-list">
            <li>
              <strong>1. Zorluk Seviyesi Seçin:</strong> Kolay, Orta veya Zor seviyelerinden birini
              seçebilirsiniz.
            </li>
            <li>
              <strong>2. Çalışmayı Başlatın:</strong> "Başlat" butonuna tıklayarak çalışmayı başlatın.
            </li>
            <li>
              <strong>3. Kelimeleri İzleyin:</strong> Ekranda beliren kelimeleri dikkatlice takip
              edin.
            </li>
            <li>
              <strong>4. Kelimeleri Yazın:</strong> Gösterim süresi bittikten sonra hatırladığınız
              kelimeleri giriş alanına yazın.
            </li>
            <li>
              <strong>5. Sonucu Görün:</strong> "Sonucu Göster" butonuna tıklayarak skorunuzu
              öğrenin.
            </li>
          </ul>
          <button className="tachisto-button" onClick={startGame}>
            <FontAwesomeIcon icon={faPlay} /> BAŞLAT
          </button>
        </div>
      </div>
    </div>
  );
};

export default Instructions;

// components/Instructions.js

import React from 'react';

const Instructions = ({ onStart }) => {
  return (
    <div className="okuma-seviye-testi-instructions">
      <h2>Sınava Hoş Geldiniz</h2>
      <p>
        Bu sınav, okuma hızınızı ölçmek için tasarlanmıştır. Karşınıza çıkacak metni mümkün olan en kısa sürede okuyun. "Sınavı Başlat" butonuna bastığınızda zamanlayıcı başlayacak ve metin görünecektir. Okumayı bitirdiğinizde "Okumayı Bitirdim" butonuna basarak sonucu görebilirsiniz.
      </p>
      <button className="okuma-seviye-testi-button" onClick={onStart}>
        Sınavı Başlat
      </button>
    </div>
  );
};

export default Instructions;

// src/dashboard/okul/Dashboard.js

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme, useMediaQuery } from '@mui/material';
import { Toolbar, IconButton, Typography, List, ListItem, ListItemIcon, ListItemText, CssBaseline, Collapse, Divider, Grid, Box, CardContent, Card,} from '@mui/material';
import { Menu as MenuIcon, Dashboard as DashboardIcon, Payment as PaymentIcon, Logout as LogoutIcon, ExpandLess, ExpandMore, School as SchoolIcon, Settings as SettingsIcon,
  LockReset as LockResetIcon, AccountCircle as AccountCircleIcon, LibraryBooks as LibraryBooksIcon, AddBox as AddBoxIcon, FormatListBulleted as FormatListBulletedIcon,
  Assignment as AssignmentIcon, Group as StudentsIcon, Person as TeacherIcon, ContactPhone as ContactPhoneIcon, LocationCity as LocationCityIcon,
} from '@mui/icons-material';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import { Main, AppBarStyled, DrawerStyled, ListItemStyled, ListItemTextStyled, NestedListItem, ProfileCard, AvatarStyled,} from './styles/SchoolDashboardStyles';
import { API_URLS } from '../../config/config';
import useFetchUserData from '../../hooks/okul/useFetchUserData';
import BookUploadForm from './ogretmen/kutuphane/Add';
import BookListe from './ogretmen/kutuphane/Liste';
import Tests from './ogretmen/kutuphane/Test';
import Ogretmen from './ogretmen/Ogretmen';
import Ogrenci from './ogrenci/Ogrenci';
import Password from './ayarlar/Password';
import Profil from './ayarlar/Profil';
import PaymentForm from './payment/PaymentForm';
import PaymentHistory from './payment/PaymentHistory';
import { motion } from 'framer-motion';

const containerVariants = {
  hidden: { opacity: 0, x: 50 },
  visible: { opacity: 1, x: 0 },
  exit: { opacity: 0, x: -50 },
};

function SchoolDashboard() {
  const [drawerOpen, setDrawerOpen] = useState(true);
  const [userData, setUserData] = useState(null);
  const [open, setOpen] = useState({});
  const [selectedMenu, setSelectedMenu] = useState('Anasayfa');
  const [studentCount, setStudentCount] = useState(0); // Öğrenci sayısı için state eklendi
  const [teacherCount, setTeacherCount] = useState(0); // Öğretmen sayısı için state eklendi
  const navigate = useNavigate();

  useFetchUserData(setUserData);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleClick = (menu) => {
    setOpen((prevOpen) => ({ ...prevOpen, [menu]: !prevOpen[menu] }));
  };

  const handleLogout = async () => {
    try {
      await fetch(API_URLS.SCHOOL_URL + 'logout', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`,
          'Content-Type': 'application/json',
        },
      });
      localStorage.removeItem('userToken');
      navigate('/okul-giris');
    } catch (error) {
      console.error('Çıkış işlemi sırasında bir hata oluştu', error);
    }
  };

  // Öğrenci ve öğretmen sayısını fetch eden useEffect hook'u eklendi
  useEffect(() => {
    const fetchCounts = async () => {
      try {
        // Öğrenci sayısını fetch et
        const studentResponse = await fetch(`${API_URLS.SCHOOL_URL}rapor/student-count`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('userToken')}`,
          },
        });
        if (studentResponse.ok) {
          const studentData = await studentResponse.json();
          setStudentCount(studentData.studentCount);
        } else {
          console.error('Öğrenci sayısı alınamadı.');
        }

        // Öğretmen sayısını fetch et
        const teacherResponse = await fetch(`${API_URLS.SCHOOL_URL}rapor/teacher-count`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('userToken')}`,
          },
        });
        if (teacherResponse.ok) {
          const teacherData = await teacherResponse.json();
          setTeacherCount(teacherData.teacherCount);
        } else {
          console.error('Öğretmen sayısı alınamadı.');
        }
      } catch (error) {
        console.error('Sayıları fetch ederken hata:', error);
      }
    };

    fetchCounts();
  }, []);

  const menuItems = [
    { text: 'Anasayfa', icon: <DashboardIcon />, action: () => setSelectedMenu('Anasayfa') },
    { text: 'Bakiye Yükle', icon: <PaymentIcon />, action: () => setSelectedMenu('BakiyeYukle') },
    { text: 'Satın Alınan Paketler', icon: <PaymentIcon />, action: () => setSelectedMenu('PaymentHistory') },
    { text: 'Öğretmenler', icon: <TeacherIcon />, action: () => setSelectedMenu('Öğretmenler') },
    { text: 'Öğrenciler', icon: <StudentsIcon />, action: () => setSelectedMenu('Öğrenciler') },
    {
      text: 'Kütüphane',
      icon: <LibraryBooksIcon />,
      subMenus: [
        { text: 'Kitap Ekle', icon: <AddBoxIcon />, action: () => setSelectedMenu('KitapEkle') },
        { text: 'Tüm Liste', icon: <FormatListBulletedIcon />, action: () => setSelectedMenu('KitapListe') },
        { text: 'Testler', icon: <AssignmentIcon />, action: () => setSelectedMenu('Testler') },
      ],
      action: () => handleClick('Kütüphane'),
    },
    {
      text: 'Ayarlar',
      icon: <SettingsIcon />,
      subMenus: [
        { text: 'Şifre Güncelle', icon: <LockResetIcon />, action: () => setSelectedMenu('SifreSet') },
        { text: 'Profil', icon: <AccountCircleIcon />, action: () => setSelectedMenu('Profil') },
      ],
      action: () => handleClick('Ayarlar'),
    },
    { text: 'Çıkış Yap', icon: <LogoutIcon />, action: handleLogout },
  ];

  const renderContent = () => {
    if (!userData) {
      return <Typography paragraph>Yükleniyor...</Typography>;
    }

    switch (selectedMenu) {
      case 'BakiyeYukle':
        return <PaymentForm userType="School" userId={userData._id} />;
      case 'PaymentHistory':
        return <PaymentHistory />;
      case 'Öğretmenler':
        return <Ogretmen />;
      case 'Öğrenciler':
        return <Ogrenci />;
      case 'KitapEkle':
        return <BookUploadForm />;
      case 'KitapListe':
        return <BookListe />;
      case 'Testler':
        return <Tests />;
      case 'SifreSet':
        return <Password />;
      case 'Profil':
        return <Profil />;
      default:
        return (
          <Box sx={{ padding: 2 }}>
            <ProfileCard
              component={motion.div}
              variants={containerVariants}
              initial="hidden"
              animate="visible"
              exit="exit"
              transition={{ duration: 0.5 }}
            >
              <AvatarStyled>
                <SchoolIcon fontSize="large" />
              </AvatarStyled>
              <Typography
                variant="h5"
                gutterBottom
                sx={{ fontWeight: 'bold', fontSize: { xs: '1.5rem', sm: '2rem' } }}
              >
                {userData.okul_adi}
              </Typography>
              <Typography
                variant="body1"
                color="textSecondary"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: 1,
                  mt: 1,
                }}
              >
                <ContactPhoneIcon /> {userData.telefon}
              </Typography>
              <Typography
                variant="body1"
                color="textSecondary"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: 1,
                  mt: 1,
                }}
              >
                <LocationCityIcon /> {userData.sehirAdi} / {userData.ilceAdi}
              </Typography>
              <Typography
                variant="body1"
                color="textSecondary"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: 1,
                  mt: 1,
                }}
              >
                <AccountBalanceWalletIcon /> Bakiye: {userData.bakiye} 
              </Typography>
              <Typography
                variant="body1"
                color="textSecondary"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: 1,
                  mt: 1,
                }}
              >
                Hesap Durumu: {userData.status === 'active' ? 'Aktif' : 'Pasif'}
              </Typography>
              {userData.abonelik && (
                <Box sx={{ mt: 2 }}>
                  <Typography
                    variant="body1"
                    color="textSecondary"
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      gap: 1,
                    }}
                  >
                    Abonelik Başlangıç Tarihi:{' '}
                    {new Date(userData.abonelik.baslangicTarihi).toLocaleDateString()}
                  </Typography>
                  <Typography
                    variant="body1"
                    color="textSecondary"
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      gap: 1,
                      mt: 1,
                    }}
                  >
                    Abonelik Bitiş Tarihi:{' '}
                    {new Date(userData.abonelik.bitisTarihi).toLocaleDateString()}
                  </Typography>
                </Box>
              )}
            </ProfileCard>

            {/* 3-6-12 Aylık Paketlerin Bakiyeleri ve Diğer Kartlar */}
            <Grid container spacing={2} sx={{ mt: 2 }}>
              {/* 3 Aylık Bakiye */}
              <Grid item xs={12} sm={6} md={4}>
                <Card sx={{ backgroundColor: '#e3f2fd' }}>
                  <CardContent>
                    <Typography variant="h6" gutterBottom>
                      3 Aylık Bakiye
                    </Typography>
                    <Typography variant="h4" color="primary">
                      {userData.bakiye3Ay} 
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>

              {/* 6 Aylık Bakiye */}
              <Grid item xs={12} sm={6} md={4}>
                <Card sx={{ backgroundColor: '#e8f5e9' }}>
                  <CardContent>
                    <Typography variant="h6" gutterBottom>
                      6 Aylık Bakiye
                    </Typography>
                    <Typography variant="h4" color="success.main">
                      {userData.bakiye6Ay} 
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>

              {/* 12 Aylık Bakiye */}
              <Grid item xs={12} sm={6} md={4}>
                <Card sx={{ backgroundColor: '#fff3e0' }}>
                  <CardContent>
                    <Typography variant="h6" gutterBottom>
                      12 Aylık Bakiye
                    </Typography>
                    <Typography variant="h4" color="warning.main">
                      {userData.bakiye12Ay} 
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>

              {/* Öğrenci Sayısı */}
              <Grid item xs={12} sm={6} md={6}>
                <Card sx={{ backgroundColor: '#fce4ec' }}>
                  <CardContent>
                    <Typography variant="h6" gutterBottom>
                      Toplam Öğrenci Sayısı
                    </Typography>
                    <Typography variant="h4" color="error.main">
                      {studentCount}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>

              {/* Öğretmen Sayısı */}
              <Grid item xs={12} sm={6} md={6}>
                <Card sx={{ backgroundColor: '#f3e5f5' }}>
                  <CardContent>
                    <Typography variant="h6" gutterBottom>
                      Toplam Öğretmen Sayısı
                    </Typography>
                    <Typography variant="h4" color="secondary.main">
                      {teacherCount}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Box>
        );
    }
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBarStyled position="fixed" open={drawerOpen}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer}
            edge="start"
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            Okul Paneli
          </Typography>
        </Toolbar>
      </AppBarStyled>
      <DrawerStyled
        variant={isMobile ? 'temporary' : 'persistent'}
        anchor="left"
        open={drawerOpen}
        onClose={toggleDrawer}
      >
        <Toolbar />
        <Divider />
        <List>
          {menuItems.map((item, index) => (
            <React.Fragment key={index}>
              <ListItemStyled
                as={ListItem}
                button
                selected={selectedMenu === item.text}
                onClick={() => {
                  item.action && item.action();
                  if (isMobile) toggleDrawer();
                }}
              >
                <ListItemIcon sx={{ color: '#fff' }}>{item.icon}</ListItemIcon>
                <ListItemTextStyled as={ListItemText} primary={item.text} />
                {item.subMenus ? (
                  open[item.text] ? (
                    <ExpandLess sx={{ color: '#fff' }} />
                  ) : (
                    <ExpandMore sx={{ color: '#fff' }} />
                  )
                ) : null}
              </ListItemStyled>
              {item.subMenus && (
                <Collapse in={open[item.text]} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {item.subMenus.map((subItem, subIndex) => (
                      <NestedListItem
                        as={ListItem}
                        button
                        key={subIndex}
                        onClick={() => {
                          subItem.action();
                          if (isMobile) toggleDrawer();
                        }}
                      >
                        <ListItemIcon sx={{ color: '#fff' }}>{subItem.icon}</ListItemIcon>
                        <ListItemTextStyled as={ListItemText} primary={subItem.text} />
                      </NestedListItem>
                    ))}
                  </List>
                </Collapse>
              )}
            </React.Fragment>
          ))}
        </List>
      </DrawerStyled>
      <Main open={drawerOpen}>
        <motion.div
          variants={containerVariants}
          initial="hidden"
          animate="visible"
          exit="exit"
          transition={{ duration: 0.5 }}
        >
          {renderContent()}
        </motion.div>
      </Main>
    </Box>
  );
}

export default SchoolDashboard;

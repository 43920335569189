import React, { useState } from 'react';
import {
  Paper,
  Grid,
  Box,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Chip,
  Stack,
  Menu,
  MenuItem as MenuItemMUI
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import * as XLSX from 'xlsx';

const StudentTable = ({
  filteredOgrenciListesi,
  searchTerm,
  setSearchTerm,
  statusFilter,
  setStatusFilter,
  cityFilter,
  setCityFilter,
  ilceFilter,
  setIlceFilter,
  sehirListesi,
  handleEdit,
  handleOpenDeleteDialog,
  handleStatusChange,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleExportClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleExportClose = () => {
    setAnchorEl(null);
  };

  // CSV İndir
  const exportToCSV = () => {
    if (filteredOgrenciListesi.length === 0) return;

    const headers = [
      'No',
      'Ekleyen',
      'Adı',
      'Soyadı',
      'Şifre',
      'Telefon',
      'Okul Adı',
      'Sınıf',
      'Yaş',
      'E-posta',
      'Şehir',
      'İlçe',
      'Durum',
      'Başlangıç Tarihi',
      'Bitiş Tarihi',
      'Paket Süresi (Ay)',
      'Paket Türü'
    ];

    const rows = filteredOgrenciListesi.map((ogrenci, index) => {
      const ekleyen = ogrenci.ekleyenAdi || '';
      const baslangicTarihi = ogrenci.abonelik?.baslangicTarihi
        ? new Date(ogrenci.abonelik.baslangicTarihi).toLocaleDateString()
        : 'N/A';
      const bitisTarihi = ogrenci.abonelik?.bitisTarihi
        ? new Date(ogrenci.abonelik.bitisTarihi).toLocaleDateString()
        : 'N/A';

      return [
        index + 1,
        ekleyen,
        ogrenci.ad,
        ogrenci.soyad,
        ogrenci.password_open || '',
        ogrenci.telefon,
        ogrenci.okul_adi,
        ogrenci.sinif,
        ogrenci.yas,
        ogrenci.email,
        ogrenci.sehirAdi,
        ogrenci.ilceAdi,
        ogrenci.status === 'active' ? 'Aktif' : 'Pasif',
        baslangicTarihi,
        bitisTarihi,
        ogrenci.abonelik?.paketSuresi || 'N/A',
        ogrenci.abonelik?.packageType || 'N/A'
      ];
    });

    const csvContent =
      'data:text/csv;charset=utf-8,' +
      [headers.join(','), ...rows.map((e) => e.join(','))].join('\n');

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.href = encodedUri;
    link.download = 'ogrenci_listesi.csv';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    handleExportClose();
  };

  // TXT İndir
  const exportToTXT = () => {
    if (filteredOgrenciListesi.length === 0) return;

    let txtContent = '';
    txtContent +=
      'No | Ekleyen | Adı | Soyadı | Şifre | Telefon | Okul Adı | Sınıf | Yaş | E-posta | Şehir | İlçe | Durum | Başlangıç Tarihi | Bitiş Tarihi | Paket Süresi (Ay) | Paket Türü\n';

    filteredOgrenciListesi.forEach((ogrenci, index) => {
      const ekleyen = ogrenci.ekleyenAdi || '';
      const baslangicTarihi = ogrenci.abonelik?.baslangicTarihi
        ? new Date(ogrenci.abonelik.baslangicTarihi).toLocaleDateString()
        : 'N/A';
      const bitisTarihi = ogrenci.abonelik?.bitisTarihi
        ? new Date(ogrenci.abonelik.bitisTarihi).toLocaleDateString()
        : 'N/A';

      txtContent += `${index + 1} | ${ekleyen} | ${ogrenci.ad} | ${ogrenci.soyad} | ${
        ogrenci.password_open || ''
      } | ${ogrenci.telefon} | ${ogrenci.okul_adi} | ${ogrenci.sinif} | ${ogrenci.yas} | ${
        ogrenci.email
      } | ${ogrenci.sehirAdi} | ${ogrenci.ilceAdi} | ${
        ogrenci.status === 'active' ? 'Aktif' : 'Pasif'
      } | ${baslangicTarihi} | ${bitisTarihi} | ${
        ogrenci.abonelik?.paketSuresi || 'N/A'
      } | ${ogrenci.abonelik?.packageType || 'N/A'}\n`;
    });

    const blob = new Blob([txtContent], { type: 'text/plain;charset=utf-8' });
    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.download = 'ogrenci_listesi.txt';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    handleExportClose();
  };

  // XLSX İndir (Excel)
  const exportToXLSX = () => {
    if (filteredOgrenciListesi.length === 0) return;

    const headers = [
      'No',
      'Ekleyen',
      'Adı',
      'Soyadı',
      'Şifre',
      'Telefon',
      'Okul Adı',
      'Sınıf',
      'Yaş',
      'E-posta',
      'Şehir',
      'İlçe',
      'Durum',
      'Başlangıç Tarihi',
      'Bitiş Tarihi',
      'Paket Süresi (Ay)',
      'Paket Türü'
    ];

    const wsData = [];
    wsData.push(headers);

    filteredOgrenciListesi.forEach((ogrenci, index) => {
      const ekleyen = ogrenci.ekleyenAdi || '';
      const baslangicTarihi = ogrenci.abonelik?.baslangicTarihi
        ? new Date(ogrenci.abonelik.baslangicTarihi).toLocaleDateString()
        : 'N/A';
      const bitisTarihi = ogrenci.abonelik?.bitisTarihi
        ? new Date(ogrenci.abonelik.bitisTarihi).toLocaleDateString()
        : 'N/A';

      const row = [
        index + 1,
        ekleyen,
        ogrenci.ad,
        ogrenci.soyad,
        ogrenci.password_open || '',
        ogrenci.telefon,
        ogrenci.okul_adi,
        ogrenci.sinif,
        ogrenci.yas,
        ogrenci.email,
        ogrenci.sehirAdi,
        ogrenci.ilceAdi,
        ogrenci.status === 'active' ? 'Aktif' : 'Pasif',
        baslangicTarihi,
        bitisTarihi,
        ogrenci.abonelik?.paketSuresi || 'N/A',
        ogrenci.abonelik?.packageType || 'N/A'
      ];
      wsData.push(row);
    });

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.aoa_to_sheet(wsData);
    XLSX.utils.book_append_sheet(wb, ws, 'Öğrenci Listesi');

    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([wbout], { type: 'application/octet-stream' });

    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'ogrenci_listesi.xlsx';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    handleExportClose();
  };

  return (
    <Paper elevation={3} style={{ padding: 20 }}>
      {/* Filtreleme Alanları */}
      <Box mb={3}>
        <Grid container spacing={2} alignItems="center">
          {/* Arama Çubuğu */}
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              fullWidth
              label="Ara..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              variant="outlined"
            />
          </Grid>

          {/* Durum Filtreleme */}
          <Grid item xs={12} sm={6} md={3}>
            <FormControl fullWidth variant="outlined">
              <InputLabel id="status-filter-label">Durum</InputLabel>
              <Select
                labelId="status-filter-label"
                value={statusFilter}
                onChange={(e) => setStatusFilter(e.target.value)}
                label="Durum"
              >
                <MenuItem value="">
                  <em>Tüm Durumlar</em>
                </MenuItem>
                <MenuItem value="active">Aktif</MenuItem>
                <MenuItem value="inactive">Pasif</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          {/* Şehir Filtreleme */}
          <Grid item xs={12} sm={6} md={3}>
            <FormControl fullWidth variant="outlined">
              <InputLabel id="city-filter-label">Şehir</InputLabel>
              <Select
                labelId="city-filter-label"
                value={cityFilter}
                onChange={(e) => {
                  setCityFilter(e.target.value);
                  setIlceFilter('');
                }}
                label="Şehir"
              >
                <MenuItem value="">
                  <em>Tüm Şehirler</em>
                </MenuItem>
                {sehirListesi.map((sehir) => (
                  <MenuItem key={sehir._id} value={sehir._id}>
                    {sehir.sehir_adi}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {/* İlçe Filtreleme */}
          {cityFilter && (
            <Grid item xs={12} sm={6} md={3}>
              <FormControl fullWidth variant="outlined">
                <InputLabel id="ilce-filter-label">İlçe</InputLabel>
                <Select
                  labelId="ilce-filter-label"
                  value={ilceFilter}
                  onChange={(e) => setIlceFilter(e.target.value)}
                  label="İlçe"
                >
                  <MenuItem value="">
                    <em>Tüm İlçeler</em>
                  </MenuItem>
                  {sehirListesi
                    .find((sehir) => sehir._id === cityFilter)
                    ?.ilceler.map((ilce) => (
                      <MenuItem key={ilce._id} value={ilce._id}>
                        {ilce.ilce_adi}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
          )}

          {/* Filtreleri Sıfırla Butonu */}
          <Grid item xs={12} sm={6} md={3}>
            <Button
              fullWidth
              variant="outlined"
              color="secondary"
              onClick={() => {
                setSearchTerm('');
                setStatusFilter('');
                setCityFilter('');
                setIlceFilter('');
              }}
              sx={{ height: '100%' }}
            >
              Filtreleri Sıfırla
            </Button>
          </Grid>
        </Grid>
      </Box>

      {/* Dışa Aktar Butonu */}
      <Box mb={3}>
        <Button variant="outlined" onClick={handleExportClick}>
          Dışa Aktar
        </Button>
        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleExportClose}>
          <MenuItemMUI onClick={exportToCSV}>CSV Olarak İndir</MenuItemMUI>
          <MenuItemMUI onClick={exportToTXT}>TXT Olarak İndir</MenuItemMUI>
          <MenuItemMUI onClick={exportToXLSX}>Excel (XLSX) Olarak İndir</MenuItemMUI>
        </Menu>
      </Box>

      {/* Öğrenci Tablosu */}
      <TableContainer component={Paper} sx={{ overflowX: 'auto' }}>
        <Table sx={{ minWidth: 1800 }}>
          <TableHead>
            <TableRow>
              <TableCell style={{ minWidth: 50 }}>No</TableCell>
              <TableCell style={{ minWidth: 100 }}>Ekleyen</TableCell>
              <TableCell style={{ minWidth: 100 }}>Adı</TableCell>
              <TableCell style={{ minWidth: 100 }}>Soyadı</TableCell>
              <TableCell style={{ minWidth: 100 }}>Şifre</TableCell>
              <TableCell style={{ minWidth: 100 }}>Telefon</TableCell>
              <TableCell style={{ minWidth: 150 }}>Okul Adı</TableCell>
              <TableCell style={{ minWidth: 80 }}>Sınıfı</TableCell>
              <TableCell style={{ minWidth: 50 }}>Yaş</TableCell>
              <TableCell style={{ minWidth: 150 }}>E-posta</TableCell>
              <TableCell style={{ minWidth: 100 }}>Şehir</TableCell>
              <TableCell style={{ minWidth: 100 }}>İlçe</TableCell>
              <TableCell style={{ minWidth: 80 }}>Durum</TableCell>
              <TableCell style={{ minWidth: 120 }}>Başlangıç Tarihi</TableCell>
              <TableCell style={{ minWidth: 120 }}>Bitiş Tarihi</TableCell>
              <TableCell style={{ minWidth: 150 }}>Paket Süresi (Ay)</TableCell>
              <TableCell style={{ minWidth: 150 }}>Paket Türü</TableCell>
              <TableCell style={{ minWidth: 200 }}>İşlemler</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredOgrenciListesi.map((ogrenci, index) => (
              <TableRow key={ogrenci._id}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>{ogrenci.ekleyenAdi}</TableCell>
                <TableCell>{ogrenci.ad}</TableCell>
                <TableCell>{ogrenci.soyad}</TableCell>
                <TableCell>{ogrenci.password_open}</TableCell>
                <TableCell>{ogrenci.telefon}</TableCell>
                <TableCell>{ogrenci.okul_adi}</TableCell>
                <TableCell>{ogrenci.sinif}</TableCell>
                <TableCell>{ogrenci.yas}</TableCell>
                <TableCell>{ogrenci.email}</TableCell>
                <TableCell>{ogrenci.sehirAdi}</TableCell>
                <TableCell>{ogrenci.ilceAdi}</TableCell>
                <TableCell>
                  <Chip
                    label={ogrenci.status === 'active' ? 'Aktif' : 'Pasif'}
                    color={ogrenci.status === 'active' ? 'success' : 'error'}
                    onClick={() => handleStatusChange(ogrenci._id, ogrenci.status)}
                    sx={{
                      cursor: 'pointer',
                      '&:hover': {
                        backgroundColor: 'lightgray',
                      },
                    }}
                  />
                </TableCell>
                <TableCell>
                  {ogrenci.abonelik?.baslangicTarihi
                    ? new Date(ogrenci.abonelik.baslangicTarihi).toLocaleDateString()
                    : 'N/A'}
                </TableCell>
                <TableCell>
                  {ogrenci.abonelik?.bitisTarihi
                    ? new Date(ogrenci.abonelik.bitisTarihi).toLocaleDateString()
                    : 'N/A'}
                </TableCell>
                <TableCell>{ogrenci.abonelik?.paketSuresi || 'N/A'}</TableCell>
                <TableCell>{ogrenci.abonelik?.packageType || 'N/A'}</TableCell>
                <TableCell>
                  <Stack direction="row" spacing={1}>
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={<EditIcon />}
                      onClick={() => handleEdit(ogrenci._id)}
                      sx={{
                        '&:hover': {
                          backgroundColor: 'lightblue',
                          color: '#fff',
                        },
                      }}
                    >
                      Düzenle
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      startIcon={<DeleteIcon />}
                      onClick={() => handleOpenDeleteDialog(ogrenci._id)}
                    >
                      Sil
                    </Button>
                  </Stack>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default StudentTable;

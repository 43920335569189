// src/components/Ogretmen/OgretmenDuzenleDialog.js
import React from 'react';
import { Dialog, DialogTitle, DialogContent, TextField, FormControl, InputLabel, Select, MenuItem, DialogActions, Button } from '@mui/material';

const OgretmenDuzenleDialog = ({
  open,
  handleClose,
  duzenlenenOgretmen,
  setDuzenlenenOgretmen,
  sehirListesi,
  handleSehirChangeDuzenle,
  handleIlceChangeDuzenle,
  handleGuncelle
}) => {
  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>Öğretmen Düzenle</DialogTitle>
      <DialogContent>
        <TextField
          fullWidth
          label="Adı"
          value={duzenlenenOgretmen.ad}
          onChange={(e) => setDuzenlenenOgretmen({ ...duzenlenenOgretmen, ad: e.target.value })}
          margin="normal"
        />

        <TextField
          fullWidth
          label="Soyadı"
          value={duzenlenenOgretmen.soyad}
          onChange={(e) => setDuzenlenenOgretmen({ ...duzenlenenOgretmen, soyad: e.target.value })}
          margin="normal"
        />

        <TextField
          fullWidth
          label="Okul Adı"
          value={duzenlenenOgretmen.okulAdi}
          onChange={(e) => setDuzenlenenOgretmen({ ...duzenlenenOgretmen, okulAdi: e.target.value })}
          margin="normal"
        />

        <FormControl fullWidth margin="dense">
          <InputLabel id="sehir-select-label-duzenle">Şehir</InputLabel>
          <Select
            labelId="sehir-select-label-duzenle"
            value={duzenlenenOgretmen.sehirId}
            onChange={handleSehirChangeDuzenle}
            displayEmpty
          >
            <MenuItem value="">
              <em>Şehir Seçiniz</em>
            </MenuItem>
            {sehirListesi.map((sehir) => (
              <MenuItem key={sehir._id} value={sehir._id}>
                {sehir.sehir_adi}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {duzenlenenOgretmen.sehirId && (
          <FormControl fullWidth margin="dense">
            <InputLabel id="ilce-select-label-duzenle">İlçe</InputLabel>
            <Select
              labelId="ilce-select-label-duzenle"
              value={duzenlenenOgretmen.ilceId}
              onChange={handleIlceChangeDuzenle}
              displayEmpty
              renderValue={(selected) => {
                if (selected) {
                  const secilenIlce = duzenlenenOgretmen.ilceler.find(ilce => ilce._id === selected);
                  return secilenIlce ? secilenIlce.ilce_adi : 'İlçe bulunamadı';
                }
                return <em>İlçe Seçiniz</em>;
              }}
            >
              {duzenlenenOgretmen.ilceler?.map((ilce) => (
                <MenuItem key={ilce._id} value={ilce._id}>
                  {ilce.ilce_adi}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        <TextField
          fullWidth
          label="Email"
          value={duzenlenenOgretmen.email}
          onChange={(e) => setDuzenlenenOgretmen({ ...duzenlenenOgretmen, email: e.target.value })}
          margin="normal"
        />
        <TextField
          fullWidth
          label="Telefon"
          value={duzenlenenOgretmen.telefon}
          onChange={(e) => setDuzenlenenOgretmen({ ...duzenlenenOgretmen, telefon: e.target.value })}
          margin="normal"
        />

        {/* Durum Seçimi */}
        <FormControl fullWidth margin="normal">
          <InputLabel id="status-select-label-duzenle">Durum</InputLabel>
          <Select
            labelId="status-select-label-duzenle"
            value={duzenlenenOgretmen.status}
            onChange={(e) => setDuzenlenenOgretmen({ ...duzenlenenOgretmen, status: e.target.value })}
          >
            <MenuItem value="active">Aktif</MenuItem>
            <MenuItem value="inactive">Pasif</MenuItem>
          </Select>
        </FormControl>

        {/* Şifre Güncelleme Alanı */}
        <TextField
          fullWidth
          label="Yeni Şifre (isteğe bağlı)"
          type="password"
          value={duzenlenenOgretmen.password}
          onChange={(e) => setDuzenlenenOgretmen({ ...duzenlenenOgretmen, password: e.target.value })}
          margin="normal"
          helperText="Şifreyi güncellemek istiyorsanız buraya yeni şifreyi giriniz."
        />

      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>İptal</Button>
        <Button onClick={handleGuncelle} color="primary" variant="contained">Güncelle</Button>
      </DialogActions>
    </Dialog>
  );
};

export default OgretmenDuzenleDialog;

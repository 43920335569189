import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppBar, Toolbar, IconButton, Typography, Drawer, List, ListItem, ListItemIcon, ListItemText, CssBaseline, Collapse, Divider, Box, useTheme, useMediaQuery, Tooltip, Card, CardContent, Grid, Avatar } from '@mui/material';
import { Menu as MenuIcon, Dashboard as DashboardIcon, School as SchoolIcon, Settings as SettingsIcon, Logout as LogoutIcon, ExpandLess, ExpandMore, Payment as PaymentIcon, Report as ReportIcon,
  LocationCity as CityIcon, Business as BranchIcon, Person as TeacherIcon, Security as SecurityIcon, People as PeopleIcon, FormatListBulleted as FormatListBulletedIcon, LibraryBooks as LibraryBooksIcon,
  AddBox as AddBoxIcon, Assignment as AssignmentIcon, MonetizationOn as MonetizationOnIcon, AccountBalanceWallet as AccountBalanceWalletIcon } from '@mui/icons-material';
import BusinessIcon from '@mui/icons-material/Business';
import { styled } from '@mui/system';
import SehirlerContent from './kurumlar/Sehirler';
import KurumlarContent from './kurumlar/Kurumlar';
import OkullarContent from './kurumlar/Okullar';
import Users from './users/Users';
import BookUploadForm from './ogretmen/kutuphane/Add';
import BookListe from './ogretmen/kutuphane/Liste';
import Tests from './ogretmen/kutuphane/Test';
import Ogretmen from './ogretmen/Ogretmen';
import Ogrenci from './ogrenci/Ogrenci';
import BlogList  from './blog/BlogList';
import BlogAdd  from './blog/BlogAdd';

import RolePermissionManagement from './users/Role';
import PaymentList from './payments/PaymentList';
import PriceManagement from './kampanya/PriceManagement';
import PaymentReports from './payments/PaymentReports';
import BalanceList from './payments/BalanceList';
import { API_URLS } from '../../config/config';
import useFetchUserData from '../../hooks/admin/useFetchUserData';

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: open ? `${drawerWidth}px` : 0,
    [theme.breakpoints.down('sm')]: { marginLeft: 0 },
    marginTop: '64px',
    overflow: 'auto',
    height: `calc(100vh - 64px)`,
  })
);

const AppBarStyled = styled(AppBar, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    backgroundColor: '#4e73df',
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      [theme.breakpoints.up('sm')]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
      },
    }),
  })
);

const DrawerStyled = styled(Drawer)(({ theme }) => ({
  '& .MuiDrawer-paper': {
    width: drawerWidth,
    backgroundColor: '#2e59d9',
    color: '#fff',
  },
}));

function AdminDashboard() {
  const [drawerOpen, setDrawerOpen] = useState(true);
  const [userData, setUserData] = useState(null);
  const [openMenus, setOpenMenus] = useState({});
  const [selectedMenu, setSelectedMenu] = useState('Anasayfa');
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [paymentSummary, setPaymentSummary] = useState(null);

  // Öğretmen için state ve loading
  const [teacherCounts, setTeacherCounts] = useState({ totalOgretmen: 0, activeOgretmen: 0, inactiveOgretmen: 0 });
  const [loadingTeacherCounts, setLoadingTeacherCounts] = useState(true);

  // Öğrenci için state ve loading
  const [studentCounts, setStudentCounts] = useState({ total: 0, active: 0, inactive: 0 });
  const [loadingStudentCounts, setLoadingStudentCounts] = useState(true);

  // Okullar için state ve loading
  const [schoolCounts, setSchoolCounts] = useState({ total: 0, active: 0, inactive: 0 });
  const [loadingSchoolCounts, setLoadingSchoolCounts] = useState(true);

  // Kurumlar için state ve loading
  const [kurumCounts, setKurumCounts] = useState({ total: 0, active: 0, inactive: 0 });
  const [loadingKurumCounts, setLoadingKurumCounts] = useState(true);

  useFetchUserData(setUserData);

  const toggleDrawer = () => setDrawerOpen(!drawerOpen);

  const handleClick = (menu) => setOpenMenus((prevOpen) => ({ ...prevOpen, [menu]: !prevOpen[menu] }));

  const handleLogout = async () => {
    try {
      await fetch(`${API_URLS.ADMIN_URL}logout`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`,
          'Content-Type': 'application/json',
        },
      });
      localStorage.removeItem('userToken');
      navigate('/login');
    } catch (error) {
      console.error('Çıkış işlemi sırasında bir hata oluştu', error);
    }
  };

  const allMenuItems = [
    { text: 'Anasayfa', icon: <DashboardIcon />, action: () => setSelectedMenu('Anasayfa') },
    {
      text: 'Kullanıcılar',
      icon: <PeopleIcon />,
      requiredAccess: 'Kullanıcılar',
      subMenus: [
        { 
          text: 'Kullanıcılar', 
          icon: <PeopleIcon />, 
          parent: 'Kullanıcılar', 
          requiredAccess: 'Kullanıcılar', 
          action: () => setSelectedMenu('Kullanıcılar') 
        },
        { 
          text: 'Roller ve Yetkiler', 
          icon: <SecurityIcon />, 
          parent: 'Kullanıcılar', 
          requiredAccess: 'Roller ve Yetkiler', 
          action: () => setSelectedMenu('Roller') 
        },
      ],
      action: () => handleClick('Kullanıcılar'),
    },
    {
      text: 'Kurumlar',
      icon: <SchoolIcon />,
      requiredAccess: 'Kurumlar',
      subMenus: [
        { text: 'Şehirler', icon: <CityIcon />, parent: 'Kurumlar', requiredAccess: 'Şehirler', action: () => setSelectedMenu('Şehirler') },
        { text: 'Bayiler', icon: <BranchIcon />, parent: 'Kurumlar', requiredAccess: 'Bayiler', action: () => setSelectedMenu('Bayiler') },
        { text: 'Kurum', icon: <BranchIcon />, parent: 'Kurumlar', requiredAccess: 'Okullar', action: () => setSelectedMenu('Okullar') },
      ],
      action: () => handleClick('Kurumlar'),
    },
    {
      text: 'Kütüphane',
      icon: <LibraryBooksIcon />,
      requiredAccess: 'Kütüphane',
      subMenus: [
        { text: 'Kitap Ekle', icon: <AddBoxIcon />, parent: 'Kütüphane', requiredAccess: 'Kitap Ekle', action: () => setSelectedMenu('KitapEkle') },
        { text: 'Tüm Kitap Listesi', icon: <FormatListBulletedIcon />, parent: 'Kütüphane', requiredAccess: 'Tüm Kitap Listesi', action: () => setSelectedMenu('KitapListe') },
        { text: 'Testler', icon: <AssignmentIcon />, parent: 'Kütüphane', requiredAccess: 'Testler', action: () => setSelectedMenu('Testler') },
      ],
      action: () => handleClick('Kütüphane'),
    },
    { text: 'Öğretmenler', icon: <TeacherIcon />, requiredAccess: 'Öğretmenler', action: () => setSelectedMenu('Öğretmenler') },
    { text: 'Öğrenciler', icon: <PeopleIcon />, requiredAccess: 'Öğrenciler', action: () => setSelectedMenu('Öğrenciler') },
    {
      text: 'Blog',
      icon: <LibraryBooksIcon />,
      requiredAccess: 'Blog', 
      subMenus: [
        { text: 'Blog Listesi', icon: <FormatListBulletedIcon />, parent: 'Blog', requiredAccess: 'Blog', action: () => setSelectedMenu('BlogList') },
        { text: 'Yeni Blog Ekle', icon: <AddBoxIcon />, parent: 'Blog', requiredAccess: 'Blog', action: () => setSelectedMenu('BlogAdd') },
      ],
      action: () => handleClick('Blog'),
    },

    { text: 'Ödemeler', icon: <PaymentIcon />, requiredAccess: 'Ödemeler', action: () => setSelectedMenu('Ödemeler') },
    { text: 'Kampanyalar', icon: <PaymentIcon />, requiredAccess: 'Kampanyalar', action: () => setSelectedMenu('Kampanyalar') },
    {
      text: 'Raporlar',
      icon: <ReportIcon />,
      requiredAccess: 'Raporlar',
      subMenus: [
        { text: 'Ödeme Raporları', icon: <MonetizationOnIcon />, parent: 'Raporlar', requiredAccess: 'Ödeme Raporları', action: () => setSelectedMenu('Ödeme Raporları') },
        { text: 'Bakiyeler', icon: <AccountBalanceWalletIcon />, parent: 'Raporlar', requiredAccess: 'Bakiyeler', action: () => setSelectedMenu('Bakiyeler') },
      ],
      action: () => handleClick('Raporlar'),
    },
    { text: 'Ayarlar', icon: <SettingsIcon />, requiredAccess: 'Ayarlar', action: () => setSelectedMenu('Ayarlar') },
    { text: 'Çıkış Yap', icon: <LogoutIcon />, action: handleLogout },
  ];

  const menuItems = allMenuItems.filter(item => {
    if (!item.requiredAccess) return true;
    return userData && userData.accessControls && userData.accessControls.includes(item.requiredAccess);
  }).map(item => {
    if (item.subMenus) {
      const filteredSubMenus = item.subMenus.filter(subItem => {
        if (!subItem.requiredAccess) return true;
        return userData && userData.accessControls && userData.accessControls.includes(subItem.requiredAccess);
      });
      return { ...item, subMenus: filteredSubMenus };
    }
    return item;
  });

  // Para Birimi Formatter'ı
  const formatter = new Intl.NumberFormat('tr-TR', {
    style: 'currency',
    currency: 'TRY',
    minimumFractionDigits: 2,
  });

  const renderAktifPasif = (aktifSayi, pasifSayi) => (
    <Box mt={2} display="flex" justifyContent="space-around" width="100%">
      <Box textAlign="center">
        <Typography
          variant="h6"
          sx={{
            color: '#fff',
            backgroundColor: '#28a745',
            borderRadius: '8px',
            padding: '4px 8px',
          }}
        >
          Aktif
        </Typography>
        <Typography
          variant="h5"
          sx={{
            color: '#fff',
            backgroundColor: '#28a745',
            borderRadius: '8px',
            padding: '4px 8px',
            marginTop: '4px'
          }}
        >
          {aktifSayi}
        </Typography>
      </Box>
      <Box textAlign="center">
        <Typography
          variant="h6"
          sx={{
            color: '#fff',
            backgroundColor: '#dc3545',
            borderRadius: '8px',
            padding: '4px 8px',
          }}
        >
          Pasif
        </Typography>
        <Typography
          variant="h5"
          sx={{
            color: '#fff',
            backgroundColor: '#dc3545',
            borderRadius: '8px',
            padding: '4px 8px',
            marginTop: '4px'
          }}
        >
          {pasifSayi}
        </Typography>
      </Box>
    </Box>
  );

  const renderContent = () => {
    if (!userData) return <Typography paragraph>Yükleniyor...</Typography>;

    switch (selectedMenu) {
      case 'Anasayfa':
        return (
          <>
            <Typography variant="h4" gutterBottom>
              Dashboard
            </Typography>
            <Typography paragraph>
              Rolleriniz: {userData.roles.map(role => role.name).join(', ')} - Yetkili Olduğunuz Modüller: {userData.accessControls.join(', ')}
            </Typography>
            <Grid container spacing={3} sx={{ mt: 2 }}>
              {/* Günlük Toplam Ödeme Kartı */}
              <Grid item xs={12} sm={6} md={4}>
                <Card
                  sx={{
                    boxShadow: 5,
                    borderRadius: 3,
                    background: 'linear-gradient(45deg, #FFC312, #EE5A24)',
                    color: '#fff',
                    position: 'relative',
                    overflow: 'hidden',
                    '&:hover': {
                      transform: 'scale(1.05)',
                      boxShadow: 8,
                    },
                  }}
                >
                  <CardContent>
                    <Box display="flex" alignItems="center">
                      <Avatar sx={{ bgcolor: 'transparent' }}>
                        <PaymentIcon sx={{ fontSize: 40, color: '#fff' }} />
                      </Avatar>
                      <Typography variant="h6" sx={{ ml: 2 }}>
                        Günlük Toplam Ödeme
                      </Typography>
                    </Box>
                    <Typography variant="h4" sx={{ mt: 2 }}>
                      {paymentSummary ? `${formatter.format(paymentSummary.dailyTotal)}` : 'Yükleniyor...'}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>

              {/* Haftalık Toplam Ödeme Kartı */}
              <Grid item xs={12} sm={6} md={4}>
                <Card
                  sx={{
                    boxShadow: 5,
                    borderRadius: 3,
                    background: 'linear-gradient(45deg, #12CBC4, #1289A7)',
                    color: '#fff',
                    position: 'relative',
                    overflow: 'hidden',
                    '&:hover': {
                      transform: 'scale(1.05)',
                      boxShadow: 8,
                    },
                  }}
                >
                  <CardContent>
                    <Box display="flex" alignItems="center">
                      <Avatar sx={{ bgcolor: 'transparent' }}>
                        <PaymentIcon sx={{ fontSize: 40, color: '#fff' }} />
                      </Avatar>
                      <Typography variant="h6" sx={{ ml: 2 }}>
                        Haftalık Toplam Ödeme
                      </Typography>
                    </Box>
                    <Typography variant="h4" sx={{ mt: 2 }}>
                      {paymentSummary ? `${formatter.format(paymentSummary.weeklyTotal)}` : 'Yükleniyor...'}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>

              {/* Aylık Toplam Ödeme Kartı */}
              <Grid item xs={12} sm={6} md={4}>
                <Card
                  sx={{
                    boxShadow: 5,
                    borderRadius: 3,
                    background: 'linear-gradient(45deg, #C4E538, #A3CB38)',
                    color: '#fff',
                    position: 'relative',
                    overflow: 'hidden',
                    '&:hover': {
                      transform: 'scale(1.05)',
                      boxShadow: 8,
                    },
                  }}
                >
                  <CardContent>
                    <Box display="flex" alignItems="center">
                      <Avatar sx={{ bgcolor: 'transparent' }}>
                        <PaymentIcon sx={{ fontSize: 40, color: '#fff' }} />
                      </Avatar>
                      <Typography variant="h6" sx={{ ml: 2 }}>
                        Aylık Toplam Ödeme
                      </Typography>
                    </Box>
                    <Typography variant="h4" sx={{ mt: 2 }}>
                      {paymentSummary ? `${formatter.format(paymentSummary.monthlyTotal)}` : 'Yükleniyor...'}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>

              {/* Öğrenci Kartı */}
              <Grid item xs={12} sm={6} md={4}>
                <Card
                  sx={{
                    boxShadow: 5,
                    borderRadius: 3,
                    background: 'linear-gradient(45deg, #FDA7DF, #D980FA)',
                    color: '#fff',
                    position: 'relative',
                    overflow: 'hidden',
                    '&:hover': {
                      transform: 'scale(1.05)',
                      boxShadow: 8,
                    },
                  }}
                >
                  <CardContent>
                    <Box display="flex" flexDirection="column" alignItems="center">
                      <Avatar sx={{ bgcolor: 'transparent', width: 56, height: 56 }}>
                        <PeopleIcon sx={{ fontSize: 40, color: '#fff' }} />
                      </Avatar>
                      <Typography variant="h6" sx={{ mt: 2 }}>
                        Toplam Öğrenci
                      </Typography>
                      <Typography variant="h4" sx={{ mt: 1 }}>
                        {loadingStudentCounts ? 'Yükleniyor...' : studentCounts.total}
                      </Typography>
                      {!loadingStudentCounts && renderAktifPasif(studentCounts.active, studentCounts.inactive)}
                    </Box>
                  </CardContent>
                </Card>
              </Grid>

              {/* Okul Kartı */}
              <Grid item xs={12} sm={6} md={4}>
                <Card
                  sx={{
                    boxShadow: 5,
                    borderRadius: 3,
                    background: 'linear-gradient(45deg, #ED4C67, #B53471)',
                    color: '#fff',
                    position: 'relative',
                    overflow: 'hidden',
                    '&:hover': {
                      transform: 'scale(1.05)',
                      boxShadow: 8,
                    },
                  }}
                >
                  <CardContent>
                    <Box display="flex" flexDirection="column" alignItems="center">
                      <Avatar sx={{ bgcolor: 'transparent', width: 56, height: 56 }}>
                        <SchoolIcon sx={{ fontSize: 40, color: '#fff' }} />
                      </Avatar>
                      <Typography variant="h6" sx={{ mt: 2 }}>
                        Toplam Okul
                      </Typography>
                      <Typography variant="h4" sx={{ mt: 1 }}>
                        {loadingSchoolCounts ? 'Yükleniyor...' : schoolCounts.total}
                      </Typography>
                      {!loadingSchoolCounts && renderAktifPasif(schoolCounts.active, schoolCounts.inactive)}
                    </Box>
                  </CardContent>
                </Card>
              </Grid>

              {/* Kurum Kartı */}
              <Grid item xs={12} sm={6} md={4}>
                <Card
                  sx={{
                    boxShadow: 5,
                    borderRadius: 3,
                    background: 'linear-gradient(45deg, #F79F1F, #EE5A24)',
                    color: '#fff',
                    position: 'relative',
                    overflow: 'hidden',
                    '&:hover': {
                      transform: 'scale(1.05)',
                      boxShadow: 8,
                    },
                  }}
                >
                  <CardContent>
                    <Box display="flex" flexDirection="column" alignItems="center">
                      <Avatar sx={{ bgcolor: 'transparent', width: 56, height: 56 }}>
                        <BusinessIcon sx={{ fontSize: 40, color: '#fff' }} />
                      </Avatar>
                      <Typography variant="h6" sx={{ mt: 2 }}>
                        Toplam Bayi
                      </Typography>
                      <Typography variant="h4" sx={{ mt: 1 }}>
                        {loadingKurumCounts ? 'Yükleniyor...' : kurumCounts.total}
                      </Typography>
                      {!loadingKurumCounts && renderAktifPasif(kurumCounts.active, kurumCounts.inactive)}
                    </Box>
                  </CardContent>
                </Card>
              </Grid>

              {/* Öğretmen Kartı */}
              <Grid item xs={12} sm={6} md={4}>
                <Card
                  sx={{
                    boxShadow: 5,
                    borderRadius: 3,
                    background: 'linear-gradient(45deg, #1B1464, #5758BB)',
                    color: '#fff',
                    position: 'relative',
                    overflow: 'hidden',
                    '&:hover': {
                      transform: 'scale(1.05)',
                      boxShadow: 8,
                    },
                  }}
                >
                  <CardContent>
                    <Box display="flex" flexDirection="column" alignItems="center">
                      <Avatar sx={{ bgcolor: 'transparent', width: 56, height: 56 }}>
                        <TeacherIcon sx={{ fontSize: 40, color: '#fff' }} />
                      </Avatar>
                      <Typography variant="h6" sx={{ mt: 2 }}>
                        Toplam Öğretmen
                      </Typography>
                      <Typography variant="h4" sx={{ mt: 1 }}>
                        {loadingTeacherCounts ? 'Yükleniyor...' : teacherCounts.totalOgretmen}
                      </Typography>
                      {!loadingTeacherCounts && renderAktifPasif(teacherCounts.activeOgretmen, teacherCounts.inactiveOgretmen)}
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </>
        );
      case 'Şehirler':
        return userData.accessControls.includes('Şehirler') ? <SehirlerContent /> : <Typography paragraph>Erişim izniniz yok.</Typography>;
      case 'Bayiler':
        return userData.accessControls.includes('Bayiler') ? <KurumlarContent /> : <Typography paragraph>Erişim izniniz yok.</Typography>;
      case 'Okullar':
        return userData.accessControls.includes('Okullar') ? <OkullarContent /> : <Typography paragraph>Erişim izniniz yok.</Typography>;
      case 'Kullanıcılar':
        return userData.accessControls.includes('Kullanıcılar') ? <Users /> : <Typography paragraph>Erişim izniniz yok.</Typography>;
      case 'Roller':
        return userData.accessControls.includes('Roller ve Yetkiler') ? <RolePermissionManagement /> : <Typography paragraph>Erişim izniniz yok.</Typography>;
      case 'KitapEkle':
        return userData.accessControls.includes('Kitap Ekle') ? <BookUploadForm /> : <Typography paragraph>Erişim izniniz yok.</Typography>;
      case 'KitapListe':
        return userData.accessControls.includes('Tüm Kitap Listesi') ? <BookListe /> : <Typography paragraph>Erişim izniniz yok.</Typography>;
      case 'Testler':
        return userData.accessControls.includes('Testler') ? <Tests /> : <Typography paragraph>Erişim izniniz yok.</Typography>;
      case 'Öğretmenler':
        return userData.accessControls.includes('Öğretmenler') ? <Ogretmen /> : <Typography paragraph>Erişim izniniz yok.</Typography>;
      case 'Öğrenciler':
        return userData.accessControls.includes('Öğrenciler') ? <Ogrenci /> : <Typography paragraph>Erişim izniniz yok.</Typography>;
      case 'BlogList':
        return userData.accessControls.includes('Blog') ? <BlogList /> : <Typography paragraph>Erişim izniniz yok.</Typography>;
      case 'BlogAdd':
        return userData.accessControls.includes('Blog') ? <BlogAdd /> : <Typography paragraph>Erişim izniniz yok.</Typography>;
      case 'Ödemeler':
        return userData.accessControls.includes('Ödemeler') ? <PaymentList /> : <Typography paragraph>Erişim izniniz yok.</Typography>;
      case 'Kampanyalar':
        return userData.accessControls.includes('Kampanyalar') ? <PriceManagement /> : <Typography paragraph>Erişim izniniz yok.</Typography>;
      case 'Ödeme Raporları':
        return userData.accessControls.includes('Ödeme Raporları') ? <PaymentReports /> : <Typography paragraph>Erişim izniniz yok.</Typography>;
      case 'Bakiyeler':
        return userData.accessControls.includes('Bakiyeler') ? <BalanceList /> : <Typography paragraph>Erişim izniniz yok.</Typography>;
      default:
        return <Typography paragraph>Anasayfa</Typography>;
    }
  };

  // Öğretmen sayıları
  useEffect(() => {
    const fetchTeacherCounts = async () => {
      try {
        const response = await fetch(`${API_URLS.ADMIN_URL}teachers/count`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('userToken')}`,
            'Content-Type': 'application/json',
          },
        });
        const data = await response.json();
        if (response.ok) {
          setTeacherCounts({
            totalOgretmen: data.totalOgretmen,
            activeOgretmen: data.activeOgretmen,
            inactiveOgretmen: data.inactiveOgretmen,
          });
        } else {
          console.error('Öğretmen sayıları alınamadı:', data.message);
        }
      } catch (error) {
        console.error('Öğretmen sayıları alınırken bir hata oluştu:', error);
      } finally {
        setLoadingTeacherCounts(false);
      }
    };

    fetchTeacherCounts();
  }, []);

  // Öğrenci sayıları
  useEffect(() => {
    const fetchStudents = async () => {
      try {
        const response = await fetch(`${API_URLS.ADMIN_URL}student/total`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('userToken')}`,
            'Content-Type': 'application/json',
          },
        });
        const data = await response.json();
        if (response.ok) {
          setStudentCounts({
            total: data.total,
            active: data.active,
            inactive: data.inactive,
          });
        } else {
          console.error('Toplam öğrenci alınamadı:', data.message);
        }
      } catch (error) {
        console.error('Toplam öğrenci alınırken bir hata oluştu:', error);
      } finally {
        setLoadingStudentCounts(false);
      }
    };
    fetchStudents();
  }, []);

  // Okul sayıları
  useEffect(() => {
    const fetchSchools = async () => {
      try {
        const response = await fetch(`${API_URLS.ADMIN_URL}schools/total`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('userToken')}`,
            'Content-Type': 'application/json',
          },
        });
        const data = await response.json();
        if (response.ok) {
          setSchoolCounts({
            total: data.total,
            active: data.active,
            inactive: data.inactive,
          });
        } else {
          console.error('Toplam okul sayısı alınamadı:', data.message);
        }
      } catch (error) {
        console.error('Toplam okul sayısı alınırken bir hata oluştu:', error);
      } finally {
        setLoadingSchoolCounts(false);
      }
    };
    fetchSchools();
  }, []);

  // Kurum sayıları
  useEffect(() => {
    const fetchKurums = async () => {
      try {
        const response = await fetch(`${API_URLS.ADMIN_URL}kurumsal/total`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('userToken')}`,
            'Content-Type': 'application/json',
          },
        });
        const data = await response.json();
        if (response.ok) {
          setKurumCounts({
            total: data.total,
            active: data.active,
            inactive: data.inactive,
          });
        } else {
          console.error('Toplam kurum sayısı alınamadı:', data.message);
        }
      } catch (error) {
        console.error('Toplam kurum sayısı alınırken bir hata oluştu:', error);
      } finally {
        setLoadingKurumCounts(false);
      }
    };
    fetchKurums();
  }, []);

  // Ödeme özetleri
  useEffect(() => {
    const fetchPayments = async () => {
      try {
        const response = await fetch(`${API_URLS.ADMIN_URL}payments/summary`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('userToken')}`,
          },
        });
        const data = await response.json();
        if (response.ok) {
          setPaymentSummary(data);
        } else {
          console.error('Toplam ödemeler alınamadı:', data.message);
        }
      } catch (error) {
        console.error('Toplam ödemeler alınırken bir hata oluştu:', error);
      }
    };

    fetchPayments();
  }, []);

  useEffect(() => {
    if (selectedMenu) {
      menuItems.forEach((item) => {
        if (item.subMenus) {
          item.subMenus.forEach((subItem) => {
            if (subItem.text === selectedMenu && subItem.parent) {
              setOpenMenus((prevOpen) => ({ ...prevOpen, [subItem.parent]: true }));
            }
          });
        }
      });
    }
  }, [selectedMenu, menuItems]);

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBarStyled position="fixed" open={drawerOpen}>
        <Toolbar>
          <IconButton color="inherit" aria-label="open drawer" onClick={toggleDrawer} edge="start" sx={{ mr: 2 }}>
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            {userData ? `Hoş Geldiniz ${userData.roles.map(role => role.name).join(', ')} - ${userData.fullname}` : 'Yükleniyor...'}
          </Typography>
        </Toolbar>
      </AppBarStyled>
      <DrawerStyled variant={isMobile ? 'temporary' : 'persistent'} anchor="left" open={drawerOpen} onClose={toggleDrawer}>
        <Toolbar />
        <Divider />
        <List>
          {menuItems.map((item, index) => (
            <React.Fragment key={index}>
              <ListItem 
                button 
                onClick={() => item.action && item.action()} 
                selected={item.text === selectedMenu}
                sx={{
                  backgroundColor: item.subMenus && item.subMenus.length > 0 ? 'rgba(255, 255, 255, 0.1)' : 'inherit',
                  '&.Mui-selected': {
                    backgroundColor: 'rgba(255, 255, 255, 0.2)',
                  },
                  '&:hover': {
                    backgroundColor: 'rgba(255, 255, 255, 0.2)',
                  },
                }}
              >
                <ListItemIcon sx={{ color: '#fff' }}>
                  <Tooltip title={item.text} placement="right" disableHoverListener={!isMobile}>
                    {item.icon}
                  </Tooltip>
                </ListItemIcon>
                <ListItemText primary={item.text} />
                {item.subMenus && item.subMenus.length > 0 && (openMenus[item.text] ? <ExpandLess /> : <ExpandMore />)}
              </ListItem>
              {item.subMenus && item.subMenus.length > 0 && (
                <Collapse in={openMenus[item.text]} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {item.subMenus.map((subItem, subIndex) => (
                      <ListItem 
                        button 
                        key={subIndex} 
                        onClick={subItem.action}
                        selected={subItem.text === selectedMenu}
                        sx={{ 
                          pl: 4, 
                          '&.Mui-selected': { backgroundColor: 'rgba(255, 255, 255, 0.2)' },
                          '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.1)' } 
                        }}
                      >
                        <ListItemIcon sx={{ color: '#fff' }}>
                          <Tooltip title={subItem.text} placement="right" disableHoverListener={!isMobile}>
                            {subItem.icon}
                          </Tooltip>
                        </ListItemIcon>
                        <ListItemText primary={subItem.text} />
                      </ListItem>
                    ))}
                  </List>
                </Collapse>
              )}
            </React.Fragment>
          ))}
        </List>
      </DrawerStyled>
      <Main open={drawerOpen}>{renderContent()}</Main>
    </Box>
  );
}

export default AdminDashboard;

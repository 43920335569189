// GameDisplay.js
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faPause, faRedo } from '@fortawesome/free-solid-svg-icons';
import parse, { domToReact } from 'html-react-parser';
import '../styles/kayanYazilarIkiserStyle.css';
import ProgressBarIkiser from './ProgressBarIkiser';

const GameDisplay = ({
  isPlaying,
  paragraphsList,
  selectedParagraphIndex,
  displayedWords,
  handlePlayPause,
  handleReset,
  message,
  gameOver,
  timeLeft,
  gameTime
}) => {
  // Kendiliğinden kapanan HTML etiketlerini tanımlıyoruz
  const voidElements = new Set([
    'area',
    'base',
    'br',
    'col',
    'embed',
    'hr',
    'img',
    'input',
    'keygen',
    'link',
    'meta',
    'param',
    'source',
    'track',
    'wbr',
  ]);

  const options = {
    replace: (node) => {
      if (node.type === 'tag') {
        if (voidElements.has(node.name)) {
          return React.createElement(
            node.name,
            { key: node.name, ...node.attribs },
            null
          );
        } else {
          return React.createElement(
            node.name,
            { key: node.name, ...node.attribs },
            domToReact(node.children, options)
          );
        }
      }
    },
  };

  // currentParagraph'i kontrol et
  const currentParagraphValue = (paragraphsList && paragraphsList[selectedParagraphIndex]) || '';
  const currentParagraph = typeof currentParagraphValue === 'string' ? currentParagraphValue : '';

  // Paragrafı kelimelere ayır
  const allWords = currentParagraph.trim().length > 0
    ? currentParagraph.trim().split(/\s+/)
    : [];

  // Zaman bazlı ilerleme hesabı
  const progress = gameTime > 0 ? (timeLeft / gameTime) * 100 : 0;

  return (
    <>
      <div className="kayan-yazilar-ikiser-controls">
        <button
          onClick={handlePlayPause}
          disabled={gameOver}
          className="kayan-yazilar-ikiser-button"
        >
          <FontAwesomeIcon icon={isPlaying ? faPause : faPlay} />{' '}
          {isPlaying ? 'Duraklat' : 'Başlat'}
        </button>
        <button onClick={handleReset} className="kayan-yazilar-ikiser-button">
          <FontAwesomeIcon icon={faRedo} /> Yeniden Başlat
        </button>
      </div>
      
      {/* Zaman bazlı ilerleme çubuğu */}
      <ProgressBarIkiser progress={progress} />

      <div className="kayan-yazilar-ikiser-game-display">
        {!paragraphsList || !paragraphsList[selectedParagraphIndex] ? (
          <div className="kayan-yazilar-ikiser-message">
            Paragraf bulunamadı. Lütfen farklı bir paragraf veya zorluk seviyesi seçin.
          </div>
        ) : (
          <div className="kayan-yazilar-ikiser-content">
            {parse(displayedWords.join(' '), options)}
          </div>
        )}
      </div>
      {message && (
        <div className="kayan-yazilar-ikiser-message">
          {message}
        </div>
      )}
    </>
  );
};

export default GameDisplay;

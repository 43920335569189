import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, FormControl, InputLabel, Select, MenuItem } from '@mui/material';

const TeacherEditDialog = ({ 
  open, 
  onClose, 
  duzenlenenOgretmen, 
  setDuzenlenenOgretmen, 
  sehirListesi, 
  onGuncelle 
}) => {

  const handleSehirChangeDuzenle = (event) => {
    const yeniSehirId = event.target.value; 
    const secilenSehir = sehirListesi.find(sehir => sehir._id === yeniSehirId);
    const ilceler = secilenSehir ? secilenSehir.ilceler : [];
    setDuzenlenenOgretmen(prev => ({ 
      ...prev, 
      sehirId: yeniSehirId, 
      ilceId: '', 
      ilceler: ilceler 
    }));
  };

  const handleIlceChangeDuzenle = (event) => {
    const yeniIlceId = event.target.value; 
    setDuzenlenenOgretmen(prev => ({ ...prev, ilceId: yeniIlceId }));
  };

  const handleGuncelleClick = () => {
    onGuncelle(duzenlenenOgretmen);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Öğretmen Düzenle</DialogTitle>
      <DialogContent>
        <TextField
          fullWidth
          label="Adı"
          value={duzenlenenOgretmen.ad}
          onChange={(e) => setDuzenlenenOgretmen({...duzenlenenOgretmen, ad: e.target.value})}
          margin="normal"
        />
        <TextField
          fullWidth
          label="Soyadı"
          value={duzenlenenOgretmen.soyad}
          onChange={(e) => setDuzenlenenOgretmen({...duzenlenenOgretmen, soyad: e.target.value})}
          margin="normal"
        />
        <TextField
          fullWidth
          label="Okul Adı"
          value={duzenlenenOgretmen.okulAdi}
          onChange={(e) => setDuzenlenenOgretmen({...duzenlenenOgretmen, okulAdi: e.target.value})}
          margin="normal"
        />
        <FormControl fullWidth margin="dense">
          <InputLabel id="sehir-select-label-duzenle">Şehir</InputLabel>
          <Select
            labelId="sehir-select-label-duzenle"
            id="sehir-select-duzenle"
            value={duzenlenenOgretmen.sehirId}
            onChange={handleSehirChangeDuzenle}
            displayEmpty 
          >
            <MenuItem value="">
              <em>Şehir Seçiniz</em>
            </MenuItem>
            {sehirListesi.map((sehir) => (
              <MenuItem key={sehir._id} value={sehir._id}>
                {sehir.sehir_adi}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {duzenlenenOgretmen.sehirId && (
          <FormControl fullWidth margin="dense">
            <InputLabel id="ilce-select-label-duzenle">İlçe</InputLabel>
            <Select
              labelId="ilce-select-label-duzenle"
              id="ilce-select-duzenle"
              value={duzenlenenOgretmen.ilceId} 
              onChange={handleIlceChangeDuzenle}
              displayEmpty
            >          
              {duzenlenenOgretmen.ilceler?.map((ilce) => ( 
                <MenuItem key={ilce._id} value={ilce._id}>
                  {ilce.ilce_adi}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        <TextField
          fullWidth
          label="Email"
          value={duzenlenenOgretmen.email}
          onChange={(e) => setDuzenlenenOgretmen({...duzenlenenOgretmen, email: e.target.value})}
          margin="normal"
        />
        <TextField
          fullWidth
          label="Telefon"
          value={duzenlenenOgretmen.telefon}
          onChange={(e) => setDuzenlenenOgretmen({...duzenlenenOgretmen, telefon: e.target.value})}
          margin="normal"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>İptal</Button>
        <Button onClick={handleGuncelleClick} color="primary" variant="contained">Güncelle</Button> 
      </DialogActions>
    </Dialog>
  );
};

export default TeacherEditDialog;

import React from 'react';
import { Typography, Button, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper, IconButton,} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { API_URLS } from '../../../../config/config';

const PermissionsTable = ({
  permissions,
  setPermissionDialogOpen,
  setEditingPermission,
  showResultDialog,
  triggerRefetch,
}) => {
  const handleEditPermissionClick = (permission) => {
    setEditingPermission(permission);
    setPermissionDialogOpen(true);
  };

  const handleDeletePermission = async (permissionId) => {
    const isConfirmed = window.confirm('Bu yetkiyi silmek istediğinize emin misiniz?');
    if (!isConfirmed) {
      return;
    }

    try {
      const response = await fetch(`${API_URLS.ADMIN_URL}roles/permissions/${permissionId}`, {
        method: 'DELETE',
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Yetki silinirken bir hata oluştu.');
      }

      triggerRefetch();
    } catch (error) {
      console.error(error);
      showResultDialog(error.message, 'error');
    }
  };

  return (
    <>
      <Typography variant="h6">Yetkiler</Typography>
      <Button
        startIcon={<AddIcon />}
        variant="contained"
        color="secondary"
        onClick={() => {
          setEditingPermission(null);
          setPermissionDialogOpen(true);
        }}
      >
        Yetki Ekle
      </Button>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Yetki Adı</TableCell>
              <TableCell>Açıklama</TableCell>
              <TableCell align="right">İşlemler</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {permissions.map((permission) => (
              <TableRow key={permission._id}>
                <TableCell>{permission.name}</TableCell>
                <TableCell>{permission.description}</TableCell>
                <TableCell align="right">
                  <IconButton color="primary" onClick={() => handleEditPermissionClick(permission)}>
                    <EditIcon />
                  </IconButton>
                  <IconButton color="secondary" onClick={() => handleDeletePermission(permission._id)}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default PermissionsTable;

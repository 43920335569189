// SpeedSliderAndScore.js
import React from 'react';

const SpeedSliderAndScore = ({ score }) => {
  return (
    <div className="score-container">
      <strong>Skor: {Math.floor(score)}</strong>
    </div>
  );
};

export default SpeedSliderAndScore;

import React from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlay,
  faPause,
  faRedo,
} from '@fortawesome/free-solid-svg-icons';
import '../styles/zigzagTrackerStyle.css';

const GameOptions = ({
  isPlaying,
  setIsPlaying,
  resetGame,
  orientation,
  setOrientation,
  difficulty,
  handleDifficultyChange,
}) => {

  return (
    <div className="zigzag-game-controls">
      <button onClick={() => setIsPlaying(!isPlaying)}>
        <FontAwesomeIcon icon={isPlaying ? faPause : faPlay} />{' '}
        {isPlaying ? 'Duraklat' : 'Başlat'}
      </button>
      <button onClick={resetGame}>
        <FontAwesomeIcon icon={faRedo} /> Sıfırla
      </button>

      <FormControl variant="filled" className="zigzag-select">
        <InputLabel id="orientation-select-label">Yön</InputLabel>
        <Select
          labelId="orientation-select-label"
          id="orientation-select"
          value={orientation}
          onChange={(e) => setOrientation(e.target.value)}
          style={{ color: '#fff' }}
          MenuProps={{
            classes: { paper: 'zigzag-menu-paper' },
          }}
        >
          <MenuItem value="horizontal">Yatay</MenuItem>
          <MenuItem value="vertical">Dikey</MenuItem>
        </Select>
      </FormControl>

      <FormControl variant="filled" className="zigzag-select">
        <InputLabel id="difficulty-select-label">Zorluk</InputLabel>
        <Select
          labelId="difficulty-select-label"
          id="difficulty-select"
          value={difficulty}
          onChange={handleDifficultyChange}
          style={{ color: '#fff' }}
          MenuProps={{
            classes: { paper: 'zigzag-menu-paper' },
          }}
        >
          <MenuItem value="seciniz">Zorluk Seviyesi Seçiniz</MenuItem>
          <MenuItem value="easy">Kolay</MenuItem>
          <MenuItem value="normal">Normal</MenuItem>
          <MenuItem value="hard">Zor</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
};

export default GameOptions;

import React, { useState, useEffect } from 'react';
import { Button, Typography, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Grid, TextField, Dialog, DialogTitle, DialogContent, DialogActions,
  FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import ResultDialog from '../../../pages/components/Auth/Dialog';

import Stack from '@mui/material/Stack';
import { API_URLS } from '../../../config/config';
import useFetchUserData from '../../../hooks/kurum/useFetchUserData';

const Ogrenci = () => {
  const [ogrenciListesi, setOgrenciListesi] = useState([]);
  const [sehirListesi, setSehirListesi] = useState([]);
  const [secilenSehirId, setSecilenSehirId] = useState('');
  const [secilenIlceId, setSecilenIlceId] = useState('');
  const [shouldFetch, setShouldFetch] = useState(true);
  const [ad, setAd] = useState('');
  const [soyad, setSoyad] = useState('');
  const [okulAdi, setOkulAdi] = useState('');
  const [telefon, setTelefon] = useState('');
  const [telefonHata, setTelefonHata] = useState(false);
  const [email, setEmail] = useState('');
  const [sifre, setSifre] = useState('');
  const [sinif, setSinif] = useState('');
  const [yas, setYas] = useState('');
  const [userData, setUserData] = useState(null);
  const [dialogContent, setDialogContent] = useState('');
  const [dialogSeverity, setDialogSeverity] = useState('info');
  const [dialogOpen, setDialogOpen] = useState(false);
  const [resultDialogOpen, setResultDialogOpen] = useState(false);
  const [resultDialogMessage, setResultDialogMessage] = useState('');
  const [resultDialogSeverity, setResultDialogSeverity] = useState('info');
  const [eklemeBasariliDialogOpen, setEklemeBasariliDialogOpen] = useState(false);
  const [duzenlemeDialogAcik, setDuzenlemeDialogAcik] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedPackageDuration, setSelectedPackageDuration] = useState('');
  const [filterSinif, setFilterSinif] = useState('');
  const [filterSehirId, setFilterSehirId] = useState('');
  const [filterPaketSuresi, setFilterPaketSuresi] = useState('');

useFetchUserData(setUserData);

const [duzenlenenOgrenci, setDuzenlenenOgrenci] = useState({
  ad:'',
  soyad:'',
  okulAdi: '',
  email: '',
  telefon: '',
  sehirId: '',
  ilceId: '', 
  ilceler: [],
});

const fetchIlceler = async (sehirId, seciliIlceId) => {
  try {
    const response = await fetch(`${API_URLS.ADMIN_URL}sehirler/ilceler/${sehirId}`);
    if (!response.ok) throw new Error('İlçeler yüklenirken bir hata oluştu.');
    const data = await response.json(); 
    const ilceler = data.ilceler ? data.ilceler : []; 

    setDuzenlenenOgrenci(prev => ({ 
      ...prev, 
      ilceler: ilceler,
   
      ilceId: seciliIlceId && ilceler.some(ilce => ilce._id === seciliIlceId) ? seciliIlceId : prev.ilceId 
    }));
  } catch (error) {
    console.error('İlçeleri yüklerken bir hata oluştu:', error);
  }
}; 

const fetchOgrenciListesi = async () => {
  if (!userData || !userData._id) return;
  
  try {
    const response = await fetch(`${API_URLS.KURUMSAL_URL}student/${userData._id}`);
    if (!response.ok) {
      throw new Error('Öğrenci listesi yüklenirken bir hata oluştu.');
    }
    const data = await response.json();
    console.log("Sunucudan gelen veri:", data);

    const enhancedData = data.map(ogrenci => {
      const sehir = sehirListesi.find(s => s._id === ogrenci.sehir);
      const ilce = sehir?.ilceler.find(i => i._id === ogrenci.ilce);

      return {
        ...ogrenci,
        sehirAdi: sehir ? sehir.sehir_adi : 'Bulunamadı',
        ilceAdi: ilce ? ilce.ilce_adi : 'Bulunamadı',
        sinif: ogrenci.sinif?.toString() || '',
        yas: ogrenci.yas?.toString() || '',
      };
    });

    setOgrenciListesi(enhancedData);
  } catch (error) {
    console.error(error);
  }
};

useEffect(() => {
  const fetchSehirler = async () => {
    if (!shouldFetch) return;

    try {
      const response = await fetch(API_URLS.ADMIN_URL + 'sehirler');
      if (!response.ok) {
        throw new Error('Veri çekme işlemi başarısız');
      }
      const sehirler = await response.json();
      setSehirListesi(sehirler);
    } catch (err) {
      console.error('Şehirler yüklenirken bir hata oluştu:', err.message);
    } finally {
      setShouldFetch(false);
    }
  };

  fetchSehirler();
}, [shouldFetch]);

useEffect(() => {
  if (userData && userData._id) {
    fetchOgrenciListesi();
  }
}, [userData, sehirListesi]);


  const handleDialogClose = () => {
    setDialogOpen(false);
    setResultDialogOpen(false); 
  };
 
  const handleTelefonChange = (event) => {
    const telefonRegex = /^0(\d{3}) (\d{3}) (\d{4})$/;
    let { value } = event.target;
    const numbers = value.replace(/\D/g, '');  
    value = numbers.substring(0, 4) + (numbers.length > 4 ? ' ' : '') + numbers.substring(4, 7) + (numbers.length > 7 ? ' ' : '') + numbers.substring(7, 11); 
    setTelefonHata(!telefonRegex.test(value) && value !== '');
    setTelefon(value);
  }; 

  const handleSehirChange = (event) => {
    setSecilenSehirId(event.target.value);
  };  

  const handleIlceChange = (event) => {
    setSecilenIlceId(event.target.value);
  };

  const handleSehirChangeDuzenle = (event) => {
    const yeniSehirId = event.target.value; 
    setDuzenlenenOgrenci(prev => ({ ...prev, sehirId: yeniSehirId }));  
    const secilenSehir = sehirListesi.find(sehir => sehir._id === yeniSehirId);
    const ilceler = secilenSehir ? secilenSehir.ilceler : [];  
    setDuzenlenenOgrenci(prev => ({ ...prev, ilceId: '', ilceler: ilceler }));
  };

  const handleIlceChangeDuzenle = (event) => {
    const yeniIlceId = event.target.value; 
    setDuzenlenenOgrenci(prev => ({ ...prev, ilceId: yeniIlceId }));
  };

  const secilenSehirinIlceleri = sehirListesi.find(sehir => sehir._id === secilenSehirId)?.ilceler || [];

  const handleEkle = async () => {
    let eksikAlanlar = [];

    if (!ad) eksikAlanlar.push('Adı');
    if (!soyad) eksikAlanlar.push('Soyadı');
    if (!email) eksikAlanlar.push('Email');
    if (!sifre) eksikAlanlar.push('Şifre');
    if (!telefon) eksikAlanlar.push('Telefon');
    if (!secilenSehirId) eksikAlanlar.push('Şehir');
    if (!secilenIlceId) eksikAlanlar.push('İlçe');
    if (!sinif) eksikAlanlar.push('Sınıf');
    if (!yas) eksikAlanlar.push('Yaş');
    if (!selectedPackageDuration) eksikAlanlar.push('Paket Süresi');

    if (eksikAlanlar.length > 0) {
      setDialogSeverity('error');
      setDialogContent(`Lütfen ${eksikAlanlar.join(', ')} alanlarını doldurunuz.`);
      setEklemeBasariliDialogOpen(true);
      return;
    }

    if (telefonHata) {
      setDialogSeverity('error');
      setDialogContent('Lütfen geçerli bir telefon numarası giriniz.');
      setEklemeBasariliDialogOpen(true);
      return;
    }

    try {
      const response = await fetch(API_URLS.KURUMSAL_URL + 'student', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ad: ad,
          soyad: soyad,
          email: email,
          password: sifre,
          password_open: sifre,
          okul_adi: okulAdi,
          telefon: telefon,
          sehir: secilenSehirId,
          ilce: secilenIlceId,
          sinif: sinif,
          yas: yas,
          kurumId: userData?._id,
          selectedPackageDuration: selectedPackageDuration,
        }),
      });

      const responseData = await response.json();
      if (response.ok) {
        setOgrenciListesi([...ogrenciListesi, responseData]);

        setDialogSeverity('success');
        setDialogContent('Yeni Öğrenci başarıyla eklendi.');
        setEklemeBasariliDialogOpen(true);
        setAd('');
        setSoyad('');
        setOkulAdi('');
        setEmail('');
        setSifre('');
        setTelefon('');
        setSecilenSehirId('');
        setSecilenIlceId('');
        setSinif('');
        setYas('');
        setSelectedPackageDuration('');
      } else {
        throw new Error(responseData.message || 'Bir hata oluştu');
      }
    } catch (err) {
      setDialogSeverity('error');
      setDialogContent(err.message);
      setEklemeBasariliDialogOpen(true);
    }
  };
  
  const handleEdit = (ogrenciId) => {
    const seciliOgrenci = ogrenciListesi.find((ogr) => ogr._id === ogrenciId);
  
    if (seciliOgrenci) {
      setDuzenlenenOgrenci({
        _id: seciliOgrenci._id,
        ad: seciliOgrenci.ad,
        soyad: seciliOgrenci.soyad,
        okulAdi: seciliOgrenci.okul_adi,
        email: seciliOgrenci.email,
        telefon: seciliOgrenci.telefon,
        sehirId: seciliOgrenci.sehir,
        ilceId: seciliOgrenci.ilce,
        sinif: seciliOgrenci.sinif,
        yas: seciliOgrenci.yas,
        ilceler: []
      });
      fetchIlceler(seciliOgrenci.sehir, seciliOgrenci.ilce);
      setDuzenlemeDialogAcik(true);
    } else {
      console.error('Seçilen öğrenci bulunamadı.');
    }
  };

  const handleGuncelle = async () => {
    const { _id, ad, soyad, okulAdi, email, telefon, sehirId, ilceId, sinif, yas } = duzenlenenOgrenci;
    
    try {
      const response = await fetch(`${API_URLS.KURUMSAL_URL}student/${_id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ad,
          soyad,
          okul_adi: okulAdi,
          email,
          telefon,
          sehir: sehirId,
          ilce: ilceId,
          sinif,
          yas,
        }),
      });
  
      const data = await response.json();
      if (!response.ok) throw new Error(data.message || 'Güncelleme sırasında bir hata oluştu');
  
      setDialogSeverity('success');
      setDialogContent('Öğrenci başarıyla güncellendi.');
      setDialogOpen(true);
      setDuzenlemeDialogAcik(false);
  
      // Öğrenci listesini yeniden yükle
      await fetchOgrenciListesi();
    } catch (err) {
      console.error('Güncelleme hatası:', err);
      setDialogSeverity('error');
      setDialogContent(err.message);
      setDialogOpen(true);
    }
  };

  const handleDeleteOgrenci = async (ogrenciId) => {
    try {
      const response = await fetch(`${API_URLS.KURUMSAL_URL}student/${ogrenciId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      if (!response.ok) throw new Error('Öğretmen silinirken bir hata oluştu.');  
     
      setOgrenciListesi(ogrenciListesi.filter((ogretmen) => ogretmen._id !== ogrenciId));
  
      setResultDialogMessage('Admin başarıyla silindi.');
      setResultDialogSeverity('success');
      setResultDialogOpen(true);
    } catch (error) {
      setResultDialogMessage(error.message);
      setResultDialogSeverity('error');
      setResultDialogOpen(true);
    }
  };

  // Filtreleme fonksiyonu
  const filteredOgrenciListesi = ogrenciListesi.filter((ogrenci) => {
    const searchTermLower = searchTerm.toLowerCase();
    const abonelik = ogrenci.abonelik;
    const paketSuresi = abonelik?.paketSuresi;

    const matchesSearchTerm =
      ogrenci.ad?.toLowerCase().includes(searchTermLower) ||
      ogrenci.soyad?.toLowerCase().includes(searchTermLower) ||
      ogrenci.email?.toLowerCase().includes(searchTermLower) ||
      ogrenci.okul_adi?.toLowerCase().includes(searchTermLower) ||
      ogrenci.telefon?.includes(searchTerm) ||
      ogrenci.sinif?.toString().toLowerCase().includes(searchTermLower) ||
      ogrenci.yas?.toString().toLowerCase().includes(searchTermLower) ||
      ogrenci.sehirAdi?.toLowerCase().includes(searchTermLower) ||
      ogrenci.ilceAdi?.toLowerCase().includes(searchTermLower);

    const matchesSinifFilter = filterSinif ? ogrenci.sinif === filterSinif : true;
    const matchesSehirFilter = filterSehirId ? ogrenci.sehir === filterSehirId : true;
    const matchesPackageFilter = filterPaketSuresi ? paketSuresi == filterPaketSuresi : true;

    return matchesSearchTerm && matchesSinifFilter && matchesSehirFilter && matchesPackageFilter;
  });

  const renderDuzenlemeFormuDialog = () => (
    <Dialog open={duzenlemeDialogAcik} onClose={() => setDuzenlemeDialogAcik(false)}>
      <DialogTitle>Öğrenci Düzenle</DialogTitle>
      <DialogContent>
        <TextField
          fullWidth
          label="Adı"
          value={duzenlenenOgrenci.ad}
          onChange={(e) => setDuzenlenenOgrenci({...duzenlenenOgrenci, ad: e.target.value})}
          margin="normal"
        />
        <TextField
          fullWidth
          label="Soyadı"
          value={duzenlenenOgrenci.soyad}
          onChange={(e) => setDuzenlenenOgrenci({...duzenlenenOgrenci, soyad: e.target.value})}
          margin="normal"
        />
        <TextField
          fullWidth
          label="Okul Adı"
          value={duzenlenenOgrenci.okulAdi}
          onChange={(e) => setDuzenlenenOgrenci({...duzenlenenOgrenci, okulAdi: e.target.value})}
          margin="normal"
        />
        <TextField
          fullWidth
          label="Sınıf"
          value={duzenlenenOgrenci.sinif}
          onChange={(e) => setDuzenlenenOgrenci({...duzenlenenOgrenci, sinif: e.target.value})}
          margin="normal"
        />
        <TextField
          fullWidth
          label="Yaş"
          value={duzenlenenOgrenci.yas}
          onChange={(e) => setDuzenlenenOgrenci({...duzenlenenOgrenci, yas: e.target.value})}
          margin="normal"
        />
        <FormControl fullWidth margin="dense">
          <InputLabel id="sehir-select-label-duzenle">Şehir</InputLabel>
          <Select
            labelId="sehir-select-label-duzenle"
            id="sehir-select-duzenle"
            value={duzenlenenOgrenci.sehirId}
            onChange={handleSehirChangeDuzenle}
            displayEmpty 
          >
            <MenuItem value="">
              <em>Şehir Seçiniz</em>
            </MenuItem>
            {sehirListesi.map((sehir) => (
              <MenuItem key={sehir._id} value={sehir._id}>
                {sehir.sehir_adi}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {duzenlenenOgrenci.sehirId && (
          <FormControl fullWidth margin="dense">
            <InputLabel id="ilce-select-label-duzenle">İlçe</InputLabel>
            <Select
              labelId="ilce-select-label-duzenle"
              id="ilce-select-duzenle"
              value={duzenlenenOgrenci.ilceId} 
              onChange={handleIlceChangeDuzenle}
              displayEmpty
            >
              <MenuItem value="">
                <em>İlçe Seçiniz</em>
              </MenuItem>
              {duzenlenenOgrenci.ilceler.map((ilce) => (
                <MenuItem key={ilce._id} value={ilce._id}>
                  {ilce.ilce_adi}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        <TextField
          fullWidth
          label="Email"
          value={duzenlenenOgrenci.email}
          onChange={(e) => setDuzenlenenOgrenci({...duzenlenenOgrenci, email: e.target.value})}
          margin="normal"
        />
        <TextField
          fullWidth
          label="Telefon"
          value={duzenlenenOgrenci.telefon}
          onChange={(e) => setDuzenlenenOgrenci({...duzenlenenOgrenci, telefon: e.target.value})}
          margin="normal"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setDuzenlemeDialogAcik(false)}>İptal</Button>
        <Button onClick={handleGuncelle} color="primary" variant="contained">
          Güncelle
        </Button>
      </DialogActions>
    </Dialog>
  );  

return (
    <Paper style={{ padding: 20 }}>

<Grid container spacing={3}>     
        <Grid item xs={12} md={3}>
        <Typography variant="h5" component="h3" marginBottom={2}>
            Öğrenci Kayıt Formu
          </Typography>
          
      <FormControl fullWidth margin="normal">
            <InputLabel id="package-select-label">Paket Süresi</InputLabel>
            <Select
              labelId="package-select-label"
              value={selectedPackageDuration}
              onChange={(e) => setSelectedPackageDuration(e.target.value)}
            >
              <MenuItem value="">
                <em>Paket Süresi Seçiniz</em>
              </MenuItem>
              <MenuItem value={3}>3 Aylık</MenuItem>
              <MenuItem value={6}>6 Aylık</MenuItem>
              <MenuItem value={12}>12 Aylık</MenuItem>
            </Select>
          </FormControl>
          <TextField fullWidth label="Adı" value={ad} onChange={(e) => setAd(e.target.value)} margin="normal" />
          <TextField fullWidth label="Soyadı" value={soyad} onChange={(e) => setSoyad(e.target.value)} margin="normal" /> 
          <TextField fullWidth label="Okul Adı (isteğe bağlı)" value={okulAdi} onChange={(e) => setOkulAdi(e.target.value)} margin="normal" /> 
          <TextField
            fullWidth
            label="Sınıf"
            value={sinif}
            onChange={(e) => setSinif(e.target.value)}
            margin="normal"
          />
          <TextField
            fullWidth
            label="Yaş"
            value={yas}
            onChange={(e) => setYas(e.target.value)}
            margin="normal"
          />
          <FormControl fullWidth margin="normal" >
          <InputLabel id="sehir-select-label">Şehir</InputLabel>
          <Select
            labelId="sehir-select-label"
            value={secilenSehirId}
            onChange={handleSehirChange}
            displayEmpty
            renderValue={(selected) => {
              if (selected === "") {
                return <em>Şehir Seçiniz</em>;
              }             
              const secilenSehir = sehirListesi.find(sehir => sehir._id === selected);
              return secilenSehir ? secilenSehir.sehir_adi : <em>Şehir Seçiniz</em>;
            }}
          >
            <MenuItem disabled value="">
              <em>Şehir Seçiniz</em>
            </MenuItem>
            {sehirListesi.map((sehir) => (
              <MenuItem key={sehir._id} value={sehir._id}>
                {sehir.sehir_adi}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {secilenSehirId && (
        <FormControl fullWidth margin="normal">
          <InputLabel id="ilce-select-label">İlçe</InputLabel>
          <Select
            labelId="ilce-select-label"
            id="ilce-select"
            value={secilenIlceId}
            onChange={handleIlceChange}
            displayEmpty
            renderValue={(selected) => {
              if (!selected) {
                return <em>İlçe Seçiniz</em>;
              }
              const secilenIlce = secilenSehirinIlceleri.find(ilce => ilce._id === selected);
              return secilenIlce ? secilenIlce.ilce_adi : <em>İlçe Seçiniz</em>;
            }}
          >
            <MenuItem disabled value="">
              <em>İlçe Seçiniz</em>
            </MenuItem>
            {secilenSehirinIlceleri.map((ilce) => (
              <MenuItem key={ilce._id} value={ilce._id}>
                {ilce.ilce_adi}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )} 

      <TextField
          fullWidth
          label="Telefon"
          value={telefon}
          onChange={handleTelefonChange}
          error={telefonHata}
          helperText={telefonHata ? 'Telefon numarası 05XX XXX XXXX formatında olmalıdır.' : ''}
          margin="normal"
        />

        <TextField fullWidth label="Email" value={email} onChange={(e) => setEmail(e.target.value)} margin="normal" />
          <TextField fullWidth label="Şifre" value={sifre} onChange={(e) => setSifre(e.target.value)} margin="normal" type="password" />
          <Button onClick={handleEkle} variant="contained" color="primary">
            Ekle
          </Button>
       
        </Grid> 

        <Grid item xs={12} md={9}>

           {/* Arama ve Filtreleme Alanları */}
           <Grid container spacing={2} style={{ marginBottom: 16 }}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Ara"
                variant="outlined"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl fullWidth>
                <InputLabel id="filter-sinif-select-label">Sınıf Filtrele</InputLabel>
                <Select
                  labelId="filter-sinif-select-label"
                  value={filterSinif}
                  onChange={(e) => setFilterSinif(e.target.value)}
                >
                  <MenuItem value="">
                    <em>Tümü</em>
                  </MenuItem>
                  {Array.from(new Set(ogrenciListesi.map((ogr) => ogr.sinif)))
                    .filter((sinif) => sinif)
                    .map((sinif) => (
                      <MenuItem key={sinif} value={sinif}>
                        {sinif}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl fullWidth>
                <InputLabel id="filter-sehir-select-label">Şehir Filtrele</InputLabel>
                <Select
                  labelId="filter-sehir-select-label"
                  value={filterSehirId}
                  onChange={(e) => setFilterSehirId(e.target.value)}
                >
                  <MenuItem value="">
                    <em>Tümü</em>
                  </MenuItem>
                  {sehirListesi.map((sehir) => (
                    <MenuItem key={sehir._id} value={sehir._id}>
                      {sehir.sehir_adi}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl fullWidth>
                <InputLabel id="filter-package-select-label">Paket Süresi Filtrele</InputLabel>
                <Select
                  labelId="filter-package-select-label"
                  value={filterPaketSuresi}
                  onChange={(e) => setFilterPaketSuresi(e.target.value)}
                >
                  <MenuItem value="">
                    <em>Tümü</em>
                  </MenuItem>
                  <MenuItem value={3}>3 Aylık</MenuItem>
                  <MenuItem value={6}>6 Aylık</MenuItem>
                  <MenuItem value={12}>12 Aylık</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          {/* Öğrenci Tablosu */}
          <TableContainer component={Paper}>     
            <Table>
           
              <TableHead>
                <TableRow>
                <TableCell>Adı</TableCell>
                <TableCell>Soyadı</TableCell>
                <TableCell>Şifre</TableCell>
                <TableCell>Telefon</TableCell>
                <TableCell>Okul Adı</TableCell>
                <TableCell>Sınıfı</TableCell>
                <TableCell>Yaş</TableCell>
                <TableCell>E-posta</TableCell>
                <TableCell>Şehir</TableCell>
                <TableCell>İlçe</TableCell>
                <TableCell>Paket Süresi</TableCell>
                <TableCell>Başlangıç Tarihi</TableCell>
                <TableCell>Bitiş Tarihi</TableCell>               
                 <TableCell>İşlemler</TableCell>
                </TableRow>
              </TableHead>   
              <TableBody>
              {filteredOgrenciListesi.map((ogrenci) => {
                  const abonelik = ogrenci.abonelik;
                  const paketSuresi = abonelik?.paketSuresi || '-';
                  const baslangicTarihi = abonelik?.baslangicTarihi
                    ? new Date(abonelik.baslangicTarihi).toLocaleDateString()
                    : '-';
                  const bitisTarihi = abonelik?.bitisTarihi
                    ? new Date(abonelik.bitisTarihi).toLocaleDateString()
                    : '-';
                return (
                  <TableRow key={ogrenci._id}>
                <TableCell>{ogrenci.ad}</TableCell>
                <TableCell>{ogrenci.soyad}</TableCell>
                <TableCell>{ogrenci.password_open}</TableCell>
                <TableCell>{ogrenci.telefon}</TableCell>
                <TableCell>{ogrenci.okul_adi}</TableCell>
                <TableCell>{ogrenci.sinif}</TableCell>
                <TableCell>{ogrenci.yas}</TableCell>
                <TableCell>{ogrenci.email}</TableCell>
                <TableCell>{ogrenci.sehirAdi}</TableCell>
               <TableCell>{ogrenci.ilceAdi}</TableCell>
               <TableCell>{paketSuresi} Ay</TableCell>
               <TableCell>{baslangicTarihi}</TableCell>
               <TableCell>{bitisTarihi}</TableCell>
                <TableCell align="right">

                <Stack direction="row" spacing={1}>

                   {/*
                    <Button

                        variant="contained"
                        color="primary"
                        startIcon={<EditIcon />}
                        onClick={() => handleEdit(ogrenci._id)}
                        sx={{
                          mr: 1, 
                          '&:hover': {
                            backgroundColor: 'lightblue', 
                            color: '#fff', 
                          }
                        }}

                    >
                        Düzenle
                    </Button>
                    <Button


                        variant="contained"
                        color="secondary"
                        startIcon={<DeleteIcon />}
                        onClick={() => handleDeleteOgrenci(ogrenci._id)}

                    >
                        Sil
                    </Button>
                    */}


                      </Stack>
                    </TableCell>
                 </TableRow>

                );

              })
            }

              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        </Grid>
        <>
          <ResultDialog
            open={eklemeBasariliDialogOpen}
            onClose={() => setEklemeBasariliDialogOpen(false)}
            title={dialogSeverity === 'error' ? "Hata Oluştu" : "İşlem Başarılı"}
            message={dialogContent}
            severity={dialogSeverity}
          />
        <ResultDialog
        open={resultDialogOpen}
        onClose={handleDialogClose}
        title={"İşlem Sonucu"}
        message={resultDialogMessage}
        severity={resultDialogSeverity}
      />

{renderDuzenlemeFormuDialog()}
      </>
</Paper>

);
}

export default Ogrenci;